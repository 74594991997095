import React, { useState, useEffect, useReducer } from "react";
import { Container, Grid, Paper } from "@material-ui/core";
import BasicTable from "../../components/common/table/BasicTable";
import ConfirmDialog from "../../components/common/dialog/ConfirmationDialog";
import {
  EquipmentClient,
  EquipmentCreateVM,
  EquipmentUpdateVM,
  EquipmentDocumentVM,
  DocumentFileUpdateVM,
  EquipmentDocumentUpdateVM,
  EquipmentNoteVM,
  EquipmentNoteCreateVM,
  ManufacturerClient,
  EquipmentTypeClient,
  CustomerClient,
  LocationClient,
} from "../../brines-refrigerator-api";
import EquipmentCRUDForm from "./EquipmentCRUDForm";
import "./Equipment.scss";
import Delete from "@material-ui/icons/Delete";
import RestoreIcon from "@material-ui/icons/Restore";
import {
  EquipmentDocumentAddVM,
  UploadFileClient,
} from "../../brines-refrigerator-api-extended";
import UserRole from "../../helpers/constants/userRole";
import { redirectIfSessionExpired } from "../../components/common/redirect/RedirectOnSessionTimeout";
import { useHistory } from "react-router-dom";
import EquipmentForm from "./EquipmentForm";
import LaunchIcon from "@material-ui/icons/Launch";
import { downloadFile } from "../../helpers/download";
import { useSnackbar } from "notistack";

const Equipment: React.FC<{}> = () => {
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const customerClient = new CustomerClient();

  // Get user data from session storage
  const userData: any = JSON.parse(sessionStorage.getItem("userData") || "{}");

  const [equipment, setEquipment] = useState([]);
  const [manufacturerList, setManufacturerList] = useState([]);
  const [equipmentTypeList, setEquipmentTypeList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [, setOpen] = useState(false);
  const [tableIsLoading, setTableLoading] = useState(true);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      equipment: {
        id: 0,
        manufacturer: "",
        model: "",
        type: "",
        serialNumber: "",
        roomLocation: "",
        notes: [],
        customer_name: { id: 0, company: "" },
        customer_location: { id: 0, name: "" },
      },
      formTitle: "ADD EQUIPMENT",
      formAction: addNewEquipment,
    }
  );

  const equipmentClient = new EquipmentClient();

  const columns = [
    { title: "#", field: "id", width: 5 },
    { title: "Name", field: "equipmentType" },
    { title: "Model", field: "model" },
  ];

  const getEquipmentList = async () => {
    try {
      const equipmentList = await equipmentClient.getAll();
      setEquipment(equipmentList);
      setTableLoading(false);
    } catch (error) {
      redirectIfSessionExpired(history, error);
      enqueueSnackbar("Error while getting equipment.", { variant: "error" });
    }
  };
  const getManufacturerList = async () => {
    try {
      const manufacturerClient = new ManufacturerClient();
      const manufacturerList = await manufacturerClient.get();
      setManufacturerList(manufacturerList);
    } catch (error) {
      redirectIfSessionExpired(history, error);
      enqueueSnackbar("Error while getting manufacturers.", {
        variant: "error",
      });
    }
  };
  const getEquipmentTypeList = async () => {
    try {
      const equipmentTypeClient = new EquipmentTypeClient();
      const equipmentTypeList = await equipmentTypeClient.get();
      setEquipmentTypeList(equipmentTypeList);
    } catch (error) {
      redirectIfSessionExpired(history, error);
      enqueueSnackbar("Error while getting equipment type.", {
        variant: "error",
      });
    }
  };
  const getCustomerList = async () => {
    try {
      const customerList = await customerClient.get();
      setCustomerList(customerList);
    } catch (error) {
      redirectIfSessionExpired(history, error);
      enqueueSnackbar("Error while getting customers.", { variant: "error" });
    }
  };
  const getLocationList = async () => {
    try {
      const locationClient = new LocationClient();
      const locationList = await locationClient.get();
      setLocationList(locationList);
    } catch (error) {
      redirectIfSessionExpired(history, error);
      enqueueSnackbar("Error while getting locations.", { variant: "error" });
    }
  };

  useEffect(() => {
    const fetchDataAsync = async () => {
      await getEquipmentList();
      getManufacturerList();
      getEquipmentTypeList();
      getCustomerList();
      getLocationList();
    };
    fetchDataAsync();
  }, []);

  interface StateFromCRUDForm {
    id: number;
    manufacturer: string;
    model: string;
    serialNumber: string;
    unitNumber: string;
    qrAssetCode: string;
    roomLocation: string;
    type: string;
    notes: [{ createdBy: string; created: string; text: string }];
    customer_name: { id: number; company: string };
    customer_location: { id: number; name: string };
  }

  const uploadDraftDocuments = async (
    id: number,
    draftDocuments: EquipmentDocumentAddVM[]
  ) => {
    try {
      const uploadClient = new UploadFileClient();
      for (const document of draftDocuments) {
        document.equipmentId = id;
        document.name = `${document.name}.${document.file.name
          .split(".")
          .pop()}`;
        await uploadClient.uploadEquipmentDocument(document);
      }
    } catch (error) {
      redirectIfSessionExpired(history, error);
      enqueueSnackbar("Error while uploading document.", { variant: "error" });
    }
  };

  const deleteDocuments = async (
    documentsToBeDeleted: EquipmentDocumentVM[]
  ) => {
    try {
      for (const document of documentsToBeDeleted) {
        await equipmentClient.deleteDocument(document.documentFileId);
      }
    } catch (error) {
      redirectIfSessionExpired(history, error);
      enqueueSnackbar("Error while deleting document.", { variant: "error" });
    }
  };

  const downloadDocument = async (id: number) => {
    const response = await equipmentClient.preview(id);
    await downloadFile(response.data, response.fileName);
  };

  async function addNewEquipment(
    e: React.FormEvent<HTMLFormElement>,
    state: StateFromCRUDForm,
    draftDocuments: EquipmentDocumentAddVM[],
    _1,
    _2,
    draftNotes: EquipmentNoteVM[]
  ) {
    e.preventDefault();
    const {
      manufacturer,
      model,
      serialNumber,
      unitNumber,
      qrAssetCode,
      type,
      customer_location,
      roomLocation,
    } = state;

    const equipment = new EquipmentCreateVM({
      model: model,
      serialNumber: serialNumber,
      unitNumber: unitNumber,
      qrAssetCode: qrAssetCode,
      equipmentType: type,
      locationId: customer_location.id,
      manufacturer: manufacturer,
      roomLocation,
    });

    setTableLoading(true);

    try {
      const newEquipment = await equipmentClient.create(equipment);
      await uploadDraftDocuments(newEquipment.id, draftDocuments);
      await updateNotes(newEquipment.id, draftNotes);
      setOpen(true);
      clearFields();
      getEquipmentList();
      setTableLoading(false);
      enqueueSnackbar("New equipment added successfuly.", {
        variant: "success",
      });
    } catch (error) {
      redirectIfSessionExpired(history, error);
      setOpen(true);
      enqueueSnackbar("Error while adding equipment.", { variant: "error" });
    }
  }

  async function editEquipment(
    e: React.FormEvent<HTMLFormElement>,
    state: StateFromCRUDForm,
    draftDocuments: EquipmentDocumentAddVM[],
    documentsToBeDeleted: EquipmentDocumentVM[],
    documents: EquipmentDocumentVM[],
    draftNotes: EquipmentNoteVM[]
  ) {
    e.preventDefault();

    const {
      id,
      manufacturer,
      model,
      serialNumber,
      type,
      customer_location,
      roomLocation,
    } = state;

    const equipment = new EquipmentUpdateVM({
      id,
      manufacturer: manufacturer,
      model: model,
      equipmentType: type,
      serialNumber,
      locationId: customer_location.id,
      roomLocation,
      documents: documents.map(
        (d) =>
          new EquipmentDocumentUpdateVM({
            documentFileId: d.documentFileId,
            equipmentId: id,
            documentFile: new DocumentFileUpdateVM({
              id: d.documentFileId,
              isConfidential: d.documentFile.isConfidential,
            }),
          })
      ),
    });

    setTableLoading(true);
    try {
      await equipmentClient.update(equipment);
      await deleteDocuments(documentsToBeDeleted);
      await uploadDraftDocuments(equipment.id, draftDocuments);
      await updateNotes(equipment.id, draftNotes);
      setOpen(true);
      enqueueSnackbar("Equipment edited successfuly.", { variant: "success" });
      clearFields();
      getEquipmentList();
    } catch (error) {
      redirectIfSessionExpired(history, error);
      setOpen(true);
      enqueueSnackbar("Error while editing equipment.", { variant: "error" });
    }
    setTableLoading(false);
  }

  function clearFields() {
    setState({
      ...state,
      equipment: {
        id: 0,
        manufacturer: "",
        model: "",
        type: "",
        serialNumber: "",
        qrAssetCode: "",
        unitNumber: "",
        roomLocation: "",
        notes: [],
        customer_name: { id: 0, company: "" },
        customer_location: { id: 0, name: "" },
      },
      formTitle: "ADD EQUIPMENT",
      formAction: addNewEquipment,
    });
    setDocuments([]);
    setDocumentsToBeDeleted([]);
    setDraftDocuments([]);
    setDraftNotes([]);
  }

  async function setEquipmentForEditing(equipmentid: number) {
    const equipment = await equipmentClient.get(equipmentid);
    const customer = customerList.find(
      (c) => c.id == equipment.location.customerId
    );
    const {
      id,
      manufacturer,
      model,
      equipmentType,
      serialNumber,
      unitNumber,
      qrAssetCode,
      location,
      notes,
      roomLocation,
    } = equipment;
    setState({
      ...state,
      formTitle: "EDIT EQUIPMENT",
      formAction: editEquipment,
      equipment: {
        id,
        manufacturer,
        model,
        type: equipmentType,
        serialNumber: serialNumber,
        unitNumber: unitNumber,
        qrAssetCode: qrAssetCode,
        roomLocation,
        notes,
        customer_name: { id: customer.id, company: customer.company },
        customer_location: { id: location.id, name: location.name },
      },
    });
    setDocuments(equipment.documents);
    setDraftNotes([]);
    setDraftDocuments([]);
  }

  async function setEquipmentForDisplay(equipmentid: number) {
    const equipment = await equipmentClient.get(equipmentid);
    const customer = customerList.find(
      (c) => c.id == equipment.location.customerId
    );
    const {
      id,
      manufacturer,
      model,
      equipmentType,
      serialNumber,
      unitNumber,
      qrAssetCode,
      location,
      notes,
      roomLocation,
    } = equipment;
    setState({
      ...state,
      formTitle: "VIEW EQUIPMENT",
      formAction: setEquipmentForEditing,
      equipment: {
        id,
        manufacturer,
        model,
        type: equipmentType,
        serialNumber: serialNumber,
        unitNumber: unitNumber,
        qrAssetCode: qrAssetCode,
        roomLocation,
        notes,
        customer_name: { id: customer.id, company: customer.company },
        customer_location: { id: location.id, name: location.name },
      },
    });
    setDocuments(equipment.documents);
    setDraftNotes([]);
    setDraftDocuments([]);
  }

  async function deleteEquipment(id: number) {
    try {
      await equipmentClient.toggleArchivedStatus(id);
      getEquipmentList();
    } catch (error) {
      redirectIfSessionExpired(history, error);
      enqueueSnackbar("Error while deleting equipment.", { variant: "error" });
    }
  }

  const [confirm, setConfirmOpen] = useState(false);
  const [idForDelete, setidForDelete] = useState(0);

  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogMessage, setDialogMessage] = useState("");

  function confirmOpen() {
    setConfirmOpen(true);
  }

  function openDialog(id: number, toArchive: boolean) {
    //dialog title & message
    if (toArchive) {
      setDialogTitle("Delete?");
      if (userData.role.name === UserRole.Admin) {
        setDialogMessage("Are you sure you want to archive this Equipment?");
      } else {
        setDialogMessage("Are you sure you want to delete this Equipment?");
      }
    } else {
      setDialogTitle("Restore?");
      setDialogMessage("Are you sure you want to restore this Equipment?");
    }
    setConfirmOpen(true);
    setidForDelete(id);
  }

  const tableAction = [
    () => ({
      icon: () => <LaunchIcon color="primary" />,
      onClick: (event, rowData: unknown) => {
        setEquipmentForDisplay((rowData as { id: number }).id);
      },
      tooltip: "View Equipment",
    }),
    (rowData) => ({
      icon: () => <Delete color="primary" />,
      onClick: (event, rowData: unknown) => {
        openDialog((rowData as { id: number }).id, true);
      },
      hidden: rowData["isArchived"],
      tooltip: "Delete Equipment",
    }),
    (rowData) => ({
      icon: () => <RestoreIcon color="primary" />,
      onClick: (event, rowData: unknown) => {
        openDialog((rowData as { id: number }).id, false);
      },
      hidden: !rowData["isArchived"],
      tooltip: "Restore Equipment",
    }),
  ];

  const [documents, setDocuments] = useState<EquipmentDocumentVM[]>([]);
  const [documentsToBeDeleted, setDocumentsToBeDeleted] = useState<
    EquipmentDocumentVM[]
  >([]);
  const [draftDocuments, setDraftDocuments] = useState<
    EquipmentDocumentAddVM[]
  >([]);
  const addDraft = (files: File[]) => {
    setDraftDocuments(
      draftDocuments.concat(
        files.map(
          (f) =>
            new EquipmentDocumentAddVM({
              equipmentId: 0,
              file: f,
              name: f.name.split(".").slice(0, -1).join("."),
              isConfidential: false,
            })
        )
      )
    );
  };
  const removeDraft = (index: number) => {
    setDraftDocuments(draftDocuments.filter((d, i) => i !== index));
  };
  const setIsConfidentialDraft = (index: number) => {
    draftDocuments[index].isConfidential =
      !draftDocuments[index].isConfidential;
    setDraftDocuments([...draftDocuments]);
  };
  const [lastEditedDraftDocIndex, setLastEditedDraftDocIndex] = useState(0);
  const changeDraftDocumentName = (index: number, newName: string) => {
    draftDocuments[index].name = newName;
    setDraftDocuments([...draftDocuments]);
    setLastEditedDraftDocIndex(index);
  };
  const removeDocument = (index: number) => {
    documentsToBeDeleted.push(documents[index]);
    setDocumentsToBeDeleted([...documentsToBeDeleted]);
    setDocuments(documents.filter((d, i) => i !== index));
  };
  const updateDocument = (index: number) => {
    for (let i = 0; i < documents.length; i++) {
      if (i === index) {
        documents[i].documentFile.isConfidential =
          !documents[i].documentFile.isConfidential;
        break;
      }
    }
    setDocuments([...documents]);
  };

  const [notesAddModalState, setNotesAddModalState] = useState(false);
  const [draftNotes, setDraftNotes] = useState<EquipmentNoteCreateVM[]>([]);

  const openNotesModal = () => {
    setNotesAddModalState(true);
  };
  const closeNotesModal = () => {
    setNotesAddModalState(false);
  };

  const addEquipmentNote = (id: number, noteText: string) => {
    draftNotes.push(
      new EquipmentNoteCreateVM({
        equipmentId: id,
        text: noteText,
      })
    );

    setDraftNotes([...draftNotes]);
    setNotesAddModalState(false);
  };

  const updateNotes = async (id: number, draftNotes: EquipmentNoteVM[]) => {
    for (const note of draftNotes) {
      note.equipmentId = id;
      await equipmentClient.createNote(note);
    }

    setDraftNotes([]);
  };

  return (
    <>
      <main className="equipment">
        <Container maxWidth={false}>
          <Grid container>
            <Grid item xl={6} lg={6} md={12} xs={12} className="equipment_list">
              <BasicTable
                columns={columns}
                title="EQUIPMENT LIST"
                data={equipment as []}
                actions={tableAction}
                components={{
                  Container: (props) => <Paper {...props} elevation={0} />,
                }}
                paging={true}
                isLoading={tableIsLoading}
              />
            </Grid>
            <Grid
              item
              xl={6}
              lg={6}
              md={12}
              xs={12}
              className="equipment_add_customer"
            >
              {state.formTitle !== "VIEW EQUIPMENT" && (
                <EquipmentCRUDForm
                  title={state.formTitle}
                  formAction={state.formAction}
                  selectedEquipment={state.equipment}
                  manufacturerList={manufacturerList}
                  equipmentTypeList={equipmentTypeList}
                  customerList={customerList}
                  locationList={locationList}
                  clearFields={clearFields}
                  addDraft={addDraft}
                  removeDraft={removeDraft}
                  removeDocument={removeDocument}
                  downloadDocument={downloadDocument}
                  setIsConfidentialDraft={setIsConfidentialDraft}
                  changeDraftDocumentName={changeDraftDocumentName}
                  lastEditedDraftDocIndex={lastEditedDraftDocIndex}
                  documents={documents}
                  draftDocuments={draftDocuments}
                  documentsToBeDeleted={documentsToBeDeleted}
                  updateDocument={updateDocument}
                  openNotesModal={openNotesModal}
                  closeNotesModal={closeNotesModal}
                  notesModal={notesAddModalState}
                  addEquipmentNote={addEquipmentNote}
                  draftNotes={draftNotes}
                />
              )}
              {state.formTitle === "VIEW EQUIPMENT" && (
                <EquipmentForm
                  title={state.formTitle}
                  formAction={state.formAction}
                  selectedEquipment={state.equipment}
                  clearFields={clearFields}
                  downloadDocument={downloadDocument}
                  documents={documents}
                />
              )}
            </Grid>
          </Grid>
        </Container>
      </main>
      {confirm && (
        <ConfirmDialog
          title={dialogTitle}
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={deleteEquipment}
          param={idForDelete}
        >
          {dialogMessage}
        </ConfirmDialog>
      )}
    </>
  );
};

Equipment.displayName = "Equipment";

export default Equipment;
