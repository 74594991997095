import React, { forwardRef } from "react";
import MaterialTable from "material-table";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
import { Icons } from "material-table";
import { SvgIconProps, Button } from "@material-ui/core";

interface TableProps {
  width: string;
  columns: { title: string; field: string }[];
  title: string;
  add: Function;
  update?: Function;
  delete: Function;
  data: [];
  search: boolean;
  isLoading?: boolean;
  tooltipText: string;
}

export default function AddEditTable2(props: TableProps) {
  const tableIcons: Icons = {
    Add: forwardRef((propss, ref) => (
      <Button color="primary" variant="contained">
        {props.tooltipText}
      </Button>
    )),
    Delete: forwardRef((props, ref) => (
      <Delete {...props} ref={ref} htmlColor="#3f51b5" />
    )),
    Edit: forwardRef((props: SvgIconProps, ref) => (
      <Edit {...props} ref={ref} htmlColor="#3f51b5" />
    )),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  };
  return (
    <div style={{ width: props.width, border: "1px" }}>
      <MaterialTable
        icons={tableIcons}
        title={props.title}
        columns={props.columns}
        data={props.data}
        editable={{
          onRowAdd: (newData) =>
            new Promise(async (resolve, reject) => {
              const requestResultSuccess = await props.add(newData);
              requestResultSuccess ? resolve(null) : reject();
            }),
        }}
        actions={[
          {
            icon: () => <Delete color="primary" />,
            onClick: (event, rowData: unknown) =>
              props.delete((rowData as { id: number }).id),
          },
        ]}
        options={{
          actionsColumnIndex: -1,
          search: props.search,
          paging: false,
        }}
        localization={{
          body: {
            addTooltip: props.tooltipText,
          },
        }}
        isLoading={props.isLoading}
      />
    </div>
  );
}
