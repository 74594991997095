import { Button, Grid, InputAdornment, InputBaseComponentProps, makeStyles, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { ElementType, useEffect, useState } from 'react';
import { StateClient, StateVM, TaxRateAddVM, TaxRatesClient, TaxRateTypeVM, TaxRateUpdateVM, TaxRateVM } from '../../brines-refrigerator-api';
import { decimalNumberFormat } from '../../helpers/inputFormatters';
import { useSnackbar } from 'notistack';
import { useSelectValidation, useTextValidation } from '../../helpers/validations';

interface TaxRateCRUDFormProps {
    taxRateId: number,
    cancel: Function,
    refreshTable: Function,
    taxRates: TaxRateVM[]
}

const useStyles = makeStyles({
    marginBottom: {
        marginBottom: "2rem"
    }
})

const TaxRateCRUDForm = (props: TaxRateCRUDFormProps) => {
    const classes = useStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const taxRatesClient = new TaxRatesClient();

    useEffect(() => {
        resetForm();
        if (props.taxRateId) fetchTaxRate(props.taxRateId)
        fetchTaxRateTypes();
        fetchStates();
    }, [props.taxRateId])

    const resetForm = () => {
        resetValidation();
        setCurrentTaxRate(null);
        setTaxRateName("");
        setTaxRateType(null);
        setTaxRateAmount(null);
        setTaxRateState(null);
    }

    const [currentTaxRate, setCurrentTaxRate] = useState(null);
    const fetchTaxRate = async (taxRateId: number) => {
        try {
            resetValidation();
            const taxRate = await taxRatesClient.getById(taxRateId);
            setCurrentTaxRate(taxRate);
            setTaxRateName(taxRate.name);
            setTaxRateType(taxRate.taxRateType);
            setTaxRateAmount(taxRate.amount.toString());
            setTaxRateState(taxRate.state);
        }
        catch (error) {
            enqueueSnackbar("Error fetching tax rates!", { variant: "error" });
        }
    }

    const [taxRateTypes, setTaxRateTypes] = useState([]);
    const fetchTaxRateTypes = async () => {
        try {
            const taxRateTypes = await taxRatesClient.getTaxRateTypes();
            setTaxRateTypes(taxRateTypes);
        }
        catch (error) {
            enqueueSnackbar("Error fetching tax rate types!", { variant: "error" });
        }
    }

    const [states, setStates] = useState([]);
    const fetchStates = async () => {
        try {
            const statesClient = new StateClient();
            const states = await statesClient.get();
            setStates(states);
        }
        catch (error) {
            enqueueSnackbar("Error fetching states!", { variant: "error" });
        }
    }

    const [taxRateName, setTaxRateName] = useState("");
    const [taxRateNameInputProps, triggerTaxRateNameValidation, resetTaxRateNameInputProps] = useTextValidation();
    const handleTaxRateNameChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setTaxRateName(event.target.value as string);
        if (taxRateNameInputProps.error) {
            triggerTaxRateNameValidation(event.target.value as string);
        }
    }

    const [taxRateType, setTaxRateType] = useState(null);
    const [taxRateTypeInputProps, triggerTaxRateTypeValidation, resetTaxRateTypeInputProps] = useSelectValidation();
    const handleTaxRateTypeChange = (event: React.ChangeEvent<{ value: unknown }>, value: TaxRateTypeVM) => {
        if (value) {
            setTaxRateType(taxRateTypes.find(taxRateType => taxRateType.id == value.id));
            if (taxRateTypeInputProps.error) {
                triggerTaxRateTypeValidation(value);
            }
        }
    }

    const [taxRateAmount, setTaxRateAmount] = useState("");
    const [taxRateAmountInputProps, triggerTaxAmountValidation, resetTaxAmountInputProps] = useTextValidation();
    const handleTaxAmountChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setTaxRateAmount(event.target.value as string);
        if (taxRateAmountInputProps.error) {
            triggerTaxAmountValidation(event.target.value as string);
        }
    }

    const [taxRateState, setTaxRateState] = useState(null);
    const [taxRateStateInputProps, triggerTaxRateStateValidation, resetTaxRateStateInputProps] = useSelectValidation();
    const handleTaxRateStateChange = (event: React.ChangeEvent<{ value: unknown }>, value: StateVM) => {
        if (value) {
            setTaxRateState(states.find(state => state.id == value.id));
            if (taxRateStateInputProps.error) {
                triggerTaxRateStateValidation(value);
            }
        }
    }

    const checkTaxRateNameExists = () => {
        //check if there is a tax rate with the same name
        //if there is, check if we're updating the same tax rate, otherwise it's a different tax rate
        //if it's a different tax rate then we can't use the same name
        //todo: validate this through back-end, probably we can make a controller action who checks this and/or just validate through standard validation
        const foundTaxRate = props.taxRates.find(tr => tr.name === taxRateName);

        if (foundTaxRate) {
            if (foundTaxRate.id === props.taxRateId) return false;
            else return true;
        }
        return false;
    }

    const checkStateTaxRateAlreadyExists = () => {
        if (isStateTaxRateType(taxRateType)) {
            const foundStateTaxRate = props.taxRates.find(tr => tr.stateId == taxRateState.id && isStateTaxRateType(tr.taxRateType));
            if (foundStateTaxRate)
                if (foundStateTaxRate.id === props.taxRateId) return false; else return true;
            return false;
        } else return false;
    }

    const isStateTaxRateType = (taxRateType: TaxRateTypeVM) => {
        return (taxRateType.id === 1 || taxRateType.id === 2 || taxRateType.id === 3);
    }

    //validation
    const validateAllFields = (): boolean => {
        const resultName = triggerTaxRateNameValidation(taxRateName);
        const resultType = triggerTaxRateTypeValidation(taxRateType);
        const resultAmount = triggerTaxAmountValidation(taxRateAmount);
        const resultState = triggerTaxRateStateValidation(taxRateState);

        return resultName
            || resultType
            || resultAmount
            || resultState
    }

    const resetValidation = () => {
        resetTaxRateNameInputProps();
        resetTaxAmountInputProps();
        resetTaxRateTypeInputProps();
        resetTaxRateStateInputProps();
    }

    const submitForm = async () => {
        if (!validateAllFields()) {
            //if there was an ID through props we update existing tax, otherwise add a new one
            const taxRateNameExists = checkTaxRateNameExists();
            const stateTaxRateAlreadyExists = checkStateTaxRateAlreadyExists();

            if (taxRateNameExists) {
                enqueueSnackbar("Tax rate with this name already exists. Please specify another name", { variant: "error" });
                return;
            };

            if (stateTaxRateAlreadyExists) {
                enqueueSnackbar("There is already a state tax rate for this state.", { variant: "error" });
                return;
            }

            try {
                if (props.taxRateId) {
                    await taxRatesClient.updateTaxRate(new TaxRateUpdateVM({
                        id: props.taxRateId,
                        stateId: Number(taxRateState.id),
                        amount: Number(taxRateAmount),
                        name: taxRateName,
                        taxRateTypeId: taxRateType.id
                    }));
                    enqueueSnackbar("Successfully updated tax rate!", { variant: "success" });
                } else {
                    await taxRatesClient.create(new TaxRateAddVM({
                        stateId: Number(taxRateState.id),
                        amount: Number(taxRateAmount),
                        name: taxRateName,
                        taxRateTypeId: taxRateType.id
                    }));
                    enqueueSnackbar("Successfully added tax rate!", { variant: "success" });
                    resetForm();
                }
                props.refreshTable();
            }
            catch (error) {
                enqueueSnackbar("Error updating/adding tax rate.", { variant: "error" });
            }
        }
    }

    return (
        <Grid container>
            <Grid item container xs={12} className={classes.marginBottom} spacing={2}>
                <Grid item xs={8} md={10} className={classes.marginBottom}>
                    <Typography variant="h2">{props.taxRateId ? "EDIT " : "ADD "}TAX RATE</Typography>
                </Grid>
                <Grid item xs={4} md={2}>
                    <Button color="primary" variant="outlined" fullWidth onClick={() => { props.cancel() }}>Cancel</Button>
                </Grid>
                <Grid item xs={6} md={3}>
                    <TextField
                        name='taxRateName'
                        label='Name'
                        variant='outlined'
                        onChange={handleTaxRateNameChange}
                        value={taxRateName}
                        type='text'
                        size='medium'
                        error={taxRateNameInputProps.error}
                        onBlur={() => triggerTaxRateNameValidation(taxRateName)}
                    />
                    <span className="validation_error">
                        {taxRateNameInputProps.errorMessage}
                    </span>
                </Grid>
                <Grid item xs={6} md={3}>
                    <Autocomplete
                        options={taxRateTypes}
                        getOptionLabel={(option) => option.name}
                        onChange={handleTaxRateTypeChange}
                        value={taxRateType ? taxRateType : null}
                        onBlur={() => { triggerTaxRateTypeValidation(taxRateType) }}
                        renderInput={
                            (params) =>
                                <>
                                    <TextField {...params} label="Tax Rate Type" variant="outlined"
                                        error={taxRateTypeInputProps.error} />
                                    <span className="validation_error">
                                        {taxRateTypeInputProps.errorMessage}
                                    </span>
                                </>
                        }
                    />
                </Grid>
            </Grid>
            <Grid item container xs={12} className={classes.marginBottom} spacing={2}>
                <Grid item xs={6} md={3}>
                    <TextField
                        name='taxRateAmount'
                        label='Amount'
                        variant='outlined'
                        onChange={handleTaxAmountChange}
                        value={taxRateAmount}
                        type='text'
                        size='medium'
                        error={taxRateAmountInputProps.error}
                        InputProps={{
                            inputComponent: decimalNumberFormat as unknown as ElementType<InputBaseComponentProps>,
                            startAdornment: <InputAdornment position="start">%</InputAdornment>,
                        }}
                        onBlur={() => triggerTaxAmountValidation(taxRateAmount)}
                    />
                    <span className="validation_error">
                        {taxRateAmountInputProps.errorMessage}
                    </span>
                </Grid>
                <Grid item xs={6} md={3}>
                    <Autocomplete
                        options={states}
                        getOptionLabel={(option) => option.name}
                        onChange={handleTaxRateStateChange}
                        value={taxRateState ? taxRateState : null}
                        renderInput={
                            (params) =>
                                <>
                                    <TextField {...params} label="State" variant="outlined"
                                        error={taxRateStateInputProps.error} />
                                    <span className="validation_error">
                                        {taxRateStateInputProps.errorMessage}
                                    </span>
                                </>
                        }
                        onBlur={() => triggerTaxRateStateValidation(taxRateState)}
                    />
                </Grid>
            </Grid>
            <Grid item container xs={3}>
                <Button color="primary" variant="contained" onClick={submitForm}>{props.taxRateId ? "SAVE CHANGES" : "ADD TAX RATE"}</Button>
            </Grid>
        </Grid>
    )
}

export default TaxRateCRUDForm;