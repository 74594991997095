import React, { useState } from "react";
import { Button, Grid, Paper, TextField, Typography } from "@material-ui/core";
import { ServiceRequestCodeVM } from "../../../brines-refrigerator-api";
import FormDialog from "../../../components/common/dialog/FormDialog";
import AddIcon from "@material-ui/icons/Add";
import CancelButton from "../../../components/common/dialog/CancelButton";
import MaterialTable from "material-table";
import tableIcons from "../../../components/common/table/tableIcons";

interface ServiceRequestCodesProps {
  disabled: boolean;
  serviceRequestCode: ServiceRequestCodeVM;
  serviceRequestCodes: ServiceRequestCodeVM[];
  setSelectedRequestCode: Function;
  addNote: Function;
}

export default function ServiceRequestCodes(props: ServiceRequestCodesProps) {
  const [modalState, setModalState] = useState(false);

  const filteredCodes = (): ServiceRequestCodeVM[] => {
    const elementsToBeExcluded = [props.serviceRequestCode]
      .filter((x) => x !== null && x !== undefined)
      .map((x) => x.id);
    return props.serviceRequestCodes.filter((x) => {
      return !elementsToBeExcluded.includes(x.id);
    });
  };

  const handleServiceCodeChange = (
    serviceRequestCode: ServiceRequestCodeVM
  ) => {
    props.setSelectedRequestCode(
      new ServiceRequestCodeVM({
        id: serviceRequestCode.id,
        code: serviceRequestCode.code,
        description: serviceRequestCode.description,
        noteTemplate: serviceRequestCode.noteTemplate,
      })
    );
    props.addNote(null, serviceRequestCode.noteTemplate);
    setModalState(false);
  };

  const serviceRequestCodesColums = [
    { title: "Code", field: "code", width: 15 },
    { title: "Description", field: "description", width: "25%" },
    {
      title: "Note Template",
      field: "noteTemplate",
      render: (rowData) => (
        <TextField
          fullWidth={true}
          multiline
          value={rowData.noteTemplate}
          InputProps={{
            readOnly: true,
            disableUnderline: true,
          }}
        />
      ),
    },
  ];

  const srcTable = (
    <Paper elevation={0}>
      <Grid container spacing={0} justify="center">
        <Grid container item xs={12}>
          <Grid item xs={10}>
            <Typography variant="h3">Select a Service Request Code</Typography>
          </Grid>
          <Grid item xs={2} style={{ textAlign: "right" }}>
            <CancelButton onClick={() => setModalState(false)} />
          </Grid>
          <Grid item xs={12}>
            <MaterialTable
              icons={tableIcons}
              columns={serviceRequestCodesColums}
              title={"Service Request Codes"}
              data={filteredCodes() as []}
              onRowClick={(event, rowData) => handleServiceCodeChange(rowData)}
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <TextField
          fullWidth
          multiline
          value={`${
            props.serviceRequestCode ? props.serviceRequestCode.code : ""
          } ${
            props.serviceRequestCode
              ? props.serviceRequestCode.description
              : "No SRC selected"
          }`}
          InputProps={{
            readOnly: true,
            disableUnderline: true,
          }}
        />
        <Button
          variant="outlined"
          color="primary"
          size="small"
          startIcon={<AddIcon />}
          onClick={() => setModalState(true)}
          disabled={props.disabled}
        >
          Add SRC
        </Button>
        {modalState && <FormDialog open={modalState} body={srcTable} />}
      </Grid>
    </Grid>
  );
}
