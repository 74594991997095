import React, { useState, useEffect, ElementType } from "react";

import {
  makeStyles,
  Grid,
  Typography,
  Button,
  FormControl,
  InputAdornment,
  InputBaseComponentProps,
  TextField,
} from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import {
  InvoiceClient,
  InvoiceVM,
  InvoiceUpdateVM,
  QbClient,
  QbRequestAddVM,
  DispatchClient,
} from "../../brines-refrigerator-api";
import InvoiceHeader from "./InvoiceHeader/InvoiceHeader";
import InvoiceParts from "./InvoiceParts/InvoiceParts";
import InvoiceLabor from "./InvoiceLabor";
import InvoiceTripCharges from "./InvoiceTripCharges";
import InvoiceWorkOrder from "./InvoiceWorkOrder";
import { decimalNumberFormat } from "../../helpers/inputFormatters";
import { downloadFile } from "../../helpers/download";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSnackbar } from "notistack";

const useStyles = makeStyles({
  root: {
    margin: "1rem 2.5rem",
  },
  companyInfo: {
    fontSize: "1.7rem",
    fontWeight: 100,
    color: "#6e6e6e",
  },
  header1Gray: {
    fontSize: "1.9rem",
    color: "#6e6e6e",
    fontWeight: 500,
  },
  header2Gray: {
    fontSize: "1.55rem",
    color: "#6e6e6e",
    fontWeight: 500,
  },
  header2Primary: {
    fontSize: "1.55rem",
    color: "#3f51b5",
    fontWeight: 500,
  },
  header3Gray: {
    fontSize: "1.1rem",
    color: "#6e6e6e",
    fontWeight: 500,
  },
  bodyText: {
    color: "#6e6e6e",
    fontSize: "1.1rem",
  },
  marginBottomMd: {
    marginBottom: "2rem",
  },
  marginBottomLg: {
    marginBottom: "6rem",
  },
  dispatchInfoCard: {
    padding: "1rem",
    backgroundColor: "rgb(245, 246, 251)",
  },
  totalSection: {
    borderTop: "1px solid rgba(0,0,0, 0.2)",
    padding: "1rem 0",
  },
  buttonSection: {
    width: "99%",
  },
  widthTaxInput: {
    width: "90%",
  },
  progress: {
    position: "fixed",
    left: "50%",
    top: "35%",
    zIndex: 1000,
  },
});

const Invoice = () => {
  const invoiceClient = new InvoiceClient();
  const qbClient = new QbClient();
  const dispatchClient = new DispatchClient();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [invoice, setInvoice] = useState(
    new InvoiceVM({
      id: 0,
      dispatchId: 0,
      parts: 0,
      labor: 0,
      materialTax: 0,
      laborTax: 0,
      number: "000000",
      trip: 0,
      tripTax: 0,
    })
  );
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const getInvoice = async (id: number) => {
    try {
      const invoice = await invoiceClient.getById(id);
      setInvoice(invoice);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const updateInvoice = async (inTaxReview: boolean) => {
    try {
      const updatedInvoice = await invoiceClient.put(
        new InvoiceUpdateVM({
          id: invoice.id,
          laborTax: invoice.laborTax,
          materialTax: invoice.materialTax,
          trip: invoice.trip,
          tripTax: invoice.tripTax,
          inTaxReview: inTaxReview,
        })
      );
      setInvoice(updatedInvoice);
      enqueueSnackbar("Invoice updated successfully.", { variant: "success" });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  useEffect(() => {
    if (location.state) {
      getInvoice((location.state as { invoiceId: number }).invoiceId);
    }
  }, []);

  const updateInvoiceTotal = () => {
    try {
      getInvoice((location.state as { invoiceId: number }).invoiceId);
      enqueueSnackbar("Invoice total updated successfully.", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const previewInvoice = () => {
    history.push({
      pathname: "/preview-invoice",
      state: { invoiceId: invoice.id, dispatchId: invoice.dispatchId },
    });
  };

  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);

  const generatePdf = async () => {
    try {
      setIsGeneratingPdf(true);
      const response = await invoiceClient.generatePdf(invoice.id);
      setIsGeneratingPdf(false);
      enqueueSnackbar("PDF generated successfully.", { variant: "success" });
      await downloadFile(response.data, response.fileName);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
    setIsGeneratingPdf(false);
  };

  const exemptInvoiceFromTax = async () => {
    try {
      const invoiceId = (location.state as { invoiceId: number }).invoiceId;
      await invoiceClient.exemptInvoiceFromTax(invoiceId);
      enqueueSnackbar("Invoice exempted from tax.", { variant: "success" });

      await getInvoice(invoiceId);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const enableInvoiceTaxes = async () => {
    try {
      const invoiceId = (location.state as { invoiceId: number }).invoiceId;
      await invoiceClient.enableInvoiceTaxes(invoiceId);
      enqueueSnackbar("Invoice taxes enabled.", { variant: "success" });

      await getInvoice(invoiceId);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const editInvoiceLocationTaxRates = () => {
    history.push({
      pathname: "/tax-rates",
      state: { locationId: invoice.locationId },
    });
  };

  const exportInvoice = async (invoiceId: number) => {
    //call back end endpoint for exporting invoice through web connector with SOAP
    try {
      await qbClient.exportInvoiceToQb(
        new QbRequestAddVM({
          invoiceId: invoiceId,
        })
      );
      enqueueSnackbar(
        "Added invoice to QB export queue. Wait up to a minute for synch.",
        { variant: "success" }
      );
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const exportInvoiceAndGeneratePdf = async () => {
    await exportInvoice(invoice.id);
    await generatePdf();
    await dispatchClient.updateToBilled(invoice.dispatchId);
  };

  return (
    <>
      {isGeneratingPdf && (
        <CircularProgress size={100} className={classes.progress} />
      )}
      <div className={`${classes.root} ${classes.marginBottomMd}`}>
        <InvoiceHeader invoice={invoice} />
        <InvoiceWorkOrder
          invoiceId={invoice.id}
          dispatchId={invoice.dispatchId}
        />
        {/* Labor table */}
        <Grid item xs={12} className={classes.marginBottomMd}>
          <InvoiceLabor
            invoiceId={(location.state as { invoiceId: number }).invoiceId}
            updateInvoiceTotal={updateInvoiceTotal}
          />
        </Grid>
        <Grid item xs={10} className={classes.marginBottomMd}>
          <Typography className={classes.header2Gray} align="right">
            Labor <br />${invoice.labor.toFixed(2)}
          </Typography>
        </Grid>
        {/* Trip charges table */}
        <Grid item xs={12} className={classes.marginBottomMd}>
          <InvoiceTripCharges
            invoiceId={(location.state as { invoiceId: number }).invoiceId}
            updateInvoiceTotal={updateInvoiceTotal}
          />
        </Grid>
        <Grid item xs={10} className={classes.marginBottomMd}>
          <Typography className={classes.header2Gray} align="right">
            Trip charges <br />${invoice.trip.toFixed(2)}
          </Typography>
        </Grid>
        {/* Parts table */}
        <Grid item xs={12} className={classes.marginBottomMd}>
          <InvoiceParts
            invoiceId={(location.state as { invoiceId: number }).invoiceId}
            updateInvoiceTotal={updateInvoiceTotal}
          />
        </Grid>
        <Grid item xs={10} className={classes.marginBottomMd}>
          <Typography className={classes.header2Gray} align="right">
            Parts/Freight/Misc <br />${invoice.parts.toFixed(2)}
          </Typography>
        </Grid>
        <Grid item container xs={12} className={classes.totalSection}>
          <Grid item xs={2}>
            <Typography className={classes.header3Gray}>
              Labor <br />${invoice.labor}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.header3Gray}>
              Labor tax
              <br />
              <FormControl fullWidth className={classes.widthTaxInput}>
                <TextField
                  value={invoice.laborTax}
                  disabled={true}
                  onChange={(e) =>
                    setInvoice(
                      new InvoiceVM({
                        ...invoice,
                        laborTax: Number(e.target.value),
                      })
                    )
                  }
                  InputProps={{
                    inputComponent:
                      decimalNumberFormat as unknown as ElementType<InputBaseComponentProps>,
                    startAdornment: (
                      <InputAdornment position="start">{"($"}</InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">{")"}</InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography className={classes.header2Gray} align="right">
              Labor Subtotal <br />$
              {invoice.labor /*+ invoice.laborTax*/
                .toFixed(2)}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={12} className={classes.totalSection}>
          <Grid item xs={2}>
            <Typography className={classes.header3Gray}>
              Trip
              <br />
              <FormControl fullWidth className={classes.widthTaxInput}>
                <TextField
                  value={invoice.trip}
                  onChange={(e) =>
                    setInvoice(
                      new InvoiceVM({
                        ...invoice,
                        trip: Number(e.target.value),
                      })
                    )
                  }
                  disabled={true}
                  InputProps={{
                    inputComponent:
                      decimalNumberFormat as unknown as ElementType<InputBaseComponentProps>,
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.header3Gray}>
              Trip tax
              <br />
              <FormControl fullWidth className={classes.widthTaxInput}>
                <TextField
                  value={invoice.tripTax}
                  onChange={(e) =>
                    setInvoice(
                      new InvoiceVM({
                        ...invoice,
                        tripTax: Number(e.target.value),
                      })
                    )
                  }
                  InputProps={{
                    inputComponent:
                      decimalNumberFormat as unknown as ElementType<InputBaseComponentProps>,
                    startAdornment: (
                      <InputAdornment position="start">{"($"}</InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">{")"}</InputAdornment>
                    ),
                  }}
                  disabled={invoice.isTaxExempt}
                />
              </FormControl>
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography className={classes.header2Gray} align="right">
              Trip Subtotal <br />$
              {invoice.trip /*+ invoice.tripTax*/
                .toFixed(2)}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={12} className={classes.totalSection}>
          <Grid item xs={2}>
            <Typography className={classes.header3Gray}>
              Parts/Freight/Misc <br />${invoice.parts}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.header3Gray}>
              Material tax
              <br />
              <FormControl fullWidth className={classes.widthTaxInput}>
                <TextField
                  value={invoice.materialTax}
                  disabled={true}
                  onChange={(e) =>
                    setInvoice(
                      new InvoiceVM({
                        ...invoice,
                        materialTax: Number(e.target.value),
                      })
                    )
                  }
                  InputProps={{
                    inputComponent:
                      decimalNumberFormat as unknown as ElementType<InputBaseComponentProps>,
                    startAdornment: (
                      <InputAdornment position="start">{"($"}</InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">{")"}</InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography className={classes.header2Gray} align="right">
              Parts/Freight/Misc <br />$
              {invoice.parts /*+ invoice.materialTax*/
                .toFixed(2)}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={12} className={classes.totalSection}>
          <Grid item xs={8}></Grid>
          <Grid item xs={4}>
            <Typography className={classes.header2Gray} align="right">
              Subtotal <br />$
              {(invoice.trip + invoice.parts + invoice.labor).toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={8}></Grid>
          <Grid item xs={4}>
            <Typography className={classes.header2Gray} align="right">
              Tax subtotal <br />$
              {(
                invoice.tripTax +
                invoice.materialTax +
                invoice.laborTax
              ).toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={8}></Grid>
          <Grid item xs={4}>
            <Typography className={classes.header2Gray} align="right">
              Total <br />$
              {(
                invoice.trip +
                invoice.tripTax +
                invoice.parts +
                invoice.labor +
                invoice.materialTax +
                invoice.laborTax
              ).toFixed(2)}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={12} className={classes.totalSection}>
          <Grid item xs={2}>
            <Button
              className={classes.buttonSection}
              style={{ height: "4rem" }}
              variant="contained"
              color="primary"
              onClick={() => exportInvoice(invoice.id)}
            >
              Generate final invoice
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              className={classes.buttonSection}
              style={{ height: "4rem" }}
              variant="contained"
              color="primary"
              onClick={exportInvoiceAndGeneratePdf}
            >
              Create PDF and Generate invoice
            </Button>
          </Grid>
        </Grid>
        <Grid item container xs={12} className={classes.totalSection}>
          <Grid item xs={2}>
            <Button
              className={classes.buttonSection}
              variant="contained"
              color="primary"
              onClick={editInvoiceLocationTaxRates}
            >
              Add/Edit Tax Rate
            </Button>
          </Grid>
          <Grid item xs={2}>
            {invoice.isTaxExempt ? (
              <Button
                className={classes.buttonSection}
                variant="contained"
                color="primary"
                onClick={enableInvoiceTaxes}
              >
                Enable Invoice Taxes
              </Button>
            ) : (
              <Button
                className={classes.buttonSection}
                variant="contained"
                color="primary"
                onClick={exemptInvoiceFromTax}
              >
                Tax Exempt Invoice
              </Button>
            )}
          </Grid>
          {/* <Grid item xs={2}>
            <Button
              className={classes.buttonSection}
              variant="contained"
              color="primary"
              onClick={generatePdf}
            >
              Download PDF
            </Button>
          </Grid> */}
          <Grid item xs={2}>
            <Button
              className={classes.buttonSection}
              variant="contained"
              color="primary"
              onClick={previewInvoice}
            >
              Preview invoice PDF
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              className={classes.buttonSection}
              variant="contained"
              color="primary"
              onClick={() => updateInvoice(true)}
            >
              Save for Tax Review
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              className={classes.buttonSection}
              variant="contained"
              color="primary"
              onClick={() => updateInvoice(false)}
            >
              Save and Continue
            </Button>
          </Grid>
        </Grid>
      </div>
      {/* <div>
        <InvoiceWorkOrder
          invoiceId={invoice.id}
          dispatchId={invoice.dispatchId}
        />
      </div> */}
    </>
  );
};

export default Invoice;
