import React, { useState } from 'react';

import './DispatchEquipment.scss';
import { Box } from '@material-ui/core';
import MaterialTable from 'material-table';
import Note from '@material-ui/icons/Note';
import FormDialog from '../../../components/common/dialog/FormDialog';
import { EquipmentClient, EquipmentVM } from '../../../brines-refrigerator-api';
import { useSnackbar } from 'notistack';
import { generateUID } from '../../../helpers/generateUID';
import NoteTempVM from '../../../components/common/note/NoteTempVM';
import DispatchEquipmentNotePreview from '../DispatchEquipmentNote/DispatchEquipmentNotePreview';
import handleServerError from '../../../helpers/handleServerError';

const dispatchHistorycolumns = [
    { title: 'S/N', field: 'serialNumber', sorting: false },
    { title: 'Model', field: 'model', sorting: false }
];

const DispatchEquipment = (props) => {
    const { enqueueSnackbar } = useSnackbar();

    const tableActions = [
        rowData => ({
            icon: () => <Note color='primary' />,
            onClick: (event, rowData: EquipmentVM) => { openEquipmentNoteModal(rowData.id) }
        })
    ]

    const [equipmentNoteModal, setEquipmentNoteModal] = useState(false)

    const [equipmentNotesForDisplay, setEquipmentNotesForDisplay] = useState([])

    //GET EQUIPMENT NOTES FOR DISPLAY
    const getEquipmentNotes = async (id: number) => {
        try {
            const equipmentClient = new EquipmentClient();
            const notes = await equipmentClient.getNotes(id, true);

            const _notes = notes.map(x => new NoteTempVM(
                x.id, x.created, x.createdBy, x.lastModified, x.lastModifiedBy, x.text, false, generateUID(), x.equipmentId))

            //we need to destructure and reinitialize the arr to force a rerender due to immutability

            setEquipmentNotesForDisplay([..._notes]);
        } catch (error) {
            handleServerError(error, enqueueSnackbar)
        }
    }

    const openEquipmentNoteModal = async (id: number) => {
        const newArr = new Array();
        setEquipmentNotesForDisplay([...newArr]);
        getEquipmentNotes(id);
        setEquipmentNoteModal(true)
    }

    const closeEquipmentNoteModal = () => {
        setEquipmentNoteModal(false)
    }

    // EQUIPMENT NOTE MODAL BODY
    const equipmentNoteModalBody = (
        <DispatchEquipmentNotePreview
            notes={equipmentNotesForDisplay}
            closeModal={closeEquipmentNoteModal}
        />
    )

    return (
        <div className='dispatch-equipment_container'>
            <Box>
                <MaterialTable
                    columns={dispatchHistorycolumns}
                    title={""}
                    data={props.equipment as []}
                    components={{
                        Pagination: () => <div hidden={true}></div>
                    }}
                    options={{
                        search: false,
                        actionsColumnIndex: -1,
                        paging: false
                    }}
                    actions={tableActions}
                />
            </Box>
            {equipmentNoteModal && <FormDialog open={equipmentNoteModal} body={equipmentNoteModalBody} />}
        </div>
    )
}

export default DispatchEquipment;