import React, { useEffect, useState } from "react";
import {
  Checkbox,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { VariableSizeList, ListChildComponentProps } from "react-window";

interface CheckboxesTagsSelectProps {
  uniqueKey: string;
  options: [];
  preselected?: [];
  optionLabel: string;
  setSelected: Function;
  inputLabel: string;
  inputPlaceholder: string;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  return (
    <Typography component="li" noWrap style={inlineStyle}>
      {dataSet}
    </Typography>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData: React.ReactChild[] = [];
  (children as React.ReactChild[]).forEach(
    (item: React.ReactChild & { children?: React.ReactChild[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    }
  );

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child: React.ReactChild) => {
    if (child.hasOwnProperty("group")) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

export default function CheckboxesTagsSelect(props: CheckboxesTagsSelectProps) {
  const [selected, setSelected] = useState(props.preselected ?? []);

  const handleOnChange = (event, newValue) => {
    props.setSelected(newValue);
    setSelected(newValue);
  };

  useEffect(() => {
    props.setSelected([]);
    setSelected(props.preselected ?? []);
  }, [props.uniqueKey, props.preselected]);

  return (
    <Autocomplete
      multiple
      defaultChecked
      id="checkboxes-tags-demo"
      options={props.options}
      disableCloseOnSelect
      ListboxComponent={ListboxComponent}
      getOptionLabel={(option) => option[props.optionLabel] + ""}
      renderOption={(option, state) => (
        <span>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={
              state.selected || selected.map((x) => x.id).includes(option.id)
            }
          />
          {option[props.optionLabel]}
        </span>
      )}
      style={{ width: 500, padding: 15 }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.inputLabel}
          placeholder={props.inputPlaceholder}
        />
      )}
      onChange={handleOnChange}
      value={selected}
    />
  );
}
