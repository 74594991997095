import React, { useEffect, useState } from 'react';
import { Grid, Paper } from '@material-ui/core';
import { DispatchTypeClient, DispatchTypeAddVM } from '../../../brines-refrigerator-api';
import ConfirmDialog from '../../common/dialog/ConfirmationDialog';
import AddTable from '../../common/table/AddTable';
import { redirectIfSessionExpired } from '../../common/redirect/RedirectOnSessionTimeout';
import { useHistory } from 'react-router-dom';
import { validateText } from '../../../helpers/validations';
import { useSnackbar } from 'notistack';

const DispatchTypes: React.FC = () => {
  const history = useHistory();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [dispatchTypes, setDispatchTypes] = useState([]);
  const [idForDelete, setIdForDelete] = useState(0);
  const [dialogMessage, setDialogMessage] = useState('');
  const [confirmOpen, setConfirmOpen] = useState(false);

  const dispatchTypesClient = new DispatchTypeClient();

  async function getDispatchTypes() {
    try {
      const dispatch = await dispatchTypesClient.get();
      setDispatchTypes(dispatch)
    } catch (error) {
      redirectIfSessionExpired(history, error)
      enqueueSnackbar("Error while getting dispatch types.", { variant: "error" });
    }
  };

  useEffect(() => {
    getDispatchTypes()
  }, []);

  const addDispatchType = async (data: unknown) => {
    const result = (data as { name: string }).name ? validateText((data as { name: string }).name) : { error: true, errorMessage: 'This field can\'t be empty.' };
    if (result.error) {
      enqueueSnackbar(result.error, { variant: "error" });
      return;
    }
    try {
      await dispatchTypesClient.create(new DispatchTypeAddVM({
        name: (data as { name: string }).name
      }));
      getDispatchTypes();
      enqueueSnackbar("Dispatch types added successfully.", { variant: "success" });
    } catch (error) {
      redirectIfSessionExpired(history, error)
      enqueueSnackbar("Error adding dispatch type.", { variant: "error" });
    }
  }

  const deleteDispatchType = async (id: number) => {
    try {
      await dispatchTypesClient.delete(id);
      getDispatchTypes()
      enqueueSnackbar("Dispatch types deleted successfully.", { variant: "success" });
    } catch (error) {
      redirectIfSessionExpired(history, error)
      enqueueSnackbar("Error deleting dispatch type.", { variant: "error" });
    }
  }

  const dispatchTypesColums = [
    { title: 'Dispatch Types', field: 'name' },
  ];

  const openDeleteDialogue = (id: number) => {
    setIdForDelete(id);
    setDialogMessage(`Are you sure you want to delete Dispatch Type #${id}?`);
    setConfirmOpen(true);
  }

  const tableTooltip = { addTooltip: "Enter Dispatch Type" }

  return (
    <Grid item container spacing={2}>
      <Grid item xl={12} lg={12} md={12} sm={12}>
        <Paper elevation={0}>
          <AddTable
            width={'100%'}
            columns={dispatchTypesColums}
            title={"Dispatch Types"}
            add={addDispatchType}
            delete={openDeleteDialogue}
            data={dispatchTypes as []}
            search={false}
            localization={tableTooltip}
            deleteTooltip={"Delete Dispatch Type"}
          />
          {confirmOpen && <ConfirmDialog
            title={"Delete dispatch type?"}
            open={confirmOpen}
            setOpen={setConfirmOpen}
            onConfirm={() => { deleteDispatchType(idForDelete) }}
          >
            {dialogMessage}
          </ConfirmDialog>
          }
        </Paper>
      </Grid>
    </Grid>
  )
};

DispatchTypes.displayName = 'DispatchTypes';

export default DispatchTypes;