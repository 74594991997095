function handleServerError(message, callback: Function) {
    if (message === undefined || message.response === undefined) {
        callback("An error has occured", { variant: "error" });
        return;
    }

    for (let prop in message.response.data.errors) {
        let propErrors = message.response.data.errors[prop];

        propErrors.forEach(element => {
            callback(element, { variant: "error" });
        });
    }
}

export default handleServerError;