import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import InvoicePartsTable from "./InvoicePartsTable";
import {
  InvoiceClient,
  PartVM,
  PartClient,
  InvoicePartsAddVM,
  InvoicePartAddVM,
  InvoicePartUpdateVM,
  ManufacturerClient,
  InvoiceCustomPartAddVM,
} from "../../../brines-refrigerator-api";
import InvoicePartTempVM from "../InvoicePartTempVM";
import { generateUID } from "../../../helpers/generateUID";
import { Grid, makeStyles, Paper, Button } from "@material-ui/core";
import BasicTable from "../../../components/common/table/BasicTable";
import MaterialTable, { MTableToolbar } from "material-table";
import CancelButton from "../../../components/common/dialog/CancelButton";
import FormDialog from "../../../components/common/dialog/FormDialog";
import { validateText } from "../../../helpers/validations";

interface InvoicePartProps {
  invoiceId: number;
  updateInvoiceTotal: Function;
}

interface CustomPartRowData {
  id: string;
  quantity: string;
  multiplier: string;
  description: string;
  model: string;
  number: string;
  manufacturer: string;
  price: string;
}

const useStyles = makeStyles({
  button: {
    marginBottom: "1em",
  },
  boxScroll: {
    overflowY: "auto",
    overflowX: "hidden",
  },
  partTable: {
    width: "60rem",
    height: "30rem",
  },
  addPartTableButton: {
    marginLeft: "70%",
  },
  highlight: {
    backgroundColor: "#3f51b5cc",
    "& .MuiTypography-h6": {
      color: "#ffffff",
    },
  },
});

const InvoiceParts = (props: InvoicePartProps) => {
  const invoiceClient = new InvoiceClient();
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // PARTS
  const partsColumns = [
    { title: "#", field: "id", hidden: true },
    { title: "Name", field: "name" },
    { title: "Manufacturer", field: "manufacturer" },
  ];

  const partsColumns1 = [
    { title: "Description", field: "description" },
    { title: "Number", field: "number" },
    {
      title: "Quantity",
      field: "quantity",
      type: "numeric",
      validate: (rowData) =>
        rowData.quantity
          ? rowData.quantity < 0
            ? { isValid: false, helperText: "Quantity can't be negative" }
            : true
          : { isValid: false, helperText: "Quantity must be specified." },
    },
    {
      title: "Price per unit",
      field: "price",
      type: "numeric",
      validate: (rowData) =>
        rowData.price != null
          ? rowData.price < 0
            ? { isValid: false, helperText: "Price can't be negative" }
            : true
          : { isValid: false, helperText: "Price must be specified." },
    },
    {
      title: "Multiplier",
      field: "multiplier",
      type: "numeric",
      validate: (rowData) =>
        rowData.multiplier
          ? rowData.multiplier < 0
            ? { isValid: false, helperText: "Multiplier can't be negative" }
            : true
          : { isValid: false, helperText: "Multiplier must be specified." },
    },
  ];

  const [partsList, setPartsList] = useState([]);
  const [selectedParts, setSelectedParts] = useState([]);
  const [addPartsModalOpen, setAddPartsModalOpen] = useState(false);
  const [partsTableLoading, setPartsTableLoading] = useState(false);
  const [invoicePartsTableLoading, setInvoicePartsTableLoading] =
    useState(false);
  const [manufacturers, setManufacturers] = useState({});

  const openAddPartsModal = () => {
    getParts();
    setAddPartsModalOpen(true);
  };

  const closeAddPartsModal = () => {
    setAddPartsModalOpen(false);
  };

  //GET INVOICE PARTS
  const [invoicePartsList, setInvoicePartsList] = useState([]);

  const invoicePartsColumns = [
    { title: "Name", field: "model" },
    { title: "Number", field: "number" },
    {
      title: "Quantity",
      field: "quantity",
      type: "numeric",
      validate: (rowData) =>
        rowData.quantity
          ? rowData.quantity < 0
            ? { isValid: false, helperText: "Quantity can't be negative" }
            : true
          : { isValid: false, helperText: "Quantity must be specified." },
    },
    {
      title: "Price per unit",
      field: "price",
      type: "numeric",
      validate: (rowData) =>
        rowData.price != null
          ? rowData.price < 0
            ? { isValid: false, helperText: "Price can't be negative" }
            : true
          : { isValid: false, helperText: "Price must be specified." },
    },
    {
      title: "Multiplier",
      field: "multiplier",
      type: "numeric",
      validate: (rowData) =>
        rowData.multiplier
          ? rowData.multiplier < 0
            ? { isValid: false, helperText: "Multiplier can't be negative" }
            : true
          : { isValid: false, helperText: "Multiplier must be specified." },
    },
    { title: "Subtotal", field: "subtotal", editable: false },
  ];

  const getInvoiceParts = async () => {
    try {
      setInvoicePartsTableLoading(true);
      const invoiceParts = await invoiceClient.getInvoiceParts(props.invoiceId);
      setInvoicePartsList(
        invoiceParts.map(
          (x) =>
            new InvoicePartTempVM(
              generateUID(),
              x.id,
              x.manufacturerId ? x.manufacturerId : null,
              x.name,
              x.description,
              x.number ? x.number : null,
              x.quantity,
              x.price ? x.price : 0,
              x.multiplier,
              x.subtotal
            )
        )
      );
      setInvoicePartsTableLoading(false);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setInvoicePartsTableLoading(false);
    }
  };

  const getParts = async () => {
    try {
      setPartsTableLoading(true);
      const partsClient = new PartClient();
      const parts = await partsClient.get();
      setPartsList(
        parts.map((e: PartVM) => ({
          id: Number(e.id),
          name: e.name,
          manufacturer: e.manufacturer ? e.manufacturer.name : null,
          quantity: null,
          multiplier: null,
          description: e.description ? e.description : null,
          number: e.number ? e.number : null,
          price: e.price ? e.price : null,
        }))
      );
      setPartsTableLoading(false);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setPartsTableLoading(false);
    }
  };

  const getManufacturers = async () => {
    try {
      const manufacturerClient = new ManufacturerClient();
      const manufacturers = await manufacturerClient.get();
      let lookup = {};
      manufacturers.forEach((m) => {
        lookup[m.id] = m.name;
      });
      setManufacturers(lookup);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  useEffect(() => {
    getInvoiceParts();
    getManufacturers();
  }, []);

  const partsToList = (rows) => {
    setSelectedParts(rows);
  };

  const addSelectedPartsToInvoice = async () => {
    try {
      await invoiceClient.addInvoiceParts(
        new InvoicePartsAddVM({
          invoiceId: props.invoiceId,
          invoiceParts: selectedParts.map(
            (x) =>
              new InvoicePartAddVM({
                partId: x.id,
                quantity: x.quantity,
                multiplier: x.multiplier,
                description: x.description,
                number: x.number,
                price: x.price,
              })
          ),
        })
      );
      getInvoiceParts();
      props.updateInvoiceTotal();
      enqueueSnackbar("Added invoice parts successfully.", {
        variant: "success",
      });
      closeAddPartsModal();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      closeAddPartsModal();
    }
  };

  const addCustomPartToInvoice = async (part: CustomPartRowData) => {
    if (validateAllFields(part)) {
      return false;
    }
    try {
      await invoiceClient.addCustomPart(
        new InvoiceCustomPartAddVM({
          invoiceId: props.invoiceId,
          quantity: Number(part.quantity),
          multiplier: Number(part.multiplier),
          description: part.description,
          name: part.description,
          number: part.number ? Number(part.number) : null,
          source: null,
          manufacturerId: null,
          price: Number(part.price),
        })
      );
      getInvoiceParts();
      props.updateInvoiceTotal();
      enqueueSnackbar("Added custom invoice part successfully.", {
        variant: "success",
      });
      return true;
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      return false;
    }
  };

  const validateAllFields = (data: CustomPartRowData): boolean => {
    // const resultName = data.model
    //   ? validateText(data.model)
    //   : { error: true, errorMessage: "Name can't be empty." };

    const resultDescription = data.description
      ? validateText(data.description)
      : { error: true, errorMessage: "Description can't be empty." };

    const resultQuantity = data.quantity
      ? { error: false, errorMessage: null }
      : { error: true, errorMessage: "Quantity can't be empty." };

    const resultMultiplier = data.multiplier
      ? { error: false, errorMessage: null }
      : { error: true, errorMessage: "Multiplier can't be empty." };

    const resultPrice = data.price
      ? { error: false, errorMessage: null }
      : { error: true, errorMessage: "Price can't be empty." };

    // if (resultDescription.error) {
    //   enqueueSnackbar(resultName.errorMessage, { variant: "error" });
    // }
    if (resultDescription.error) {
      enqueueSnackbar(resultDescription.errorMessage, { variant: "error" });
    }
    if (resultQuantity.error) {
      enqueueSnackbar(resultQuantity.errorMessage, { variant: "error" });
    }
    if (resultMultiplier.error) {
      enqueueSnackbar(resultMultiplier.errorMessage, { variant: "error" });
    }
    if (resultPrice.error) {
      enqueueSnackbar(resultPrice.errorMessage, { variant: "error" });
    }

    return (
      //   resultName.error ||
      resultDescription.error ||
      resultQuantity.error ||
      resultMultiplier.error ||
      resultPrice.error
    );
  };

  const updateInvoicePart = async (invoicePartData: CustomPartRowData) => {
    try {
      await invoiceClient.updateInvoicePart(
        new InvoicePartUpdateVM({
          id: Number(invoicePartData.id),
          quantity: Number(invoicePartData.quantity),
          multiplier: Number(invoicePartData.multiplier),
          description: invoicePartData.description,
          name: invoicePartData.model ? invoicePartData.model : " ",
          number: invoicePartData.number
            ? Number(invoicePartData.number)
            : null,
          source: null,
          manufacturerId: null,
          price: Number(invoicePartData.price),
        })
      );
      getInvoiceParts();
      props.updateInvoiceTotal();
      enqueueSnackbar("Updated invoice part successfully.", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const deleteInvoicePart = async (id: number) => {
    try {
      await invoiceClient.deleteInvoicePart(id);
      getInvoiceParts();
      props.updateInvoiceTotal();
      enqueueSnackbar("Deleted invoice parts successfully.", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const editSelectedPart = async (invoicePartData: CustomPartRowData) => {
    let editedPartIndex = selectedParts.findIndex(
      (p) => p.id === invoicePartData.id
    );
    selectedParts[editedPartIndex] = new InvoicePartUpdateVM({
      id: Number(invoicePartData.id),
      quantity: Number(invoicePartData.quantity),
      multiplier: Number(invoicePartData.multiplier),
      description: invoicePartData.description,
      name: invoicePartData.model ? invoicePartData.model : " ",
      number: invoicePartData.number ? Number(invoicePartData.number) : null,
      source: null,
      manufacturerId: null,
      price: Number(invoicePartData.price),
    });

    setSelectedParts([...selectedParts]);
  };

  const partsTable = (
    <div className="parts-modal">
      <Grid container className={classes.partTable}>
        <Grid item xs={12}>
          <MaterialTable
            title="Add Invoice Parts/Freight/Misc"
            data={partsList as []}
            columns={partsColumns1 as []}
            options={{
              actionsColumnIndex: -1,
              selection: true,
              paging: false,
              pageSize: 10,
              rowStyle: {
                fontSize: 16,
              },
            }}
            editable={{
              onRowUpdate: (newData) =>
                new Promise((resolve) => {
                  editSelectedPart(newData);
                  resolve(null);
                }),
            }}
            components={{
              Container: (props) => <Paper {...props} elevation={0} />,
              Toolbar: (props) => (
                <Grid
                  container
                  item
                  xs={12}
                  justify="flex-start"
                  alignItems="center"
                >
                  <Grid item xs={11}>
                    <MTableToolbar
                      classes={{ highlight: classes.highlight }}
                      {...props}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <CancelButton onClick={closeAddPartsModal} />
                  </Grid>
                </Grid>
              ),
            }}
            onSelectionChange={partsToList}
            isLoading={partsTableLoading}
          />
        </Grid>
        <Grid className={classes.addPartTableButton} item xs={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={addSelectedPartsToInvoice}
          >
            Add Selected To Invoice
          </Button>
        </Grid>
      </Grid>
    </div>
  );

  return (
    <div>
      <InvoicePartsTable
        width="100%"
        columns={invoicePartsColumns}
        title="Parts/Freight/Misc"
        add={openAddPartsModal}
        addInline={addCustomPartToInvoice}
        update={updateInvoicePart}
        delete={deleteInvoicePart}
        data={invoicePartsList as []}
        search={false}
        isLoading={invoicePartsTableLoading}
      />
      {addPartsModalOpen && (
        <FormDialog open={addPartsModalOpen} body={partsTable} />
      )}
    </div>
  );
};

export default InvoiceParts;
