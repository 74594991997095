enum DispatchStatusType {
  Unassigned = 1,
  Pending = 2,
  Traveling = 3,
  Working = 4,
  DispatchRvw = 5,
  AccountManagerFollowUp = 6,
  NoInvoice = 7,
  Completed = 8,
}
export default DispatchStatusType;
