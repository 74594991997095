import { Button, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { StateClient, StateVM, TaxRatesClient, TaxRatesReportFilterVM } from '../../brines-refrigerator-api';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import DateType from '../../helpers/constants/dateTypes';
import { dateTimePickerDefaultProps } from '@material-ui/pickers/constants/prop-types';

const useStyles = makeStyles({
    root: {
        padding: '1rem 2.5rem'
    },
    marginBottom: {
        marginBottom: "2rem"
    },
    line: {
        height: "1px",

    }
});

const TaxRatesReport = () => {
    const classes = useStyles();

    const [taxRatesReports, setTaxRatesReports] = useState([]);

    const taxRatesClient = new TaxRatesClient();

    useEffect(() => {
        fetchTaxRateReports();
        fetchData();
    }, [])

    const ColoredLine = ({ color }) => (
        <hr
            style={{
                color: color,
                backgroundColor: color,
                height: 1
            }}
        />
    );

    const fetchData = async () => {
        const statesClient = new StateClient();
        const states = await statesClient.get();
        setStates(states);
    }

    const fetchTaxRateReports = async () => {
        const reports = await taxRatesClient.getTaxRateReport();
        setTaxRatesReports(reports.taxRateReports);
    }

    const [selectedState, setSelectedState] = useState(null);
    const [states, setStates] = useState([]);

    const handleStateChange = (event: React.ChangeEvent<{ value: unknown }>, value: StateVM) => {
        setSelectedState(value);
    }

    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);

    const handleDateFromChange = async (e: Date) => {
        if (!e) {
            setDateFrom(e);
        } else {
            e instanceof Date && !isNaN(e.getTime())
                ? setDateFrom(e)
                : setDateFrom(null)
        }
    }

    const handleDateToChange = async (e: Date) => {
        if (!e) {
            setDateTo(e);
        } else {
            e instanceof Date && !isNaN(e.getTime())
                ? setDateTo(e)
                : setDateTo(null)
        }
    }

    const filterTaxRates = async () => {
        const searchResult = await taxRatesClient.getTaxRateReportWithFilter(new TaxRatesReportFilterVM({
            dateFrom: dateFrom,
            dateTo: dateTo,
            stateId: selectedState ? Number(selectedState.id) : null
        }));
        setTaxRatesReports(searchResult.taxRateReports);
    }

    return (
        <Grid className={classes.root} container>
            <Grid item container xs={12} spacing={2}>
                <Grid item xs={2} className={classes.marginBottom}>
                    <Autocomplete
                        value={selectedState}
                        options={states}
                        getOptionLabel={(option) => option.name}
                        onChange={handleStateChange}
                        renderInput={
                            (params) =>
                                <TextField
                                    {...params}
                                    label='State'
                                    variant='outlined'
                                    type='text'
                                    size='medium'
                                />

                        }
                    />
                </Grid>
                <Grid item xs={2}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            autoOk
                            clearable
                            format="MM/dd/yyyy"
                            label='Date from'
                            value={dateFrom}
                            emptyLabel='mm/dd/yyyy'
                            invalidDateMessage={null}
                            allowKeyboardControl={false}
                            inputVariant='outlined'
                            onChange={e => handleDateFromChange(e)}
                            fullWidth
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={2}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            autoOk
                            clearable
                            format="MM/dd/yyyy"
                            label='Date to'
                            value={dateTo}
                            emptyLabel='mm/dd/yyyy'
                            invalidDateMessage={null}
                            allowKeyboardControl={false}
                            inputVariant='outlined'
                            onChange={e => handleDateToChange(e)}
                            fullWidth
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={2}>
                    <Button variant="contained" color="primary" onClick={filterTaxRates} fullWidth>Search</Button>
                </Grid>
            </Grid>
            {taxRatesReports.map(report => {
                let taxesSubtotal = report.taxRates.reduce(function (prev, current) {
                    return prev + +current.amountTaxed
                }, 0);
                return (
                    <Grid container item xs={12} className={classes.marginBottom} key={`taxRateReport-${report.state.id}`}>
                        <Grid item xs={12} lg={8}>
                            <Typography variant="h5">{report.state.name}</Typography>
                            <ColoredLine color="#cccccc" />
                        </Grid>
                        <Grid item container xs={12} lg={8}>
                            {report.taxRates.length > 0 ? report.taxRates.map(taxRate => {
                                if (taxRate.taxRateTypeId <= 3)
                                    return (
                                        <React.Fragment key={`taxRate-${Date.now()}`}>
                                            <Grid item xs={10}>
                                                <Typography variant="h6">{`State sales tax(${taxRate.amount.toFixed(1)}%)`}</Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography variant="h6" align="right">
                                                    {`$${taxRate.amountTaxed.toFixed(2)}`}
                                                </Typography>
                                            </Grid>
                                        </React.Fragment>
                                    )
                                else
                                    return (
                                        <React.Fragment key={`taxRate-${Date.now()}`}>
                                            <Grid item xs={10}>
                                                <Typography variant="body1">{`${taxRate.name}(${taxRate.amount.toFixed(1)}%)`}</Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography variant="body1" align="right">
                                                    {`$${taxRate.amountTaxed.toFixed(2)}`}
                                                </Typography>
                                            </Grid>
                                        </React.Fragment>
                                    )

                            }) : "No tax rates to show yet."}
                            {report.taxRates.length > 0 ?
                                <React.Fragment>
                                    <Grid item xs={10}>
                                        <Typography variant="h5">
                                            Subtotal
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography variant="h5" align="right">
                                            {`$${taxesSubtotal.toFixed(2)}`}
                                        </Typography>
                                    </Grid>
                                </React.Fragment> : null}
                        </Grid>
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default TaxRatesReport;