import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import {
  ClockInQuestionsClient,
  ClockOutQuestionsClient,
  DispatchClient,
  UserVM,
  VisitsClient,
} from "../../../brines-refrigerator-api";
import MaterialTable from "material-table";
import { Card, Chip, Grid, makeStyles, Typography } from "@material-ui/core";

interface DispatchLaborPreviewProps {
  dispatchId: number;
  technicians: UserVM[];
}

const useStyles = makeStyles({
  visitsContainer: {
    marginTop: "2rem",
  },
  questionContainer: {
    marginBottom: "1rem",
  },
  visitsTitle: {
    marginBottom: "1rem",
  },
  visitsDataContainer: {
    marginTop: "1rem",
  },
  visitDataContainer: {
    marginBottom: "1rem",
    border: "solid #dddddd 1px",
    boxShadow: "3px 3px #eeeeee",
  },
  visitDataBlock: {
    marginBottom: "0.5rem",
  },
  tripTableContainer: {
    margin: "1rem 0",
  },
});

const DispatchLaborPreview = (props: DispatchLaborPreviewProps) => {
  const classes = useStyles();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const laborColumns = [
    {
      title: "Technician",
      field: "technicianName",
    },
    {
      title: "Hourly rate",
      field: "hourlyRate",
    },
    {
      title: "Hours",
      field: "hours",
    },
    { title: "Subtotal", field: "subtotal" },
  ];

  const tripChargeColumns = [
    {
      title: "Rate",
      field: "price",
    },
    { title: "Quantity", field: "quantity" },
    { title: "Subtotal", field: "subtotal" },
  ];

  const [laborList, setLaborList] = useState([]);
  const [laborTableLoading, setLaborTableLoading] = useState(false);

  const [tripChargeList, setTripChargeList] = useState([]);
  const [tripChargeTableLoading, setTripChargeTableLoading] = useState(false);

  const getDispatchLabor = async () => {
    try {
      setLaborTableLoading(true);
      const dispatchClient = new DispatchClient();
      const labor = await dispatchClient.getDispatchLabor(props.dispatchId);
      setLaborList(labor);
      setLaborTableLoading(false);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setLaborTableLoading(false);
    }
  };

  const getDispatchTripCharges = async () => {
    try {
      setTripChargeTableLoading(true);
      const dispatchClient = new DispatchClient();
      const tripCharges = await dispatchClient.getDispatchTripCharges(
        props.dispatchId
      );
      setTripChargeList(tripCharges);
      setTripChargeTableLoading(false);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setTripChargeTableLoading(false);
    }
  };

  const options = {
    year: "numeric",
    day: "numeric",
    month: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  const dateTimeFormat = new Intl.DateTimeFormat("en-US", options);

  const formatAnswer = (answer: boolean) => {
    return answer ? (
      <Chip color="primary" variant="outlined" size="small" label="Yes" />
    ) : (
      <Chip color="secondary" variant="outlined" size="small" label="No" />
    );
  };

  const formatIndicator = (text: string) => {
    return (
      <Chip color="primary" variant="outlined" size="small" label={text} />
    );
  };

  const [questions, setQuestions] = useState([]);
  const getQuestions = async () => {
    const clockInQClient = new ClockInQuestionsClient();
    const clockOutQClient = new ClockOutQuestionsClient();
    const clockInQuestions = (await clockInQClient.get()).map(
      (question) => question.questionText
    );
    const clockOutQuestions = (await clockOutQClient.get()).map(
      (question) => question.questionText
    );
    setQuestions(clockInQuestions.concat(clockOutQuestions));
  };

  const [technicians, setTechnicians] = useState({});
  useEffect(() => {
    if (props.dispatchId != null) {
      getDispatchLabor();
      getQuestions();
      getDispatchTripCharges();
      formatTechnicians();
    } else {
      setLaborList([]);
    }
  }, [props.dispatchId]);

  useEffect(() => {
    getVisitsData();
  }, [technicians]);

  const [visits, setVisits] = useState([]);

  const getVisitsData = async () => {
    const visitsClient = new VisitsClient();

    const visitsData = await visitsClient.getForDisplay(props.dispatchId);
    setVisits(visitsData);
    console.log(visitsData);
  };

  const formatTechnicians = () => {
    const technicians = {};
    props.technicians.forEach((tech) => {
      technicians[tech.id] = tech.userName ?? tech.email;
    });
    setTechnicians(technicians);
  };

  useEffect(() => {
    if (props.dispatchId != null) {
      getDispatchLabor();
    } else {
      setLaborList([]);
    }
  }, [props.dispatchId]);

  return (
    <div key={`dispatch-labor-table-${new Date().toLocaleString()}`}>
      <MaterialTable
        title="Labor"
        columns={laborColumns}
        data={laborList as unknown as []}
        options={{
          search: false,
          paging: false,
        }}
        isLoading={laborTableLoading}
      />
      <div className={classes.tripTableContainer}>
        <MaterialTable
          title="Trip"
          columns={tripChargeColumns}
          data={tripChargeList as unknown as []}
          options={{
            search: false,
            paging: false,
          }}
          isLoading={tripChargeTableLoading}
        />
      </div>
      <Grid container xs={12} className={classes.visitsContainer}>
        <Grid item xs={12} className={classes.visitsTitle}>
          <b>Visits log</b>
        </Grid>
        {questions.map((question, index) => {
          return (
            <Grid item xs={12} className={classes.questionContainer}>
              <Card>
                <Typography>
                  <b>{`Q${index + 1}`}</b> {`${question}`}
                </Typography>
              </Card>
            </Grid>
          );
        })}
        <Grid item container xs={12} className={classes.visitsDataContainer}>
          {visits.map((visit) => {
            return (
              <Grid
                item
                container
                xs={12}
                className={classes.visitDataContainer}
              >
                <Grid item xs={6} className={classes.visitDataBlock}>
                  Technician: {technicians[visit.techId]}
                </Grid>
                <Grid item xs={6} className={classes.visitDataBlock}>
                  Status:{" "}
                  {visit.finished
                    ? "Finished"
                    : visit.clockIn.arrivedOnSite
                    ? "Arrived"
                    : "Traveling"}
                </Grid>
                <Grid item container xs={12} className={classes.visitDataBlock}>
                  <Grid item xs={4}>
                    Travel start time:{" "}
                    {dateTimeFormat.format(visit.clockIn.startedTravelingTime)}
                  </Grid>
                  <Grid item xs={4}>
                    Clock in time:{" "}
                    {visit.clockIn.arrivedOnSite
                      ? dateTimeFormat.format(visit.clockIn.clockInTime)
                      : "Pending"}
                  </Grid>
                  <Grid item xs={4}>
                    Clock out time:{" "}
                    {visit.clockOut
                      ? dateTimeFormat.format(visit.clockOut.clockOutTime)
                      : "Pending"}
                  </Grid>
                </Grid>
                <Grid item container xs={12} className={classes.visitDataBlock}>
                  <Grid item xs={3}>
                    <b>Q1:</b>{" "}
                    {visit.clockIn.ArrivedOnSite
                      ? formatAnswer(visit.clockIn.clockInAnswers[0].answer)
                      : formatIndicator("Pending")}
                  </Grid>
                  <Grid item xs={3}>
                    <b>Q2:</b>{" "}
                    {visit.clockIn.ArrivedOnSite
                      ? formatAnswer(visit.clockIn.clockInAnswers[1].answer)
                      : formatIndicator("Pending")}
                  </Grid>
                  <Grid item xs={3}>
                    <b>Q3:</b>{" "}
                    {visit.clockIn.ArrivedOnSite
                      ? formatAnswer(visit.clockIn.clockInAnswers[2].answer)
                      : formatIndicator("Pending")}
                  </Grid>
                  <Grid item xs={3}>
                    <b>Q4:</b>{" "}
                    {visit.finished
                      ? formatAnswer(visit.clockOut.clockOutAnswers[0].answer)
                      : formatIndicator("Pending")}
                  </Grid>
                  <Grid item xs={3}>
                    <b>Q5:</b>{" "}
                    {visit.finished
                      ? formatAnswer(visit.clockOut.clockOutAnswers[1].answer)
                      : formatIndicator("Pending")}
                  </Grid>
                  <Grid item xs={3}>
                    <b>Q6:</b>{" "}
                    {visit.finished
                      ? formatAnswer(visit.clockOut.clockOutAnswers[2].answer)
                      : formatIndicator("Pending")}
                  </Grid>
                  <Grid item xs={3}>
                    <b>Q7:</b>{" "}
                    {visit.finished
                      ? formatAnswer(visit.clockOut.clockOutAnswers[3].answer)
                      : formatIndicator("Pending")}
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </div>
  );
};

export default DispatchLaborPreview;
