import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Typography, TextField, Button, GridList, GridListTile, IconButton } from '@material-ui/core';
import { UserBaseVM } from '../../../brines-refrigerator-api';
import CancelButton from '../../../components/common/dialog/CancelButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTextValidation, validateText } from '../../../helpers/validations';

interface Note {
  id: number,
  created: Date,
  createdBy: UserBaseVM,
  createdById: number,
  text: string,
  draft: boolean,
  uid: string
}

interface NoteProps {
  notes: Note[],
  deleteNoteCallback?: Function;
  buttonTitle: string,
  buttonAction: Function,
  button2Action: Function,
  editEquipmentNote?: Function,
  noteId?: number,
  closeModal: Function,
  equipmentId: number
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    marginBottom: {
      marginBottom: 16
    },
    rootList: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
    },
    rootTile: {
      width: "100%",
    },
    gridList: {
      width: 500,
      height: 450,
    },
    icon: {
      color: 'rgba(255, 255, 255, 0.54)',
    },
    section1: {
      margin: 16,
      height: "100%",
      backgroundColor: "rgb(245, 245, 245)"
    },
    note: {
      marginTop: "5%"
    }
  }),
);


export default function EquipmentNotes(props: NoteProps) {
  const options = { year: 'numeric', day: 'numeric', month: 'numeric', hour: 'numeric', minute: 'numeric', timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone };
  const dateTimeFormat = new Intl.DateTimeFormat('en-US', options);
  const [noteText, setNoteText] = useState('')
  const classes = useStyles();

  const validateInput = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let noteText = e.target.value;
    setNoteText(noteText)
  }

  function deleteNote(id: number, uid: string) {
    props.deleteNoteCallback(id, uid)
  }

  useEffect(() => {
    setNoteText('')
  }, [props]);

  // Get user data from session storage
  const userData: any = JSON.parse(sessionStorage.getItem('userData') || '{}');

  // Map user roles
  const role = userData.role.name;

  //VALIDATION
  const [noteInputProps, triggerNoteInputValidation, resetNoteInputProps] = useTextValidation();

  const validateNoteModal = () => {
    const formValid = !validateText(noteText).error;

    if (!formValid) triggerNoteInputValidation(noteText, false);

    return formValid;
  }

  const submitAddNote = () => {
    if (validateNoteModal()) props.buttonAction(props.equipmentId, noteText);
  }

  const saveAllChanges = () => {
    props.button2Action();
  }

  return (
    <div className={classes.root}>
      <Grid container item xs={12} spacing={0}>
        <Grid container item xs={12}>
          <Paper elevation={0}>
            <Grid item container >
              <Grid item xs={8}>
                <Typography variant="h6" className={classes.marginBottom}>
                  Notes
            </Typography>
              </Grid>
              <Grid item xs={2}>
                <CancelButton
                  onClick={props.closeModal}
                />
              </Grid>
            </Grid>

            <Grid item className={classes.marginBottom}>
              <Paper variant="outlined" square >
                <div className={classes.rootList}>
                  <GridList cellHeight={160} className={classes.gridList}>
                    {props.notes.map((tile) => (
                      <GridListTile key={tile.uid} style={{ width: "100%" }} >
                        <div className={classes.section1}>
                          <Grid container alignItems="center" >
                            <Grid container item xs>
                              <Grid item xs={8}>
                                <Typography gutterBottom>Added at:  {dateTimeFormat.format(tile.created)}</Typography>
                              </Grid>
                              {role.includes('Admin') &&
                                <Grid item xs={4}>
                                  {/* <IconButton aria-label="edit" onClick={() => props.setNoteForEditing(tile.id, tile.text)}>
                                    <EditIcon />
                                  </IconButton> */}
                                  <IconButton aria-label="delete" onClick={() => deleteNote(tile.id, tile.uid)}>
                                    <DeleteIcon />
                                  </IconButton>
                                </Grid>
                              }
                              <Typography gutterBottom>
                                Added By:  {tile.draft ? 'DRAFT' : tile.createdBy.email}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Typography className={classes.note} variant="body2">
                            {tile.text}
                          </Typography>
                        </div>
                      </GridListTile>
                    ))}
                  </GridList>
                </div>
              </Paper>
            </Grid>
            <Grid item>
              <TextField
                className={classes.marginBottom}
                fullWidth={true}
                id="outlined-multiline-static"
                label="Note"
                multiline
                rows={4}
                variant="outlined"
                value={noteText}
                onChange={event => validateInput(event)}
                error={noteInputProps.error}
                onBlur={() => { triggerNoteInputValidation(noteText) }}
              />
              <span className="validation_error">
                {noteInputProps.errorMessage}
              </span>
            </Grid>
            <Grid item>
              <Button className={classes.marginBottom} onClick={submitAddNote} variant="contained" color="primary" fullWidth={true}>
                {props.buttonTitle}
              </Button>
              <Button onClick={saveAllChanges} variant="contained" color="primary" fullWidth={true}>
                Save changes
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};