import React, { useReducer, useEffect, useState } from "react";
import {
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
  Button,
  makeStyles,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  AuthClient,
  UserClient,
  StateClient,
  UpdateUserClientVM,
} from "../../brines-refrigerator-api";
import "./MyAccount.scss";
import * as client from "../../helpers/offline/offline-api";
import { useSnackbar } from "notistack";
import {
  useEmailValidation,
  useTextValidation,
} from "../../helpers/validations";
import UserRole from "../../helpers/constants/userRole";

const useStyles = makeStyles({
  marginBottom: {
    marginBottom: "1.5em",
  },
});

const MyAccount: React.FC = () => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [ifOfflineDispatchState] = client.useIfOfflineDispatchState();
  // Get user data from session storage
  const userObj = JSON.parse(sessionStorage.getItem("userData") || "{}");

  // Get user client methods
  const userClient = new UserClient();

  // Set component state
  const [input_placeholder, setState] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      passwordsDontMatch: false,
      user: {
        id: 0,
      },
      userRole: "",
      userName: "",
      userEmail: "",
      userPhoneNumber: "",
      selectedState: {
        id: "",
        abbreviation: "",
        name: "",
      },
      states: [],
      dataIsReady: false,
    }
  );

  const [
    usernameInputProps,
    triggerUsernameValidation,
    resetUsernameInputProps,
  ] = useTextValidation();

  const handleUsernameChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    setState({ userName: e.target.value });
    if (usernameInputProps.error) triggerUsernameValidation(e.target.value);
  };

  const [emailInputProps, triggerEmailValidation, resetEmailInputProps] =
    useEmailValidation();

  const handleEmailChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    setState({ userEmail: e.target.value });
    if (emailInputProps.error) triggerEmailValidation(e.target.value);
  };

  async function setProfileState() {
    const client = new StateClient();
    const states = await client.get();
    const { id, userName, email, phoneNumber, state } =
      await userClient.getProfile();
    setState({
      user: { id },
      userName,
      userEmail: email,
      userPhoneNumber: phoneNumber,
      selectedState: {
        id: state?.id,
        abbreviation: state?.abbreviation,
        name: state?.name,
      },
      states,
      dataIsReady: true,
    });
  }

  const [isTech, setIsTech] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        const userData = JSON.parse(sessionStorage.getItem("userData") || "{}");
        setIsTech(userData.role.name === UserRole.Technician);
        await setProfileState();
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
        ifOfflineDispatchState(error);
      }
    })();
  }, []);

  const [
    phoneNumberInputProps,
    triggerPhoneNumberValidation,
    resetPhoneNumberInputProps,
  ] = useTextValidation();

  const updatePhoneNumber = (e: React.ChangeEvent<{ value: unknown }>) => {
    setState({ userPhoneNumber: e.target.value as string });
    if (phoneNumberInputProps.error)
      triggerPhoneNumberValidation(e.target.value as string, true, true);
  };

  const updateProfile = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.currentTarget;
    const id = userObj.user.id;
    const email: string = form.email.value;
    const phoneNumber: string = form.phone.value;
    const stateId: any = input_placeholder.selectedState;

    const user = new UpdateUserClientVM({
      id,
      phoneNumber,
      email,
      stateId: Number(stateId?.id),
    });

    try {
      await userClient.update(user);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      ifOfflineDispatchState(error);
    }
    await setProfileState();
  };

  const changePassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.currentTarget;
    const oldPassword = form.old_password.value;
    const newPassword = form.new_password.value;
    const confirmPassword = form.confirm_password.value;

    if (newPassword !== confirmPassword) {
      setState({ passwordsDontMatch: true });
      return;
    }

    try {
      let client = new AuthClient();
      await client.changePasswordSelf(
        oldPassword,
        newPassword,
        confirmPassword
      );
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      ifOfflineDispatchState(error);
    }
  };

  const clearValidations = () => {
    setState({ passwordsDontMatch: false });
  };

  interface CountryType {
    abbreviation: string;
    name: string;
    id: number;
  }

  return (
    <main className="my_account">
      <Container maxWidth="xl">
        <Grid container alignItems="center" className="my_account_page_title">
          <Typography variant="h1">My Account</Typography>
        </Grid>
        <Container className="my_account_form_container">
          <Grid
            container
            justify="center"
            alignItems="flex-start"
            className="my_account_forms_container"
          >
            <Grid item lg={4} xs={12} className="my_account_form">
              <Paper className="my_account_paper">
                <Typography variant="h2">Profile</Typography>
                <Grid container justify="center">
                  <form onSubmit={updateProfile}>
                    <Grid
                      container
                      direction="column"
                      justify="space-evenly"
                      className="my_account_form_fields"
                    >
                      <TextField
                        type="text"
                        name="role"
                        label="Role"
                        InputProps={{ readOnly: true }}
                        defaultValue={input_placeholder.userRole}
                      />
                      <TextField
                        required
                        type="text"
                        name="name"
                        label="Name"
                        variant="outlined"
                        value={input_placeholder.userName ?? ""}
                        onChange={handleUsernameChange}
                        onBlur={() => {
                          triggerUsernameValidation(input_placeholder.userName);
                        }}
                        error={usernameInputProps.error}
                      />
                      <span
                        className={`validation_error ${classes.marginBottom}`}
                        hidden={!usernameInputProps.error}
                      >
                        {usernameInputProps.errorMessage}
                      </span>
                      <TextField
                        required
                        type="email"
                        name="email"
                        label="E-mail"
                        variant="outlined"
                        value={input_placeholder.userEmail ?? ""}
                        onChange={handleEmailChange}
                        onBlur={() => {
                          triggerEmailValidation(input_placeholder.userEmail);
                        }}
                        error={emailInputProps.error}
                      />
                      <span
                        className={`validation_error ${classes.marginBottom}`}
                        hidden={!emailInputProps.error}
                      >
                        {emailInputProps.errorMessage}
                      </span>
                      <TextField
                        name="phone"
                        type="tel"
                        label="Phone"
                        variant="outlined"
                        onChange={updatePhoneNumber}
                        inputProps={{ maxLength: 10 }}
                        value={input_placeholder.userPhoneNumber ?? ""}
                        onBlur={() => {
                          triggerPhoneNumberValidation(
                            input_placeholder.userPhoneNumber,
                            true,
                            true
                          );
                        }}
                        error={phoneNumberInputProps.error}
                      />
                      <span
                        className={`validation_error ${classes.marginBottom}`}
                        hidden={!phoneNumberInputProps.error}
                      >
                        {phoneNumberInputProps.errorMessage}
                      </span>
                      <Autocomplete
                        loading={input_placeholder.dataIsReady}
                        options={input_placeholder.states as CountryType[]}
                        getOptionLabel={(option) => {
                          return `${
                            option.name || input_placeholder.selectedState.name
                          }`;
                        }}
                        value={input_placeholder.selectedState.name ?? ""}
                        onChange={(e: any, value: any) =>
                          setState({
                            selectedState: {
                              abbreviation: value?.abbreviation,
                              id: Number(value?.id),
                              name: value?.name,
                            },
                          })
                        }
                        renderOption={(option) => (
                          <span>
                            ({option.abbreviation}) {option.name}
                          </span>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="State"
                            variant="outlined"
                            inputProps={{
                              ...params.inputProps,
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid>
                      <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        size="large"
                        fullWidth
                      >
                        UPDATE PROFILE
                      </Button>
                    </Grid>
                    <p className="my_account_form_caption">
                      * Required fields - all must be filled and validated in
                      order to save changes.
                    </p>
                  </form>
                </Grid>
              </Paper>
            </Grid>
            {!isTech && (
              <Grid item lg={4} xs={12} className="my_account_form">
                <Paper className="my_account_paper">
                  <Typography variant="h2">Change Password</Typography>
                  <Grid container justify="center">
                    <form onSubmit={changePassword}>
                      <Grid
                        container
                        direction="column"
                        justify="space-evenly"
                        className="my_account_form_fields"
                      >
                        <TextField
                          type="text"
                          name="hidden_input"
                          label="Hidden input"
                          className="my_account_form_fields_hidden"
                        />
                        <TextField
                          required
                          type="text"
                          name="old_password"
                          label="Old Password"
                          variant="outlined"
                          className="hide_password_input_field"
                        />
                        <TextField
                          required
                          type="text"
                          name="new_password"
                          label="New Password"
                          variant="outlined"
                          className="hide_password_input_field"
                          onFocus={clearValidations}
                          error={input_placeholder.passwordsDontMatch}
                          helperText={
                            input_placeholder.passwordsDontMatch &&
                            "Passwords do not match"
                          }
                        />
                        <TextField
                          required
                          type="text"
                          name="confirm_password"
                          label="Repeat New Password"
                          variant="outlined"
                          className="hide_password_input_field"
                          onFocus={clearValidations}
                          error={input_placeholder.passwordsDontMatch}
                          helperText={
                            input_placeholder.passwordsDontMatch &&
                            "Passwords do not match"
                          }
                        />
                      </Grid>
                      <Grid>
                        <Button
                          type="submit"
                          color="primary"
                          variant="contained"
                          size="large"
                          fullWidth
                        >
                          CHANGE PASSWORD
                        </Button>
                      </Grid>
                      <p className="my_account_form_caption">
                        * Required fields
                      </p>
                    </form>
                  </Grid>
                </Paper>
              </Grid>
            )}
          </Grid>
        </Container>
      </Container>
    </main>
  );
};

MyAccount.displayName = "MyAccount";

export default MyAccount;
