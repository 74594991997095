import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Grid,
  Paper,
  Button,
  Box,
  AppBar,
  Tabs,
  Tab,
  TextField,
  Typography,
} from "@material-ui/core";
import BasicTable from "../../common/table/BasicTable";
import { MTableToolbar } from "material-table";
import CancelButton from "../../common/dialog/CancelButton";
import { PartVM, DispatchVM } from "../../../brines-refrigerator-api";
import * as client from "../../../helpers/offline/offline-api";
import { useSnackbar } from "notistack";

interface PartsUsedProps {
  dispatch: DispatchVM;
  closePartsModal: Function;
  updateDispatchParts: Function;
  addItemToDispatch: Function;
  enterPart: Function;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    "& #cancel-btn": {
      marginLeft: "2.5em",
      fontSize: "0.6rem",
    },
    height: "37rem",
  },
  appbar: {
    backgroundColor: "#ffffff",
    color: "#000",
  },
  button: {
    marginBottom: "1em",
  },
  boxScroll: {
    overflowY: "auto",
    overflowX: "hidden",
  },
  partTable: {
    height: "30rem",
  },
  addPartTableButton: {
    marginLeft: "67%%",
  },
  addItemHeading: {
    marginBottom: "1em",
  },
  highlight: {
    backgroundColor: "#3f51b5cc",
    "& .MuiTypography-h6": {
      color: "#ffffff",
    },
  },
});

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const DispatchPartsUsed = (props: PartsUsedProps) => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  //=============  PARTS ===================//

  const [selectedParts, setSelectedParts] = useState([]);
  const [partsTableIsLoading, setTableLoading] = useState(true);
  const [partsList, setPartsList] = useState([]);

  const [value, setValue] = useState(0);

  const [_getParts] = client.useGetParts();

  const getParts = async () => {
    try {
      const parts = await _getParts();
      setPartsList(
        parts.map((e: PartVM) => ({
          id: Number(e.id),
          name: e.name,
          manufacturer: e.manufacturer?.name,
          price: e.price,
        }))
      );
    } catch (error) {
      enqueueSnackbar("Error while getting parts.", { variant: "error" });
    }
    setTableLoading(false);
  };

  useEffect(() => {
    getParts();
  }, []);

  const addSelectedPartsToDispatch = async () => {
    props.updateDispatchParts(selectedParts);
    closePartsModal();
    setPartsList(
      partsList.map((e) => ({ ...e, tableData: { checked: false } }))
    );
    setTableLoading(false);
  };

  const enterNewPartAndAddToDispatch = () => {
    closePartsModal();
    props.enterPart();
  };

  const partsToList = (rows) => {
    setSelectedParts(rows);
  };

  const closePartsModal = () => {
    props.closePartsModal();
  };

  const partsColumns = [
    { title: "#", field: "id", hidden: true },
    { title: "Name", field: "name" },
    { title: "Manufacturer", field: "manufacturer" },
  ];

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  //======================= ITEM =================//
  const [itemDescription, setItemDescription] = useState("");
  const [itemPrice, setItemPrice] = useState(null);

  const changeDescription = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const description = e.target.value;
    setItemDescription(description);
  };

  const changeItemPrice = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const price = e.target.value;
    setItemPrice(price);
  };

  const addItemToDispatch = () => {
    props.addItemToDispatch(itemDescription, itemPrice);
    closePartsModal();
  };

  return (
    <div className="parts-modal">
      <div className={classes.root}>
        <AppBar position="static" className={classes.appbar}>
          <Tabs value={value} onChange={handleChange} aria-label="part tabs">
            <Tab label="Add Material" />
            <Tab label="Add non-listed Material" />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <Grid container className={classes.partTable}>
            <Grid item xs={12}>
              <BasicTable
                width={"100%"}
                title={"Add Material"}
                data={partsList as []}
                columns={partsColumns}
                selection={true}
                components={{
                  Container: (props) => <Paper {...props} elevation={0} />,
                  Toolbar: (props) => (
                    <Grid
                      container
                      item
                      xs={12}
                      justify="flex-start"
                      alignItems="center"
                    >
                      <Grid item xs={10}>
                        <MTableToolbar
                          classes={{ highlight: classes.highlight }}
                          {...props}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <CancelButton onClick={closePartsModal} />
                      </Grid>
                    </Grid>
                  ),
                }}
                onSelectionChange={partsToList}
                search={true}
                paging={false}
                isLoading={partsTableIsLoading}
              />
            </Grid>
            <Grid className={classes.addPartTableButton} item xs={4}>
              <Button
                variant="contained"
                color="primary"
                onClick={addSelectedPartsToDispatch}
              >
                Add Selected
              </Button>
            </Grid>
            <Grid className={classes.addPartTableButton} item xs={4}>
              <Button
                variant="contained"
                color="primary"
                onClick={enterNewPartAndAddToDispatch}
              >
                Enter new material
              </Button>
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Grid container>
            <Grid container className={classes.addItemHeading}>
              <Grid item xs={8}>
                <Typography variant="h1">Add non-listed Material</Typography>
              </Grid>
              <Grid item xs={3}>
                <Grid item xs={1}>
                  <CancelButton onClick={closePartsModal} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.addItemHeading}>
              <TextField
                id="outlined-multiline-static"
                label="Item Description"
                multiline
                value={itemDescription}
                onChange={(event) => changeDescription(event)}
                rows={5}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} className={classes.addItemHeading}>
              <TextField
                label="Item Price"
                value={itemPrice}
                onChange={(event) => changeItemPrice(event)}
                variant="outlined"
                type="number"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                onClick={addItemToDispatch}
              >
                Add non-listed Material
              </Button>
            </Grid>
          </Grid>
        </TabPanel>
      </div>
    </div>
  );
};
export default DispatchPartsUsed;
