import {
  Button,
  Checkbox,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete, Color } from "@material-ui/lab";
import { useSnackbar } from "notistack";
import React, { useEffect, useReducer, useRef, useState } from "react";
import {
  RoleClient,
  StateClient,
  UpdateUserVM,
  UserClient,
  UserCreateVM,
  UserNoteVM,
  UserNewPasswordVM,
  RegionClient,
} from "../../../../brines-refrigerator-api";
import UserRole from "../../../../helpers/constants/userRole";
import {
  useTextValidation,
  usePasswordValidation,
  useEmailValidation,
  useSelectValidation,
} from "../../../../helpers/validations";
import CheckboxesTagsSelect from "../../../common/select/CheckboxesTagsSelect";
import "./UserCRUDForms.scss";

const useStyles = makeStyles({
  bottomBorder: {
    borderBottom: "2px solid rgb(97, 97, 97)",
  },
  marginTop: {
    marginTop: "0.6875rem",
  },
  crudMarginTop: {
    marginTop: "1em",
  },
  marginBottom: {
    marginBottom: "1.5em",
  },
});

interface CountryType {
  abbreviation: string;
  name: string;
  id: string;
}

interface UserFormProps {
  user: {
    id: number;
    role: string;
    userName: string;
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    workPhone: string;
    city: string;
    state: any;
    active: boolean;
    note: string | undefined;
  };
  refreshUsersTable: Function;
}

const UserCRUDForms = (props: UserFormProps) => {
  const classes = useStyles();
  const loggedInUser = JSON.parse(sessionStorage.getItem("userData") || "{}");

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const userClient = new UserClient();
  const regionClient = new RegionClient();

  const [regions, setRegions] = useState([]);
  const [preselectedRegions, setPreselectedRegions] = useState([]);

  const selectedRegions = useRef([]);
  const setSelectedRegions = async (value: []) => {
    selectedRegions.current = value;
  };

  async function getRegions() {
    try {
      const regions = await regionClient.get();
      setRegions(regions);
    } catch (error) {
      enqueueSnackbar("Error while getting regions.", { variant: "error" });
    }
  }

  async function getRegionsByUser(id: number) {
    try {
      const regions = await regionClient.getByUserId(id);
      setPreselectedRegions(regions as []);
    } catch (error) {
      enqueueSnackbar("Error while getting regions.", { variant: "error" });
    }
  }

  useEffect(() => {
    (async () => {
      const stateClient = new StateClient();
      const states = await stateClient.get();
      const rolesClient = new RoleClient();
      const roles = await rolesClient.getAll();
      setState({ states, roles, formsAreDisabled: true });
      await getRegions();
    })();
  }, []);

  const [formData, setState] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      id: 0,
      isActive: false,
      userName: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      workPhone: "",
      city: "",
      role: "",
      note: "",
      password: "",
      states: [],
      roles: [],
      selectedState: { abbreviation: "", name: "", id: 0 },
      formDisabled: true,
    }
  );

  useEffect(() => {
    if (props.user) {
      setState({
        id: props.user.id,
        isActive: props.user.active,
        userName: props.user.userName,
        firstName: props.user.firstName,
        lastName: props.user.lastName,
        email: props.user.email,
        phoneNumber: props.user.phone,
        workPhoneNumber: props.user.workPhone,
        city: props.user.city,
        role: props.user.role,
        note: props.user.note ? props.user.note : "",
        selectedState: {
          abbreviation: props.user.state.abreviation,
          name: props.user.state.name,
          id: props.user.state.id,
        },
        formDisabled: false,
      });
      getRegionsByUser(props.user.id);
    }
  }, [props.user]);

  const addNewUser = async () => {
    if (validateAddUserFields()) {
      const email: string = addUserEmail;
      const password: string = addUserPassword;

      const user = new UserCreateVM({
        userName: email,
        email: email,
        password: password,
        role: addUserRole,
      });

      try {
        await userClient.create(user);
        enqueueSnackbar("Success adding user.", { variant: "success" });
        props.refreshUsersTable();
      } catch (error) {
        enqueueSnackbar("Error adding user.", { variant: "error" });
      }
    }
  };

  const updateUserProfile = async () => {
    if (validateAllFields()) {
      const preselectedRegionsIds = preselectedRegions.map((x) => x.id);
      var selectedRegionsIds = selectedRegions.current.map(
        (region) => region.id
      );
      // const getRowVersion: any = await userClient.userById(id);
      const user = new UpdateUserVM({
        id: formData.id,
        userName: formData.userName,
        email: formData.email,
        firstName: formData.firstName,
        lastName: formData.lastName,
        phoneNumber: formData.phoneNumber,
        workPhoneNumber: formData.workPhoneNumber,
        city: formData.city,
        isActive: formData.isActive,
        note: new UserNoteVM({
          text: formData.note,
        }),
        role: formData.role,
        stateId: formData.selectedState.id
          ? Number(formData.selectedState.id)
          : null,
        regions: selectedRegionsIds
          .filter((id) => !preselectedRegionsIds.includes(id))
          .concat(
            preselectedRegionsIds.filter((pId) =>
              selectedRegionsIds.includes(pId)
            )
          ),
      });

      await userClient
        .updateAdmin(user)
        .then(() => {
          if (formData.password !== "") {
            userClient.setPassword(
              new UserNewPasswordVM({
                id: formData.id,
                password: formData.password,
                repeatPassword: formData.password,
              })
            );
          }
          enqueueSnackbar("Success updating user info.", {
            variant: "success",
          });
          resetUpdateUserFields();
          props.refreshUsersTable();
        })
        .catch(() => {
          enqueueSnackbar("Error trying to update user info.", {
            variant: "error",
          });
        });
    }
  };

  //VALIDATION
  const [
    usernameInputProps,
    triggerUsernameValidation,
    resetUsernameInputProps,
  ] = useTextValidation();
  const [
    passwordInputProps,
    triggerPasswordValidation,
    resetPasswordInputProps,
  ] = usePasswordValidation();
  const [emailInputProps, triggerEmailValidation, resetEmailInputProps] =
    useEmailValidation();

  const handleUsernameChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    setState({ userName: e.target.value });
    if (usernameInputProps.error) triggerUsernameValidation(e.target.value);
  };

  const handleEmailChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    setState({ email: e.target.value });
    // if (emailInputProps.error) triggerEmailValidation(e.target.value);
  };

  const validateAllFields = (): boolean => {
    const username = triggerUsernameValidation(formData.userName);
    // const email = triggerEmailValidation(formData.email);

    const password = formData.password
      ? triggerPasswordValidation(formData.password)
      : false;

    return !username && /*!email &&*/ !password;
  };

  const handlePasswordChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const passwordString = (e.target.value as string).trim();
    setState({ password: passwordString });
    if (passwordString) {
      if (passwordInputProps.error) triggerPasswordValidation(e.target.value);
    } else {
      resetPasswordInputProps();
    }
  };

  const [
    addUserPasswordInputProps,
    triggerAddUserPasswordValidation,
    resetAddUserPasswordInputProps,
  ] = usePasswordValidation();
  const [
    addUserEmailInputProps,
    triggerAddUserEmailValidation,
    resetAddUserEmailInputProps,
  ] = useEmailValidation();
  const [
    addUserRoleInputProps,
    triggerAddUserRoleValidation,
    resetAddUserRoleInputProps,
  ] = useSelectValidation();

  const validateAddUserFields = (): boolean => {
    // const email = triggerAddUserEmailValidation(addUserEmail);
    const password = triggerAddUserPasswordValidation(addUserPassword);
    const role = triggerAddUserRoleValidation(addUserRole);

    return /*!email &&*/ !password && !role;
  };

  const [addUserEmail, setAddUserEmail] = useState("");
  const [addUserPassword, setAddUserPassword] = useState("");
  const [addUserRole, setAddUserRole] = useState("Technician");

  const handleAddUserEmailChange = (
    e: React.ChangeEvent<{ value: unknown }>
  ) => {
    const email = e.target.value as string;
    setAddUserEmail(email);
    // if (addUserEmailInputProps.error) triggerAddUserEmailValidation(email);
  };

  const handleAddUserPasswordChange = (
    e: React.ChangeEvent<{ value: unknown }>
  ) => {
    const passwordString = (e.target.value as string).trim();
    setAddUserPassword(passwordString);
    triggerAddUserPasswordValidation(passwordString);
  };

  const handleAddUserRoleChange = (
    event: React.ChangeEvent<{ value: unknown }>,
    value: string
  ) => {
    setAddUserRole(value);
    if (addUserRoleInputProps.error) triggerAddUserRoleValidation();
  };

  const submitAddUser = async () => {
    await addNewUser();
    resetAddUserFields();
  };

  const submitUpdateUser = async () => {
    await updateUserProfile();
  };

  const resetAddUserFields = () => {
    setAddUserEmail("");
    setAddUserPassword("");
  };

  const resetUpdateUserFields = () => {
    setState({
      id: 0,
      isActive: false,
      userName: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      workPhoneNumber: "",
      city: "",
      role: "",
      note: "",
      password: "",
      selectedState: { abbreviation: "", name: "", id: 0 },
      formDisabled: true,
    });
    setRegionsSelectKey(new Date().toUTCString());
    setPreselectedRegions([]);
  };
  const [regionsSelectKey, setRegionsSelectKey] = useState(
    new Date().toUTCString()
  );

  return (
    <>
      <Grid
        container
        justify="center"
        alignItems="flex-start"
        spacing={1}
        className={`user_crud_forms_container ${classes.crudMarginTop}`}
      >
        <Grid item sm={6} xs={12} className="user_crud_form">
          <Paper className="user_crud_paper" elevation={0}>
            <Typography variant="h2">Update User Profile</Typography>
            <Grid
              container
              alignItems="center"
              className={`user_crud_checkbox_container ${classes.bottomBorder} ${classes.marginTop}`}
            >
              <Checkbox
                checked={formData.isActive !== "" && formData.isActive}
                color="primary"
                onChange={(e, value) => {
                  setState({ isActive: value });
                }}
                inputProps={{ "aria-label": "secondary checkbox" }}
                disabled={
                  formData.id ? formData.id === loggedInUser.user.id : true
                }
              />
              <Typography>Active</Typography>
            </Grid>
            <Grid container justify="center">
              <Grid
                container
                direction="column"
                justify="space-evenly"
                className="user_crud_form_fields"
              >
                <Autocomplete
                  disabled={
                    formData.id === loggedInUser.user.id ||
                    formData.formDisabled
                  }
                  options={formData.roles.map((options: any) => options.name)}
                  value={formData.role}
                  onChange={(e: any, value: object) =>
                    setState({ role: value })
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Role" variant="outlined" />
                  )}
                />
                <TextField
                  type="text"
                  name="user_name"
                  label="Username"
                  variant="outlined"
                  value={formData.userName}
                  disabled={formData.formDisabled}
                  onChange={handleUsernameChange}
                  onBlur={() => {
                    triggerUsernameValidation(formData.userName);
                  }}
                  error={usernameInputProps.error}
                />
                <span
                  className={`validation_error ${classes.marginBottom}`}
                  hidden={!usernameInputProps.error}
                >
                  {usernameInputProps.errorMessage}
                </span>

                <TextField
                  type="email"
                  name="email"
                  label="E-mail"
                  variant="outlined"
                  value={formData.email}
                  disabled={formData.formDisabled}
                  onChange={handleEmailChange}
                  // onBlur={() => {
                  //   triggerEmailValidation(formData.email);
                  // }}
                  // error={emailInputProps.error}
                />
                {/* <span
                  className={`validation_error ${classes.marginBottom}`}
                  hidden={!emailInputProps.error}
                >
                  {emailInputProps.errorMessage}
                </span> */}

                <TextField
                  type="text"
                  name="first_name"
                  label="First name"
                  variant="outlined"
                  value={formData.firstName ? formData.firstName : ""}
                  disabled={formData.formDisabled}
                  onChange={(e: any) => setState({ firstName: e.target.value })}
                />

                <TextField
                  type="text"
                  name="last_name"
                  label="Last name"
                  variant="outlined"
                  value={formData.lastName ? formData.lastName : ""}
                  disabled={formData.formDisabled}
                  onChange={(e: any) => setState({ lastName: e.target.value })}
                />

                <TextField
                  disabled={formData.formDisabled}
                  type="tel"
                  name="phone"
                  label="Personal phone"
                  variant="outlined"
                  value={
                    formData.phoneNumber !== undefined
                      ? formData.phoneNumber
                      : ""
                  }
                  onChange={(e: any) =>
                    setState({ phoneNumber: e.target.value })
                  }
                  inputProps={{ maxLength: 10 }}
                />
                <TextField
                  disabled={formData.formDisabled}
                  type="tel"
                  name="workphone"
                  label="Work phone"
                  variant="outlined"
                  value={
                    formData.workPhoneNumber !== undefined
                      ? formData.workPhoneNumber
                      : ""
                  }
                  onChange={(e: any) =>
                    setState({ workPhoneNumber: e.target.value })
                  }
                  inputProps={{ maxLength: 10 }}
                />
                <TextField
                  type="text"
                  name="city"
                  label="City"
                  variant="outlined"
                  value={formData.city ? formData.city : ""}
                  disabled={formData.formDisabled}
                  onChange={(e: any) => setState({ city: e.target.value })}
                />
                <Autocomplete
                  options={formData.states as CountryType[]}
                  disabled={formData.formDisabled}
                  getOptionLabel={(option) => {
                    return `${option.name || formData.selectedState.name}`;
                  }}
                  value={formData.selectedState.name}
                  onChange={(e: any, value: any) =>
                    setState({
                      selectedState: {
                        abbreviation: value?.abbreviation,
                        id: Number(value?.id),
                        name: value?.name,
                      },
                    })
                  }
                  renderOption={(option) => (
                    <span>
                      ({option.abbreviation}) {option.name}
                    </span>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="State"
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
                {formData.role === UserRole.Technician && (
                  <CheckboxesTagsSelect
                    uniqueKey={regionsSelectKey}
                    options={regions as []}
                    preselected={preselectedRegions as []}
                    optionLabel="name"
                    setSelected={setSelectedRegions}
                    inputLabel="Regions"
                    inputPlaceholder="Assign to a Region..."
                  />
                )}
                <TextField
                  type="text"
                  name="user_password"
                  label="Password"
                  variant="outlined"
                  className="hide_password_input_field"
                  disabled={formData.formDisabled}
                  onChange={handlePasswordChange}
                  onBlur={() => {
                    if (formData.password)
                      triggerPasswordValidation(formData.password);
                  }}
                  error={passwordInputProps.error}
                  value={formData.password}
                />
                <span
                  className={`validation_error ${classes.marginBottom}`}
                  hidden={!passwordInputProps.error}
                >
                  {passwordInputProps.errorMessage}
                </span>
                <TextField
                  multiline
                  rows={5}
                  type="text"
                  name="note"
                  label="Note"
                  variant="outlined"
                  disabled={formData.formDisabled}
                  onChange={(e: any) => setState({ userNote: e.target.value })}
                  value={formData.userNote !== "" ? formData.userNote : ""}
                />
              </Grid>
              <Grid>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={submitUpdateUser}
                  fullWidth
                  disabled={formData.formDisabled}
                >
                  UPDATE PROFILE
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item sm={6} xs={12} className="user_crud_form">
          <Paper className="user_crud_paper" elevation={0}>
            <Typography variant="h2">Add New User</Typography>
            <Grid
              container
              alignItems="center"
              className={`user_crud_checkbox_container ${classes.bottomBorder} ${classes.marginTop}`} /* style={{ background: '#f5f5f5' }}*/
            >
              <Checkbox
                color="primary"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
              <Typography>Send invite email</Typography>
            </Grid>
            <Grid container justify="center">
              <Grid
                container
                direction="column"
                justify="space-evenly"
                className="user_crud_form_fields"
              >
                <Autocomplete
                  options={formData.roles.map((options: any) => options.name)}
                  onChange={handleAddUserRoleChange}
                  value={addUserRole}
                  onBlur={() => {
                    triggerAddUserRoleValidation(addUserRole);
                  }}
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        error={addUserRoleInputProps.error}
                        label="Role"
                        variant="outlined"
                      />
                      <span className="validation_error">
                        {addUserRoleInputProps.errorMessage}
                      </span>
                    </>
                  )}
                />
                <TextField
                  type="text"
                  name="new_user_email"
                  label="Email"
                  variant="outlined"
                  onChange={handleAddUserEmailChange}
                  value={addUserEmail}
                  // onBlur={() => {
                  //   triggerAddUserEmailValidation(addUserEmail);
                  // }}
                  // error={addUserEmailInputProps.error}
                />
                {/* <span
                  className={`validation_error ${classes.marginBottom}`}
                  hidden={!addUserEmailInputProps.error}
                >
                  {addUserEmailInputProps.errorMessage}
                </span> */}

                <TextField
                  type="text"
                  name="new_user_password"
                  label="Password"
                  variant="outlined"
                  className="hide_password_input_field"
                  onChange={handleAddUserPasswordChange}
                  value={addUserPassword}
                  onBlur={() => {
                    triggerAddUserPasswordValidation(addUserPassword);
                  }}
                  error={addUserPasswordInputProps.error}
                />
                <span
                  className={`validation_error ${classes.marginBottom}`}
                  hidden={!addUserPasswordInputProps.error}
                >
                  {addUserPasswordInputProps.errorMessage}
                </span>
              </Grid>
              <Grid>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  fullWidth
                  onClick={submitAddUser}
                >
                  ADD NEW USER
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default UserCRUDForms;
