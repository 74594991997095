import React, { useState, useEffect, useReducer } from "react";
import Board from "react-trello";
import {
  makeStyles,
  Theme,
  createStyles,
  Paper,
  Button,
  Grid,
  Divider,
  Drawer,
  IconButton,
  useTheme,
} from "@material-ui/core";
import {
  CustomerClient,
  DispatchBoardColumnClient,
  DispatchClient,
  DispatchMoveVM,
  DispatchVM,
  TradeVM,
  TradeClient,
  DispatchStatusClient,
  LocationClient,
  PriorityClient,
  PriorityTypeVM,
  UserClient,
  DispatchUpdateVM,
  ServiceRequestCodeClient,
  DispatchBoardDispatchVM,
  DispatchSecondaryStatusClient,
  RegionClient,
} from "../../brines-refrigerator-api";
import StateSelect from "../../components/common/select/StateSelect";
import { Link } from "react-router-dom";
import FormDialog from "../../components/common/dialog/FormDialog";
import DispatchBoardCRUDForm from "./DispatchBoardCRUDForm/DispatchBoardCRUDForm";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import SyncIcon from "@material-ui/icons/Sync";
import { ColumnsDialog } from "./ColumnsDialog";
import TechColumnsDialog from "./TechColumnsDialog";
import UserRole from "../../helpers/constants/userRole";
import DispatchStatusType from "../../helpers/constants/dispatchStatusType";
import {
  Kanban,
  KanbanColumn,
  KanbanDataSource,
} from "smart-webcomponents-react/kanban";
import "smart-webcomponents-react/source/styles/smart.default.css";

const drawerWidth = 800;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      "& > *": {
        width: "100%",
        height: theme.spacing(10),
      },
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        width: 200,
      },
    },
    textfield: {
      marginLeft: "0.4rem",
    },
    button: {
      margin: "1.3rem",
      width: "max-content",
    },
    dialog: {
      width: "26rem",
      height: "20rem",
    },
    boardHeader: {
      zIndex: 1,
    },
    boardContainer: {
      alignItems: "start",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
    },
    disabledBoard: {
      opacity: 0.15,
      pointerEvents: "none",
    },
    boardTitle: {
      marginLeft: "0.9em",
    },
    boardMain: {
      width: "100%",
    },
    addDispatchButton: {
      marginLeft: "8em",
    },
    selectAndRefreshContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    syncButton: {
      height: "2rem",
    },
  })
);

interface DispatchBoardCRUDFormState {
  dispatch: DispatchVM;
}

interface Card {
  id: number;
  index: number;
  locationName: string;
  workOrderNumber?: string;
  city?: string;
  state?: string;
  dispatchStatus: DispatchStatusType;
  metadata: {
    date: Date;
    realIndex: number;
  };
  style: {};
  draggable: boolean;
}

export function DispatchBoardView() {
  const classes = useStyles();
  const theme = useTheme();
  const options = {
    weekday: "long",
    year: "numeric",
    day: "numeric",
    month: "numeric",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  const dateTimeFormat = new Intl.DateTimeFormat("en-US", options);

  const [data, setData] = useState({ lanes: [] });
  const [_states, _setStates] = useState([]);
  const [_state, _setState] = useState(
    Number(sessionStorage.getItem("boardState")) || 0
  );
  const [leftBoardColumns, setLeftBoardColumns] = useState<KanbanColumn[]>([]);
  const [techBoardColumns, setTechBoardColumns] = useState<KanbanColumn[]>([]);
  const [boardDataSource, setBoardDataSource] = useState<KanbanDataSource[]>(
    []
  );

  const [showColumnDialog, setShowColumnDialog] = useState(false);
  const [showTechColumnsDialog, setShowTechColumnsDialog] = useState(false);
  const openDialog = () => {
    setShowColumnDialog(true);
  };

  const resetColumnsDialog = () => {
    setShowColumnDialog(false);
  };

  const openTechColumnsDialog = () => {
    setShowTechColumnsDialog(true);
  };

  const resetTechColumnsDialog = async () => {
    setShowTechColumnsDialog(false);
    await refreshAndPopulateBoard(_state);
  };

  const onCardMoveAcrossLanes = async (
    fromLaneId: number,
    toLaneId: number,
    cardId: number,
    addedIndex: number
  ) => {
    if (cardId > 0) {
      const laneWithFakeCards = data.lanes.find((l) => l.id === toLaneId);
      const addedCard = data.lanes
        .find((l) => l.id === fromLaneId)
        .cards.find((c: Card) => c.id === cardId);
      if (laneWithFakeCards.cards.length === 0) {
        await updateColumns(toLaneId, cardId, 0);
      } else {
        if (laneWithFakeCards.cards[addedIndex] === undefined) {
          // card is dropped at the end of the lane
          const newIndex = getIndexFromSortedCards(
            laneWithFakeCards.cards,
            addedCard,
            fromLaneId === toLaneId
          );
          await updateColumns(toLaneId, cardId, newIndex);
          await populateBoard(_state);
          return;
        }
        const realIndex =
          laneWithFakeCards.cards[addedIndex].metadata.realIndex;
        if (
          areDatesEqual(
            laneWithFakeCards.cards[addedIndex].metadata.date,
            addedCard.metadata.date
          )
        ) {
          await updateColumns(toLaneId, cardId, realIndex);
        } else if (
          isDateLessThan(
            laneWithFakeCards.cards[addedIndex].metadata.date,
            addedCard.metadata.date
          )
        ) {
          const newIndex = getIndexFromSortedCards(
            laneWithFakeCards.cards,
            addedCard,
            fromLaneId === toLaneId,
            false
          );
          await updateColumns(toLaneId, cardId, newIndex);
        } else {
          const newIndex = getIndexFromSortedCards(
            laneWithFakeCards.cards,
            addedCard,
            fromLaneId === toLaneId
          );
          await updateColumns(toLaneId, cardId, newIndex);
        }
      }
    }
    await populateBoard(_state);
    refreshAndPopulateBoard(_state);
  };

  const getIndexFromSortedCards = (
    fakeAndRealCards: Card[],
    newCard: Card,
    sameLane: boolean,
    endPosition: boolean = true
  ): number => {
    const realCards = fakeAndRealCards.filter(
      (c) => c.id > 0 && (sameLane ? c.id !== newCard.id : true)
    );
    if (endPosition) {
      realCards.push(newCard);
    } else {
      realCards.unshift(newCard);
    }
    const sortedCards = realCards.sort((a, b) =>
      compareDates(a.metadata.date, b.metadata.date)
    );
    return sortedCards.findIndex((c) => c.id === newCard.id);
  };

  /**
   *
   * 0 - a and be are equal
   *
   * -1 - a is less than b
   *
   * 1 - a is greater than b
   */
  const compareDates = (a: Date, b: Date) => {
    if (areDatesEqual(a, b)) {
      return 0;
    }
    if (isDateLessThan(a, b)) {
      return -1;
    }
    return 1;
  };

  const updateColumns = async (
    toLaneId: number,
    cardId: number,
    index: number
  ) => {
    const lane = data.lanes.find((l) => l.id === toLaneId);
    if (lane.isTechColumn) {
      await dispatchClient.moveToTechColumn(
        new DispatchMoveVM({
          id: cardId,
          toColumnId: toLaneId,
          index: index,
        })
      );
    } else {
      await dispatchClient.moveToColumn(
        new DispatchMoveVM({
          id: cardId,
          toColumnId: toLaneId,
          index: index,
        })
      );
    }
  };

  async function getStates() {
    const regionClient = new RegionClient();
    const regions = await regionClient.get();
    _setStates(regions);
  }

  const laneStyle = {
    backgroundColor: "white",
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    borderRadius: "4px",
    minWidth: "7.5px",
    maxWidth: "10em",
  };

  const separatorLaneStyle = {
    backgroundColor: "light-gray",
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    borderRadius: "4px",
    height: "100vh",
    width: "0.5rem",
    padding: "0",
    margin: "0 1rem",
    minWidth: "0.5rem",
    maxWidth: "0.5rem",
  };

  const onTaskRender = (task, data) => {
    task.style.background = data.priority;
    task.style.color = getTextColorFromBackground(data.priority);
  };

  const getTextColorFromBackground = function (hexcolor: string) {
    // If a leading #  of (hexcolor) is provided, remove it
    if (hexcolor.slice(0, 1) === "#") {
      hexcolor = hexcolor.slice(1);
    }

    // If a three-character hexcolor, make six-character
    if (hexcolor.length === 3) {
      hexcolor = hexcolor
        .split("")
        .map(function (hex) {
          return hex + hex;
        })
        .join("");
    }

    // Convert hexcolor to RGB value
    let r = parseInt(hexcolor.substr(0, 2), 16);
    let g = parseInt(hexcolor.substr(2, 2), 16);
    let b = parseInt(hexcolor.substr(4, 2), 16);

    // Get YIQ ratio from RGB value
    let yiq = (r * 299 + g * 587 + b * 114) / 1000;

    // Check contrast and set color text
    return yiq >= 128 ? "black" : "white";
  };

  const refreshAndPopulateBoard = async (stateId: number) => {
    setData({ lanes: [] });
    await populateBoard(stateId);
  };

  async function populateBoard(stateId: number) {
    _setState(stateId);
    sessionStorage.setItem("boardState", stateId.toString());
    if (stateId === 0) {
      setData({ lanes: [] });
      return;
    }
    const boardClient = new DispatchBoardColumnClient();
    const columns =
      stateId === 0 ? [] : await boardClient.getByRegionId(stateId);
    columns.sort((a, b) => a.index - b.index);
    setLeftBoardColumns(
      columns.map(
        (column) =>
          ({
            // style: laneStyle,
            id: column.id,
            // index: column.index,
            label: column.name,
            dataField: column.name,
            count: column.dispatches.length,
            // cards: mapToCards(column.dispatches),
            // isTechColumn: false,
            // droppable: column.name === "Dispatch Review" ? false : true,
          } as KanbanColumn)
      )
    );

    const cards: KanbanDataSource[] = [];
    columns.forEach((column) => {
      column.dispatches.forEach((dispatch) => {
        cards.push({
          id: dispatch.id,
          status: column.name,
          text: `${dispatch.location.name} #${dispatch.location.number}\n
          ${
            dispatch.workOrderNumber ? dispatch.workOrderNumber.toString() : ""
          }\n
           ${dispatch.location.city}\n
           ${dispatch.location.state.abbreviation}`,
          priority: dispatch.priority
            ? `#${dispatch.priority.hexCode}`
            : "#FFFFFF",
        });
      });
    });

    const data = {
      lanes: columns.map((column) => ({
        style: laneStyle,
        id: column.id,
        index: column.index,
        title: column.name,
        cards: mapToCards(column.dispatches),
        isTechColumn: false,
        droppable: column.name === "Dispatch Review" ? false : true,
      })),
    };

    const techColumns =
      stateId === 0 ? [] : await boardClient.getTechColumnsByRegionId(stateId);
    techColumns.sort((a, b) => a.index - b.index);
    setTechBoardColumns(
      techColumns.map(
        (column) =>
          ({
            // style: laneStyle,
            id: column.id,
            // index: column.index,
            label: column.name,
            dataField: column.name,
            count: column.dispatches.length,
            // cards: mapToCards(column.dispatches),
            // isTechColumn: true,
            // droppable: true,
          } as KanbanColumn)
      )
    );

    const techColumnData = {
      lanes: techColumns.map((column) => ({
        style: laneStyle,
        id: column.id,
        index: column.index,
        title: column.name,
        cards: mapToCards(column.dispatches),
        isTechColumn: true,
        droppable: true,
      })),
    };

    techColumns.forEach((column) => {
      column.dispatches.forEach((dispatch) => {
        cards.push({
          id: dispatch.id,
          status: column.name,
          text: `${dispatch.location.name} #${dispatch.location.number}\n
          ${
            dispatch.workOrderNumber ? dispatch.workOrderNumber.toString() : ""
          }\n
           ${dispatch.location.city}\n
           ${dispatch.location.state.abbreviation}`,
          priority: dispatch.priority
            ? `#${dispatch.priority.hexCode}`
            : "#FFFFFF",
        });
      });
    });

    data.lanes = [...data.lanes.concat(techColumnData.lanes)];

    data.lanes.sort((a, b) => a.index - b.index);

    cards.sort((a, b) => (a as any).index - (b as any).index);
    setBoardDataSource(cards);

    // insert separators
    const separatorColumn = {
      id: null,
      title: null,
      style: separatorLaneStyle,
      index: null,
      cards: [],
      isTechColumn: false,
      droppable: false,
    };
    const firstTechColumnIndex = data.lanes.findIndex((c) => c.isTechColumn);
    data.lanes.splice(
      firstTechColumnIndex < 0 ? data.lanes.length : firstTechColumnIndex,
      0,
      separatorColumn
    );

    if (
      [...data.lanes].pop().isTechColumn ||
      [...data.lanes].pop().index === null
    ) {
      data.lanes.push(separatorColumn);
    } else {
      data.lanes.splice(
        [...data.lanes].reverse()[
          [...data.lanes].reverse().findIndex((c) => c.isTechColumn)
        ].index + 2,
        0,
        separatorColumn
      );
    }

    setData(data);
  }

  function getCardStyle(dispatchPriority: PriorityTypeVM | null) {
    let hexCode;

    if (dispatchPriority == null) {
      hexCode = "#FFFFFF";
    } else {
      hexCode = `#${dispatchPriority.hexCode}`;
    }

    return {
      backgroundColor: hexCode,
      minWidth: "7.5em",
      filter: "contrast(85%)",
      color: getTextColorFromBackground(hexCode),
    };
  }

  const mapToCards = (dispatches: DispatchBoardDispatchVM[]): Card[] => {
    const cards: Card[] = [];
    const sortedDispatches = dispatches.sort((a, b) => {
      if (!a.followUpDate) return -1;
      else return a.dispatchBoardColumnIndex - b.dispatchBoardColumnIndex;
    });
    let followUpDate: Date = sortedDispatches[0]?.followUpDate;
    let numberOfFakeCards = 0;

    sortedDispatches.forEach((dispatch, index) => {
      if (!areDatesEqual(followUpDate, dispatch.followUpDate) || index === 0) {
        followUpDate = dispatch.followUpDate;
        numberOfFakeCards = numberOfFakeCards + 1;
        cards.push({
          id: -1 * index,
          index: dispatch.dispatchBoardColumnIndex + numberOfFakeCards - 1,
          locationName: dispatch.followUpDate
            ? dateTimeFormat.format(dispatch.followUpDate)
            : "No Follow Up",
          dispatchStatus: DispatchStatusType.Unassigned,
          style: {
            backgroundColor: "white",
            maxWidth: "9.375em",
            minWidth: "7.5em",
          },
          draggable: false,
          metadata: {
            date: dispatch.followUpDate,
            realIndex: dispatch.dispatchBoardColumnIndex,
          },
        });
      }
      cards.push({
        id: dispatch.id,
        index: dispatch.dispatchBoardColumnIndex + numberOfFakeCards,
        locationName: `${dispatch.location.name} #${dispatch.location.number}`,
        workOrderNumber: dispatch.workOrderNumber
          ? dispatch.workOrderNumber.toString()
          : null,
        city: dispatch.location.city,
        state: dispatch.location.state.abbreviation,
        dispatchStatus: dispatch.statusId as DispatchStatusType,
        style: getCardStyle(dispatch.priority),
        draggable: true,
        metadata: {
          date: dispatch.followUpDate,
          realIndex: dispatch.dispatchBoardColumnIndex,
        },
      });
    });

    return cards;
  };

  const areDatesEqual = (first: Date, second: Date): boolean => {
    if (!first && !second) return true;
    if ((first && !second) || (!first && second)) return false;
    if (first && second) {
      if (first.getFullYear() !== second.getFullYear()) return false;
      if (first.getMonth() !== second.getMonth()) return false;
      if (first.getDate() !== second.getDate()) return false;
    }
    return true;
  };

  const isDateLessThan = (first: Date, second: Date): boolean => {
    if (!first && !second) return false;
    if ((first && !second) || (!first && second)) return false;
    if (first.getFullYear() < second.getFullYear()) return true;
    if (
      first.getFullYear() === second.getFullYear() &&
      first.getMonth() < second.getMonth()
    )
      return true;
    if (
      first.getFullYear() === second.getFullYear() &&
      first.getMonth() === second.getMonth() &&
      first.getDate() < second.getDate()
    )
      return true;
    return false;
  };

  //crud form data
  const dispatchClient = new DispatchClient();

  const [trades, setTrades] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [secondaryStatuses, setSecondaryStatuses] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const [locations, setLocations] = useState([]);
  const [users, setUsers] = useState([]);
  const [serviceRequestCodes, setServiceRequestCodes] = useState([]);

  async function getTrades() {
    const tradesClient = new TradeClient();
    const trades = await tradesClient.getAll();
    trades.push(
      new TradeVM({
        id: 0,
        name: "N/A",
        hexCode: "BCBCBC",
      })
    );
    setTrades(trades);
  }

  async function getStatuses() {
    const statusesClient = new DispatchStatusClient();
    const statuses = await statusesClient.getAll();
    setStatuses(statuses);
  }

  async function getSecondaryStatuses() {
    const statusesClient = new DispatchSecondaryStatusClient();
    const statuses = await statusesClient.getAll();
    setSecondaryStatuses(statuses);
  }

  async function getCustomers() {
    const customersClient = new CustomerClient();
    const customers = await customersClient.getCustomersBase();
    setCustomers(customers);
  }

  async function getLocations() {
    const locationsClient = new LocationClient();
    const locations = await locationsClient.getLocationsDispatchHq();
    setLocations(locations);
  }

  async function getPriorities() {
    const prioritiesClient = new PriorityClient();
    const priorities = await prioritiesClient.get();
    priorities.push(
      new PriorityTypeVM({
        id: 0,
        name: "N/A",
        hexCode: "BCBCBC",
      })
    );
    setPriorities(priorities);
  }

  async function getUsers() {
    const usersClient = new UserClient();
    const users = await usersClient.usersByRole(UserRole.Technician);
    setUsers(users);
  }

  const getServiceRequestCodes = async () => {
    const serviceRequestCodesClient = new ServiceRequestCodeClient();
    const serviceRequestCodes = await serviceRequestCodesClient.get();
    setServiceRequestCodes(serviceRequestCodes);
  };

  const populateCRUDForm = async () => {
    await getTrades();
    await getStatuses();
    await getSecondaryStatuses();
    await getCustomers();
    await getLocations();
    await getPriorities();
    await getUsers();
    await getServiceRequestCodes();
  };

  useEffect(() => {
    getStates();
    populateCRUDForm();
    refreshAndPopulateBoard(_state);
  }, []);

  const [state, setState] = useReducer(
    (
      state: DispatchBoardCRUDFormState,
      newState: DispatchBoardCRUDFormState
    ) => ({ ...state, ...newState }),
    {
      dispatch: new DispatchVM({
        id: null,
        created: null,
        locationId: null,
        priorityId: null,
        tradeId: null,
        workOrderNumber: null,
        slaDate: null,
        tehnicianId: null,
        regionId: null,
        followUpDate: null,
        completedDate: null,
        statusId: null,
        secondaryStatusId: null,
      }),
    }
  );

  const setDispatchForEditing = async (dispatchId: number) => {
    handleDrawerOpen();
    const selectedDispatch = await dispatchClient.getById(dispatchId);
    setState({
      ...state,
      dispatch: new DispatchVM({
        id: selectedDispatch.id,
        locationId: selectedDispatch.locationId,
        created: selectedDispatch.created,
        priorityId: selectedDispatch.priorityId,
        tradeId: selectedDispatch.tradeId,
        workOrderNumber: selectedDispatch.workOrderNumber,
        slaDate: selectedDispatch.slaDate,
        tehnicianId: selectedDispatch.tehnicianId,
        regionId: selectedDispatch.regionId,
        followUpDate: selectedDispatch.followUpDate,
        completedDate: selectedDispatch.completedDate,
        statusId: selectedDispatch.statusId,
        secondaryStatusId: selectedDispatch.secondaryStatusId,
      }),
    });
  };

  const updateDispatch = async (props: DispatchUpdateVM) => {
    try {
      await dispatchClient.put(
        new DispatchUpdateVM({
          ...props,
        })
      );
      await populateBoard(_state);
      handleDrawerClose();
    } catch (err) {
      throw new Error(err);
    }
  };

  const clearFields = () => {
    handleDrawerClose();
    setState({
      ...state,
      dispatch: new DispatchVM({
        id: null,
        locationId: null,
        priorityId: null,
        tradeId: null,
        workOrderNumber: null,
        slaDate: null,
        tehnicianId: null,
        regionId: null,
        followUpDate: null,
        completedDate: null,
        statusId: null,
        secondaryStatusId: null,
      }),
    });
  };

  //============== DRAWER =============//
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [disabledBoard, setDisabledBoard] = useState(false);

  const handleDrawerOpen = () => {
    setDisabledBoard(true);
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDisabledBoard(false);
    setDrawerOpen(false);
  };

  //============== DRAWER =============//
  const syncBoardData = async () => {
    handleDrawerClose();
    await populateBoard(_state);
  };

  const getColumnIdAndCount = (status: string) => {
    let result = leftBoardColumns.find((c) => c.dataField === status);
    if (result) {
      return { id: result["id"], count: result["count"] };
    }
    result = techBoardColumns.find((c) => c.dataField === status);
    return result ? { id: result["id"], count: result["count"] } : null;
  };

  const onChange = async (e: any) => {
    debugger;
    // alert(
    //   "card id: " +
    //     e.detail.value.id +
    //     "\n old column: " +
    //     e.detail.oldValue.status +
    //     " " +
    //     getColumnIdAndCount(e.detail.oldValue.status).id +
    //     " " +
    //     getColumnIdAndCount(e.detail.oldValue.status).count +
    //     "\n new column: " +
    //     e.detail.value.status +
    //     " " +
    //     getColumnIdAndCount(e.detail.value.status).id +
    //     " " +
    //     getColumnIdAndCount(e.detail.value.status).count
    // );
    const newColumn = getColumnIdAndCount(e.detail.value.status);
    await updateColumns(newColumn.id, e.detail.value.id, newColumn.count);
    await populateBoard(_state);
  };

  return (
    <>
      <div className={classes.root}>
        <Paper elevation={3} className={classes.boardHeader}>
          <Grid item container xs={12}>
            <Grid item xs={1}>
              <h1 className={classes.boardTitle}>BOARD</h1>
            </Grid>
            <Grid item xs={2} className={classes.selectAndRefreshContainer}>
              <StateSelect
                getState={refreshAndPopulateBoard}
                states={_states}
              />
              <Button onClick={syncBoardData} className={classes.syncButton}>
                <SyncIcon color="primary" />
              </Button>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={1}>
              <Button
                variant="outlined"
                color="primary"
                className={classes.button}
                onClick={openDialog}
                disabled={_state === 0}
              >
                Columns
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="outlined"
                color="primary"
                className={classes.button}
                onClick={openTechColumnsDialog}
                disabled={
                  _state === 0 || !data.lanes.some((lane) => lane.isTechColumn)
                }
              >
                Tech columns
              </Button>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={1}>
              <Link to="/dispatch">
                <Button
                  variant="contained"
                  color="primary"
                  className={`${classes.button} ${classes.addDispatchButton}`}
                >
                  Add Dispatch
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Paper>
      </div>
      {showColumnDialog && (
        <FormDialog
          open={showColumnDialog}
          body={
            <ColumnsDialog
              data={data}
              _state={_state}
              populateBoard={refreshAndPopulateBoard}
              resetColumnsDialog={resetColumnsDialog}
            />
          }
        />
      )}
      {showTechColumnsDialog && (
        <FormDialog
          open={showTechColumnsDialog}
          body={
            <TechColumnsDialog
              stateId={_state}
              resetTechColumnsDialog={resetTechColumnsDialog}
            />
          }
        />
      )}
      {/*<Grid container className={classes.boardContainer}>
        <Grid
          item
          container
          xs={12}
          className={!disabledBoard ? "" : `${classes.disabledBoard}`}
          style={{ overflowX: "scroll" }}
        >
          <Board
            onCardMoveAcrossLanes={onCardMoveAcrossLanes}
            data={data}
            cardDraggable={true}
            hideCardDeleteIcon={true}
            style={{ backgroundColor: "white" }}
            onCardClick={async (cardId: number) => {
              if (cardId > 0) await setDispatchForEditing(cardId);
            }}
            components={{ Card: CustomCard }}
            className={classes.boardMain}
          />
        </Grid>
      </Grid> */}
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={drawerOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <DispatchBoardCRUDForm
          trades={trades}
          statuses={statuses}
          secondaryStatuses={secondaryStatuses}
          customers={customers}
          locations={locations}
          priorities={priorities}
          technicians={users}
          serviceRequestCodes={serviceRequestCodes}
          dispatch={state.dispatch}
          formAction={updateDispatch}
          clearFields={clearFields}
          showGenerateInvoiceButton={false}
        />
      </Drawer>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "80vh",
        }}
      >
        <div>
          <Kanban
            id="phaseOneKanban"
            dataSource={boardDataSource}
            columns={leftBoardColumns}
            onTaskClick={(e: any) => setDispatchForEditing(e.detail.id)}
            onChange={onChange}
            onTaskRender={onTaskRender}
          ></Kanban>
        </div>
        <div
          style={{
            overflow: "scroll",
            width: "70vw",
          }}
        >
          {techBoardColumns.map((column) => (
            <Kanban
              dataSource={boardDataSource}
              columns={[column]}
              onTaskClick={(e: any) => setDispatchForEditing(e.detail.id)}
              onChange={onChange}
              onTaskRender={onTaskRender}
            ></Kanban>
          ))}
        </div>
      </div>
    </>
  );
}
