import React, { useEffect, useReducer, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  Accordion,
  AccordionDetails,
  Typography,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import { useHistory, useLocation } from "react-router-dom";
import {
  DispatchVM,
  PartVM,
  EquipmentVM,
  DocumentTypeVM,
  ManufacturerClient,
  ManufacturerVM,
  PartAddVM,
  EquipmentNoteVM,
  EquipmentClient,
  EquipmentNoteCreateVM,
  EquipmentDocumentVM,
  EquipmentCreateVM,
  CustomerClient,
  EquipmentTypeClient,
  LocationClient,
  PartPriceVM,
  DispatchPartVM,
  DispatchEquipmentVM,
} from "../../brines-refrigerator-api";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useStyles } from "./TechDispatchViewStyle";
import TechDispatchDetails from "../../components/tech/TechDispatchDetails";
import SignatureSection from "./Signature/SignatureSection";
import TechDocuments from "../../components/tech/TechDocuments";
import UploadButton from "../../components/common/upload/UploadButton";
import UploadButtonType from "../../helpers/constants/uploadButtonTypes";
import TechNotes from "../../components/tech/TechServiceNotes/TechNotes";
import AddNote from "../../components/common/note/AddNote";
import FormDialog from "../../components/common/dialog/FormDialog";
import DispatchPartsUsed from "../../components/tech/DispatchPartsUsed/DispatchPartsUsed";
import PartsItemsList from "../../components/tech/PartsItems/PartsItemsLists";
import TechContacts from "./TechContacts";
import TechLocation from "./TechLocation";
import TechEquipment from "./TechEquipment";
import TechEquipmentAdd from "./TechEquipmentAdd";
import * as client from "../../helpers/offline/offline-api";
import TechVisits from "./TechVisits";
import { useSnackbar } from "notistack";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setCachedRequestsSync } from "../../global-state/actions/cachedRequestsActions";
import camera from "../../helpers/camera";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import PartDetails from "../../components/admin/PartsList/PartDetails";
import {
  EquipmentDocumentAddVM,
  UploadFileClient,
} from "../../brines-refrigerator-api-extended";
import { redirectIfSessionExpired } from "../../components/common/redirect/RedirectOnSessionTimeout";
import { downloadFile } from "../../helpers/download";
import TechEquipmentCRUDForm from "./TechEquipmentCRUDForm";

const AccordionSummary = withStyles({
  expandIcon: {
    position: "absolute",
    left: "1%",
  },
})(MuiAccordionSummary);

export default function TechDispatch() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [_getDispatch] = client.useGetDispatch();
  const [_getDispatchNotes] = client.useGetDispatchNotes();
  const [_getServiceNotes] = client.useGetServiceNotes();
  const [_getDispatchDocuments] = client.useGetDispatchDocuments();
  const [_getDispatchParts] = client.useGetDispatchParts();
  const [_getDispatchEquipment] = client.useGetDispatchEquipment();
  const [_getDispatchItems] = client.useGetDispatchItems();
  const [_addDispatchNote] = client.useAddDispatchNote();
  const [_addServiceNote] = client.useAddServiceNote();
  const [_updateDispatchParts] = client.useUpdateDispatchParts();
  const [_addItemToDispatch] = client.useAddItemToDispatch();
  const [_updateDispatchEquipment] = client.useUpdateDispatchEquipment();
  const [_addDocument] = client.useAddDocument();
  const [_getVisits] = client.useGetVisits();
  const [_getClockInQuestions] = client.useGetClockInQuestions();
  const [_getClockOutQuestions] = client.useGetClockOutQuestions();
  const [_visitStartTravel] = client.useVisitStartTravel();
  const [_getDocumentTypes] = client.useGetDocumentTypes();
  const [_addPart] = client.useAddPart();
  const dispatchAction = useDispatch();
  const onlineState = useSelector(
    (state) =>
      (state as { onlineStateReducer: { currState: { online: boolean } } })
        .onlineStateReducer.currState.online,
    shallowEqual
  );

  const options = {
    weekday: "long",
    year: "numeric",
    day: "numeric",
    month: "numeric",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  const dateTimeFormat = new Intl.DateTimeFormat("en-US", options);

  const optionsWithTime = {
    year: "numeric",
    day: "numeric",
    month: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  const dateTimeFormatPhotoTitle = new Intl.DateTimeFormat(
    "en-US",
    optionsWithTime
  );

  const manufacturerClient = new ManufacturerClient();

  function handleBackClick() {
    history.goBack();
  }

  // ERROR HANDLING //
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [dispatch, setDispatch] = useState<DispatchVM>();
  const [loader, setLoader] = useState(true);
  const [dispatchId, setDispatchId] = useState(0);

  // GET DISPATCH WITH DISPATCH ID SENT FROM TECH VIEW
  async function getDispatch() {
    try {
      const dispatch = await _getDispatch(Number(location.state));
      setDispatchId(dispatch.id);
      setDispatch(dispatch);
      setState({
        ...state,
        equipment: {
          id: 0,
          manufacturer: { id: 0, name: "" },
          model: "",
          type: { id: 0, name: "" },
          serialNumber: "",
          unitNumber: "",
          qrAssetCode: "",
          roomLocation: "",
          notes: [],
          customer_name: {
            id: dispatch.location.customer.id,
            company: dispatch.location.customer.company,
          },
          customer_location: {
            id: dispatch.location.id,
            name: dispatch.location.name,
          },
        },
        formTitle: "ADD EQUIPMENT",
        formAction: addNewEquipment,
      });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  // DISPATCH NOTES
  const [dispatchNotes, setDispatchNotes] = useState([]);

  async function getDispatchNotes() {
    try {
      const dispatchNotes = await _getDispatchNotes(
        Number(location.state),
        true
      );
      setDispatchNotes(dispatchNotes);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  // SERVICE NOTES
  const [serviceNotes, setServiceNotes] = useState([]);

  async function getServiceNotes() {
    try {
      const serviceNotes = await _getServiceNotes(Number(location.state), true);
      setServiceNotes(serviceNotes);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  // DISPATCH DOCUMENTS
  const [dispatchDocuments, setDispatchDocuments] = useState([]);

  async function getDispatchDocuments() {
    try {
      const documents = await _getDispatchDocuments(Number(location.state));
      setDispatchDocuments(documents);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  //VISITS
  const [visits, setVisits] = useState([]);
  const [questions, setQuestions] = useState([]);

  const getVisits = async () => {
    try {
      const visits = await _getVisits(Number(location.state));
      setVisits(visits);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  //QUESTIONS

  const getQuestions = async () => {
    const clockInQuestions = await _getClockInQuestions();
    const clockOutQuestions = await _getClockOutQuestions();

    setQuestions([
      clockInQuestions[0].text,
      clockInQuestions[1].text,
      clockOutQuestions[0].text,
      clockOutQuestions[1].text,
    ]);
  };

  const [documentTypes, setDocumentTypes] = useState([]);

  async function getDocumentTypes() {
    try {
      const documentTypes = await _getDocumentTypes();
      setDocumentTypes(
        documentTypes.map((e: DocumentTypeVM) => `.${e.extension}`)
      );
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    const fetchDataAsync = async () => {
      await getDispatch();
      await getDispatchNotes();
      await getVisits();
      await getQuestions();
      await getDispatchDocuments();
      await getServiceNotes();
      await checkClockInOutState();
      await getDispatchParts();
      await getDispatchEquipment();
      await getDispatchItems();
      await getDocumentTypes();
      await getManufacturers();
      setLoader(false);
    };
    fetchDataAsync();
  }, []);

  //CLOCK IN OUT
  const [ongoingVisitId, setOngoingVisitId] = useState(null);
  const [visitStatus, setVisitStatus] = useState(null);
  const checkClockInOutState = async () => {
    try {
      const visits = await _getVisits(Number(location.state));
      const unfinishedVisit = visits.find((x) => !x.finished);
      if (unfinishedVisit) {
        const clockIn = unfinishedVisit.clockIn;
        clockIn.arrivedOnSite
          ? setVisitStatus("on-site")
          : setVisitStatus("travelled");
        setOngoingVisitId(unfinishedVisit.id);
      } else {
        setVisitStatus("not-travelled");
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  // PLACEHOLDERS FOR BUTTON ACTIONS
  const openNotes = (event) => {
    event.stopPropagation();
    alert("placeholder");
  };

  //ADD DISPATCH NOTE
  const [dispatchNoteModal, setDispatchNoteModal] = useState(false);

  const addDispatchNoteModal = (event) => {
    event.stopPropagation();
    setDispatchNoteModal(true);
  };

  const addDispatchNote = async (id: number, noteText: string) => {
    try {
      await _addDispatchNote(id, noteText);
      getDispatchNotes();
      setDispatchNoteModal(false);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const dispatchAddNote = (
    <AddNote
      buttonAction={addDispatchNote}
      id={Number(location.state)}
      noteText={""}
      title={"Add Note"}
      buttonText={"Add Note"}
      onClick={() => setDispatchNoteModal(false)}
      uid={null}
    />
  );

  //ADD SERVICE NOTE
  const [serviceNoteModal, setServiceNoteModal] = useState(false);

  const addServiceNoteModal = (event) => {
    event.stopPropagation();
    setServiceNoteModal(true);
  };

  const addServiceNote = async (id: number, noteText: string) => {
    try {
      await _addServiceNote(id, noteText);
      getServiceNotes();
      setServiceNoteModal(false);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };
  const serviceAddNote = (
    <AddNote
      buttonAction={addServiceNote}
      id={Number(location.state)}
      noteText={""}
      title={"Add Note"}
      buttonText={"Add Note"}
      onClick={() => setServiceNoteModal(false)}
      uid={null}
    />
  );
  //============================//

  // DISPATCH PARTS //
  const [addPartsModal, setAddPartsModal] = useState(false);
  const [dispatchParts, setDispatchParts] = useState([]);

  const closePartsModal = () => {
    setAddPartsModal(false);
  };

  const [enterNewPartsModal, setEnterNewPartsModal] = useState(false);

  const openNewPartsModal = () => {
    setEnterNewPartsModal(true);
  };

  const getDispatchParts = async () => {
    try {
      const dispatchParts = await _getDispatchParts(Number(location.state));
      setDispatchParts(dispatchParts);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const updateDispatchParts = async (selectedParts: PartVM[]) => {
    //take the existing part
    const existingPart = dispatch.dispatchParts.map(
      (e) => new PartVM({ ...e.part, price: e.partPrice })
    );
    //copy into new part
    const newParts = existingPart.slice(0, existingPart.length);
    //add each piece of part unless it's already added before
    selectedParts.forEach((elem) => {
      if (!existingPart.some((ele) => ele.id === elem.id)) newParts.push(elem);
    });

    try {
      const mappedParts = newParts.map(
        (elem) => new PartPriceVM({ id: elem.id, price: elem.price })
      );
      await _updateDispatchParts(dispatch.id, mappedParts);
      await getDispatchParts();
      await getDispatch();
      enqueueSnackbar("Updated dispatch parts successfully.", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const updateDispatchPartsPrices = async (dispatchParts: DispatchPartVM[]) => {
    try {
      const mappedParts = dispatchParts.map(
        (elem) =>
          new PartPriceVM({
            id: elem.part.id,
            price: elem.partPrice,
            quantity: elem.partQuantity,
          })
      );
      await _updateDispatchParts(dispatch.id, mappedParts);
      await getDispatchParts();
      await getDispatch();
      enqueueSnackbar("Updated dispatch parts prices successfully.", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const addParts = (event) => {
    event.stopPropagation();
    setAddPartsModal(true);
  };

  const [dispatchItems, setDispatchItems] = useState([]);

  const getDispatchItems = async () => {
    try {
      const dispatchitems = await _getDispatchItems(Number(location.state));
      setDispatchItems(dispatchitems);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const addItemToDispatch = async (
    itemDescription: string,
    itemPrice: number
  ) => {
    try {
      await _addItemToDispatch(
        Number(location.state),
        itemDescription,
        itemPrice
      );
      getDispatchItems();
      enqueueSnackbar("Added dispatch item successfully.", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const dispatchPartsUsed = (
    <DispatchPartsUsed
      dispatch={dispatch}
      closePartsModal={closePartsModal}
      updateDispatchParts={updateDispatchParts}
      addItemToDispatch={addItemToDispatch}
      enterPart={openNewPartsModal}
    />
  );

  const [manufacturers, setManufacturers] = useState([]);

  async function getManufacturers() {
    try {
      const manufacturers = await manufacturerClient.get();
      setManufacturers(manufacturers);
    } catch (error) {
      enqueueSnackbar("Error getting manufacturers.", { variant: "error" });
    }
  }

  const [newPart, setNewPart] = useState(
    new PartVM({
      id: 0,
      number: 0,
      name: "",
      source: "",
      manufacturer: new ManufacturerVM({ id: 0, name: "" }),
      description: "",
      price: 0,
    })
  );

  const clearPartFields = () => {
    setEnterNewPartsModal(false);
    setNewPart(
      new PartVM({
        id: 0,
        number: 0,
        name: "",
        source: "",
        manufacturer: new ManufacturerVM({ id: 0, name: "" }),
        description: "",
        price: 0,
      })
    );
  };

  const addPart = async (
    e: React.FormEvent<HTMLFormElement>,
    id: number,
    manufacturer: ManufacturerVM
  ) => {
    e.preventDefault();
    const form = e.currentTarget;
    const part = new PartAddVM({
      number:
        form.partNumber.value === 0 ? null : Number(form.partNumber.value),
      name: form.partName.value,
      source: form.source.value,
      manufacturerId: manufacturer.id === 0 ? null : manufacturer.id,
      description: form.description.value,
      price: form.price.value === 0 ? null : Number(form.price.value),
    });
    try {
      const createdPart = await _addPart(part);

      enqueueSnackbar("Part added successfully.", { variant: "success" });
      setEnterNewPartsModal(false);
      clearPartFields();

      await updateDispatchParts([createdPart]);
    } catch (error) {
      enqueueSnackbar("Error adding part.", { variant: "error" });
    }
  };

  const enterNewPartsBody = (
    <PartDetails
      clearFields={clearPartFields}
      part={newPart}
      formAction={addPart}
      manufacturers={manufacturers}
      formTitle="Add Part"
      formButtonTitle="Add Part"
    />
  );
  //==================================//

  /// DISPATCH EQUIPMENT //
  const [addEquipmentModal, setAddEquipmentModal] = useState(false);
  const [dispatchEquipment, setDispatchEquipment] = useState([]);

  const closeEquipmentModal = () => {
    setAddEquipmentModal(false);
  };

  const getDispatchEquipment = async (): Promise<DispatchEquipmentVM[]> => {
    try {
      const dispatchEquipment = await _getDispatchEquipment(
        Number(location.state)
      );
      setDispatchEquipment(dispatchEquipment);

      return dispatchEquipment;
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const updateDispatchEquipment = async (selectedEquipment: EquipmentVM[]) => {
    //take the existing part
    const existingEquipment = (
      dispatchEquipment.length === 0
        ? await getDispatchEquipment()
        : dispatchEquipment
    ).map((e) => e.equipment);
    //copy into new part
    const newEquipment = existingEquipment.slice(0, existingEquipment.length);
    //add each piece of part unless it's already added before
    selectedEquipment.forEach((elem) => {
      if (!existingEquipment.some((ele) => ele.id === elem.id))
        newEquipment.push(elem);
    });

    try {
      const mappedEquipment = newEquipment.map((elem) => elem.id);
      await _updateDispatchEquipment(
        dispatch ? dispatch.id : Number(location.state),
        mappedEquipment
      );
      await getDispatchEquipment();
      await getDispatch();
      enqueueSnackbar("Updated dispatch equipment successfully.", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const addEquipment = (event) => {
    event.stopPropagation();
    setAddEquipmentModal(true);
  };

  const dispatchEquipmentUsed = (
    <TechEquipmentAdd
      closeEquipmentModal={closeEquipmentModal}
      updateDispatchEquipment={updateDispatchEquipment}
    />
  );

  /// ADD NEW EQUIPMENT

  ///ADD NEW EQUIPMENT
  const [addNewEquipmentModalOpen, setAddNewEquipmentModalOpen] =
    useState(false);
  const customerClient = new CustomerClient();
  const equipmentClient = new EquipmentClient();

  const [manufacturerList, setManufacturerList] = useState([]);
  const [equipmentTypeList, setEquipmentTypeList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [locationList, setLocationList] = useState([]);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      equipment: {
        id: 0,
        manufacturer: "",
        model: "",
        type: { id: 0, name: "" },
        serialNumber: "",
        unitNumber: "",
        qrAssetCode: "",
        roomLocation: "",
        notes: [],
        customer_name: { id: 0, company: "" },
        customer_location: { id: 0, name: "" },
      },
      formTitle: "ADD EQUIPMENT",
      formAction: addNewEquipment,
    }
  );

  const getManufacturerList = async () => {
    try {
      const manufacturerClient = new ManufacturerClient();
      const manufacturerList = await manufacturerClient.get();
      setManufacturerList(manufacturerList);
    } catch (error) {
      redirectIfSessionExpired(history, error);
      enqueueSnackbar("Error while getting manufacturers.", {
        variant: "error",
      });
    }
  };

  const getEquipmentTypeList = async () => {
    try {
      const equipmentTypeClient = new EquipmentTypeClient();
      const equipmentTypeList = await equipmentTypeClient.get();
      setEquipmentTypeList(equipmentTypeList);
    } catch (error) {
      redirectIfSessionExpired(history, error);
      enqueueSnackbar("Error while getting equipment type.", {
        variant: "error",
      });
    }
  };

  const getCustomerList = async () => {
    try {
      const customerList = await customerClient.get();
      setCustomerList(customerList);
    } catch (error) {
      redirectIfSessionExpired(history, error);
      enqueueSnackbar("Error while getting customers.", { variant: "error" });
    }
  };

  const getLocationList = async () => {
    try {
      const locationClient = new LocationClient();
      const locationList = await locationClient.get();
      setLocationList(locationList);
    } catch (error) {
      redirectIfSessionExpired(history, error);
      enqueueSnackbar("Error while getting locations.", { variant: "error" });
    }
  };

  useEffect(() => {
    const fetchDataAsync = async () => {
      getManufacturerList();
      getEquipmentTypeList();
      getCustomerList();
      getLocationList();
    };
    fetchDataAsync();
  }, []);

  interface StateFromCRUDForm {
    id: number;
    manufacturer: string;
    model: string;
    serialNumber: string;
    unitNumber: string;
    qrAssetCode: string;
    roomLocation: string;
    type: string;
    notes: [{ createdBy: string; created: string; text: string }];
    customer_name: { id: number; company: string };
    customer_location: { id: number; name: string };
  }

  const uploadDraftDocuments = async (
    id: number,
    draftDocuments: EquipmentDocumentAddVM[]
  ) => {
    try {
      const uploadClient = new UploadFileClient();
      for (const document of draftDocuments) {
        document.equipmentId = id;
        document.name = `${document.name}.${document.file.name
          .split(".")
          .pop()}`;
        await uploadClient.uploadEquipmentDocument(document);
      }
    } catch (error) {
      redirectIfSessionExpired(history, error);
      enqueueSnackbar("Error while uploading document.", { variant: "error" });
    }
  };

  const downloadDocument = async (id: number) => {
    const response = await equipmentClient.preview(id);
    await downloadFile(response.data, response.fileName);
  };

  async function addNewEquipment(
    e: React.FormEvent<HTMLFormElement>,
    state: StateFromCRUDForm,
    draftDocuments: EquipmentDocumentAddVM[],
    _1,
    _2,
    draftNotes: EquipmentNoteVM[]
  ) {
    e.preventDefault();
    const {
      manufacturer,
      model,
      serialNumber,
      unitNumber,
      qrAssetCode,
      type,
      customer_location,
      roomLocation,
    } = state;

    const equipment = new EquipmentCreateVM({
      model: model,
      serialNumber: serialNumber,
      unitNumber: unitNumber,
      qrAssetCode: qrAssetCode,
      equipmentType: type,
      locationId: customer_location.id,
      manufacturer: manufacturer,
      roomLocation,
    });

    try {
      const newEquipment = await equipmentClient.create(equipment);
      await uploadDraftDocuments(newEquipment.id, draftDocuments);
      await updateNotes(newEquipment.id, draftNotes);

      clearFields();
      enqueueSnackbar("New equipment added successfuly.", {
        variant: "success",
      });
      await getDispatchEquipment();

      await updateDispatchEquipment([newEquipment]);
    } catch (error) {
      redirectIfSessionExpired(history, error);
      enqueueSnackbar("Error while adding equipment.", { variant: "error" });
    }
  }

  function clearFields() {
    setAddNewEquipmentModalOpen(false);
    getDispatchEquipment();
  }

  const [documents, setDocuments] = useState<EquipmentDocumentVM[]>([]);
  const [documentsToBeDeleted, setDocumentsToBeDeleted] = useState<
    EquipmentDocumentVM[]
  >([]);
  const [draftDocuments, setDraftDocuments] = useState<
    EquipmentDocumentAddVM[]
  >([]);
  const addDraft = (files: File[]) => {
    setDraftDocuments(
      draftDocuments.concat(
        files.map(
          (f) =>
            new EquipmentDocumentAddVM({
              equipmentId: 0,
              file: f,
              name: f.name.split(".").slice(0, -1).join("."),
              isConfidential: false,
            })
        )
      )
    );
  };

  const removeDraft = (index: number) => {
    setDraftDocuments(draftDocuments.filter((d, i) => i !== index));
  };
  const setIsConfidentialDraft = (index: number) => {
    draftDocuments[index].isConfidential =
      !draftDocuments[index].isConfidential;
    setDraftDocuments([...draftDocuments]);
  };

  const [lastEditedDraftDocIndex, setLastEditedDraftDocIndex] = useState(0);
  const changeDraftDocumentName = (index: number, newName: string) => {
    draftDocuments[index].name = newName;
    setDraftDocuments([...draftDocuments]);
    setLastEditedDraftDocIndex(index);
  };

  const removeDocument = (index: number) => {
    documentsToBeDeleted.push(documents[index]);
    setDocumentsToBeDeleted([...documentsToBeDeleted]);
    setDocuments(documents.filter((d, i) => i !== index));
  };
  const updateDocument = (index: number) => {
    for (let i = 0; i < documents.length; i++) {
      if (i === index) {
        documents[i].documentFile.isConfidential =
          !documents[i].documentFile.isConfidential;
        break;
      }
    }
    setDocuments([...documents]);
  };

  const [notesAddModalState, setNotesAddModalState] = useState(false);
  const [draftNotes, setDraftNotes] = useState<EquipmentNoteCreateVM[]>([]);

  const openNotesModal = () => {
    setNotesAddModalState(true);
  };
  const closeNotesModal = () => {
    setNotesAddModalState(false);
  };

  const addNewEquipmentNote = (id: number, noteText: string) => {
    draftNotes.push(
      new EquipmentNoteCreateVM({
        equipmentId: id,
        text: noteText,
      })
    );

    setDraftNotes([...draftNotes]);
    setNotesAddModalState(false);
  };

  const updateNotes = async (id: number, draftNotes: EquipmentNoteVM[]) => {
    const equipmentClient = new EquipmentClient();
    for (const note of draftNotes) {
      note.equipmentId = id;
      await equipmentClient.createNote(note);
    }

    setDraftNotes([]);
  };

  const enterNewEquipmentBody = (
    <TechEquipmentCRUDForm
      title={state.formTitle}
      formAction={state.formAction}
      selectedEquipment={state.equipment}
      manufacturerList={manufacturerList}
      equipmentTypeList={equipmentTypeList}
      customerList={customerList}
      locationList={locationList}
      clearFields={clearFields}
      addDraft={addDraft}
      removeDraft={removeDraft}
      removeDocument={removeDocument}
      downloadDocument={downloadDocument}
      setIsConfidentialDraft={setIsConfidentialDraft}
      changeDraftDocumentName={changeDraftDocumentName}
      lastEditedDraftDocIndex={lastEditedDraftDocIndex}
      documents={documents}
      draftDocuments={draftDocuments}
      documentsToBeDeleted={documentsToBeDeleted}
      updateDocument={updateDocument}
      openNotesModal={openNotesModal}
      closeNotesModal={closeNotesModal}
      notesModal={notesAddModalState}
      addEquipmentNote={addNewEquipmentNote}
      draftNotes={draftNotes}
    />
  );

  //==================================//

  const [cameraOn, setCameraOn] = React.useState(false);

  const toggleCameraOn = () => {
    if (!cameraOn) {
      camera.startCamera("video", "offscreen-canvas");
    }
    setCameraOn((prev) => !prev);
  };

  const addDocument = async (files: File[]) => {
    try {
      for (let file of files) {
        await _addDocument(dispatchId, file);
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
    getDispatchDocuments();
  };

  const takeAndUploadPhoto = async (url: string) => {
    try {
      const file = await urlToFile(
        url,
        `Photo-${dateTimeFormatPhotoTitle
          .format(new Date())
          .replaceAll("/", "-")}.png`,
        "image/png"
      );
      await _addDocument(dispatchId, file);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
    getDispatchDocuments();
  };

  const urlToFile = async (url: string, filename: string, mimeType: string) => {
    const data = await fetch(url);
    const buffer = await data.arrayBuffer();
    return new File([buffer], filename, { type: mimeType });
  };

  const openAddSignatureView = () => {
    history.push({
      pathname: "/tech-dispatch-add-signature",
      state: { dispatchIdFromTechDispatchView: location.state },
    });
  };

  if (loader) {
    return (
      <div>
        <Grid className={classes.loaderRoot} justify="center" container>
          <CircularProgress />
        </Grid>
      </div>
    );
  }

  const changeVisitStatusHandler = async () => {
    const thereAreUnsynchedVisits = await client.areThereUnsynchedVisits();
    if (thereAreUnsynchedVisits && onlineState) {
      dispatchAction(setCachedRequestsSync(true));
      return;
    }
    if (visitStatus == "on-site") {
      history.push({
        pathname: "/tech-dispatch-clockout",
        state: { dispatchId: dispatch.id, visitId: ongoingVisitId },
      });
    } else if (visitStatus == "travelled") {
      history.push({
        pathname: "/tech-dispatch-clockin",
        state: { dispatchId: dispatch.id, visitId: ongoingVisitId },
      });
    } else {
      await techStartTravel();
      // refresh visits section
      await getVisits();
    }
  };

  const techStartTravel = async () => {
    try {
      const ongoingVisitId = await _visitStartTravel(dispatch.id);
      setOngoingVisitId(ongoingVisitId);
      setVisitStatus("travelled");
      enqueueSnackbar("Traveling towards site successfully.", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const renderChangeVisitStatusButtonText = () => {
    if (visitStatus == "not-travelled") return "TRAVEL";
    if (visitStatus == "travelled") return "ARRIVED AT SITE";
    if (visitStatus == "on-site") return "LEAVE";
  };

  const renderVisitStatusText = () => {
    if (visitStatus == "travelled")
      return "You are travelling towards the dispatch site.";
    if (visitStatus == "on-site")
      return "You are on site. Enter a signature before leaving. ";
  };

  const canUserLeaveSite = () => {
    if (dispatch.signatures.length !== 0) {
      const time = dispatch.signatures[dispatch.signatures.length - 1].created;
      const now = new Date();
      const diff = (now.valueOf() - time.valueOf()) / 60000;
      return diff < 30;
    }
    return false;
  };

  return (
    <div className={classes.root}>
      <Button
        startIcon={<ArrowBackIosIcon />}
        onClick={handleBackClick}
        color="primary"
      >
        Back
      </Button>
      <Grid container>
        <Grid className={classes.header} item xs={12}>
          <Typography align="center">
            {dateTimeFormat.format(dispatch.followUpDate)}
          </Typography>
          <Typography variant="h3" align="center">
            {" "}
            DISPATCH WO# {dispatch.workOrderNumber}
          </Typography>
          <Typography variant="h2" align="center">
            {renderVisitStatusText()}
          </Typography>

          <Button
            className={classes.clockOutBtn}
            variant="contained"
            color="primary"
            disabled={!canUserLeaveSite() && visitStatus == "on-site"}
            onClick={changeVisitStatusHandler}
          >
            {renderChangeVisitStatusButtonText()}
          </Button>
        </Grid>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="primary" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="h3"
                  className={`${classes.heading} ${classes.alignText}`}
                >
                  Location
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <Grid container className={classes.summaryContent}>
            <AccordionDetails className={classes.accordionDetails}>
              <TechLocation
                dispatchId={dispatch.id}
                dispatchLocationId={dispatch.locationId}
              />
            </AccordionDetails>
          </Grid>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="primary" />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography variant="h3" className={classes.heading}>
              Contacts
            </Typography>
          </AccordionSummary>
          <Grid container className={classes.summaryContent}>
            <AccordionDetails>
              <TechContacts
                dispatchId={dispatch.id}
                dispatchLocationId={dispatch.locationId}
              />
            </AccordionDetails>
          </Grid>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="primary" />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography variant="h3" className={classes.heading}>
              Dispatch Details
            </Typography>
          </AccordionSummary>
          <Grid container className={classes.summaryContent}>
            <AccordionDetails>
              <Grid container xs={12}>
                <TechDispatchDetails dispatch={dispatch} />
                <Grid container xs={12}>
                  <Grid item xs={6}>
                    <Typography variant="h3" className={`${classes.alignText}`}>
                      Dispatch Notes
                    </Typography>
                  </Grid>
                  <Grid className={classes.alignButtonRight} item xs={6}>
                    <Button
                      onClick={(event) => addDispatchNoteModal(event)}
                      onFocus={(event) => event.stopPropagation()}
                      color="primary"
                    >
                      Add Note
                    </Button>
                  </Grid>
                  {dispatchNoteModal && (
                    <FormDialog
                      open={dispatchNoteModal}
                      body={dispatchAddNote}
                    />
                  )}
                </Grid>
                <Grid container>
                  {dispatchNotes.length === 0 ? (
                    "No notes to display."
                  ) : (
                    <TechNotes
                      notes={dispatchNotes}
                      subheading="for inter-office use only"
                    />
                  )}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Grid>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="primary" />}
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            <Grid container>
              <Grid item xs={6}>
                <Typography
                  variant="h3"
                  className={`${classes.heading} ${classes.alignText}`}
                >
                  Service notes ({serviceNotes.length})
                </Typography>
              </Grid>
              <Grid className={classes.alignButtonRight} item xs={6}>
                <Button
                  onClick={(event) => addServiceNoteModal(event)}
                  onFocus={(event) => event.stopPropagation()}
                  color="primary"
                >
                  Add Note
                </Button>
              </Grid>
              {serviceNoteModal && (
                <FormDialog open={serviceNoteModal} body={serviceAddNote} />
              )}
            </Grid>
          </AccordionSummary>
          <Grid container className={classes.summaryContent}>
            <AccordionDetails>
              {serviceNotes.length === 0 ? (
                "No notes to display."
              ) : (
                <TechNotes
                  notes={serviceNotes}
                  subheading="notes show on customer invoice"
                />
              )}
            </AccordionDetails>
          </Grid>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="primary" />}
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            <Grid container>
              <Grid item xs={6}>
                <Typography
                  variant="h3"
                  className={`${classes.heading} ${classes.alignText}`}
                >
                  Equipment ({dispatchEquipment.length})
                </Typography>
              </Grid>
              <Grid className={classes.alignButtonRight} item xs={6}>
                <Button
                  onClick={(event) => addEquipment(event)}
                  onFocus={(event) => event.stopPropagation()}
                  color="primary"
                >
                  Add From Existing Equipment
                </Button>
              </Grid>
              <Grid className={classes.alignButtonRight} item xs={12}>
                <Button
                  onClick={() => {
                    setAddNewEquipmentModalOpen(true);
                  }}
                  onFocus={(event) => event.stopPropagation()}
                  color="primary"
                >
                  Add New Equipment
                </Button>
              </Grid>
              {addEquipmentModal && (
                <FormDialog
                  open={addEquipmentModal}
                  body={dispatchEquipmentUsed}
                />
              )}
              {addNewEquipmentModalOpen && (
                <FormDialog
                  open={addNewEquipmentModalOpen}
                  body={enterNewEquipmentBody}
                />
              )}
            </Grid>
          </AccordionSummary>
          <Grid container className={classes.summaryContent}>
            <AccordionDetails>
              {dispatchEquipment.length === 0 ? (
                "No equipment to display."
              ) : (
                <TechEquipment dispatchEquipment={dispatchEquipment} />
              )}
            </AccordionDetails>
          </Grid>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="primary" />}
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            <Grid container>
              <Grid item xs={6}>
                <Typography
                  variant="h3"
                  className={`${classes.heading} ${classes.alignText}`}
                >
                  Parts/Misc/Freight / Items ({dispatchParts.length}/
                  {dispatchItems.length})
                </Typography>
              </Grid>
              <Grid className={classes.alignButtonRight} item xs={6}>
                <Button
                  onClick={(event) => addParts(event)}
                  onFocus={(event) => event.stopPropagation()}
                  color="primary"
                >
                  Add Material/Non-listed Material
                </Button>
              </Grid>
              {addPartsModal && (
                <FormDialog open={addPartsModal} body={dispatchPartsUsed} />
              )}
              {enterNewPartsModal && (
                <FormDialog
                  open={enterNewPartsModal}
                  body={enterNewPartsBody}
                />
              )}
            </Grid>
          </AccordionSummary>
          <Grid container className={classes.summaryContent}>
            <AccordionDetails>
              {dispatchParts.length === 0 && dispatchItems.length === 0 ? (
                "No parts or items to display."
              ) : (
                <PartsItemsList
                  dispatchParts={dispatchParts}
                  dispatchItems={dispatchItems}
                  updatePartsPrices={updateDispatchPartsPrices}
                />
              )}
            </AccordionDetails>
          </Grid>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="primary" />}
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            <Grid container>
              <Grid item xs={6}>
                <Typography
                  variant="h3"
                  className={`${classes.heading} ${classes.alignText}`}
                >
                  Documents ({dispatchDocuments.length})
                </Typography>
              </Grid>
              <Grid className={classes.alignButtonRight} item xs={6}>
                <UploadButton
                  buttonType={UploadButtonType.TechAddDocument}
                  onDropAction={addDocument}
                  size="small"
                  acceptFileTypes={documentTypes}
                />
              </Grid>
            </Grid>
          </AccordionSummary>
          <Grid container className={classes.summaryContent}>
            <AccordionDetails style={{ width: "100%" }}>
              <Grid container>
                <Grid item xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={cameraOn}
                          onChange={toggleCameraOn}
                        />
                      }
                      label="Camera"
                      labelPlacement="start"
                    />
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ display: cameraOn ? "initial" : "none" }}
                >
                  <video id="video" className={classes.videoSection}></video>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.takePhotoButton}
                    startIcon={<PhotoCameraIcon />}
                    onClick={() => takeAndUploadPhoto(camera.takeSnapshot())}
                  >
                    Take and upload a photo
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  {dispatchDocuments.length === 0 ? (
                    "No documents to display."
                  ) : (
                    <TechDocuments dispatchDocuments={dispatchDocuments} />
                  )}
                </Grid>
                <canvas
                  id="offscreen-canvas"
                  style={{ display: "none" }}
                ></canvas>
              </Grid>
            </AccordionDetails>
          </Grid>
        </Accordion>

        <Accordion style={{ width: "100%" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="primary" />}
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            <Grid container>
              <Grid item xs={6}>
                <Typography
                  variant="h3"
                  className={`${classes.heading} ${classes.alignText}`}
                >
                  Signatures ({dispatch.signatures.length})
                </Typography>
              </Grid>
              <Grid className={classes.alignButtonRight} item xs={6}>
                <Button
                  onClick={openAddSignatureView}
                  onFocus={(event) => event.stopPropagation()}
                  color="primary"
                >
                  Add Signature
                </Button>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            {dispatch.signatures.length === 0 ? (
              "No signatures to display."
            ) : (
              <SignatureSection signatures={dispatch.signatures} />
            )}
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="primary" />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography variant="h3" className={classes.heading}>
              Visits
            </Typography>
          </AccordionSummary>
          <Grid container className={classes.summaryContent}>
            <AccordionDetails>
              <TechVisits questions={questions} visits={visits} />
            </AccordionDetails>
          </Grid>
        </Accordion>
      </Grid>
    </div>
  );
}
