import {
  AppBar,
  Container,
  createStyles,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  Theme,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import { TeamsClient, TeamAddVM } from "../../../brines-refrigerator-api";
import { redirectIfSessionExpired } from "../../common/redirect/RedirectOnSessionTimeout";
import TeamManagement from "./TeamManagement";
import TeamsTable from "./TeamsTable";
import CustomersManagment from "./CustomersManagement";
import { validateText, useTextValidation } from "../../../helpers/validations";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginTop: "0.40em",
    },
    titleRow: {
      height: "max-content",
      borderBottom: "solid 2px gray",
      marginBottom: "0rem",
      marginTop: "0rem",
      marginLeft: "1em",
      marginRight: "1em",
    },
    buttonCancel: {
      height: "max-content",
    },
  })
);

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel: React.FC<TabPanelProps> = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container style={{ margin: 0 }}>{children}</Container>
      )}
    </div>
  );
};

const TeamsView = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const history = useHistory();
  const [teams, setTeams] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const teamsClient = new TeamsClient();

  const switchView = (event: any, newValue: React.SetStateAction<number>) => {
    setValue(newValue);
  };

  async function getTeams() {
    try {
      const teams = await teamsClient.get();
      setTeams(teams);
    } catch (error) {
      redirectIfSessionExpired(history, error);
      enqueueSnackbar("Error while getting teams.", { variant: "error" });
    }
  }

  useEffect(() => {
    getTeams();
  }, []);

  const refreshTable = () => {
    setIsLoading(true);
    getTeams();
    setIsLoading(false);
  };

  const [team, setTeam] = useState(null);

  const setTeamForEditing = async (id: number) => {
    try {
      const team = await teamsClient.getById(id);

      setTeam(team);
      setTeamName(team.name);
      resetNameInputProps();
    } catch (error) {
      enqueueSnackbar("Error while getting team.", { variant: "error" });
    }
  };

  const deleteTeam = async (id: number) => {
    try {
      await teamsClient.deleteTeam(id);
      refreshTable();

      cancelTeamEdit();
      enqueueSnackbar("Team deleted successfully.", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Error while deleting team.", { variant: "error" });
    }
  };

  const addTeam = async (data: { name: string }) => {
    const result = data.name
      ? validateText(data.name)
      : { error: true, errorMessage: "This field can't be empty." };
    if (result.error) {
      enqueueSnackbar(result.errorMessage, { variant: "error" });
      return;
    }
    try {
      await teamsClient.addTeam(
        new TeamAddVM({
          name: data.name,
        })
      );
      refreshTable();
      enqueueSnackbar("Team added successfully.", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Error while adding team.", { variant: "error" });
    }
  };

  const cancelTeamEdit = () => {
    setTeam(null);
    setTeamName("");
    resetNameInputProps();
  };

  const [teamName, setTeamName] = useState("");

  const [nameInputProps, triggerNameValidation, resetNameInputProps] =
    useTextValidation();

  const handleNameChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTeamName(event.target.value as string);
    if (nameInputProps.error) {
      triggerNameValidation(event.target.value);
    }
  };

  return (
    <Grid item container xs={12}>
      <Grid item container lg={5} xs={12}>
        <TeamsTable
          teams={teams}
          setTeamForEditing={setTeamForEditing}
          addTeam={addTeam}
          deleteTeam={deleteTeam}
          isLoading={isLoading}
        />
      </Grid>
      <Grid item container lg={1} xs={12}></Grid>
      <Grid item container lg={6} xs={12} alignItems="flex-start">
        <Grid
          item
          container
          xs={12}
          className={classes.titleRow}
          alignItems="center"
          spacing={2}
        >
          <Grid item lg={3} xs={12}>
            <Typography variant="h2">Edit team</Typography>
          </Grid>
          <Grid item lg={5}>
            <TextField
              type="text"
              name="team_name"
              fullWidth
              label="Team Name"
              variant="outlined"
              onChange={handleNameChange}
              value={teamName}
              disabled={!team}
              error={nameInputProps.error}
              onBlur={() => triggerNameValidation(teamName)}
            />
            <span className="validation_error">
              {nameInputProps.errorMessage}
            </span>
          </Grid>
          <Grid item container lg={4} justify="flex-end">
            <Button
              endIcon={<CancelOutlinedIcon />}
              className={classes.buttonCancel}
              color="primary"
              variant="outlined"
              disabled={!team}
              size="large"
              disableElevation
              onClick={() => cancelTeamEdit()}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
        <AppBar
          elevation={0}
          position="static"
          color="default"
          style={{ background: "#fff" }}
        >
          <Grid container alignItems="center">
            <Tabs
              value={value}
              onChange={switchView}
              variant="scrollable"
              scrollButtons="on"
              indicatorColor="primary"
            >
              <Tab label="People" />
              <Tab label="Customers" />
            </Tabs>
          </Grid>
        </AppBar>

        <TabPanel value={value} index={0}>
          <div className={classes.root}>
            <TeamManagement
              team={team}
              newTeamName={teamName}
              cancelTeamEdit={cancelTeamEdit}
              triggerNameValidation={triggerNameValidation}
              refreshTable={refreshTable}
            />
          </div>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <div className={classes.root}>
            <CustomersManagment
              team={team}
              newTeamName={teamName}
              cancelTeamEdit={cancelTeamEdit}
              triggerNameValidation={triggerNameValidation}
              refreshTable={refreshTable}
            />
          </div>
        </TabPanel>
      </Grid>
    </Grid>
  );
};

export default TeamsView;
