interface CachedRequestsState {
    prevState: { sync: boolean },
    currState: { sync: boolean },
}

interface Action {
    type: string;
    payload: boolean;
}

const initialState = {
    prevState: { sync: true },
    currState: { sync: false },
}

export const cachedRequestsReducer = (state: CachedRequestsState = initialState, action: Action) => {
    switch (action.type) {
        case 'SET_SYNC':
            const prevState = state.currState;
            const currState = { sync: action.payload };
            return {
                ...state,
                prevState,
                currState
            }
        default:
            return state
    }
}