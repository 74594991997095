import React from 'react';
import { Grid, Divider, Box, Link, } from '@material-ui/core';
import { DispatchDocumentVM } from '../../brines-refrigerator-api';
import { downloadFile } from '../../helpers/download';
import * as client from '../../helpers/offline/offline-api';

interface TechDispatchDetailsProps {
  dispatchDocuments: DispatchDocumentVM[];
}

export default function TechDocuments(props: TechDispatchDetailsProps) {
  const [_download] = client.useDownload()

  const openFile = async (event: React.SyntheticEvent, document: DispatchDocumentVM) => {
    event.preventDefault()
    const response = await _download(document.documentFile.id);
    await downloadFile(response.data, response.fileName);
  }

  return (
    <Grid container item xs={12}>
      {props.dispatchDocuments.map((document) =>
        <Grid xs={12} item>
          <Box m="1.5rem">
            <Link href="#" onClick={(event) => openFile(event, document)} color="inherit">
              {document.documentFile.name}
            </Link>
          </Box>
          <Divider />
        </Grid>
      )}
    </Grid>
  );
}