import { useState } from 'react';

//#region COMMON

// standard api for Input components from Material-UI
export interface ValidationInputProps {
    error: boolean,
    errorMessage: string
}

const resetInputProps = (): ValidationInputProps => {
    return {
        error: false,
        errorMessage: null
    }
}

//#endregion COMMON

//#region EMAIL VALIDATION

export const validateEmail = (email: string): ValidationInputProps => {
    if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))) {
        return {
            error: true,
            errorMessage: 'Incorrect email address format.'
        }
    } else {
        return {
            error: false,
            errorMessage: null
        }
    }
}

export const useEmailValidation = (): [ValidationInputProps, Function, Function] => {
    const [contactEmailInputProps, setContactEmailInputProps] = useState<ValidationInputProps>({ error: false, errorMessage: null });
    const triggerEmailValidation = (contactEmail: string) => {
        const result = validateEmail(contactEmail)
        setContactEmailInputProps(result)
        return result.error
    }
    const resetEmailInputProps = () => {
        setContactEmailInputProps(resetInputProps())
    }
    return [contactEmailInputProps, triggerEmailValidation, resetEmailInputProps]
}

//#endregion EMAIL VALIDATION

//#region TEXT VALIDATION

export const validateText = (input: string, numbersOnly = false, canBeEmpty = false): ValidationInputProps => {
    // we can add more conditions here and return specific error message for specific error
    if (!canBeEmpty) {
        const result = { error: true, errorMessage: 'This field can\'t be empty.' }
        if (!input) return result
        if (input.trim().length === 0) return result
    }
    if (numbersOnly && isNaN(Number(input))) {
        return { error: true, errorMessage: 'Enter numeric values only.' }
    } else {
        return { error: false, errorMessage: null }
    }
}

export const useTextValidation = (): [ValidationInputProps, Function, Function] => {
    const [textInputProps, setTextInputProps] = useState<ValidationInputProps>({ error: false, errorMessage: null });
    const triggerTextValidation = (input: string, numbersOnly = false, canBeEmpty = false) => {
        const result = validateText(input, numbersOnly, canBeEmpty)
        setTextInputProps(result)
        return result.error
    }
    const resetTextInputProps = () => {
        setTextInputProps(resetInputProps())
    }
    return [textInputProps, triggerTextValidation, resetTextInputProps]
}

//#endregion TEXT VALIDATION

//#region SELECT VALIDATION

//any because select value is also of type any
export const validateSelect = (selectedValue: any, message = "Select an option please"): ValidationInputProps => {
    if (!selectedValue) {
        return { error: true, errorMessage: message }
    } else {
        return { error: false, errorMessage: null }
    }
}

export const useSelectValidation = (): [ValidationInputProps, Function, Function] => {
    const [textInputProps, setTextInputProps] = useState<ValidationInputProps>({ error: false, errorMessage: null });
    const triggerSelectValidation = (value: any, message: string) => {
        const result = validateSelect(value, message)
        setTextInputProps(result)
        return result.error;
    }
    const resetTextInputProps = () => {
        setTextInputProps(resetInputProps())
    }
    return [textInputProps, triggerSelectValidation, resetTextInputProps]
}

//#endregion SELECT VALIDATION

//#region PASSWORD VALIDATION

const validatePassword = (password: string): ValidationInputProps => {
    if (!(/^.{8,}$/.test(password))) {
        return {
            error: true,
            errorMessage: 'Include 8 characters.'
        }
    } else {
        return {
            error: false,
            errorMessage: null
        }
    }
}

export const usePasswordValidation = (): [ValidationInputProps, Function, Function] => {
    const [passwordInputProps, setPasswordInputProps] = useState<ValidationInputProps>({ error: false, errorMessage: null });
    const triggerPasswordValidation = (password: string) => {
        const result = validatePassword(password)
        setPasswordInputProps(result)
        return result.error
    }
    const resetPasswordInputProps = () => {
        setPasswordInputProps(resetInputProps())
    }
    return [passwordInputProps, triggerPasswordValidation, resetPasswordInputProps]
}

//#endregion PASSWORD VALIDATION