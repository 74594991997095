import { Chip, Grid, Typography } from '@material-ui/core';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import { VisitVM } from '../../brines-refrigerator-api';

interface TechVisitsProps {
    questions: String[],
    visits: VisitVM[]
}

const TechVisits = (props: TechVisitsProps) => {

    useEffect(() => {
        formatVisits();
    }, [props.visits]);

    const [formattedVisits, setFormattedVisits] = useState([]);

    const formatTime = (time: number) => {
        return <span><b>{time ? Math.floor(time / 60) : 0}</b>hrs <b>{time ? time % 60 : 0}</b>mins</span>
    }

    const formatIndicator = (text: string) => {
        return <Chip variant="outlined" size="small" label={text} />
    }

    const options = { year: 'numeric', day: 'numeric', month: 'numeric', hour: 'numeric', minute: 'numeric', timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone };
    const dateTimeFormat = new Intl.DateTimeFormat('en-US', options);
    const formatAnswer = (answer: boolean) => {
        return answer
            ? <Chip color="primary" variant="outlined" size="small" label="Yes" />
            : <Chip color="secondary" variant="outlined" size="small" label="No" />
    }

    const formatVisits = () => {
        setIsLoading(true);
        try {
            setFormattedVisits(props.visits.map((v) => {
                return {
                    visitTime: formatTime(v.visitTime),
                    status: v.finished ? formatIndicator('Finished') : v.clockIn.arrivedOnSite ? formatIndicator('Arrived') : formatIndicator('Traveling'),
                    startedTravelingTime: dateTimeFormat.format(v.clockIn.startedTravelingTime),
                    clockInTime: v.clockIn.arrivedOnSite ? dateTimeFormat.format(v.clockIn.clockInTime) : formatIndicator('Pending'),
                    clockOutTime: v.clockOut ? dateTimeFormat.format(v.clockOut.clockOutTime) : formatIndicator('Pending'),
                    answers: <>
                        <Grid item container sm={12}>
                            <Grid item sm={5}>
                                Q1: {v.clockIn.arrivedOnSite ? formatAnswer(v.clockIn.clockInAnswers[0].answer) : formatIndicator('Pending')}
                            </Grid>
                            <Grid item sm={5}>
                                Q2: {v.clockIn.arrivedOnSite ? formatAnswer(v.clockIn.clockInAnswers[1].answer) : formatIndicator('Pending')}
                            </Grid>
                        </Grid>
                        <Grid item container sm={12}>
                            <Grid item sm={5}>
                                Q3: {v.clockOut ? formatAnswer(v.clockOut.clockOutAnswers[0].answer) : formatIndicator('Pending')}
                            </Grid>
                            <Grid item sm={5}>
                                Q4: {v.clockOut ? formatAnswer(v.clockOut.clockOutAnswers[1].answer) : formatIndicator('Pending')}
                            </Grid>
                        </Grid>
                    </>
                }
            }));
            setIsLoading(false);
        }
        catch {
            setIsLoading(false);
        }
    }

    const visitsColumns = [
        { title: 'Visit Time', field: 'visitTime', sorting: false },
        { title: 'Status', field: 'status', width: 15, sorting: false },
        { title: 'Started Traveling Time', field: 'startedTravelingTime', sorting: false },
        { title: 'ClockIn Time', field: 'clockInTime', sorting: false },
        { title: 'ClockOut Time', field: 'clockOutTime', sorting: false },
        { title: 'Answers', field: 'answers', sorting: false }
    ];

    const [isLoading, setIsLoading] = useState(true)

    return (
        <Grid item container xs={12}>
            <Grid item container sm={7} className='clockIn_questions'>
                <Grid item sm={6}><Typography><b>Q1</b> - {props.questions[0]}</Typography></Grid>
                <Grid item sm={6}><Typography><b>Q2</b> - {props.questions[1]}</Typography></Grid>
            </Grid>
            <Grid item container sm={7} className='clockOut_questions'>
                <Grid item sm={6}><Typography><b>Q3</b> - {props.questions[2]}</Typography></Grid>
                <Grid item sm={6}><Typography><b>Q4</b> - {props.questions[3]}</Typography></Grid>
            </Grid>
            <Grid item sm={12}>
                <MaterialTable
                    columns={visitsColumns}
                    title={""}
                    data={formattedVisits as []}
                    options={{
                        search: false,
                        paging: false
                    }}
                    components={{
                        Toolbar: () => <></>,
                    }}
                    isLoading={isLoading}
                />
            </Grid>
        </Grid>
    )
}

export default TechVisits;