import { Grid } from "@material-ui/core";
import { Color } from "@material-ui/lab/Alert";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  RoleClient,
  RoleVM,
  UserClient,
  UserVM,
} from "../../../brines-refrigerator-api";
import { redirectIfSessionExpired } from "../../common/redirect/RedirectOnSessionTimeout";
import UserCRUDForms from "./UserCRUD/UserCRUDForms";
import UsersTable from "./UsersTable/UsersTable";

interface Row {
  id: number;
  role: string;
  userName: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  workPhone: string;
  city: any;
  state: any;
  active: boolean;
  note: string | undefined;
}

const UsersView = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [currentEditedUser, setCurrentEditedUser] = useState(null);

  const setUserForEditing = (user: Row) => {
    setCurrentEditedUser(user);
  };

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    populateTable();
  }, []);

  const populateTable = async () => {
    setIsLoading(true);
    try {
      const rolesClient = new RoleClient();
      const allRoles = await rolesClient.getAll();
      const userClient = new UserClient();

      const usersInRolePromise = allRoles.map(async (row: RoleVM) => {
        let clientsInRole = await userClient.usersByRole(row.name);
        return clientsInRole.map((c) => createRow(c, row.name));
      });

      const usersInRole = await Promise.all(usersInRolePromise);

      const users = usersInRole.flat();

      setUsers(users);
    } catch (error) {
      redirectIfSessionExpired(history, error);
      enqueueSnackbar("Error getting users.", { variant: "error" });
      setUsers([]);
    }
    setIsLoading(false);
  };

  const createRow = (userData: UserVM, role: string | undefined): Row => {
    return {
      id: userData.id,
      role: role,
      userName: userData.userName,
      email: userData.email,
      firstName: userData.firstName,
      lastName: userData.lastName,
      phone: userData.phoneNumber ?? "",
      workPhone: userData.workPhoneNumber ?? "",
      city: userData.city ?? "",
      state: {
        name: userData.state?.name ?? "",
        abreviation: userData.state?.abbreviation ?? "",
        id: userData.state?.id ?? "",
      },
      active: userData.isActive ?? false,
      note: userData.note ? userData.note.text : "",
    };
  };

  const refreshUsersTable = async () => {
    await populateTable();
  };

  const history = useHistory();

  return (
    <Grid
      item
      container
      xs={12}
      spacing={3}
      justify="space-between"
      className="admin_navbar_hero"
    >
      <Grid item lg={8} xs={12}>
        <UsersTable
          setUserForEditing={setUserForEditing}
          isLoading={isLoading}
          users={users}
        />
      </Grid>
      <Grid item lg={4} xs={12}>
        <UserCRUDForms
          user={currentEditedUser}
          refreshUsersTable={refreshUsersTable}
        />
      </Grid>
    </Grid>
  );
};

export default UsersView;
