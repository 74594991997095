import { SwaggerException } from "../../../brines-refrigerator-api";
import { AxiosResponse } from "axios";
import { History } from 'history';

export const redirectIfSessionExpired = (history: History, error: unknown) => {

  if (error as SwaggerException && (error as {response: AxiosResponse}).response.status == 401){
    history.push({
      pathname: "/"
    });
  }
}
