const Camera = function () {
    return {
        video: null,
        context: null,
        canvas: null,

        startCamera: function (videoId: string, canvasId: string) {
            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                this.video = document.getElementById(videoId);
                this.video.width = window.innerWidth * 0.9;
                if (window.matchMedia("(orientation: portrait)").matches) {
                    this.video.height = this.video.width / (4 / 3);
                }
                else {
                    this.video.height = window.innerHeight * 0.9;
                }

                this.canvas = document.getElementById(canvasId);
                this.canvas.width = this.video.width;
                this.canvas.height = this.video.height;

                this.context = this.canvas.getContext('2d');

                (function (video) {
                    navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } }).then(function (stream) {
                        video.srcObject = stream;
                        video.play();
                    });
                })(this.video)

            }
        },

        // Capture a photo by fetching the current contents of the video
        // and drawing it into a canvas, then converting that to a PNG
        // format data URL.
        takeSnapshot: function (): string {
            this.context.drawImage(this.video, 0, 0);
            return this.canvas.toDataURL()
        }

    }
}();

export default Camera;