import { Grid, Paper } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';

import MaterialTable from 'material-table';
import React, { useState } from 'react';

import { TeamVM } from '../../../brines-refrigerator-api';
import ConfirmDialog from '../../common/dialog/ConfirmationDialog';
import tableIcons from '../../common/table/tableIcons';

interface TeamsTableProps {
    teams: TeamVM[],
    setTeamForEditing: Function,
    addTeam: Function,
    deleteTeam: Function,
    isLoading: boolean
}

const TeamsTable = (props: TeamsTableProps) => {

    const [idForDelete, setIdForDelete] = useState(0);
    const [dialogMessage, setDialogMessage] = useState('');
    const [confirmOpen, setConfirmOpen] = useState(false);

    const openDeleteDialogue = (id: number) => {
        setIdForDelete(id);
        setDialogMessage(`Are you sure you want to delete team #${id}?`);
        setConfirmOpen(true);
    }

    const teamsColumns = [
        { title: 'Teams', field: 'name' },
    ];

    const addTeam = (data) => {
        console.log(data);
        props.addTeam(data);
    }

    const editTeam = (id: number) => {
        props.setTeamForEditing(id);
    }

    const deleteTeam = (id: number) => {
        props.deleteTeam(id);
    }

    return (
        <Grid item container spacing={2}>
            <Grid item xl={12} lg={12} md={12} sm={12}>
                <Paper elevation={0}>
                    <MaterialTable
                        icons={tableIcons}
                        title={"Teams"}
                        columns={teamsColumns}
                        data={props.teams as []}
                        actions=
                        {[
                            {
                                icon: () => <Edit color='primary' />,
                                onClick: (event, rowData: unknown) => editTeam((rowData as { id: number }).id)
                            },
                            {
                                icon: () => <Delete color='primary' />,
                                onClick: (event, rowData: unknown) => openDeleteDialogue((rowData as { id: number }).id)
                            }
                        ]}
                        editable={{
                            onRowAdd: (newData) =>
                                new Promise((resolve) => {
                                    addTeam(newData)
                                    resolve();
                                })
                        }}
                        options={{
                            actionsColumnIndex: -1,
                            search: true
                        }}
                        isLoading={props.isLoading}
                    />
                    {confirmOpen && <ConfirmDialog
                        title={"Delete team?"}
                        open={confirmOpen}
                        setOpen={setConfirmOpen}
                        onConfirm={() => { deleteTeam(idForDelete) }}
                    >
                        {dialogMessage}
                    </ConfirmDialog>
                    }
                </Paper>
            </Grid>
        </Grid>
    )
}

export default TeamsTable;