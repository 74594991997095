import React, { useState, useEffect } from 'react';
import { TradeClient, TradeVM } from '../../../brines-refrigerator-api';
import { useTextValidation, validateText } from '../../../helpers/validations';
import { useHistory } from 'react-router-dom';
import { redirectIfSessionExpired } from '../../common/redirect/RedirectOnSessionTimeout';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import { Grid, Paper, Typography, Button, Input, TextField } from '@material-ui/core';
import { ChromePicker } from 'react-color';
import CancelButton from '../../common/dialog/CancelButton';
import BasicTable from '../../common/table/BasicTable';
import './PriorityAndTrades.scss';
import { useSnackbar } from 'notistack';


const Trades = () => {
  const history = useHistory();
  const tradesClient = new TradeClient();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [tableDataTrades, setTradesTableData] = useState<{ id?: number, name?: string, hexCode?: string }[]>([]);

  const [selectedColorTrade, setTradeSelectedColor] = useState('');
  const [colorTradeInputProps, triggerColorTradeValidation, resetColorTradeInputProps] = useTextValidation();
  const [displayTradeColorPicker, setDisplayTradeColorPicker] = useState(false);
  const [tradeName, setTradeName] = useState('');
  const [tradeNameInputProps, triggerTradeNameValidation, resetTradeNameInputProps] = useTextValidation();
  const [tradeAction, setTradeAction] = useState({ id: 0, buttonLabel: 'Add' });

  const setTradeForEditing = (id: number) => {
    const trade = tableDataTrades.find(p => p.id === id);
    setTradeSelectedColor(`#${trade.hexCode}`)
    setDisplayTradeColorPicker(true)
    setTradeName(trade.name)
    setTradeAction({ id: id, buttonLabel: 'Edit' })
  }
  const populateTradesTable = async () => {
    const tradesList = await tradesClient.getAll();
    setTradesTableData(tradesList);
  }

  useEffect(() => {
    (async () => {
      try {
        await populateTradesTable()
      } catch (error) {
        redirectIfSessionExpired(history, error)
        enqueueSnackbar("Error getting classes.", { variant: "error" });
      }
    })();
  }, []);


  const addEditTrade = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const resultName = validateText(tradeName).error;
    const resultColor = validateText(selectedColorTrade).error;
    if (resultName) {
      triggerTradeNameValidation(tradeName)
    }
    if (resultColor) {
      triggerColorTradeValidation(selectedColorTrade)
    }
    if (resultName || resultColor) {
      return;
    }

    const form = e.currentTarget;
    const name = form.trade_text.value;
    const hexCode = selectedColorTrade.split('#')[1];

    const tradeItem = new TradeVM({
      id: tradeAction.id,
      name,
      hexCode
    })

    let successMessage = 'Class added successfully.'
    try {
      if (tradeAction.id) {
        await tradesClient.update(tradeItem)
        successMessage = 'Class edited successfully.'
      } else {
        await tradesClient.create(tradeItem)
      }
      populateTradesTable()
      enqueueSnackbar(successMessage, { variant: "success" });
      form.reset();
      resetTrade()
    } catch (error) {
      redirectIfSessionExpired(history, error)
      enqueueSnackbar(tradeAction.id
        ? 'Error while editing class.'
        : 'Error while adding new class.', { variant: "error" });
    }
  }

  const resetTrade = () => {
    setTradeSelectedColor('')
    resetTradeNameInputProps()
    resetColorTradeInputProps()
    setDisplayTradeColorPicker(false)
    setTradeName('')
    setTradeAction({ id: 0, buttonLabel: 'Add' })
  }

  const handleClickTradeColorPicker = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setDisplayTradeColorPicker(!displayTradeColorPicker)
  };

  const handleCloseTradeColorPicker = () => {
    setDisplayTradeColorPicker(false)
    triggerColorTradeValidation(selectedColorTrade)
  };


  const tradeTableColumns = [
    { title: 'Color', field: 'hexCode' },
    { title: 'Trade', field: 'name' },
  ];

  const tradeTableActions = [
    rowData => ({
      icon: () => <Edit color='primary' />,
      onClick: (event, rowData: unknown) => { setTradeForEditing((rowData as { id: number }).id) },
      tooltip: "Edit Class"
    }),
    rowData => ({
      icon: () => <Delete color='primary' />,
      onClick: (event, rowData: unknown) => { removeTrade((rowData as { id: number }).id) },
      tooltip: "Delete Class"
    })
  ]

  const removeTrade = async (id: number) => {
    try {
      await tradesClient.delete(id);
      enqueueSnackbar("Class deleted successfully.", { variant: "success" });
      await populateTradesTable()
    } catch (error) {
      redirectIfSessionExpired(history, error)
      enqueueSnackbar("Error deleting class", { variant: "error" });
    }
  }

  return (
    <>
      <Grid item container xl={3} lg={4} md='auto' sm={6}>
        <Paper elevation={0}>
          <Grid item container>
            <form onSubmit={addEditTrade}>
              <Grid item container className='priority_and_trades_form_title'>
                <Grid item lg={10}>
                  <Typography variant="h2">Classes</Typography>
                </Grid>
                <Grid item lg={2}>
                  <CancelButton onClick={resetTrade} />
                </Grid>
              </Grid>
              <div className='priority_and_trades_form_add_data'>
                <Grid item container justify='space-evenly' alignItems='center' className='priority_and_trades_form_add_data_container'>
                  <Grid item lg={3} sm='auto'>
                    {selectedColorTrade === '' && <span>
                      <Button
                        color={colorTradeInputProps.error ? 'secondary' : 'default'}
                        onClick={handleClickTradeColorPicker}>
                        Pick color
                                            </Button>
                      <p className="validation_error">
                        {colorTradeInputProps.errorMessage}
                      </p>
                    </span>}
                    {selectedColorTrade !== ''
                      && <span className='priority_and_trades_color_input'>
                        <Input type="color" id="favcolor" name="favcolor"
                          placeholder="Pick color"
                          disableUnderline={true}
                          value={selectedColorTrade}
                          onClick={handleClickTradeColorPicker} />
                      </span>}
                    {displayTradeColorPicker &&
                      <span className='priority_and_trades_popover'>
                        <span className='priority_and_trades_cover' onClick={handleCloseTradeColorPicker} />
                        <ChromePicker
                          disableAlpha={true}
                          color={selectedColorTrade}
                          onChange={(color) => setTradeSelectedColor(color.hex)} />
                      </span>}
                  </Grid>
                  <Grid item lg={6} sm='auto'>
                    <TextField
                      name='trade_text'
                      label='Enter class'
                      variant='outlined'
                      className='priority_and_trades_form_add_data_container_input'
                      margin='dense'
                      value={tradeName}
                      onChange={e => {
                        setTradeName(e.target.value)
                        if (tradeNameInputProps.error) {
                          triggerTradeNameValidation(e.target.value)
                        }
                      }}
                      error={tradeNameInputProps.error}
                      onBlur={() => triggerTradeNameValidation(tradeName)}
                    />
                    <p className="validation_error">
                      {tradeNameInputProps.errorMessage}
                    </p>
                  </Grid>
                  <Grid item lg={2} md='auto' className='priority_and_trades_form_add_data_container_button'>
                    <Button type='submit' variant='contained' color='primary' disableElevation data-testid='event-add-trade'>
                      {tradeAction.buttonLabel}
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </form>
            <Grid item container>
              <BasicTable
                width={'100%'}
                columns={tradeTableColumns}
                data={tableDataTrades.map(p => ({
                  ...p,
                  hexCode: <div className='priority_and_trades_table_cell' style={{ background: `#${p.hexCode}` }}></div>
                })) as []}
                actions={tradeTableActions}
                isLoading={false}
                paging={true}
                search={false}
                selection={false}
                components={{
                  Container: props => <Paper {...props} elevation={0} />,
                  Toolbar: () => (
                    <></>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
}
export default Trades;