import React, { useState, Fragment } from "react";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, TextField, Button } from "@material-ui/core";
import SignatureCanvas from 'react-signature-canvas'
import { useHistory, useLocation } from "react-router-dom";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useSnackbar, SnackbarKey } from "notistack";
import * as client from '../../../helpers/offline/offline-api';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "fit-content",
        },
        body: {
            '& > *': {
                margin: theme.spacing(1),
                width: "95vw",
            },
        },
        canvas: {
            outline: 'black 0.0625rem solid',
            width: "95vw",
            height: "40vh",
        }
    }),
);

export function AddSignatureView() {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const [signatureName, setSignatureName] = useState('');
    const [signaturePad, setSignaturePad] = useState<SignatureCanvas>();
    const mimeType = 'image/png';
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [_addSignature] = client.useAddSignature()

    function handleClick() {
        history.goBack();
    }

    const action = (key: SnackbarKey) => (
        <Fragment>
            <Button onClick={() => { closeSnackbar(key) }}>
                Dismiss
            </Button>
        </Fragment>
    );

    const submit = async () => {
        if (signaturePad.isEmpty()) {
            enqueueSnackbar("Signature can't be empty.", { variant: "error", action });
        } else {
            try {
                await _addSignature(
                    (location.state as { dispatchIdFromTechDispatchView: number }).dispatchIdFromTechDispatchView,
                    signatureName,
                    '',
                    await urlToFile(signaturePad.getTrimmedCanvas().toDataURL(mimeType), `${signatureName}.png`, mimeType)
                )
                enqueueSnackbar("Successfully added signature.", { variant: "success", action });
                history.goBack();
            } catch (error) {
                enqueueSnackbar(error.message, { variant: "error", action });
            }
        }
    }

    const urlToFile = async (url: string, filename: string, mimeType: string) => {
        const data = await fetch(url);
        const buffer = await data.arrayBuffer();
        return new File([buffer], filename, { type: mimeType });
    }

    return (
        <div className={classes.root}>
            <Button startIcon={<ArrowBackIosIcon />} onClick={handleClick} color="primary">Back</Button>
            <div className={classes.body}>
                <Typography variant="h3">Add Signature</Typography>
                <TextField label="Name" variant="outlined" value={signatureName} onChange={(e) => setSignatureName(e.target.value)} />
                <SignatureCanvas
                    backgroundColor='white'
                    canvasProps={{ className: classes.canvas }}
                    ref={(ref) => { setSignaturePad(ref) }}
                />
                <Button variant="contained" color="primary" onClick={submit}>Submit</Button>
            </div>
        </div>
    )
}