import React from 'react';
import { createStyles, Grid, Icon, makeStyles } from '@material-ui/core';
import SignalWifiOffSharpIcon from '@material-ui/icons/SignalWifiOffSharp';

interface OfflineIconProps {
  isOffline?: boolean
}

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      color: "red",
      paddingLeft: "0.5em"
    }
  }),
);

const OfflineIcon = (props: OfflineIconProps) => {
  const classes = useStyles();
  return (
    <Grid>
      {props.isOffline && <Icon className={classes.icon} fontSize="large" ><SignalWifiOffSharpIcon /></Icon>}
    </Grid>
  );
}
export default OfflineIcon;