import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  GridList,
  TextField,
  GridListTile,
  withStyles,
  makeStyles,
  Theme,
  createStyles,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
} from "@material-ui/core";
import {
  DispatchPartVM,
  DispatchItemVM,
} from "../../../brines-refrigerator-api";

interface PartsItemsListProps {
  dispatchParts: DispatchPartVM[];
  dispatchItems: DispatchItemVM[];
  updatePartsPrices: Function;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    partsHeader: {
      marginBottom: "0.5em",
    },
    tileList: {
      maxHeight: "25rem",
      width: "100%",
    },
  })
);

const CustomTextField = withStyles({
  root: {
    "& .MuiInputBase-input": {
      color: "#000000",
    },
  },
})(TextField);

export default function PartsItemsList(props: PartsItemsListProps) {
  const classes = useStyles();

  const [localDispatchParts, setLocalDispatchParts] = useState(
    props.dispatchParts
  );

  const handlePriceChange = (
    event: React.ChangeEvent<{ value: unknown }>,
    id: number
  ) => {
    const newPrice = event.target.value as number;
    setLocalDispatchParts(
      localDispatchParts.map((dp) =>
        dp.part.id === id
          ? new DispatchPartVM({ ...dp, partPrice: Number(newPrice) })
          : dp
      )
    );
  };

  const handleQuantityChange = (
    event: React.ChangeEvent<{ value: unknown }>,
    id: number
  ) => {
    const newQuantity = event.target.value as number;
    setLocalDispatchParts(
      localDispatchParts.map((dp) =>
        dp.part.id === id
          ? new DispatchPartVM({ ...dp, partQuantity: Number(newQuantity) })
          : dp
      )
    );
  };

  useEffect(() => {
    setLocalDispatchParts(props.dispatchParts);
  }, [props.dispatchParts]);

  return (
    <div>
      <Grid container>
        <Grid sm={6} xs={6}>
          <Paper square elevation={0}>
            <div>
              <Typography variant="h3" className={classes.partsHeader}>
                Parts/Misc/Freight
              </Typography>
              {localDispatchParts.length === 0 ? (
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    label="No Materials Used"
                  />
                </FormGroup>
              ) : (
                <GridList className={classes.tileList} cellHeight={200}>
                  {localDispatchParts.map((tile) => (
                    <GridListTile key={tile.part.id} style={{ width: "100%" }}>
                      <div>
                        <Grid xs={12} item>
                          <CustomTextField
                            disabled
                            id="outlined-disabled-1"
                            label="Parts/Misc/Freight #"
                            defaultValue={tile.part.number}
                            InputProps={{ disableUnderline: true }}
                          />
                        </Grid>
                        <Grid xs={12} item>
                          <CustomTextField
                            disabled
                            id="outlined-disabled-2"
                            label="Parts/Misc/Freight Name"
                            defaultValue={tile.part.name}
                            InputProps={{ disableUnderline: true }}
                          />
                        </Grid>
                        <Grid xs={12} item>
                          <CustomTextField
                            id="outlined-3"
                            type="number"
                            label="Parts/Misc/Freight Price"
                            value={tile.partPrice}
                            onChange={(e) => handlePriceChange(e, tile.part.id)}
                          />
                        </Grid>
                        <Grid xs={12} item>
                          <CustomTextField
                            id="outlined-3"
                            type="number"
                            label="Parts/Misc/Freight qty"
                            value={tile.partQuantity}
                            onChange={(e) =>
                              handleQuantityChange(e, tile.part.id)
                            }
                          />
                        </Grid>
                      </div>
                    </GridListTile>
                  ))}
                </GridList>
              )}
            </div>
            <Button
              color="primary"
              variant="contained"
              size="large"
              onClick={() => props.updatePartsPrices(localDispatchParts)}
              disableElevation
            >
              Update Parts/Misc/Freight prices
            </Button>
          </Paper>
        </Grid>

        <Grid sm={6} xs={6}>
          <Paper square elevation={0}>
            <div>
              <Typography variant="h3" className={classes.partsHeader}>
                Items
              </Typography>
              <GridList className={classes.tileList}>
                {props.dispatchItems.length === 0
                  ? "No items to display."
                  : props.dispatchItems.map((item) => (
                      <GridListTile style={{ width: "100%" }}>
                        <div>
                          <Grid xs={12} item>
                            <CustomTextField
                              disabled
                              id="outlined-disabled"
                              label="Item id"
                              multiline
                              defaultValue={item.id}
                              InputProps={{ disableUnderline: true }}
                            />
                          </Grid>
                          <Grid xs={12} item>
                            <CustomTextField
                              disabled
                              id="outlined-disabled"
                              label="Item Description"
                              multiline
                              defaultValue={item.description}
                              InputProps={{ disableUnderline: true }}
                            />
                          </Grid>
                        </div>
                      </GridListTile>
                    ))}
              </GridList>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
