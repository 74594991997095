import React, { useState, useEffect, Fragment, useRef } from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  FormControl,
  Select,
  MenuItem,
  Paper,
  Grid,
  Button,
  IconButton,
  InputAdornment,
  Input,
  InputLabel,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import RefreshIcon from "@material-ui/icons/Refresh";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { useSnackbar, SnackbarKey } from "notistack";
import BasicTable from "../../components/common/table/BasicTable";
import {
  DispatchClient,
  DispatchVM,
  DispatchStatusVM,
  DispatchStatusClient,
  CustomerClient,
  DispatchUpdateVM,
  ServiceRequestCodeClient,
  TradeClient,
  TradeVM,
  LocationClient,
  PriorityClient,
  PriorityTypeVM,
  UserClient,
  DispatchSecondaryStatusClient,
  DispatchFollowUpTableVM,
  StateClient,
  TeamsClient,
  RegionClient,
} from "../../brines-refrigerator-api";
import DispatchCRUDForm from "../Dispatch/DispatchCRUDForm/DispatchCRUDForm";
import { redirectIfSessionExpired } from "../../components/common/redirect/RedirectOnSessionTimeout";
import { useHistory } from "react-router-dom";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import Visits from "../../components/dispatch/visitsModal/visits";
import FormDialog from "../../components/common/dialog/FormDialog";
import handleServerError from "../../helpers/handleServerError";
import UserRole from "../../helpers/constants/userRole";
import CheckboxesTagsSelect from "../../components/common/select/CheckboxesTagsSelect";
import { DateRangePicker, DateRange } from "materialui-daterange-picker";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    followUpTable: {
      padding: "1.5rem",
    },
    dispatchCrud: {
      padding: "1.5rem",
      marginTop: "0.7rem",
    },
    disabledDispatchCrud: {
      pointerEvents: "none",
      opacity: 0.4,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

export default function FollowUpView() {
  const customersClient = new CustomerClient();
  const dispatchClient = new DispatchClient();
  const teamsClient = new TeamsClient();
  const regionClient = new RegionClient();

  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  const dateRangeOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  const dateTimeFormat = new Intl.DateTimeFormat("en-US", { ...options });
  const dateRangeFormat = new Intl.DateTimeFormat("en-US", {
    ...dateRangeOptions,
  });
  const classes = useStyles();
  const history = useHistory();

  const [tableIsLoading, setTableLoading] = useState(true);

  const columns = [
    { title: "Follow Up / Due Date", field: "followUpDate" },
    { title: "Work Order #", field: "workOrderNumber" },
    { title: "Customer", field: "customer" },
    { title: "Location", field: "location" },
    { title: "Secondary Status", field: "secondaryStatus" },
    { title: "Status", field: "status" },
    { title: "State", field: "state" },
    { title: "SLA", field: "slaDate" },
    { title: "Completed Date", field: "completedDate" },
    { title: "Team", field: "team" }, // currently we will display one team's name, we will see later if we need to display multiple
  ];

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [dispatches, setDispatches] = useState([]);
  const [dispatchesData, setDispatchesData] = useState([]);
  const [trades, setTrades] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [secondaryStatuses, setSecondaryStatuses] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const [locations, setLocations] = useState([]);
  const [locationsForFilter, setLocationsForFilter] = useState([]);
  const [users, setUsers] = useState([]);
  const [serviceRequestCodes, setServiceRequestCodes] = useState([]);
  const [teams, setTeams] = useState([]);
  const [dispatch, setDispatch] = useState(
    new DispatchVM({
      id: null,
      locationId: null,
      priorityId: null,
      tradeId: null,
      workOrderNumber: null,
      slaDate: null,
      tehnicianId: null,
      followUpDate: null,
      completedDate: null,
      statusId: null,
    })
  );

  const action = (key: SnackbarKey) => (
    <Fragment>
      <Button
        onClick={() => {
          closeSnackbar(key);
        }}
      >
        Dismiss
      </Button>
    </Fragment>
  );

  const [regions, setRegions] = useState([]);

  async function getRegions() {
    try {
      const regions = await regionClient.get();
      setRegions(regions);
    } catch (error) {
      enqueueSnackbar("Error while getting regions.", { variant: "error" });
    }
  }

  const [states, setStates] = useState([]);

  async function getStates() {
    const stateClient = new StateClient();
    const states = await stateClient.get();
    setStates(states);
  }

  async function getTeams() {
    try {
      const teams = await teamsClient.get();
      setTeams(teams);
    } catch (error) {
      redirectIfSessionExpired(history, error);
      enqueueSnackbar("Error while getting teams.", { variant: "error" });
    }
  }

  const getServiceRequestCodes = async () => {
    try {
      const serviceRequestCodesClient = new ServiceRequestCodeClient();
      const serviceRequestCodes = await serviceRequestCodesClient.get();
      setServiceRequestCodes(serviceRequestCodes);
    } catch (error) {
      redirectIfSessionExpired(history, error);
    }
  };

  async function getTrades() {
    try {
      const tradesClient = new TradeClient();
      const trades = await tradesClient.getAll();
      trades.push(
        new TradeVM({
          id: 0,
          name: "N/A",
          hexCode: "BCBCBC",
        })
      );
      setTrades(trades);
    } catch (error) {
      redirectIfSessionExpired(history, error);
    }
  }

  async function getCustomers() {
    try {
      const customers = await customersClient.getCustomersBase();
      setCustomers(customers);
    } catch (error) {
      redirectIfSessionExpired(history, error);
    }
  }

  async function getLocations() {
    try {
      const locationsClient = new LocationClient();
      const locations = await locationsClient.getLocationsDispatchHq();
      setLocations(locations);
      setLocationsForFilter(
        locations.map((l) => {
          return { ...l, name: `${l.name}, ${l.addressLine1}, #${l.number}` };
        })
      );
    } catch (error) {
      redirectIfSessionExpired(history, error);
    }
  }

  async function getPriorities() {
    try {
      const prioritiesClient = new PriorityClient();
      const priorities = await prioritiesClient.get();
      priorities.push(
        new PriorityTypeVM({
          id: 0,
          name: "N/A",
          hexCode: "BCBCBC",
        })
      );
      setPriorities(priorities);
    } catch (error) {
      redirectIfSessionExpired(history, error);
    }
  }

  async function getUsers() {
    try {
      const usersClient = new UserClient();
      const users = await usersClient.usersByRole(UserRole.Technician);
      setUsers(users);
    } catch (error) {
      redirectIfSessionExpired(history, error);
    }
  }

  const handleDateChange = async (date: Date | null, dispatch: DispatchVM) => {
    setTableLoading(true);
    try {
      await dispatchClient.put(
        new DispatchUpdateVM({
          id: dispatch.id!,
          locationId: dispatch.locationId!,
          followUpDate: date,
          statusId: dispatch.statusId === 0 ? null : dispatch.statusId,
          workOrderNumber: dispatch.workOrderNumber,
          priorityId: dispatch.priorityId,
          tradeId: dispatch.tradeId === 0 ? null : dispatch.tradeId,
          slaDate: dispatch.slaDate,
          tehnicianId: dispatch.tehnicianId,
          regionId: dispatch.regionId,
        })
      );
      enqueueSnackbar("Follow up date updated successfully.", {
        variant: "success",
      });
    } catch (error) {
      redirectIfSessionExpired(history, error);
      enqueueSnackbar(error.response.data.Title, { variant: "error", action });
    }
    getDispatchesAndPopulateTable();
  };

  const handleChange = async (
    event: React.ChangeEvent<{ value: unknown }>,
    dispatch: DispatchVM
  ) => {
    setTableLoading(true);
    try {
      await dispatchClient.put(
        new DispatchUpdateVM({
          id: dispatch.id!,
          locationId: dispatch.locationId!,
          statusId:
            (event.target.value as number) === 0
              ? null
              : (event.target.value as number),
          followUpDate: dispatch.followUpDate,
          workOrderNumber: dispatch.workOrderNumber,
          priorityId: dispatch.priorityId,
          tradeId: dispatch.tradeId === 0 ? null : dispatch.tradeId,
          slaDate: dispatch.slaDate,
          tehnicianId: dispatch.tehnicianId,
          regionId: dispatch.regionId,
        })
      );
      enqueueSnackbar("Dispatch status updated successfully.", {
        variant: "success",
      });
    } catch (error) {
      redirectIfSessionExpired(history, error);
      enqueueSnackbar(error.response.data.Title, { variant: "error", action });
    }
    getDispatchesAndPopulateTable();
  };

  const deleteDispatch = async (id: number) => {
    try {
      await dispatchClient.delete(id);

      enqueueSnackbar("Dispatch deleted successfully.", { variant: "success" });
      setTableLoading(true);
      getDispatchesAndPopulateTable();
    } catch (error) {
      redirectIfSessionExpired(history, error);
      handleServerError(error, enqueueSnackbar);
    }
  };

  async function getDispatchesAndPopulateTable() {
    try {
      const dispatches = await dispatchClient.getDispatchFollowUpDates();
      await populateTable(dispatches);
    } catch (error) {
      console.log(error);
      handleServerError(error, enqueueSnackbar);
    }
  }

  async function populateTable(dispatches: DispatchFollowUpTableVM[]) {
    try {
      const statusesClient = new DispatchStatusClient();
      const statuses = await statusesClient.getAll();

      const secondaryStatusesClient = new DispatchSecondaryStatusClient();
      const secondaryStatuses = await secondaryStatusesClient.getAll();

      clearFields();
      setDispatchesData(dispatches);
      setDispatches(
        dispatches.map((e) => ({
          id: e.id,
          followUpDate: (
            <div style={{ display: "flex" }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  autoOk
                  clearable
                  format="MM/dd/yyyy"
                  margin="dense"
                  value={e.followUpDate}
                  onChange={(event) => handleDateChange(event, e)}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </MuiPickersUtilsProvider>
              <IconButton
                size="small"
                onClick={() => handleDateChange(null, e)}
              >
                <ClearIcon />
              </IconButton>
            </div>
          ),
          workOrderNumber: e.workOrderNumber,
          customer: e.location ? e.location.customer.company : "",
          location: e.location
            ? `${e.location.name} #${e.location.number}`
            : "",
          state: e.location ? e.location.state.name : "",
          secondaryStatus: e.secondaryStatus ? e.secondaryStatus.name : "",
          slaDate: e.slaDate ? dateTimeFormat.format(e.slaDate) : "",
          completedDate: e.completedDate
            ? dateTimeFormat.format(e.completedDate)
            : "",
          team: e.location
            ? e.location.customer.memberTeams.length > 0
              ? e.location.customer.memberTeams
                  .map((customerTeam) => customerTeam.team.name)
                  .join(", ")
              : ""
            : "",
          status: (
            <FormControl className={classes.formControl}>
              <Select
                disableUnderline
                value={e.statusId}
                onChange={(event) => handleChange(event, e)}
              >
                {statuses.map((e: DispatchStatusVM) => (
                  <MenuItem key={e.id} value={e.id}>
                    {e.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ),
        }))
      );
      setStatuses(statuses);
      setSecondaryStatuses(secondaryStatuses);
    } catch (error) {
      console.log(error);
      handleServerError(error, enqueueSnackbar);
    }
  }

  const clearFields = () => {
    setDispatch(
      new DispatchVM({
        id: null,
        locationId: null,
        priorityId: null,
        tradeId: null,
        workOrderNumber: null,
        slaDate: null,
        tehnicianId: null,
        followUpDate: null,
        completedDate: null,
        statusId: null,
      })
    );
  };

  useEffect(() => {
    const fetchDataAsync = async () => {
      setTableLoading(true);
      await getDispatchesAndPopulateTable();
      await getLocations();
      await getCustomers();
      await getPriorities();
      await getTrades();
      await getUsers();
      await getServiceRequestCodes();
      await getTeams();
      await getStates();
      await getRegions();
      setTableLoading(false);
    };
    fetchDataAsync();
  }, []);

  const setDispatchForEditing = (rowData) => {
    setDispatch(
      dispatchesData.filter((e) => e.id === (rowData as { id: number }).id)[0]
    );
  };

  const openDispatchVisits = async (dispatchId: number) => {
    setVisitsSignaturesModal({ open: true, dispatchId: dispatchId });
  };

  const [visitsSignaturesModal, setVisitsSignaturesModal] = useState({
    open: false,
    dispatchId: 0,
  });

  const visitsSignaturesModalBody = (
    <>
      <Visits
        setModalState={setVisitsSignaturesModal}
        dispatchId={visitsSignaturesModal.dispatchId}
        technicians={users}
      />
    </>
  );

  const tableActions = [
    (rowData) => ({
      icon: () => <DriveEtaIcon color="primary" />,
      onClick: (event, rowData: unknown) => {
        openDispatchVisits((rowData as { id: number }).id);
      },
      tooltip: "View Visits",
    }),
    (rowData) => ({
      icon: () => <Edit color="primary" />,
      onClick: (event, rowData: unknown) => {
        setDispatchForEditing(rowData);

        dispatchFormRef.current.scrollIntoView({ behavior: "smooth" });
      },
      tooltip: "Edit Dispatch",
    }),
    (rowData) => ({
      icon: () => <Delete color="primary" />,
      onClick: (event, rowData: unknown) => {
        deleteDispatch((rowData as { id: number }).id);
      },
      tooltip: "Delete Dispatch",
    }),
  ];

  const updateDispatch = async (props: DispatchUpdateVM) => {
    try {
      await dispatchClient.put(
        new DispatchUpdateVM({
          ...props,
        })
      );
      clearFields();
      enqueueSnackbar("Dispatch updated successfully.", { variant: "success" });
      await getDispatchesAndPopulateTable();
    } catch (err) {
      enqueueSnackbar(err.response.data.Title, { variant: "error", action });
    }
  };

  const applyFilter = async () => {
    try {
      setTableLoading(true);
      const result = await dispatchClient.getDispatchFollowUpDatesByFilter(
        locationsFilter.current.map((x) => x.id),
        statesFilter.current.map((x) => x.id),
        customersFilter.current.map((x) => x.id),
        prioritiesFilter.current.map((x) => x.id),
        tradesFilter.current.map((x) => x.id),
        statusesFilter.current.map((x) => x.id),
        secondaryStatusesFilter.current.map((x) => x.id),
        teamsFilter.current.map((x) => x.id),
        completedDateFilter.current.startDate
          ? new Date(
              Date.UTC(
                completedDateFilter.current.startDate.getFullYear(),
                completedDateFilter.current.startDate.getMonth(),
                completedDateFilter.current.startDate.getDate()
              )
            )
          : null,
        completedDateFilter.current.endDate
          ? new Date(
              Date.UTC(
                completedDateFilter.current.endDate.getFullYear(),
                completedDateFilter.current.endDate.getMonth(),
                completedDateFilter.current.endDate.getDate()
              )
            )
          : null,
        slaDateFilter.current.startDate
          ? new Date(
              Date.UTC(
                slaDateFilter.current.startDate.getFullYear(),
                slaDateFilter.current.startDate.getMonth(),
                slaDateFilter.current.startDate.getDate()
              )
            )
          : null,
        slaDateFilter.current.endDate
          ? new Date(
              Date.UTC(
                slaDateFilter.current.endDate.getFullYear(),
                slaDateFilter.current.endDate.getMonth(),
                slaDateFilter.current.endDate.getDate()
              )
            )
          : null
      );
      enqueueSnackbar("Dispatches filtered successfully.", {
        variant: "success",
      });
      await populateTable(result);
    } catch (err) {
      enqueueSnackbar(err.response.data.Title, { variant: "error", action });
    }
    setTableLoading(false);
  };

  const prioritiesFilter = useRef([]);
  const setPrioritiesFilter = async (value: []) => {
    prioritiesFilter.current = value;
  };

  const customersFilter = useRef([]);
  const setCustomersFilter = async (value: []) => {
    customersFilter.current = value;
  };

  const locationsFilter = useRef([]);
  const setLocationsFilter = async (value: []) => {
    locationsFilter.current = value;
  };

  const statesFilter = useRef([]);
  const setStatesFilter = async (value: []) => {
    statesFilter.current = value;
  };

  const teamsFilter = useRef([]);
  const setTeamsFilter = async (value: []) => {
    teamsFilter.current = value;
  };

  const tradesFilter = useRef([]);
  const setTradeFilters = async (value: []) => {
    tradesFilter.current = value;
  };

  const statusesFilter = useRef([]);
  const setStatusesFilter = async (value: []) => {
    statusesFilter.current = value;
  };

  const secondaryStatusesFilter = useRef([]);
  const setSecondaryStatusesFilter = async (value: []) => {
    secondaryStatusesFilter.current = value;
  };

  const completedDateFilter = useRef<DateRange>({});
  const setCompletedDateFilter = async (value: DateRange) => {
    completedDateFilter.current = value;
  };
  const [completedDateRangeOpen, setCompletedDateRangeOpen] = useState(false);
  const toggleCompletedDateRange = () =>
    setCompletedDateRangeOpen(!completedDateRangeOpen);

  const [completedDateRange, setCompletedDateRange] = useState("");

  const slaDateFilter = useRef<DateRange>({});
  const setSlaDateFilter = async (value: DateRange) => {
    slaDateFilter.current = value;
  };
  const [slaDateRangeOpen, setSlaDateRangeOpen] = useState(false);
  const toggleSlaDateRange = () => setSlaDateRangeOpen(!slaDateRangeOpen);

  const [slaDateRange, setSlaDateRange] = useState("");

  const [locationsSelectKey, setLocationsSelectKey] = useState(
    new Date().toUTCString()
  );
  const [statesSelectKey, setStatesSelectKey] = useState(
    new Date().toUTCString()
  );
  const [customersSelectKey, setCustomersSelectKey] = useState(
    new Date().toUTCString()
  );
  const [prioritiesSelectKey, setPrioritiesSelectKey] = useState(
    new Date().toUTCString()
  );
  const [teamsSelectKey, setTeamsSelectKey] = useState(
    new Date().toUTCString()
  );
  const [tradesSelectKey, setTradesSelectKey] = useState(
    new Date().toUTCString()
  );
  const [statusesSelectKey, setStatusesSelectKey] = useState(
    new Date().toUTCString()
  );
  const [secondaryStatusesSelectKey, setSecondaryStatusesSelectKey] = useState(
    new Date().toUTCString()
  );

  const onClearFilter = () => {
    setLocationsSelectKey(new Date().toUTCString());
    setStatesSelectKey(new Date().toUTCString());
    setCustomersSelectKey(new Date().toUTCString());
    setPrioritiesSelectKey(new Date().toUTCString());
    setTeamsSelectKey(new Date().toUTCString());
    setTradesSelectKey(new Date().toUTCString());
    setStatusesSelectKey(new Date().toUTCString());
    setSecondaryStatusesSelectKey(new Date().toUTCString());
    setCompletedDateRange("");
    setCompletedDateFilter({});
    setSlaDateRange("");
    setSlaDateFilter({});
    getDispatchesAndPopulateTable();
  };

  useEffect(() => {
    if (!completedDateRangeOpen) {
      setCompletedDateRange(
        completedDateFilter.current.endDate
          ? `${dateRangeFormat.format(
              completedDateFilter.current.startDate
            )}-${dateRangeFormat.format(completedDateFilter.current.endDate)}`
          : ""
      );
    }
  }, [completedDateRangeOpen]);

  useEffect(() => {
    if (!slaDateRangeOpen) {
      setSlaDateRange(
        slaDateFilter.current.endDate
          ? `${dateRangeFormat.format(
              slaDateFilter.current.startDate
            )}-${dateRangeFormat.format(slaDateFilter.current.endDate)}`
          : ""
      );
    }
  }, [slaDateRangeOpen]);

  const dispatchFormRef = useRef<HTMLDivElement>();

  return (
    <>
      <Grid container classes={{ root: classes.root }}>
        <Grid item container xs={12}>
          <CheckboxesTagsSelect
            uniqueKey={locationsSelectKey}
            options={locationsForFilter as []}
            optionLabel="name"
            setSelected={setLocationsFilter}
            inputLabel="Locations"
            inputPlaceholder="Filter by Location..."
          />
          <CheckboxesTagsSelect
            uniqueKey={statesSelectKey}
            options={states as []}
            optionLabel="name"
            setSelected={setStatesFilter}
            inputLabel="States"
            inputPlaceholder="Filter by State..."
          />
          <CheckboxesTagsSelect
            uniqueKey={customersSelectKey}
            options={customers as []}
            optionLabel="company"
            setSelected={setCustomersFilter}
            inputLabel="Customers"
            inputPlaceholder="Filter by Customer..."
          />
          <CheckboxesTagsSelect
            uniqueKey={prioritiesSelectKey}
            options={priorities as []}
            optionLabel="name"
            setSelected={setPrioritiesFilter}
            inputLabel="Priorities"
            inputPlaceholder="Filter by Priority..."
          />
          <CheckboxesTagsSelect
            uniqueKey={teamsSelectKey}
            options={teams as []}
            optionLabel="name"
            setSelected={setTeamsFilter}
            inputLabel="Teams"
            inputPlaceholder="Filter by Team..."
          />
          <CheckboxesTagsSelect
            uniqueKey={tradesSelectKey}
            options={trades as []}
            optionLabel="name"
            setSelected={setTradeFilters}
            inputLabel="Trades"
            inputPlaceholder="Filter by Trade..."
          />
          <CheckboxesTagsSelect
            uniqueKey={statusesSelectKey}
            options={statuses as []}
            optionLabel="name"
            setSelected={setStatusesFilter}
            inputLabel="Statuses"
            inputPlaceholder="Filter by Status..."
          />
          <CheckboxesTagsSelect
            uniqueKey={secondaryStatusesSelectKey}
            options={secondaryStatuses as []}
            optionLabel="name"
            setSelected={setSecondaryStatusesFilter}
            inputLabel="Secondary Statuses"
            inputPlaceholder="Filter by Secondary Status..."
          />
          <FormControl style={{ padding: 15 }}>
            <InputLabel>Completed Date Range</InputLabel>
            <Input
              readOnly={true}
              type="text"
              value={completedDateRange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle completed DateRange visibility"
                    onClick={
                      completedDateRange != ""
                        ? () => {
                            setCompletedDateRange("");
                            setCompletedDateFilter({});
                          }
                        : toggleCompletedDateRange
                    }
                  >
                    {completedDateRange != "" ? (
                      <ClearIcon />
                    ) : (
                      <DateRangeIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl style={{ padding: 15 }}>
            <InputLabel>SLA Date Range</InputLabel>
            <Input
              readOnly={true}
              type="text"
              value={slaDateRange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle sla DateRange visibility"
                    onClick={
                      slaDateRange != ""
                        ? () => {
                            setSlaDateRange("");
                            setSlaDateFilter({});
                          }
                        : toggleSlaDateRange
                    }
                  >
                    {slaDateRange != "" ? <ClearIcon /> : <DateRangeIcon />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          {completedDateRangeOpen && (
            <FormDialog
              open={completedDateRangeOpen}
              body={
                <DateRangePicker
                  open={completedDateRangeOpen}
                  toggle={toggleCompletedDateRange}
                  onChange={(range) => setCompletedDateFilter(range)}
                />
              }
            />
          )}
          {slaDateRangeOpen && (
            <FormDialog
              open={slaDateRangeOpen}
              body={
                <DateRangePicker
                  open={slaDateRangeOpen}
                  toggle={toggleSlaDateRange}
                  onChange={(range) => setSlaDateFilter(range)}
                />
              }
            />
          )}
          <section style={{ padding: 15 }}>
            <Button
              color="primary"
              variant="contained"
              size="large"
              disableElevation
              startIcon={<SearchIcon />}
              style={{ height: "fit-content" }}
              onClick={applyFilter}
            >
              Filter
            </Button>
          </section>
          <section style={{ padding: 15 }}>
            <Button
              color="primary"
              variant="contained"
              size="large"
              disableElevation
              startIcon={<RefreshIcon />}
              style={{ height: "fit-content" }}
              onClick={onClearFilter}
            >
              Reset
            </Button>
          </section>
        </Grid>
        <Grid item container xs={12}>
          <section className={classes.followUpTable}>
            <BasicTable
              columns={columns}
              title="FOLLOW UP / DUE DATE"
              data={dispatches as []}
              actions={tableActions}
              components={{
                Container: (props) => <Paper {...props} elevation={0} />,
              }}
              paging={true}
              isLoading={tableIsLoading}
            />
          </section>
        </Grid>
        <Grid item container xs={12} md={6} ref={dispatchFormRef}>
          <section
            className={
              dispatch.id
                ? `${classes.dispatchCrud}`
                : `${classes.dispatchCrud} ${classes.disabledDispatchCrud}`
            }
          >
            <DispatchCRUDForm
              locationId={dispatch.id ? dispatch.locationId : null}
              trades={trades}
              statuses={statuses}
              secondaryStatuses={secondaryStatuses}
              customers={customers}
              locations={locations}
              priorities={priorities}
              technicians={users}
              regions={regions}
              serviceRequestCodes={serviceRequestCodes}
              dispatch={dispatch}
              formAction={null}
              formTitle="EDIT DISPATCH"
              clearFields={clearFields}
              formButton1Action={null}
              formButton2Action={updateDispatch}
            />
          </section>
        </Grid>
      </Grid>
      {visitsSignaturesModal && (
        <FormDialog
          open={visitsSignaturesModal.open}
          body={visitsSignaturesModalBody}
        />
      )}
    </>
  );
}
