import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';

import './FormDialog.scss';

interface DialogProps {
  open: boolean;
  body: unknown;
}
const useStyles = makeStyles(() => ({
  paper: {
     maxWidth: "100%",
     maxHeight: "100%"
  },

}));

export default function FormDialog(props: DialogProps) {
  const [open, setOpen] = useState(props.open);
  const classes = useStyles();

  return (
    <div>
      <Dialog className={classes.paper}  open={props.open} aria-labelledby="form-dialog-title">
        <DialogContent >
          {props.body}
        </DialogContent>
      </Dialog>
    </div>
  );
}
