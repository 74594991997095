import { Grid, makeStyles, Paper } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { LocationBaseVM, LocationClient, TaxRatesClient } from '../../brines-refrigerator-api';
import BasicTable from '../../components/common/table/BasicTable';
import { useDebouncedSearch } from '../../helpers/search';
import UserRole from '../../helpers/constants/userRole';
import { useHistory, useLocation } from 'react-router-dom';
import { redirectIfSessionExpired } from '../../components/common/redirect/RedirectOnSessionTimeout';
import { useSnackbar } from 'notistack';
import Edit from '@material-ui/icons/Edit';
import EditLocationTaxRates from './EditLocationTaxRates';
import TaxRateCRUDForm from './TaxRateCRUDForm';
import Delete from '@material-ui/icons/Delete';
import handleServerError from '../../helpers/handleServerError';

const useStyles = makeStyles({
    root: {
        padding: '1rem 2.5rem'
    },
    marginBottom: {
        marginBottom: "2rem"
    }
});

const TaxRatesView = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const taxRatesClient = new TaxRatesClient();
    const locationClient = new LocationClient();

    const classes = useStyles();

    const userData: any = JSON.parse(sessionStorage.getItem('userData') || '{}');
    const role = userData.role.name;

    const locationColumns = [
        { title: '#', field: 'id' },
        { title: 'Location', field: 'name' },
        { title: 'Number', field: 'number' },
        { title: 'ZIP', field: 'zip' }
    ];

    const taxRateColumns = [
        { title: '#', field: 'id' },
        { title: 'Name', field: 'name' },
        { title: 'Amount', field: 'amount' },
        { title: 'Type', field: 'taxRateType.name' },
        { title: 'State', field: 'state.name' }
    ];

    const location = useLocation();

    const { inputText, setInputText, search } = useDebouncedSearch((text: string) => searchLocations(text))

    const searchLocations = async (input: string) => {
        const locationsTable = await locationClient.getByFilter(input, page, rowsPerPage);

        setLocationsCount(locationsTable.count)
        return locationsTable.locations.map((e: LocationBaseVM) => ({ id: Number(e.id), location: e.name, zip: e.zip, isArchived: e.isArchived })).filter(x => (x.isArchived && role === UserRole.Admin) || !x.isArchived)
    }

    useEffect(() => {
        const fetchDataAsync = async () => {
            await populateTaxRatesTable();
            await populateLocationsTable();
            if (location.state) {
                await fetchLocationForEditing((location.state as { locationId: number }).locationId);
            }
        }
        fetchDataAsync()
    }, []);

    const fetchLocationForEditing = async (locationId: number) => {
        const result = await locationClient.getById(locationId);

        setLocationForEditing(new LocationBaseVM({
            id: result.id,
            name: result.name,
            zip: result.zip,
            isArchived: result.isArchived,
            customerId: result.customerId,
            stateId: result.stateId
        }))
    }

    const [taxRates, setTaxRates] = useState([]);

    const populateTaxRatesTable = async () => {
        try {
            const taxRates = await taxRatesClient.get();
            setTaxRates(taxRates);
        }
        catch (error) {
            redirectIfSessionExpired(history, error)
            handleServerError(error, enqueueSnackbar)
        }
    }


    async function populateLocationsTable() {
        try {
            const locationsTable = await locationClient.getByTeam(page, rowsPerPage);
            setLocationsCount(locationsTable.count)
            setLocations(locationsTable.locations.map((e: LocationBaseVM) => ({ id: Number(e.id), name: e.name, number: e.number, zip: e.zip, isArchived: e.isArchived, stateId: e.stateId })).filter(x => (x.isArchived && role === UserRole.Admin) || !x.isArchived));
        } catch (error) {
            redirectIfSessionExpired(history, error)
            handleServerError(error, enqueueSnackbar)
        }
        setTableIsLoading(false);
    };

    const history = useHistory();

    const locationsTableActions = [
        rowData => ({
            icon: () => <Edit color='primary' />,
            onClick: (event, rowData: unknown) => { setLocationForEditing((rowData as LocationBaseVM)) },
            tooltip: "Edit location tax rates"
        }),
    ]

    const taxRatesTableActions = [
        rowData => ({
            icon: () => <Edit color='primary' />,
            onClick: (event, rowData: unknown) => { setTaxRateForEditing((rowData as { id: number }).id) },
            tooltip: "Edit location tax rate"
        }),
        rowData => ({
            icon: () => <Delete color='primary' />,
            onClick: (event, rowData: unknown) => { deleteTaxRate((rowData as { id: number }).id) },
            hidden: rowData['isArchived'],
            tooltip: "Delete tax rate"
        })
    ]

    const deleteTaxRate = async (taxRateId: number) => {
        try {
            await taxRatesClient.deleteTaxRate(taxRateId);
            enqueueSnackbar("Successfully deleted tax rate", { variant: "success" });
            await populateTaxRatesTable();
        }
        catch (error) {
            enqueueSnackbar("Error deleting tax rate", { variant: "error" });
        }
    }

    const setTaxRateForEditing = (taxRateId: number) => {
        setCurrentTaxRateId(taxRateId);
    }

    const [currentTaxRateId, setCurrentTaxRateId] = useState(null);

    //hooks for the edit tax rate crud form including all kinds of tax rates
    const [selectedLocationTaxRates, setSelectedLocationTaxRates] = useState(null);
    const [locationStateTaxRate, setLocationStateTaxRate] = useState(null);
    const [countyTaxRates, setCountyTaxRates] = useState([]);
    const [cityTaxRates, setCityTaxRates] = useState([]);
    const [zipCodeTaxRates, setZipCodeTaxRates] = useState([]);

    const setLocationForEditing = async (location: LocationBaseVM) => {
        clearLocationTaxRatesForm();
        setCurrentLocation(location);
        await fetchTaxRatesForLocation(location);
    }

    const clearLocationTaxRatesForm = () => {
        setLocationStateTaxRate(null);
        setCountyTaxRates([]);
        setCityTaxRates([]);
        setZipCodeTaxRates([]);
    }


    const fetchTaxRatesForLocation = async (location: LocationBaseVM) => {
        const stateTaxRate = await taxRatesClient.getStateTaxRate(location.stateId);
        const countyTaxRates = await taxRatesClient.getCountyTaxRates(location.stateId);
        const cityTaxRates = await taxRatesClient.getCityTaxRates(location.stateId);
        const zipCodeTaxRates = await taxRatesClient.getZipCodeTaxRates(location.stateId);
        const locationTaxRates = await taxRatesClient.getLocationTaxRates(location.id);

        setCountyTaxRates(countyTaxRates);
        setCityTaxRates(cityTaxRates);
        setLocationStateTaxRate(stateTaxRate);
        setZipCodeTaxRates(zipCodeTaxRates);
        setSelectedLocationTaxRates(locationTaxRates);
    }

    const [currentLocation, setCurrentLocation] = useState(null);

    const [tableIsLoading, setTableIsLoading] = useState(false);

    const [locations, setLocations] = useState([]);

    const clearTaxRateForm = () => {
        setCurrentTaxRateId(null);
    }

    const refreshTaxRatesTable = async () => {
        await populateTaxRatesTable();
    }

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [locationsCount, setLocationsCount] = useState(10);
    const firstEffectByPagination = useRef(true);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const tableRef = useRef(null)

    useEffect(() => {
        // do not trigger on initial state
        if (firstEffectByPagination.current) {
            firstEffectByPagination.current = false;
            return;
        }

        setTableIsLoading(true);
        populateLocationsTable();
        tableRef.current.dataManager.changePageSize(rowsPerPage)
    }, [page, rowsPerPage]);

    return (
        <Grid className={classes.root} container>
            <Grid item container xs={12} spacing={4}>
                <Grid item xs={6}>
                    <div className={classes.marginBottom}>
                        <BasicTable
                            columns={taxRateColumns}
                            title="TAX RATES LIST"
                            data={taxRates as []}
                            actions={taxRatesTableActions}
                            paging={true}
                            isLoading={tableIsLoading || search.loading}
                            components={{
                                Container: props => <Paper {...props} elevation={0} />
                            }}
                        />
                    </div>
                    <TaxRateCRUDForm
                        taxRateId={currentTaxRateId}
                        cancel={clearTaxRateForm}
                        refreshTable={refreshTaxRatesTable}
                        taxRates={taxRates}
                    />
                </Grid>
                <Grid item container xs={6}>
                    <Grid item xs={12}>
                        <BasicTable
                            tableRef={tableRef}
                            columns={locationColumns}
                            title="LOCATIONS LIST"
                            data={inputText ? search.result : locations as []}
                            actions={locationsTableActions}
                            components={{
                                Container: props => <Paper {...props} elevation={0} />,
                            }}
                            paging={true}
                            isLoading={tableIsLoading || search.loading}
                            isCustomPagination={true}
                            count={locationsCount}
                            page={page}
                            onChangePage={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {currentLocation && <EditLocationTaxRates
                            location={currentLocation ? currentLocation : null}
                            stateId={currentLocation ? currentLocation.stateId : null}
                            selectedLocationTaxRates={selectedLocationTaxRates}
                            locationStateTaxRate={locationStateTaxRate}
                            countyTaxRates={countyTaxRates}
                            cityTaxRates={cityTaxRates}
                            zipCodeTaxRates={zipCodeTaxRates}
                            syncTaxData={fetchTaxRatesForLocation}
                        />}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default TaxRatesView;