interface INoteTempVM {
    id: number,
    created: Date,
    createdBy: string,
    lastModified: Date,
    lastModifiedBy: string,
    text: string,
    draft: boolean,
    uid: string,
    history?: any[]
}

class NoteTempVM implements INoteTempVM {
    id: number;
    created: Date;
    createdBy: string;
    lastModified: Date;
    lastModifiedBy: string;
    text: string;
    draft: boolean;
    uid: string;
    pid?: number; //parent entity id if it exists,
    history?: any[];

    constructor(id, c, cb, m, mb, t, d, uid, pid = null, history = null) {
        this.id = id;
        this.created = c;
        this.createdBy = cb;
        this.lastModified = m;
        this.lastModifiedBy = mb;
        this.text = t;
        this.draft = d;
        this.uid = uid;
        this.pid = pid;
        this.history = history;
    }
}

export default NoteTempVM;
