import React, {
  useEffect,
  useReducer,
  useState,
  ElementType,
  ChangeEvent,
} from "react";
import TextField from "@material-ui/core/TextField";
import {
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Radio,
  FormControlLabel,
  Typography,
  InputBaseComponentProps,
  InputAdornment,
} from "@material-ui/core";
import "./LocationCRUDForm.scss";
import AddNote from "../common/note/AddNote";
import FormDialog from "../common/dialog/FormDialog";
import {
  CustomerVM,
  StateVM,
  LocationVM,
  LocationNoteVM,
  LocationNoteCreateVM,
  ContactVM,
  ContactAddVM,
  PhoneAddVM,
  CustomerBaseVM,
  CustomerClient,
} from "../../brines-refrigerator-api";
import {
  useEmailValidation,
  useSelectValidation,
  useTextValidation,
} from "../../helpers/validations";
import {
  phoneNumberFormat,
  decimalNumberFormat,
} from "../../helpers/inputFormatters";
import { Autocomplete } from "@material-ui/lab";
import UserRole from "../../helpers/constants/userRole";
import { debug } from "console";

interface FormProps {
  title: string;
  button: JSX.Element;
  location: LocationVM;
  customers: CustomerBaseVM[];
  states: StateVM[];
  formAction: Function;
}

interface StateType {
  id: number;
  name: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  zip: string;
  ivr: string;
  ivrPin: string;
  number: string;
  emsNumber: string;
  active: boolean;
  pmInfo: string;
  stateId: number;
  customerId: number;
  laborRate: number;
  tripRate: number;
  otRate: number;
  holidayRate: number;
  locationMarkup: number;
  notes: LocationNoteVM[];
  contacts: ContactVM[];
  customers: CustomerVM[];
  states: StateVM[];
}

export default function LocationCRUDForm(props: FormProps) {
  const dateTimeFormat = new Intl.DateTimeFormat("en-US", {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
  const [statee, setStatee] = useReducer(
    (state: StateType, newState: StateType) => ({ ...state, ...newState }),
    {
      id: props.location.id,
      name: props.location.name,
      addressLine1: props.location.addressLine1,
      addressLine2: props.location.addressLine2,
      city: props.location.city,
      zip: props.location.zip,
      ivr: props.location.ivr,
      ivrPin: props.location.ivrPin,
      number: props.location.number,
      emsNumber: props.location.emsNumber,
      active: props.location.active,
      pmInfo: props.location.pmInfo,
      laborRate: props.location.laborRate,
      tripRate: props.location.tripRate,
      otRate: props.location.otRate,
      holidayRate: props.location.holidayRate,
      locationMarkup: props.location.locationMarkup,
      stateId: props.location.stateId,
      customerId: props.location.customerId,
      notes: props.location.notes,
      contacts: props.location.contacts,
      customers: props.customers,
      states: props.states,
    }
  );

  // Get user data from session storage
  const userData: any = JSON.parse(sessionStorage.getItem("userData") || "{}");
  //get user role
  const role = userData.role.name;

  const validateAllFields = (): boolean => {
    const resultName = triggerNameValidation(statee.name);
    const resultAdressLine1 = triggerAddressLine1Validation(
      statee.addressLine1
    );
    const resultCity = triggerCityValidation(statee.city);
    const resultZip = triggerZipValidation(statee.zip, true);
    const resultIvr = triggerIvrValidation(statee.ivr, false);
    const resultEms = triggerEmsValidation(statee.emsNumber, false);
    const resultCstmr = triggerCustomerValidation(company);

    let resultEmptyContact = 0;

    if (showEmptyContact) {
      resultEmptyContact =
        triggerContactNameValidation(contactName) |
        triggerContactEmailValidation(contactEmail) |
        triggerContactTitleValidation(contactTitle) |
        triggerContactPhoneValidation(contactPhone, true);
    }

    return (
      resultName ||
      resultAdressLine1 ||
      resultCity ||
      resultZip ||
      resultIvr ||
      resultEms ||
      resultEmptyContact ||
      resultCstmr
    );
  };

  const [nameInputProps, triggerNameValidation, resetNameInputProps] =
    useTextValidation();
  const [
    addressLine1InputProps,
    triggerAddressLine1Validation,
    resetAddressLine1InputProps,
  ] = useTextValidation();
  const [cityInputProps, triggerCityValidation, resetCityInputProps] =
    useTextValidation();
  const [zipInputProps, triggerZipValidation, resetZipInputProps] =
    useTextValidation();
  const [ivrInputProps, triggerIvrValidation, resetIvrInputProps] =
    useTextValidation();
  const [emsInputProps, triggerEmsValidation, resetEmsInputProps] =
    useTextValidation();
  const [
    customerInputProps,
    triggerCustomerValidation,
    resetCustomerInputProps,
  ] = useSelectValidation();
  const [stateInputProps, triggerStateValidation, resetStateInputProps] =
    useSelectValidation();

  const [company, setCompany] = useState(props.location.customerId);
  const handleCustomerChange = async (
    event: React.ChangeEvent<{ value: unknown }>,
    value: CustomerBaseVM
  ) => {
    if (value) {
      const companyId = value.id;
      setCompany(companyId);
      const customerClient = new CustomerClient();
      const customer = await customerClient.getById(companyId);
      setStatee({
        ...statee,
        laborRate: customer.laborRate,
        tripRate: customer.tripRate,
        otRate: customer.otRate,
        holidayRate: customer.holidayRate,
        locationMarkup: customer.customerMarkup,
      });
    } else {
      setStatee({
        ...statee,
        laborRate: null,
        tripRate: null,
        otRate: null,
        holidayRate: null,
        locationMarkup: null,
      });
      setCompany(null);
    }
  };

  const [state, setState] = useState(props.location.state);
  const handleStateChange = (
    event: React.ChangeEvent<{ value: unknown }>,
    value: StateVM
  ) => {
    setState(value);
    resetStateInputProps();
  };

  const [contactName, setContactName] = useState("");
  const handleContactNameChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setContactName(event.target.value as string);
    if (contactNameInputProps.error) {
      triggerContactNameValidation(event.target.value as string);
    }
  };
  const [
    contactNameInputProps,
    triggerContactNameValidation,
    resetContactNameInputProps,
  ] = useTextValidation();

  const [contactEmail, setContactEmail] = useState("");
  const handleContactEmailChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setContactEmail(event.target.value as string);
    if (contactEmailInputProps.error) {
      triggerContactEmailValidation(event.target.value as string);
    }
  };
  const [
    contactEmailInputProps,
    triggerContactEmailValidation,
    resetEmailInputProps,
  ] = useEmailValidation();

  const [contactTitle, setContactTitle] = useState("");
  const handleContactTitleChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setContactTitle(event.target.value as string);
    if (contactTitleInputProps.error) {
      triggerContactTitleValidation(event.target.value as string);
    }
  };
  const [
    contactTitleInputProps,
    triggerContactTitleValidation,
    resetContactTitleInputProps,
  ] = useTextValidation();

  const [contactPhone, setContactPhone] = useState("");
  const handleContactPhoneChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setContactPhone(event.target.value as string);
    if (contactPhoneInputProps.error) {
      triggerContactPhoneValidation(event.target.value as string);
    }
  };
  const [
    contactPhoneInputProps,
    triggerContactPhoneValidation,
    resetContactPhoneInputProps,
  ] = useTextValidation();

  const [contactPrimary, setContactPrimary] = useState(false);
  const handleRadioButtonOfNewContact = () => {
    setContactPrimary(true);
    setStatee({
      ...statee,
      contacts: statee.contacts.map((e) => {
        return new ContactVM({ ...e, isPrimary: false });
      }),
    });
  };

  const handleDecimalOnChange = (value: string, fieldName: string) => {
    setStatee({ ...statee, [fieldName]: Number(value).toFixed(2) });
  };

  function handleRadioButton(index: number) {
    setContactPrimary(false);
    setStatee({
      ...statee,
      contacts: statee.contacts.map((e, i) => {
        return new ContactVM({ ...e, isPrimary: i === index ? true : false });
      }),
    });
  }

  const [showEmptyContact, setShowEmptyContact] = useState(
    props.location.contacts.length === 0
  );

  const initContactInfo = () => {
    setContactName("");
    setContactEmail("");
    setContactTitle("");
    setContactPhone("");
    setContactPrimary(false);
  };

  function addContact() {
    if (showEmptyContact) {
      setStatee({
        ...statee,
        contacts: [
          ...statee.contacts,
          new ContactAddVM({
            name: contactName,
            email: contactEmail,
            title: contactTitle,
            isPrimary: statee.contacts.length === 0 ? true : contactPrimary,
            phone: new PhoneAddVM({
              number: contactPhone,
            }),
          }),
        ],
      });
    }
    initContactInfo();
    setShowEmptyContact(true);
  }

  const [isSavingChanges, setIsSavingChanges] = useState(false);
  function executeFormAction(e) {
    setIsSavingChanges(true);
    if (validateAllFields()) {
      e.preventDefault();
      setIsSavingChanges(false);
      return;
    }
    const contacts = [...statee.contacts];
    if (!!contactName && !!contactEmail && !!contactTitle && !!contactPhone) {
      contacts.push(
        new ContactAddVM({
          name: contactName,
          email: contactEmail,
          title: contactTitle,
          isPrimary: statee.contacts.length === 0 ? true : contactPrimary,
          phone: new PhoneAddVM({
            number: contactPhone,
          }),
        })
      );
    }
    if (!statee.id && locationNote)
      notesToAdd.push(
        new LocationNoteCreateVM({
          locationId: 0,
          text: locationNote,
        })
      );
    props.formAction(
      e,
      Number(statee.id),
      Number(state.id),
      company,
      contacts,
      notesToAdd
    );
    setIsSavingChanges(false);
  }

  useEffect(() => {
    setStatee({
      id: props.location.id,
      name: props.location.name,
      addressLine1: props.location.addressLine1,
      addressLine2: props.location.addressLine2,
      city: props.location.city,
      zip: props.location.zip,
      ivr: props.location.ivr,
      ivrPin: props.location.ivrPin,
      number: props.location.number,
      emsNumber: props.location.emsNumber,
      active: props.location.active,
      pmInfo: props.location.pmInfo,
      laborRate: props.location.laborRate,
      tripRate: props.location.tripRate,
      otRate: props.location.otRate,
      holidayRate: props.location.holidayRate,
      locationMarkup: props.location.locationMarkup,
      stateId: props.location.stateId,
      customerId: props.location.customerId,
      notes: props.location.notes,
      contacts: props.location.contacts,
      customers: props.customers,
      states: props.states,
    });
    setCompany(props.location.customerId as number);
    setState(props.location.state);
    initContactInfo();
    setShowEmptyContact(false);
    resetEmailInputProps();
    resetNameInputProps();
    resetAddressLine1InputProps();
    resetCityInputProps();
    resetZipInputProps();
    resetIvrInputProps();
    resetEmsInputProps();
    resetContactNameInputProps();
    resetContactTitleInputProps();
    resetContactPhoneInputProps();
    resetCustomerInputProps();
    resetStateInputProps();
  }, [props]);

  //ADD NOTES

  useEffect(() => {
    setNotesToAdd([]);
    setLocationNote("");
  }, [props]);

  const [locationNote, setLocationNote] = useState("");
  const [notesToAdd, setNotesToAdd] = useState([]);
  const [addNoteOpened, setAddNoteOpened] = useState(false);

  const addNote = (_, text) => {
    notesToAdd.push(
      new LocationNoteCreateVM({
        locationId: statee.id,
        text: text,
      })
    );

    setAddNoteOpened(false);
  };

  const addNoteBody = (
    <AddNote
      noteText=""
      id={null}
      buttonAction={addNote}
      title="Add location note"
      buttonText="Add note"
      onClick={() => setAddNoteOpened(false)}
      uid={null}
    />
  );

  const LocationNotes = () => {
    return (
      <div>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setAddNoteOpened(true)}
          fullWidth
        >
          Add Note
        </Button>

        <Grid item container justify="center">
          <Grid item container xl={10} className="notes_container">
            <Grid container className="notes_container_textarea">
              {statee.notes
                .concat(
                  notesToAdd.map(
                    (n) =>
                      new LocationNoteVM({
                        text: n.text,
                        id: null,
                        createdBy: null,
                        locationId: null,
                        created: new Date(),
                      })
                  )
                )
                .map(({ text, created, createdBy }) => (
                  <div className="notes_container_textarea_data_has_content">
                    <span>
                      {createdBy != null ? createdBy.userName : "new note"},{" "}
                      {dateTimeFormat.format(created)}
                    </span>
                    <div style={{ marginTop: ".5em" }}>
                      <span spellCheck>{text}</span>
                    </div>
                  </div>
                ))}
            </Grid>
          </Grid>
        </Grid>
        {addNoteOpened && (
          <FormDialog open={addNoteOpened} body={addNoteBody} />
        )}
      </div>
    );
  };

  return (
    <>
      <Grid container alignItems="center" className="location_crud_form_title">
        <Grid item container xl={4} sm={3} xs={6} alignItems="center">
          <Typography>
            <b>{props.title}</b>
          </Typography>
        </Grid>
        <Grid item container xl={4} sm={4} xs={6}>
          <Autocomplete
            options={props.customers}
            getOptionLabel={(option) => option.company}
            value={
              company ? props.customers.find((c) => c.id == company) : null
            }
            onChange={handleCustomerChange}
            fullWidth
            onBlur={() => {
              triggerCustomerValidation(company);
            }}
            renderInput={(params) => (
              <>
                <TextField
                  {...params}
                  label="Customer"
                  variant="outlined"
                  error={customerInputProps.error}
                />
                <span className="validation_error">
                  {customerInputProps.errorMessage}
                </span>
              </>
            )}
          />
        </Grid>
        <Grid
          item
          container
          xl={4}
          sm={5}
          xs={5}
          className="location_crud_form_clr_fields"
        >
          {props.button}
        </Grid>
        <hr />
      </Grid>
      <form
        noValidate
        autoComplete="off"
        onSubmit={(e) => executeFormAction(e)}
      >
        <Grid container className="location_form">
          <Grid item lg={4} sm={6}>
            <div className="location_details">
              <Typography>Location details</Typography>
              <TextField
                required
                name="locationName"
                label="Location Name"
                className="name_input"
                value={statee.name}
                onChange={(e) => {
                  setStatee({ ...statee, name: e.target.value });
                  if (nameInputProps.error) {
                    triggerNameValidation(e.target.value);
                  }
                }}
                error={nameInputProps.error}
                onBlur={() => triggerNameValidation(statee.name)}
                fullWidth
              />
              <span className="validation_error">
                {nameInputProps.errorMessage}
              </span>
              <TextField
                name="number"
                label="Location Number"
                value={statee.number}
                onChange={(e) => {
                  setStatee({ ...statee, number: e.target.value });
                }}
                fullWidth
              />
              <TextField
                required
                name="addressLine1"
                label="Address 1"
                value={statee.addressLine1 ? statee.addressLine1 : ""}
                onChange={(e) => {
                  setStatee({ ...statee, addressLine1: e.target.value });
                  if (addressLine1InputProps.error) {
                    triggerAddressLine1Validation(e.target.value);
                  }
                }}
                error={addressLine1InputProps.error}
                onBlur={() =>
                  triggerAddressLine1Validation(statee.addressLine1)
                }
                fullWidth
              />
              <span className="validation_error">
                {addressLine1InputProps.errorMessage}
              </span>
              <TextField
                name="addressLine2"
                label="Address 2"
                value={statee.addressLine2 ? statee.addressLine2 : ""}
                onChange={(e) =>
                  setStatee({ ...statee, addressLine2: e.target.value })
                }
                fullWidth
              />
              <TextField
                required
                name="city"
                label="City"
                value={statee.city ? statee.city : ""}
                onChange={(e) => {
                  setStatee({ ...statee, city: e.target.value });
                  if (cityInputProps.error) {
                    triggerCityValidation(e.target.value);
                  }
                }}
                error={cityInputProps.error}
                onBlur={() => triggerCityValidation(statee.city)}
                fullWidth
              />
              <span className="validation_error">
                {cityInputProps.errorMessage}
              </span>
              <section>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Autocomplete
                      options={props.states}
                      getOptionLabel={(option) => option.name}
                      getOptionSelected={(option) => !!Number(option.id)}
                      disableClearable
                      fullWidth
                      value={state}
                      onChange={handleStateChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label="State"
                          name="state"
                          error={stateInputProps.error}
                          onBlur={() => triggerStateValidation(state.name)}
                        />
                      )}
                    />
                    <span className="validation_error">
                      {stateInputProps.errorMessage}
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      name="zip"
                      label="ZIP"
                      value={statee.zip ? statee.zip : ""}
                      onChange={(e) => {
                        setStatee({ ...statee, zip: e.target.value });
                        if (zipInputProps.error) {
                          triggerZipValidation(e.target.value, true);
                        }
                      }}
                      error={zipInputProps.error}
                      onBlur={() => triggerZipValidation(statee.zip, true)}
                      fullWidth
                    />
                    <span className="validation_error">
                      {zipInputProps.errorMessage}
                    </span>
                  </Grid>
                </Grid>
              </section>
              <TextField
                required
                name="ivr"
                label="IVR / Check In"
                value={statee.ivr ? statee.ivr : ""}
                onChange={(e) => {
                  setStatee({ ...statee, ivr: e.target.value });
                  if (ivrInputProps.error) {
                    triggerIvrValidation(e.target.value, false);
                  }
                }}
                error={ivrInputProps.error}
                onBlur={() => triggerIvrValidation(statee.ivr, false)}
                fullWidth
              />
              <span className="validation_error">
                {ivrInputProps.errorMessage}
              </span>
              <TextField
                name="ivrPin"
                label="IVR PIN"
                value={statee.ivrPin ? statee.ivrPin : ""}
                onChange={(e) => {
                  setStatee({ ...statee, ivrPin: e.target.value });
                }}
                fullWidth
              />
              <TextField
                required
                name="emsNumber"
                label="EMS"
                value={statee.emsNumber ? statee.emsNumber : ""}
                onChange={(e) => {
                  setStatee({ ...statee, emsNumber: e.target.value });
                  if (emsInputProps.error) {
                    triggerEmsValidation(e.target.value, false);
                  }
                }}
                error={emsInputProps.error}
                onBlur={() => triggerEmsValidation(statee.emsNumber, false)}
                fullWidth
              />
              <span className="validation_error">
                {emsInputProps.errorMessage}
              </span>
            </div>
          </Grid>
          <Grid container item lg={4} sm={6}>
            <div className="location_note">
              <Typography>Location notes</Typography>
              {!statee.id && (
                <TextField
                  className="location_note_textbox"
                  name={"pmInfo"}
                  placeholder="Add location note"
                  multiline
                  fullWidth
                  rows={18}
                  variant="outlined"
                  value={locationNote}
                  onChange={(e) => setLocationNote(e.target.value)}
                />
              )}
              {statee.id && <LocationNotes />}
            </div>
          </Grid>
          <Grid item className="location_contact" lg={4} sm={12}>
            <Grid container alignItems="center">
              <Grid item xs={6}>
                <Typography>Contact Name</Typography>
              </Grid>
              <Grid item container xs={6} justify="flex-end">
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ width: "max-content" }}
                  onClick={(e: React.MouseEvent<HTMLElement>) => addContact()}
                >
                  Add Contact
                </Button>
              </Grid>
            </Grid>
            <div className="location_contact_input_fields">
              {statee.contacts &&
                statee.contacts.map((e, index) => (
                  <section className="contact_box">
                    <section>
                      <FormControlLabel
                        value="isPrimary"
                        control={
                          <Radio
                            checked={e.isPrimary}
                            color={"primary"}
                            onChange={() => handleRadioButton(index)}
                          />
                        }
                        label="Primary"
                      />
                    </section>
                    <section>
                      <TextField
                        name="contactName"
                        label="Name"
                        value={e.name}
                        fullWidth
                        className="first_contact_input"
                      />
                    </section>
                    <section>
                      <TextField
                        name="email"
                        label="Email"
                        value={e.email}
                        fullWidth
                      />
                    </section>
                    <section>
                      <TextField label="Title" value={e.title} fullWidth />
                    </section>
                    <section>
                      <TextField
                        label="Phone"
                        value={e.phone.number}
                        fullWidth
                      />
                    </section>
                  </section>
                ))}
              {showEmptyContact && (
                <section className="contact_box">
                  <section>
                    <FormControlLabel
                      value="isPrimary"
                      control={
                        <Radio
                          color={"primary"}
                          checked={contactPrimary}
                          onChange={handleRadioButtonOfNewContact}
                        />
                      }
                      label="Primary"
                    />
                  </section>
                  <section>
                    <TextField
                      name="contactName"
                      label="Name"
                      value={contactName}
                      onChange={handleContactNameChange}
                      error={contactNameInputProps.error}
                      onBlur={() => triggerContactNameValidation(contactName)}
                      fullWidth
                      className="first_contact_input"
                    />
                    <span className="validation_error">
                      {contactNameInputProps.errorMessage}
                    </span>
                  </section>
                  <section>
                    <TextField
                      name="email"
                      label="Email"
                      value={contactEmail}
                      onChange={handleContactEmailChange}
                      error={contactEmailInputProps.error}
                      onBlur={() => triggerContactEmailValidation(contactEmail)}
                      fullWidth
                    />
                    <span className="validation_error">
                      {contactEmailInputProps.errorMessage}
                    </span>
                  </section>
                  <section>
                    <TextField
                      label="Title"
                      value={contactTitle}
                      onChange={handleContactTitleChange}
                      error={contactTitleInputProps.error}
                      onBlur={() => triggerContactTitleValidation(contactTitle)}
                      fullWidth
                    />
                    <span className="validation_error">
                      {contactTitleInputProps.errorMessage}
                    </span>
                  </section>
                  <section>
                    <TextField
                      label="Phone"
                      value={contactPhone}
                      onChange={handleContactPhoneChange}
                      error={contactPhoneInputProps.error}
                      onBlur={() => triggerContactPhoneValidation(contactPhone)}
                      InputProps={{
                        inputComponent:
                          phoneNumberFormat as unknown as ElementType<InputBaseComponentProps>,
                      }}
                      fullWidth
                    />
                    <span className="validation_error">
                      {contactPhoneInputProps.errorMessage}
                    </span>
                  </section>
                </section>
              )}
            </div>
          </Grid>
        </Grid>
        <Grid item container xl={12}>
          <Grid
            item
            container
            xl={12}
            justify="space-between"
            className="location_rates"
          >
            <Grid item container xl={12}>
              <Typography className="rates_title">Rates</Typography>
            </Grid>
            <Grid item container xl={2} sm={2} xs={6}>
              <TextField
                className="location_rates_input"
                name="laborRate"
                label="Labor"
                defaultValue={0.0}
                variant="outlined"
                value={statee.laborRate}
                onChange={(e) =>
                  handleDecimalOnChange(e.target.value, "laborRate")
                }
                InputProps={{
                  inputComponent:
                    decimalNumberFormat as unknown as ElementType<InputBaseComponentProps>,
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>
            <Grid item container xl={2} sm={2} xs={6}>
              <TextField
                className="location_rates_input"
                name="otRate"
                label="OT"
                defaultValue={0.0}
                variant="outlined"
                value={statee.otRate}
                onChange={(e) =>
                  handleDecimalOnChange(e.target.value, "otRate")
                }
                InputProps={{
                  inputComponent:
                    decimalNumberFormat as unknown as ElementType<InputBaseComponentProps>,
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>
            <Grid item container xl={2} sm={2} xs={6}>
              <TextField
                className="location_rates_input"
                name="tripRate"
                label="Trip"
                defaultValue={0.0}
                variant="outlined"
                value={statee.tripRate}
                onChange={(e) =>
                  handleDecimalOnChange(e.target.value, "tripRate")
                }
                InputProps={{
                  inputComponent:
                    decimalNumberFormat as unknown as ElementType<InputBaseComponentProps>,
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>
            <Grid item container xl={2} sm={2} xs={6}>
              <TextField
                className="location_rates_input"
                name="holidayRate"
                label="Holiday"
                defaultValue={0.0}
                variant="outlined"
                value={statee.holidayRate}
                onChange={(e) =>
                  handleDecimalOnChange(e.target.value, "holidayRate")
                }
                InputProps={{
                  inputComponent:
                    decimalNumberFormat as unknown as ElementType<InputBaseComponentProps>,
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>
            <Grid item container xl={2} sm={2} xs={6}>
              <TextField
                className="location_rates_input"
                name="locationMarkup"
                label="Markup"
                defaultValue={0.0}
                variant="outlined"
                value={statee.locationMarkup}
                onChange={(e) =>
                  handleDecimalOnChange(e.target.value, "locationMarkup")
                }
                InputProps={{
                  inputComponent:
                    decimalNumberFormat as unknown as ElementType<InputBaseComponentProps>,
                  startAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>
          </Grid>
          <hr />
        </Grid>
        <div className="location_form_footer">
          <Grid container>
            <Grid item xl={6} sm={6}>
              <TextField
                className="location_form_footer_pm_info"
                name={"pmInfo"}
                placeholder="Type inside this box for PM info"
                multiline
                rows={5}
                variant="outlined"
                value={statee.pmInfo}
                onChange={(e) =>
                  setStatee({ ...statee, pmInfo: e.target.value })
                }
              />
            </Grid>
            <Grid item container xs={6} sm={6} alignContent="flex-end">
              <Button
                type="submit"
                color="primary"
                variant="contained"
                size="large"
                fullWidth
                disabled={
                  role === UserRole.Dispatcher ||
                  role === UserRole.Technician ||
                  isSavingChanges
                }
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </div>
      </form>
    </>
  );
}
