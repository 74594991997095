import React from "react";
import BasicTable from "../../../common/table/BasicTable";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { UserVM } from "../../../../brines-refrigerator-api";
import { Edit } from "@material-ui/icons";
import NumberFormat from "react-number-format";

interface UserTableProps {
  users: UserVM[];
  isLoading: boolean;
  setUserForEditing: Function;
}

const UsersTable = (props: UserTableProps) => {
  const userColumns = [
    { title: "User ID", field: "id" },
    { title: "Role", field: "role" },
    { title: "UserName", field: "userName" },
    { title: "E-mail", field: "email" },
    { title: "First name", field: "firstName" },
    { title: "Last name", field: "lastName" },
    {
      title: "Phone",
      field: "phone",
      render: (rowData) => (
        <NumberFormat
          value={rowData.phone}
          format="(###) ###-####"
          displayType="text"
        />
      ),
    },
    { title: "State", field: "state.name" },
    {
      title: "Active",
      field: "active",
      render: (rowData) =>
        rowData.active ? (
          <CheckBoxIcon color="primary" />
        ) : (
          <CheckBoxOutlineBlankIcon color="disabled" />
        ),
    },
  ];

  const tableActions = [
    (rowData) => ({
      icon: () => <Edit color="primary" />,
      onClick: (event, rowData) => {
        props.setUserForEditing(rowData);
      },
      tooltip: "Edit user",
    }),
  ];

  return (
    <>
      <BasicTable
        columns={userColumns}
        data={props.users as []}
        search={false}
        sorting={true}
        title={"Users list"}
        paging={true}
        isLoading={props.isLoading}
        actions={tableActions}
      />
    </>
  );
};

export default UsersTable;
