import React, { useState } from 'react';
import { Grid, makeStyles, createStyles, Paper, GridList, GridListTile, withStyles, TextField, Divider, Button, Typography } from '@material-ui/core';
import { DispatchEquipmentVM } from '../../brines-refrigerator-api';
import * as client from '../../helpers/offline/offline-api';

interface TechEquipment {
  dispatchEquipment: DispatchEquipmentVM[]
}

const useStyles = makeStyles(() =>
  createStyles({
    tileList: {
      maxHeight: "25rem",
      width: "100%"
    },
    marginTop: {
      marginTop: "1rem"
    },
    fontSize: {
      fontSize: "0.8rem"
    }
  })
);

const CustomTextField = withStyles({
  root: {
    '& .MuiInputBase-input': {
      color: '#000000',
    },
  },
})(TextField);

export default function TechEquipment(props: TechEquipment) {
  const classes = useStyles();
  const options = { year: 'numeric', day: 'numeric', month: 'numeric', hour: 'numeric', minute: 'numeric', timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone };
  const dateTimeFormat = new Intl.DateTimeFormat('en-US', options);
  const [noteText, setNoteText] = useState('')
  const [notesForm, setNotesForm] = useState(false)
  const [equipmentNotes, setEquipmentNotes] = useState([])
  const [equipmentNoteAddId, setEquipmentNoteAddId] = useState(0)
  const [_addEquipmentNote] = client.useAddEquipmentNote()
  const [_getEquipment] = client.useGetEquipment()

  const getEquipmentnotes = async (equipmentId: number) => {
    try {
      const notesForDisplay = await _getEquipment(equipmentId)
      setEquipmentNotes(notesForDisplay.notes)
    } catch (error) {
      console.log(error.message)
    }
  }

  const showNotes = async (equipmentId: number) => {
    getEquipmentnotes(equipmentId)
    setEquipmentNoteAddId(equipmentId)
    setNotesForm(true)
  }

  const addEquipmentNote = async () => {
    await _addEquipmentNote(equipmentNoteAddId, noteText)
    getEquipmentnotes(equipmentNoteAddId)
    setNoteText('')
    setNotesForm(false)
  }

  const validateInput = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let noteText = e.target.value;
    setNoteText(noteText)
  }

  return (
    <div>
      <Grid container item xs={12}>
        <Grid sm={6} xs={6}>
          <Paper square elevation={0}>
            <div>
              <GridList className={classes.tileList} cellHeight={100}>
                {props.dispatchEquipment.map((tile) => (
                  <GridListTile key={tile.equipment.id} style={{ width: "100%" }} >
                    <div>
                      <Grid xs={12} container item>
                        <Grid xs={6} item>
                          <CustomTextField
                            disabled
                            id="outlined-disabled-s/n"
                            label="S/N"
                            defaultValue={tile.equipment.serialNumber}
                            InputProps={{ disableUnderline: true }}
                          />
                          <CustomTextField
                            disabled
                            id="outlined-disabled-model"
                            label="Model"
                            defaultValue={tile.equipment.model}
                            InputProps={{ disableUnderline: true }}
                          />
                          <Divider />
                        </Grid>
                        <Grid xs={1} item>
                          <Button color="primary" variant="contained" size="small" onClick={() => showNotes(tile.equipment.id)}>Notes</Button>
                        </Grid>
                      </Grid>
                    </div>
                  </GridListTile>
                ))}
              </GridList>
            </div>
          </Paper>
        </Grid>

        <Grid sm={6} xs={6} item>
          {notesForm ?
            <Grid item xs={12} className={classes.marginTop}>
              <Paper variant="outlined" square>
                <div>
                  <GridList className={classes.tileList} cellHeight={160}>
                    {equipmentNotes.map((tile) => (
                      <GridListTile key={tile.id} style={{ width: "100%" }} >
                        <div>
                          <Grid container alignItems="center" >
                            <Grid container item xs>
                              <Grid item xs={12}>
                                <Typography className={classes.fontSize} gutterBottom>Added at: {dateTimeFormat.format(tile.created)}</Typography>
                              </Grid>
                              <Typography className={classes.fontSize} gutterBottom>Added by: {tile.createdBy.userName}</Typography>
                            </Grid>
                          </Grid>
                          <Typography className={classes.marginTop} variant="body2">
                            {tile.text}
                          </Typography>
                        </div>
                      </GridListTile>
                    ))}
                  </GridList>
                </div>
              </Paper>
              <TextField
                fullWidth={true}
                label="Add Note"
                multiline
                rows={4}
                variant="outlined"
                onChange={event => validateInput(event)}
                value={noteText}
                className={classes.marginTop}
              />
              <Button
                onClick={() => addEquipmentNote()}
                variant="contained"
                color="primary"
                fullWidth={true}
                className={classes.marginTop}
              >
                Add Note
              </Button>
            </Grid>
            :
            <Grid xs={6} item></Grid>
          }
        </Grid>
      </Grid>
    </div>
  );
}