import React, { useReducer, useEffect, useState } from "react";

import "./DispatchBoardCRUDForm.scss";
import {
  Box,
  Button,
  Grid,
  Tab,
  Tabs,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import AddIcon from "@material-ui/icons/Add";

import {
  DispatchVM,
  TradeVM,
  PriorityTypeVM,
  DispatchStatusVM,
  LocationVM,
  UserVM,
  DispatchDocumentsClient,
  DispatchNotesClient,
  DispatchNoteCreateVM,
  ServiceNotesClient,
  ServiceNoteCreateVM,
  InternalNotesClient,
  InternalNoteCreateVM,
  DispatchNoteUpdateVM,
  ServiceNoteUpdateVM,
  InternalNoteUpdateVM,
  PartClient,
  PartVM,
  DispatchPartsUpdateVM,
  EquipmentClient,
  DispatchEquipmentUpdateVM,
  EquipmentVM,
  ServiceRequestCodeVM,
  CustomerBaseVM,
  LocationDispatchHqVM,
  UserBaseVM,
  DispatchSecondaryStatusVM,
  UserClient,
  PartPriceVM,
} from "../../../brines-refrigerator-api";
import { useSnackbar } from "notistack";

import ServiceRequestCodes from "../../Dispatch/ServiceRequestCodes/ServiceRequestCodes";
import AddNote from "../../../components/common/note/AddNote";
import FormDialog from "../../../components/common/dialog/FormDialog";
import NoteDispatch from "../../../components/common/note/NoteDispatch";
import UserRole from "../../../helpers/constants/userRole";
import EquipmentChangeAction from "../../../helpers/constants/equipmentChangeActions";
import {
  EquipmentDocumentAddVM,
  UploadFileClient,
  CustomDispatchDocumentsClient,
  DispatchDocumentAddVM,
} from "../../../brines-refrigerator-api-extended";
import DispatchParts from "../../Dispatch/DispatchParts/DispatchParts";
import DispatchDocuments from "../../Dispatch/DispatchDocuments/DispatchDocuments";
import DispatchEquipment from "../../Dispatch/DispatchEquipment/DispatchEquipment";
import { generateUID } from "../../../helpers/generateUID";
import {
  useSelectValidation,
  validateSelect,
} from "../../../helpers/validations";
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import DateType from "../../../helpers/constants/dateTypes";
import DispatchLabor from "../../Dispatch/DispatchLabor/DispatchLabor";
import handleServerError from "../../../helpers/handleServerError";
import Visits from "../../../components/dispatch/visitsModal/visits";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

interface FormProps {
  trades: TradeVM[];
  priorities: PriorityTypeVM[];
  customers: CustomerBaseVM[];
  locations: LocationDispatchHqVM[];
  statuses: DispatchStatusVM[];
  secondaryStatuses: DispatchSecondaryStatusVM[];
  technicians: UserVM[];
  serviceRequestCodes: ServiceRequestCodeVM[];
  formAction: Function;
  clearFields: Function;
  dispatch: DispatchVM;
  showGenerateInvoiceButton: boolean;
  redirectToInvoice?: Function;
}

interface DispatchFormStateType {
  id: number;
  locationId: number;
  location: LocationVM;
  priorityId: number;
  tradeId: number;
  workOrderNumber: number;
  technicianId: number;
  regionId: number;
  created: Date;
  followUpDate: Date;
  slaDate: Date;
  completedDate: Date;
  statusId: number;
  secondaryStatusId: number;
  customers: CustomerBaseVM[];
  locations: LocationDispatchHqVM[];
  trades: TradeVM[];
  priorities: PriorityTypeVM[];
  statuses: DispatchStatusVM[];
  secondaryStatuses: DispatchSecondaryStatusVM[];
  technicians: UserVM[];
}

const useStyles = makeStyles({
  button: {
    marginBottom: "1em",
  },
  boxScroll: {
    overflowY: "auto",
    overflowX: "hidden",
  },
  partTable: {
    width: "60rem",
    height: "30rem",
  },
  addPartTableButton: {
    marginLeft: "70%",
  },
});

const DispatchBoardCRUDForm = (props: FormProps) => {
  const dateTimeFormat = new Intl.DateTimeFormat("en-US", {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
  const { enqueueSnackbar } = useSnackbar();
  const usersClient = new UserClient();

  const classes = useStyles();

  const [state, setState] = useReducer(
    (state: DispatchFormStateType, newState: DispatchFormStateType) => ({
      ...state,
      ...newState,
    }),
    {
      id: props.dispatch.id,
      customers: props.customers,
      locations: props.locations,
      trades: props.trades,
      priorities: props.priorities,
      statuses: props.statuses,
      secondaryStatuses: props.secondaryStatuses,
      statusId: props.dispatch.statusId,
      secondaryStatusId: props.dispatch.secondaryStatusId,
      locationId: props.dispatch.locationId,
      location: props.dispatch.location,
      priorityId: props.dispatch.priorityId,
      tradeId: props.dispatch.tradeId,
      workOrderNumber: props.dispatch.workOrderNumber,
      technicians: props.technicians,
      technicianId: props.dispatch.tehnicianId,
      regionId: props.dispatch.regionId,
      followUpDate: props.dispatch.followUpDate,
      slaDate: props.dispatch.slaDate,
      completedDate: props.dispatch.completedDate,
      created: props.dispatch.created,
    }
  );

  useEffect(() => {
    setState({
      id: props.dispatch.id,
      customers: props.customers,
      locations: props.locations,
      trades: props.trades,
      priorities: props.priorities,
      statuses: props.statuses,
      secondaryStatuses: props.secondaryStatuses,
      statusId: props.dispatch.statusId,
      secondaryStatusId: props.dispatch.secondaryStatusId,
      locationId: props.dispatch.locationId,
      location: props.dispatch.location,
      priorityId: props.dispatch.priorityId,
      tradeId: props.dispatch.tradeId,
      workOrderNumber: props.dispatch.workOrderNumber,
      technicians: props.technicians,
      technicianId: props.dispatch.tehnicianId,
      regionId: props.dispatch.regionId,
      followUpDate: props.dispatch.followUpDate,
      slaDate: props.dispatch.slaDate,
      completedDate: props.dispatch.completedDate,
      created: props.dispatch.created,
    });
    setDispatchEquipment([]);
    setDocuments([]);
    clearValidation();
    getUsers();
  }, [props]);

  const [documents, setDocuments] = useState<EquipmentDocumentAddVM[]>([]);

  //needed for switching between tabs
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = async (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    setTabValue(newValue);
  };

  const handleColorChange = (
    event: React.ChangeEvent<{ value: unknown }>,
    table: string
  ) => {
    if (table === "priority") {
      setState({ ...state, priorityId: event.target.value as number });
    } else {
      setState({ ...state, tradeId: event.target.value as number });
    }
  };

  const generateInvoice = async () => {
    await editDispatchHandler();
    props.redirectToInvoice(state.id);
  };

  const [prioritySelectOpen, setPrioritySelectOpen] = useState(false);
  const [tradeSelectOpen, setTradeSelectOpen] = useState(false);

  const ListComponent: React.FC<{
    backgroundColor: string;
    text?: string;
    textColor?: string;
  }> = ({ backgroundColor, text, textColor }) => (
    <>
      <div
        className="priority_and_trades_form_add_data_container_pick_color_text"
        style={{
          backgroundColor,
          width: "100%",
          height: 38,
          color: textColor,
        }}
      ></div>
      <div style={{ whiteSpace: "pre-wrap", marginLeft: "1rem" }}>{text}</div>
    </>
  );

  const handleWONumberChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setState({ ...state, workOrderNumber: Number(event.target.value) });
  };

  const handleTechnicianChange = (
    event: React.ChangeEvent<{ value: UserVM }>,
    value: UserVM
  ) => {
    let selectedTech = value;
    //status 1 is unassigned, 2 is pending
    if (selectedTech) {
      setState({ ...state, technicianId: selectedTech.id, statusId: 2 });
    } else {
      setState({ ...state, technicianId: null, statusId: 1 });
    }
    if (technicianInputProps.error) validateTechnician(value?.id);
  };

  const handleStatusChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const _statusId = event.target.value as number;
    setState({
      ...state,
      statusId: _statusId as number,
      technicianId: _statusId === 1 ? null : state.technicianId,
    });
    if (_statusId === 1) {
      resetTechnicianInputProps();
    }
  };

  const handleSecondaryStatusChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const _secondaryStatusId = event.target.value as number;
    setState({ ...state, secondaryStatusId: _secondaryStatusId as number });
  };

  const editDispatchHandler = async () => {
    if (validateUpdateDispatch()) {
      await updateInternalNotes();
      await updatedServiceNotes();
      await updateDispatchNotes();
      await updateDispatchEquipment();
      await updateDispatchParts();
      await uploadEquipmentDocuments();
      await props.formAction({
        id: state.id,
        locationId: state.locationId,
        priorityId: state.priorityId,
        tradeId: state.tradeId,
        workOrderNumber: state.workOrderNumber,
        tehnicianId: state.technicianId,
        regionId: state.regionId,
        followUpDate: state.followUpDate,
        completedDate: state.completedDate,
        slaDate: state.slaDate,
        statusId: state.statusId,
        secondaryStatusId: state.secondaryStatusId,
        serviceRequestCodeId: selectedRequestCode
          ? selectedRequestCode.id
          : null,
      });
      clearValidation();
    }
  };

  const clearFields = async () => {
    await props.clearFields();
    await clearServiceRCodes();
    setDispatchDocuments([]);
    setInternalNotes([]);
    setDispatchNotes([]);
    setServiceNotes([]);
    setDispatchParts([]);
    setDispatchEquipment([]);
  };

  const [srcTabValue, setSrcTabValue] = useState(0);

  const handleSrcTabChange = async (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    setSrcTabValue(newValue);
  };

  // SERVICE REQUEST CODES
  const [selectedRequestCode, setSelectedRequestCode] = useState(null);

  const clearServiceRCodes = async () => {
    setSelectedRequestCode(null);
  };

  const getDispatchServiceRequestCodes = async () => {
    if (props.dispatch.id !== null) {
      setSelectedRequestCode(props.dispatch.serviceRequestCode);
    }
  };
  useEffect(() => {
    getDispatchServiceRequestCodes();
  }, [props.dispatch]);

  // NOTES
  const [noteText, setNoteText] = useState("");
  const [modalState, setModalState] = useState(false);
  const [modalTitle, setModalTitle] = useState("Add Note");
  const [buttonText, setButtonText] = useState("Add Note");

  const openDispatchNoteModal = () => {
    setAddNoteModalState();
  };

  const openServiceNoteModal = () => {
    setAddNoteModalState();
  };

  const openInternalNoteModal = () => {
    setAddNoteModalState();
  };

  const closeModal = () => {
    setModalState(false);
    setNoteText("");
  };

  // ADD NOTES
  const addDispatchNote = async (_, noteText) => {
    setAddNoteButtonActionState(noteText);

    const uid = generateUID();
    const newNote = new NoteTempVM(
      null,
      null,
      null,
      null,
      null,
      noteText,
      true,
      uid
    );
    dispatchNotes.push(newNote);
    const newDispatchNotes = [...dispatchNotes];
    setDispatchNotes(newDispatchNotes);

    draftDispatchNotes.push(newNote);
    const newDraftNotes = [...draftDispatchNotes];
    setDraftDispatchNotes(newDraftNotes);

    setNoteText("");

    setModalState(false);
  };

  const addServiceNote = async (_, noteText) => {
    setAddNoteButtonActionState(noteText);

    const uid = generateUID();
    const newNote = new NoteTempVM(
      null,
      null,
      null,
      null,
      null,
      noteText,
      true,
      uid
    );
    serviceNotes.push(newNote);
    const newServiceNotes = [...serviceNotes];
    setServiceNotes(newServiceNotes);

    draftServiceNotes.push(newNote);
    const newDraftNotes = [...draftServiceNotes];
    setDraftServiceNotes(newDraftNotes);

    setNoteText("");

    setModalState(false);
  };

  const addInternalNote = async (_, noteText) => {
    setAddNoteButtonActionState(noteText);

    const uid = generateUID();
    const newNote = new NoteTempVM(
      null,
      null,
      null,
      null,
      null,
      noteText,
      true,
      uid
    );
    internalNotes.push(newNote);
    const newInternalNotes = [...internalNotes];
    setInternalNotes(newInternalNotes);

    draftInternalNotes.push(newNote);
    const newDraftNotes = [...draftInternalNotes];
    setDraftInternalNotes(newDraftNotes);

    setNoteText("");

    setModalState(false);
  };

  const [dispatchNoteUIdForEditing, setDispatchNoteUIdForEditing] =
    useState("");
  const [serviceNoteUIdForEditing, setserviceNoteUIdForEditing] = useState("");
  const [internalNoteUIdForEditing, setInternalNoteUIdForEditing] =
    useState("");

  // ADD NOTE COMPONENTS
  const dispatchAddNote = (
    <AddNote
      buttonAction={
        modalTitle === "Edit Note" ? editDispatchNote : addDispatchNote
      }
      id={state.id}
      noteText={noteText}
      title={modalTitle}
      buttonText={buttonText}
      onClick={closeModal}
      uid={dispatchNoteUIdForEditing}
    />
  );

  const serviceAddNote = (
    <AddNote
      buttonAction={
        modalTitle === "Edit Note" ? editServiceNote : addServiceNote
      }
      id={state.id}
      noteText={noteText}
      title={modalTitle}
      buttonText={buttonText}
      onClick={closeModal}
      uid={serviceNoteUIdForEditing}
    />
  );

  const internalAddNote = (
    <AddNote
      buttonAction={
        modalTitle === "Edit Note" ? editInternalNote : addInternalNote
      }
      id={state.id}
      noteText={noteText}
      title={modalTitle}
      buttonText={buttonText}
      onClick={closeModal}
      uid={internalNoteUIdForEditing}
    />
  );

  async function editDispatchNoteCallback(
    id: number,
    noteText: string,
    uid: string
  ) {
    setEditModalState(noteText);

    setDispatchNoteUIdForEditing(uid);
  }

  function editDispatchNote(_, noteText: string, uid: string) {
    const foundNote = dispatchNotes.find((x) => x.uid === uid);

    const noteIndex = dispatchNotes.findIndex((x) => x.uid === uid);
    dispatchNotes[noteIndex].text = noteText;
    dispatchNotes[noteIndex].lastModified = new Date();
    dispatchNotes[noteIndex].lastModifiedBy = new UserBaseVM({
      userName: userData.user.userName,
      email: userData.user.email,
    });

    const newDispatchNotes = [...dispatchNotes];
    setDispatchNotes(newDispatchNotes);

    if (foundNote.draft) {
      const draftNoteIndex = draftDispatchNotes.findIndex((x) => x.uid === uid);
      draftDispatchNotes[draftNoteIndex].text = noteText;

      setDraftDispatchNotes([...draftDispatchNotes]);
    } else {
      //was the note on the pile of non-draft notes to be edited before?
      const check = editedDispatchNotes.findIndex((x) => x.id === foundNote.id);
      const tempEditedNotes = [...editedDispatchNotes];
      if (check !== -1) {
        //if so, find it and change the text to new one
        tempEditedNotes[check].text = noteText;
      } else {
        console.log("PUSHING NEW");
        //else push a new element
        tempEditedNotes.push(
          new DispatchNoteUpdateVM({
            id: foundNote.id,
            text: noteText,
          })
        );
      }
      setEditedDispatchNotes(tempEditedNotes);
    }

    setModalState(false);
    setNoteText("");
  }

  async function editServiceNoteCallback(
    id: number,
    noteText: string,
    uid: string
  ) {
    setEditModalState(noteText);

    setserviceNoteUIdForEditing(uid);
  }

  function editServiceNote(_, noteText: string, uid: string) {
    const foundNote = serviceNotes.find((x) => x.uid === uid);

    const noteIndex = serviceNotes.findIndex((x) => x.uid === uid);
    serviceNotes[noteIndex].text = noteText;
    serviceNotes[noteIndex].lastModified = new Date();
    serviceNotes[noteIndex].lastModifiedBy = new UserBaseVM({
      userName: userData.user.userName,
      email: userData.user.email,
    });

    const newServiceNotes = [...serviceNotes];
    setServiceNotes(newServiceNotes);

    if (foundNote.draft) {
      const draftNoteIndex = draftServiceNotes.findIndex((x) => x.uid === uid);
      draftServiceNotes[draftNoteIndex].text = noteText;

      setDraftServiceNotes([...draftInternalNotes]);
    } else {
      //was the note on the pile of non-draft notes to be edited before?
      const check = editedServiceNotes.findIndex((x) => x.id === foundNote.id);
      const tempEditedNotes = [...editedServiceNotes];
      if (check !== -1) {
        //if so, find it and change the text to new one
        tempEditedNotes[check].text = noteText;
      } else {
        //else push a new element
        tempEditedNotes.push(
          new ServiceNoteUpdateVM({
            id: foundNote.id,
            text: noteText,
          })
        );
      }
      setEditedServiceNotes(tempEditedNotes);
    }

    setModalState(false);
    setNoteText("");
  }

  function editInternalNoteCallback(id: number, noteText: string, uid: string) {
    setEditModalState(noteText);

    setInternalNoteUIdForEditing(uid);
  }

  function editInternalNote(_, noteText: string, uid: string) {
    const foundNote = internalNotes.find((x) => x.uid === uid);

    const noteIndex = internalNotes.findIndex((x) => x.uid === uid);
    internalNotes[noteIndex].text = noteText;
    internalNotes[noteIndex].lastModified = new Date();
    internalNotes[noteIndex].lastModifiedBy = new UserBaseVM({
      userName: userData.user.userName,
      email: userData.user.email,
    });

    const newInternalNotes = [...internalNotes];
    setInternalNotes(newInternalNotes);

    if (foundNote.draft) {
      const draftNoteIndex = draftInternalNotes.findIndex((x) => x.uid === uid);
      draftInternalNotes[draftNoteIndex].text = noteText;

      setDraftInternalNotes([...draftInternalNotes]);
    } else {
      //was the note on the pile of non-draft notes to be edited before?
      const check = editedInternalNotes.findIndex((x) => x.id === foundNote.id);
      const tempEditedNotes = [...editedInternalNotes];
      if (check !== -1) {
        //if so, find it and change the text to new one
        tempEditedNotes[check].text = noteText;
      } else {
        console.log("PUSHING NEW");
        //else push a new element
        tempEditedNotes.push(
          new InternalNoteUpdateVM({
            id: foundNote.id,
            text: noteText,
          })
        );
      }
      setEditedInternalNotes(tempEditedNotes);
    }

    setModalState(false);
    setNoteText("");
  }

  const updateInternalNotes = async () => {
    const client = new InternalNotesClient();

    //function responsible for turning temp into permanent state
    if (removedInternalNotes.length > 0) {
      removedInternalNotes.forEach((x) => {
        client.delete(x);
      });
    }

    if (draftInternalNotes.length > 0) {
      draftInternalNotes.forEach((x) => {
        client.create(
          new InternalNoteCreateVM({
            dispatchId: state.id,
            text: x.text,
          })
        );
      });
    }

    if (editedInternalNotes.length > 0) {
      editedInternalNotes.forEach((x) => {
        client.update(x);
      });
    }

    setRemovedInternalNotes([]);
    setDraftInternalNotes([]);
    setEditedInternalNotes([]);
  };

  function deleteNoteCallback(id: number, uid: string) {
    const foundNote = dispatchNotes.find((x) => x.uid === uid);

    //remove the note from the overall note list
    const noteIndex = dispatchNotes.findIndex((x) => x.uid === uid);

    dispatchNotes.splice(noteIndex, 1);
    //create a new array through destructuring as react would sometimes fail to re-render, because of react recognizing it to be completely the same(Even though it isn't)
    const newNotes = [...dispatchNotes];

    setDispatchNotes(newNotes);

    if (foundNote.draft) {
      //if the specified note to remove is a draft we need to remove it from the draft notes list
      const draftNoteIndex = draftDispatchNotes.findIndex((x) => x.uid === uid);
      draftDispatchNotes.splice(draftNoteIndex, 1);
      const newDraftNotes = [...draftDispatchNotes];
      setDraftDispatchNotes(newDraftNotes);
    } else {
      //otherwise it's a non-draft note and we need to add it to the list according to which back-end api calls are made to remove non-draft notes
      removedDispatchNotes.push(foundNote.id);

      //also, we need to check whether this note was scheduled for editing before and remove it accordingly
      const check = editedDispatchNotes.findIndex((x) => x.id === id);

      if (check !== -1) {
        editedDispatchNotes.splice(check, 1);
        const newEditedNotes = [...editedDispatchNotes];
        setEditedDispatchNotes(newEditedNotes);
      }
    }
  }

  const updateDispatchNotes = async () => {
    const client = new DispatchNotesClient();

    //function responsible for turning temp into permanent state
    if (removedDispatchNotes.length > 0) {
      removedDispatchNotes.forEach((x) => {
        client.delete(x);
      });
    }

    if (draftDispatchNotes.length > 0) {
      draftDispatchNotes.forEach((x) => {
        client.create(
          new DispatchNoteCreateVM({
            dispatchId: state.id,
            text: x.text,
          })
        );
      });
    }

    if (editedDispatchNotes.length > 0) {
      editedDispatchNotes.forEach((x) => {
        client.update(x);
      });
    }

    setRemovedDispatchNotes([]);
    setDraftDispatchNotes([]);
    setEditedDispatchNotes([]);
  };

  function deleteServiceNotetCallback(id: number, uid: string) {
    const foundNote = serviceNotes.find((x) => x.uid === uid);

    //remove the note from the overall note list
    const noteIndex = serviceNotes.findIndex((x) => x.uid === uid);

    serviceNotes.splice(noteIndex, 1);
    //create a new array through destructuring as react would sometimes fail to re-render, because of react recognizing it to be completely the same(Even though it isn't)
    const newNotes = [...serviceNotes];

    setServiceNotes(newNotes);

    if (foundNote.draft) {
      //if the specified note to remove is a draft we need to remove it from the draft notes list
      const draftNoteIndex = draftServiceNotes.findIndex((x) => x.uid === uid);
      draftServiceNotes.splice(draftNoteIndex, 1);
      const newDraftNotes = [...draftServiceNotes];
      setDraftServiceNotes(newDraftNotes);
    } else {
      //otherwise it's a non-draft note and we need to add it to the list according to which back-end api calls are made to remove non-draft notes
      removedServiceNotes.push(foundNote.id);

      //also, we need to check whether this note was scheduled for editing before and remove it accordingly
      const check = editedServiceNotes.findIndex((x) => x.id === id);

      if (check !== -1) {
        editedServiceNotes.splice(check, 1);
        const newEditedNotes = [...editedServiceNotes];
        setEditedServiceNotes(newEditedNotes);
      }
    }
  }

  const updatedServiceNotes = async () => {
    const client = new ServiceNotesClient();

    //function responsible for turning temp into permanent state
    if (removedServiceNotes.length > 0) {
      removedServiceNotes.forEach((x) => {
        client.delete(x);
      });
    }

    if (draftServiceNotes.length > 0) {
      draftServiceNotes.forEach((x) => {
        client.create(
          new ServiceNoteCreateVM({
            dispatchId: state.id,
            text: x.text,
          })
        );
      });
    }

    if (editedServiceNotes.length > 0) {
      editedServiceNotes.forEach((x) => {
        client.update(x);
      });
    }

    setRemovedServiceNotes([]);
    setDraftServiceNotes([]);
    setEditedServiceNotes([]);
  };

  function deleteInternalNoteCallback(id: number, uid: string) {
    const foundNote = internalNotes.find((x) => x.uid === uid);

    //remove the note from the overall note list
    const noteIndex = internalNotes.findIndex((x) => x.uid === uid);

    internalNotes.splice(noteIndex, 1);
    //create a new array through destructuring as react would sometimes fail to re-render, because of react recognizing it to be completely the same(Even though it isn't)
    const newNotes = [...internalNotes];

    setInternalNotes(newNotes);

    if (foundNote.draft) {
      //if the specified note to remove is a draft we need to remove it from the draft notes list
      const draftNoteIndex = draftInternalNotes.findIndex((x) => x.uid === uid);
      draftInternalNotes.splice(draftNoteIndex, 1);
      const newDraftNotes = [...draftInternalNotes];
      setDraftInternalNotes(newDraftNotes);
    } else {
      //otherwise it's a non-draft note and we need to add it to the list according to which back-end api calls are made to remove non-draft notes
      removedInternalNotes.push(foundNote.id);

      //also, we need to check whether this note was scheduled for editing before and remove it accordingly
      const check = editedInternalNotes.findIndex((x) => x.id === id);

      if (check !== -1) {
        editedInternalNotes.splice(check, 1);
        const newEditedNotes = [...editedInternalNotes];
        setEditedInternalNotes(newEditedNotes);
      }
    }
  }

  const setEditModalState = (noteText: string) => {
    setModalState(true);
    setModalTitle("Edit Note");
    setButtonText("Edit Note");
    setNoteText(noteText);
  };
  const setAddNoteButtonActionState = (noteText: string) => {
    setNoteText(noteText);
    setModalTitle("Add Note");
    setButtonText("Add Note");
  };

  const setAddNoteModalState = () => {
    setModalState(true);
    setModalTitle("Add Note");
    setButtonText("Add Note");
  };

  //NOTES FOR DISPLAY

  const [dispatchNotes, setDispatchNotes] = useState([]);

  async function getDispatchNotes() {
    if (props.dispatch.id !== null) {
      try {
        const notesClient = new DispatchNotesClient();
        const dispatchNotes = await notesClient.get(props.dispatch.id, true);
        const modifiedDispatchNotes = dispatchNotes.map(
          (x) =>
            new NoteTempVM(
              x.id,
              x.created,
              x.createdBy,
              x.lastModified,
              x.lastModifiedBy,
              x.text,
              false,
              generateUID()
            )
        );
        setDispatchNotes(modifiedDispatchNotes);
      } catch (error) {
        handleServerError(error, enqueueSnackbar);
      }
    }
  }

  useEffect(() => {
    getDispatchNotes();
  }, [props.dispatch]);

  const [serviceNotes, setServiceNotes] = useState([]);

  async function getServiceNotes() {
    if (props.dispatch.id !== null) {
      try {
        const serviceNotesClient = new ServiceNotesClient();
        const serviceNotes = await serviceNotesClient.get(
          props.dispatch.id,
          true
        );
        const modifiedServiceNotes = serviceNotes.map(
          (x) =>
            new NoteTempVM(
              x.id,
              x.created,
              x.createdBy,
              x.lastModified,
              x.lastModifiedBy,
              x.text,
              false,
              generateUID()
            )
        );
        setServiceNotes(modifiedServiceNotes);
      } catch (error) {
        handleServerError(error, enqueueSnackbar);
      }
    }
  }
  useEffect(() => {
    getServiceNotes();
  }, [props.dispatch]);

  //notes for displaying(both actual and draft)
  const [internalNotes, setInternalNotes] = useState([]);

  //added draft notes(removed and edited draft notes are also done through this as for the back-end it makes no difference whether we edited a draft note)
  const [draftInternalNotes, setDraftInternalNotes] = useState([]);
  const [draftDispatchNotes, setDraftDispatchNotes] = useState([]);
  const [draftServiceNotes, setDraftServiceNotes] = useState([]);
  //removed non-draft notes
  const [removedInternalNotes, setRemovedInternalNotes] = useState([]);
  const [removedDispatchNotes, setRemovedDispatchNotes] = useState([]);
  const [removedServiceNotes, setRemovedServiceNotes] = useState([]);

  //edited non-draft notes
  const [editedInternalNotes, setEditedInternalNotes] = useState([]);
  const [editedDispatchNotes, setEditedDispatchNotes] = useState([]);
  const [editedServiceNotes, setEditedServiceNotes] = useState([]);

  interface INoteTempVM {
    id: number;
    created: Date;
    createdBy: string;
    lastModified: Date;
    lastModifiedBy: string;
    text: string;
    draft: boolean;
    uid: string;
  }

  class NoteTempVM implements INoteTempVM {
    id: number;
    created: Date;
    createdBy: string;
    lastModified: Date;
    lastModifiedBy: string;
    text: string;
    draft: boolean;
    uid: string;

    constructor(id, c, cb, m, mb, t, d, uid) {
      this.id = id;
      this.created = c;
      this.createdBy = cb;
      this.lastModified = m;
      this.lastModifiedBy = mb;
      this.text = t;
      this.draft = d;
      this.uid = uid;
    }
  }

  async function getInternalNotes() {
    if (props.dispatch.id !== null) {
      try {
        const internalNotesClient = new InternalNotesClient();
        const internalNotes = await internalNotesClient.get(
          props.dispatch.id,
          true
        );
        const modifiedInternalNotes = internalNotes.map(
          (x) =>
            new NoteTempVM(
              x.id,
              x.created,
              x.createdBy,
              x.lastModified,
              x.lastModifiedBy,
              x.text,
              false,
              generateUID()
            )
        );
        setInternalNotes(modifiedInternalNotes);
      } catch (error) {
        handleServerError(error, enqueueSnackbar);
      }
    }
  }
  useEffect(() => {
    getInternalNotes();
  }, [props.dispatch]);

  // USER ROLE
  // Get user data from session storage
  const userData: any = JSON.parse(sessionStorage.getItem("userData") || "{}");
  //get user role
  const role = userData.role.name;

  const uploadEquipmentDocuments = async () => {
    const uploadClient = new UploadFileClient();
    for (const document of documents) {
      await uploadClient.uploadEquipmentDocument(document);
    }
  };

  //PARTS
  const [dispatchTableParts, setDispatchParts] = useState([]);

  async function getDispatchParts(id: number) {
    try {
      const partsClient = new PartClient();
      const dispatchParts = await partsClient.getDispatchParts(id);
      setDispatchParts(
        dispatchParts.map(
          (e) =>
            new PartPriceVM({
              ...e.part,
              price: e.partPrice,
              number: e.partNumber,
              quantity: e.partQuantity,
            })
        )
      );
    } catch (error) {
      handleServerError(error, enqueueSnackbar);
    }
  }

  //DISPATCH EQUIPMENT
  const [dispatchEquipment, setDispatchEquipment] = useState([]);

  useEffect(() => {
    if (props.dispatch.id) getDispatchEquipment();
  }, [props.dispatch]);

  const getDispatchEquipment = async () => {
    try {
      const dispatchEquipmentClient = new EquipmentClient();
      const equipment = await dispatchEquipmentClient.getDispatchEquipment(
        props.dispatch.id
      );
      setDispatchEquipment(equipment.map((elem) => elem.equipment));
    } catch (error) {
      handleServerError(error, enqueueSnackbar);
    }
  };

  const handleEquipmentChange = async (
    action: EquipmentChangeAction,
    payload: unknown
  ) => {
    switch (action) {
      case EquipmentChangeAction.Delete:
        setDispatchEquipment(
          dispatchEquipment.filter((elem) => elem.id !== payload)
        );
        break;
      case EquipmentChangeAction.AddNote:
        console.log("adding note");
        break;
      case EquipmentChangeAction.UploadDocument:
        setDocuments(
          documents.concat(
            (payload as { equipmentId: number; files: File[] }).files.map(
              (f) =>
                new EquipmentDocumentAddVM({
                  equipmentId: (
                    payload as { equipmentId: number; files: File[] }
                  ).equipmentId,
                  file: f,
                  name: f.name,
                  isConfidential: false,
                })
            )
          )
        );
        break;
    }
  };

  const addSelectedEquipment = (selectedEquipment: EquipmentVM[]) => {
    //take the existing equipment
    const existingEquipment = dispatchEquipment;

    //copy into new equipment
    const newEquipment = existingEquipment.slice(0, existingEquipment.length);

    //add each piece of equipment unless it's already added before
    selectedEquipment.forEach((elem) => {
      if (!existingEquipment.some((ele) => ele.id === elem.id))
        newEquipment.push(elem);
    });

    //set the new equipment
    setDispatchEquipment(newEquipment);
  };

  const updateDispatchEquipment = async () => {
    try {
      const dispatchEquipmentClient = new EquipmentClient();

      //prepare the equipment for back-end endpoint
      const mappedEquipment = dispatchEquipment.map((elem) => elem.id);
      await dispatchEquipmentClient.updateDispatchEquipment(
        new DispatchEquipmentUpdateVM({
          dispatchId: props.dispatch.id,
          equipment: mappedEquipment,
        })
      );
    } catch (error) {
      handleServerError(error, enqueueSnackbar);
    }
  };

  useEffect(() => {
    if (props.dispatch.id) getDispatchParts(props.dispatch.id);
  }, [props.dispatch]);

  const addSelectedPart = (selectedParts: PartVM[]) => {
    //take the existing part
    const existingPart = dispatchTableParts;

    //copy into new part
    const newParts = existingPart.slice(0, existingPart.length);

    //add each piece of part unless it's already added before
    selectedParts.forEach((elem) => {
      if (!existingPart.some((ele) => ele.id === elem.id)) newParts.push(elem);
    });

    //set the new part
    setDispatchParts(newParts);
  };

  const updateDispatchParts = async () => {
    try {
      const partClient = new PartClient();

      const mappedParts = dispatchTableParts.map(
        (elem) =>
          new PartPriceVM({
            id: elem.id,
            price: elem.price,
            number: elem.number,
            quantity: elem.quantity,
          })
      );

      await partClient.updateDispatchParts(
        new DispatchPartsUpdateVM({
          dispatchId: props.dispatch.id,
          parts: mappedParts,
        })
      );
    } catch (error) {
      handleServerError(error, enqueueSnackbar);
    }
  };

  const handlePartChange = async (action, payload) => {
    switch (action) {
      case "CHANGE_PRICE":
        setDispatchParts(
          dispatchTableParts.map((elem) =>
            elem.id !== payload.id
              ? elem
              : {
                  ...elem,
                  price: payload.price,
                  number: payload.number,
                  quantity: payload.quantity,
                }
          )
        );
        break;
      case "delete":
        setDispatchParts(
          dispatchTableParts.filter((elem) => elem.id !== payload)
        );
        break;
    }
  };

  //Dispatch documents

  //represents VM for both draft and non-draft documents
  interface IDispatchDocumentTempVM {
    dispatchId: number;
    id: string;
    name: string;
    type: string;
    relativePath: string;
    isConfidential: boolean;
    draft: boolean;
    modified: boolean;
    uid: string;
  }

  class DispatchDocumentTempVM implements IDispatchDocumentTempVM {
    dispatchId: number;
    id: string; //string so we can directly use this field to display draft when needed
    name: string;
    type: string;
    relativePath: string;
    isConfidential: boolean;
    draft: boolean;
    modified: boolean;
    uid: string;
    document?: DispatchDocumentAddVM;

    constructor(
      dispatchId,
      id,
      name,
      type,
      path,
      isConfidential,
      draft,
      modified,
      uid,
      document = null
    ) {
      this.dispatchId = dispatchId;
      this.id = id;
      this.name = name;
      this.type = type;
      this.relativePath = path;
      this.isConfidential = isConfidential;
      this.draft = draft;
      this.modified = modified;
      this.uid = uid;
      this.document = document;
    }
  }

  const [dispatchDocuments, setDispatchDocuments] = useState([]);

  useEffect(() => {
    if (props.dispatch.id) getDispatchDocuments(props.dispatch.id);
  }, [props.dispatch]);

  const getDispatchDocuments = async (dispatchId: number) => {
    const documentClient = new DispatchDocumentsClient();
    const files = await documentClient.getDispatchDocuments(dispatchId);
    const modifiedFiles = files.map((x) => {
      return new DispatchDocumentTempVM(
        x.dispatchId,
        x.documentFile.id,
        x.documentFile.name,
        x.documentFile.type.extension,
        x.documentFile.relativePath,
        x.documentFile.isConfidential,
        false,
        false,
        generateUID()
      );
    });

    setDispatchDocuments(modifiedFiles);
  };

  const uploadDocuments = async (files: File[]) => {
    try {
      const documentClient = new CustomDispatchDocumentsClient();

      for (let file of files) {
        await documentClient.create(
          new DispatchDocumentAddVM({
            dispatchId: props.dispatch.id,
            name: file.name,
            isConfidential: false,
            file: file,
          })
        );
      }
    } catch (error) {
      handleServerError(error, enqueueSnackbar);
    }
  };

  const addDocuments = async (files: File[]) => {
    await uploadDocuments(files);

    getDispatchDocuments(props.dispatch.id);
  };

  const deleteDocument = async (documentId: number) => {
    const documentClient = new DispatchDocumentsClient();

    await documentClient.delete(documentId);
    getDispatchDocuments(props.dispatch.id);
  };

  /**SLA AND FOLLOWUP date */

  const handleDateChange = async (e: Date, fieldName: DateType) => {
    if (!e) {
      setState({ ...state, [fieldName]: e });
    } else {
      e instanceof Date && !isNaN(e.getTime())
        ? setState({ ...state, [fieldName]: e })
        : setState({ ...state, [fieldName]: null });
    }
  };

  /**
   * VALIDATION
   */
  const [
    technicianInputProps,
    triggerTechnicianValidation,
    resetTechnicianInputProps,
  ] = useSelectValidation();

  const validateTechnician = (techId: number) => {
    //due to validation being different from other examples and DRY separate function here
    if (state.statusId !== 1) {
      //if the status is not unassigned there should be an assigned technician
      //validate select
      triggerTechnicianValidation(
        techId,
        `For this dispatch status, select a technician.`
      );
      return validateSelect(techId).error;
    } else {
      resetTechnicianInputProps();
      return false;
    }
  };

  const validateUpdateDispatch = (): boolean => {
    //put other rules for updating dispatch validation here
    const techError = validateTechnician(state.technicianId);

    return !techError;
  };

  const clearValidation = () => {
    resetTechnicianInputProps();
  };

  const openDispatchVisits = async (dispatchId: number) => {
    setVisitsSignaturesModal({ open: true, dispatchId: dispatchId });
  };

  const [visitsSignaturesModal, setVisitsSignaturesModal] = useState({
    open: false,
    dispatchId: 0,
  });

  const [users, setUsers] = useState([]);

  async function getUsers() {
    try {
      const users = await usersClient.usersByRole(UserRole.Technician);
      setUsers(users);
    } catch (error) {
      // todo
    }
  }

  const visitsSignaturesModalBody = (
    <>
      <Visits
        setModalState={setVisitsSignaturesModal}
        dispatchId={visitsSignaturesModal.dispatchId}
        technicians={users}
      />
    </>
  );

  return (
    <Grid className="dispatch-crud-form board" container>
      <Grid
        container
        xs={12}
        item
        alignItems="center"
        className={`customer-location ${state.id ? "disabled" : ""}`}
        spacing={1}
      >
        <Grid item xs={12} lg={3}>
          <Autocomplete
            options={state.customers}
            getOptionLabel={(option) => option.company}
            value={
              state.locationId
                ? state.customers.find(
                    (customer) =>
                      customer.id ===
                      state.locations.find(
                        (location) => location.id === state.locationId
                      ).customerId
                  )
                : null
            }
            disabled
            renderInput={(params) => (
              <TextField {...params} label="Customer" variant="outlined" />
            )}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <Autocomplete
            options={state.locations}
            getOptionLabel={(option) => `${option.name} #${option.number}`}
            value={
              state.locationId
                ? state.locations.find(
                    (location) => location.id === state.locationId
                  )
                : null
            }
            disabled
            renderInput={(params) => (
              <TextField {...params} label="Location" variant="outlined" />
            )}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <FormControl variant="outlined" className="fw-select">
            <InputLabel>Status</InputLabel>
            <Select
              fullWidth
              className="fw-select"
              label="Status"
              value={state.statusId ? state.statusId : 1} //1 is unassigned - default
              onChange={handleStatusChange}
              disabled={state.id ? false : true}
            >
              {props.statuses.map((e: DispatchStatusVM) => (
                <MenuItem key={e.id} value={e.id}>
                  {e.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={3} className="btn-add-dispatch">
          <Button
            endIcon={<CancelOutlinedIcon />}
            color="primary"
            variant="outlined"
            size="large"
            onClick={clearFields}
            disableElevation
            fullWidth
            disabled={state.id ? false : true}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
      <Grid xs={12} spacing={1} item container className="line"></Grid>
      <Grid container className={`${state.id ? "" : "form-disabled"}`}>
        <Grid
          item
          container
          xs={12}
          lg={4}
          alignContent="flex-start"
          className="dispatch-details"
        >
          <Grid
            item
            xs={12}
            className={`dispatch-details-title form-tabs ${
              state.id ? "" : "disabled"
            }`}
          >
            {/* <Typography variant="h3">{`Dispatch ${state.id ? `#${state.id}` : ''} details`}</Typography> */}
            <Tabs
              value={srcTabValue}
              onChange={handleSrcTabChange}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab
                label="Dispatch details"
                className="details"
                disabled={state.id ? false : true}
              />
              <Tab
                label="Service request codes"
                className="src"
                disabled={state.id ? false : true}
              />
            </Tabs>
          </Grid>
          <TabPanel value={srcTabValue} index={0}>
            <Grid
              item
              justify="space-between"
              container
              xs={12}
              spacing={1}
              className="dispatch-details-inputs"
            >
              <Grid item xs={6}>
                <FormControl variant="outlined" className="fw-select">
                  <InputLabel>Priority</InputLabel>
                  <Select
                    className="fw-select"
                    onChange={(e) => handleColorChange(e, "priority")}
                    value={state.priorityId ? state.priorityId : 0}
                    label="Priority"
                    name="priority_color"
                    disabled={state.id ? false : true}
                    onOpen={() => setPrioritySelectOpen(true)}
                    onClose={() => setPrioritySelectOpen(false)}
                  >
                    {props.priorities
                      .filter((p) => {
                        if (state.id && state.priorityId) return p.id !== 0;
                        else return p;
                      })
                      .map((e: PriorityTypeVM) => (
                        <MenuItem key={e.id} value={e.id}>
                          <ListComponent
                            backgroundColor={`#${e.hexCode}`}
                            text={prioritySelectOpen ? e.name : ""}
                          />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="outlined" className="fw-select">
                  <InputLabel>Class</InputLabel>
                  <Select
                    className="fw-select"
                    onChange={(e) => handleColorChange(e, "trade")}
                    label="Class*"
                    value={state.tradeId ? state.tradeId : 0}
                    name="trades_color"
                    disabled={state.id ? false : true}
                    onOpen={() => setTradeSelectOpen(true)}
                    onClose={() => setTradeSelectOpen(false)}
                  >
                    {props.trades
                      .filter((t) => {
                        if (state.id && state.tradeId) return t.id !== 0;
                        else return t;
                      })
                      .map((e: TradeVM) => (
                        <MenuItem key={e.id} value={e.id}>
                          <ListComponent
                            backgroundColor={`#${e.hexCode}`}
                            text={tradeSelectOpen ? e.name : ""}
                          />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="number"
                  name="wo_number"
                  fullWidth
                  label="WO#"
                  variant="outlined"
                  onChange={handleWONumberChange}
                  disabled={state.id ? false : true}
                  value={state.workOrderNumber ? state.workOrderNumber : ""}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  options={state.technicians}
                  getOptionLabel={(option) => option.userName}
                  onChange={handleTechnicianChange}
                  value={
                    state.technicianId
                      ? state.technicians.find(
                          (technician) => technician.id === state.technicianId
                        )
                      : null
                  }
                  onBlur={() => validateTechnician(state.technicianId)}
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        label="Technician"
                        variant="outlined"
                        error={technicianInputProps.error}
                      />
                      <span className="validation_error">
                        {technicianInputProps.errorMessage}
                      </span>
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="outlined" className="fw-select">
                  <InputLabel>Secondary Status</InputLabel>
                  <Select
                    fullWidth
                    className="fw-select"
                    label="Secondary Status"
                    value={
                      state.secondaryStatusId ? state.secondaryStatusId : 0
                    }
                    onChange={handleSecondaryStatusChange}
                    disabled={state.id ? false : true}
                  >
                    {props.secondaryStatuses.map(
                      (e: DispatchSecondaryStatusVM) => (
                        <MenuItem key={e.id} value={e.id}>
                          {e.name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <span className="date-created">
                  Date created:{" "}
                  {state.created
                    ? dateTimeFormat.format(state.created)
                    : dateTimeFormat.format(new Date())}
                </span>
              </Grid>
              <Grid item xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDateTimePicker
                    autoOk
                    clearable
                    format="MM/dd/yyyy hh:mm a"
                    label="SLA Date"
                    value={state.slaDate ? state.slaDate : null}
                    emptyLabel="mm/dd/yyyy"
                    onChange={(e) => handleDateChange(e, DateType.SlaDate)}
                    invalidDateMessage={null}
                    allowKeyboardControl={false}
                    inputVariant="outlined"
                    disabled={state.id ? false : true}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    clearable
                    format="MM/dd/yyyy"
                    label="Follow Up/Due Date"
                    value={state.followUpDate ? state.followUpDate : null}
                    emptyLabel="mm/dd/yyyy"
                    onChange={(e) => handleDateChange(e, DateType.FollowUpDate)}
                    invalidDateMessage={null}
                    allowKeyboardControl={false}
                    inputVariant="outlined"
                    disabled={state.id ? false : true}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    clearable
                    format="MM/dd/yyyy"
                    label="Completed Date"
                    value={state.completedDate ? state.completedDate : null}
                    emptyLabel="mm/dd/yyyy"
                    onChange={(e) =>
                      handleDateChange(e, DateType.CompletedDate)
                    }
                    invalidDateMessage={null}
                    allowKeyboardControl={false}
                    inputVariant="outlined"
                    disabled={state.id ? false : true}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </TabPanel>
          {/* src = service request codes */}
          <TabPanel value={srcTabValue} index={1}>
            <Grid item xs={12}>
              <ServiceRequestCodes
                disabled={state.id ? false : true}
                serviceRequestCodes={props.serviceRequestCodes}
                serviceRequestCode={selectedRequestCode}
                setSelectedRequestCode={setSelectedRequestCode}
                addNote={addDispatchNote}
              />
            </Grid>
          </TabPanel>
        </Grid>

        {/* the state id checks should be included in one variable so it doesn't need to repeatedly check for the same thing -- performance thing */}
        <Grid item container xs={12} lg={8} alignContent="flex-start">
          {/* Dispatch tabs */}
          <Grid item container xs={12} className={`tabs-container`}>
            <Grid
              item
              xs={12}
              className={`form-tabs ${state.id ? "" : "disabled"}`}
            >
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab
                  label="Dispatch notes"
                  disabled={state.id ? false : true}
                />
                <Tab label="Service notes" disabled={state.id ? false : true} />
                <Tab
                  label="Internal notes"
                  disabled={state.id ? false : true}
                />
                <Tab label="Equipment" disabled={state.id ? false : true} />
                <Tab label="Parts" disabled={state.id ? false : true} />
                <Tab label="Labor" disabled={state.id ? false : true} />
                <Tab label="Documents" disabled={state.id ? false : true} />
              </Tabs>
            </Grid>
            <Grid item className="form-tab-content short">
              <TabPanel value={tabValue} index={0}>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  className={classes.button}
                  startIcon={<AddIcon />}
                  onClick={openDispatchNoteModal}
                  disabled={state.id ? false : true}
                >
                  Add Note
                </Button>
                {modalState && (
                  <FormDialog open={modalState} body={dispatchAddNote} />
                )}
                <Box className={classes.boxScroll}>
                  <NoteDispatch
                    deleteNoteCallback={deleteNoteCallback}
                    setNoteForEditing={editDispatchNoteCallback}
                    notes={dispatchNotes}
                  />
                </Box>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  className={classes.button}
                  startIcon={<AddIcon />}
                  onClick={openServiceNoteModal}
                  disabled={state.id ? false : true}
                >
                  Add Note
                </Button>
                <Box className={classes.boxScroll}>
                  <NoteDispatch
                    deleteNoteCallback={deleteServiceNotetCallback}
                    setNoteForEditing={editServiceNoteCallback}
                    notes={serviceNotes}
                  />
                </Box>
                {modalState && (
                  <FormDialog open={modalState} body={serviceAddNote} />
                )}
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                {role !== UserRole.Technician && (
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    className={classes.button}
                    startIcon={<AddIcon />}
                    onClick={openInternalNoteModal}
                    disabled={state.id ? false : true}
                  >
                    Add Note
                  </Button>
                )}
                {modalState && (
                  <FormDialog open={modalState} body={internalAddNote} />
                )}
                <Box className={classes.boxScroll}>
                  {role !== UserRole.Technician && (
                    <NoteDispatch
                      deleteNoteCallback={deleteInternalNoteCallback}
                      setNoteForEditing={editInternalNoteCallback}
                      notes={internalNotes}
                    />
                  )}
                </Box>
              </TabPanel>
              <TabPanel value={tabValue} index={3}>
                <DispatchEquipment
                  equipment={dispatchEquipment}
                  handleEquipmentChange={handleEquipmentChange}
                  addSelectedEquipment={addSelectedEquipment}
                  locationId={props.dispatch.locationId}
                  disabled={state.id ? false : true}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={4}>
                <DispatchParts
                  dispatchTableParts={dispatchTableParts}
                  addSelectedPart={addSelectedPart}
                  handlePartChange={handlePartChange}
                  disabled={state.id ? false : true}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={5}>
                <DispatchLabor
                  dispatchId={state.id}
                  technicians={state.technicians}
                  disabled={state.id ? false : true}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={6}>
                <DispatchDocuments
                  documents={dispatchDocuments}
                  addDocuments={addDocuments}
                  deleteDocument={deleteDocument}
                  disabled={state.id ? false : true}
                />
              </TabPanel>
            </Grid>
          </Grid>
          {/* Dispatch tabs end */}

          <Grid
            item
            xs={6}
            sm={6}
            className={`update-dispatch ${
              state.id ? "update-dispatch_visible" : ""
            }`}
          >
            {props.showGenerateInvoiceButton == true ? (
              <Button
                color="primary"
                variant="contained"
                size="large"
                fullWidth
                disableElevation
                onClick={generateInvoice}
                disabled={state.statusId === 7}
              >
                Generate Invoice
              </Button>
            ) : (
              // <Button
              //   color="primary"
              //   variant="contained"
              //   size="large"
              //   fullWidth
              //   disableElevation
              //   onClick={() => openDispatchVisits(state.id)}
              // >
              //   View visits
              // </Button>
              <></>
            )}
          </Grid>

          <Grid
            item
            xs={6}
            sm={6}
            className={`update-dispatch ${
              state.id ? "update-dispatch_visible" : ""
            }`}
          >
            <Button
              type="submit"
              color="primary"
              variant="contained"
              size="large"
              onClick={editDispatchHandler}
              disableElevation
              fullWidth
            >
              UPDATE DISPATCH
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {visitsSignaturesModal && (
        <FormDialog
          open={visitsSignaturesModal.open}
          body={visitsSignaturesModalBody}
        />
      )}
    </Grid>
  );
};

export default DispatchBoardCRUDForm;
