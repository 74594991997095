import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import AddEditTable2 from "../../components/common/table/AddEditTable2";
import {
  InvoiceClient,
  InvoiceTripChargeAddVM,
  InvoiceTripChargeUpdateVM,
} from "../../brines-refrigerator-api";
import AddEditTable from "../../components/common/table/AddEditTable";

interface InvoiceTripChargesProps {
  invoiceId: number;
  updateInvoiceTotal: Function;
}

const InvoiceTripCharges = (props: InvoiceTripChargesProps) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const invoiceClient = new InvoiceClient();
  const tripChargesColumns = [
    {
      title: "Label",
      field: "label",
    },
    {
      title: "Quantity",
      field: "quantity",
      type: "numeric",
    },
    {
      title: "Rate",
      field: "price",
      type: "numeric",
      validate: (rowData) =>
        rowData.price === ""
          ? { isValid: false, helperText: "Price can't be empty" }
          : true,
    },
    {
      title: "Subtotal",
      field: "subtotal",
      editable: false,
    },
  ];

  const [tripChargesList, setTripChargesList] = useState([]);
  const [tripChargesTableLoading, setTripChargesTableLoading] = useState(false);

  const getInvoiceTripCharges = async () => {
    try {
      setTripChargesTableLoading(true);
      const tripCharges = await invoiceClient.getInvoiceTripCharges(
        props.invoiceId
      );
      setTripChargesList(tripCharges);
      setTripChargesTableLoading(false);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setTripChargesTableLoading(false);
    }
  };

  const addInvoiceTripCharge = async (data) => {
    if (validateAllFields(data)) {
      return false;
    }
    try {
      await invoiceClient.addInvoiceTripCharges(
        new InvoiceTripChargeAddVM({
          invoiceId: props.invoiceId,
          price: data.price,
          quantity: data.quantity,
          label: data.label,
        })
      );
      getInvoiceTripCharges();
      props.updateInvoiceTotal();
      enqueueSnackbar("Added invoice trip charges successfully.", {
        variant: "success",
      });
      return true;
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      return false;
    }
  };

  const updateInvoiceTripCharge = async (data) => {
    if (validateAllFields(data)) {
      return false;
    }
    try {
      await invoiceClient.updateInvoiceTripCharge(
        new InvoiceTripChargeUpdateVM({
          id: data.id,
          invoiceId: props.invoiceId,
          price: data.price,
          quantity: data.quantity,
          label: data.label,
        })
      );
      getInvoiceTripCharges();
      props.updateInvoiceTotal();
      enqueueSnackbar("Updated invoice trip charges successfully.", {
        variant: "success",
      });
      return true;
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      return false;
    }
  };

  const validateAllFields = (data: unknown): boolean => {
    const resultPrice = (data as { price: number }).price
      ? { error: false, errorMessage: null }
      : { error: true, errorMessage: "Price can't be empty." };

    return resultPrice.error;
  };

  const deleteInvoiceTripCharge = async (id: number) => {
    try {
      await invoiceClient.deleteInvoiceTripCharge(id);
      getInvoiceTripCharges();
      props.updateInvoiceTotal();
      enqueueSnackbar("Deleted invoice trip charges successfully.", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  useEffect(() => {
    getInvoiceTripCharges();
  }, []);

  return (
    <div>
      <AddEditTable
        width="100%"
        title="Trip Charges"
        columns={tripChargesColumns}
        add={addInvoiceTripCharge}
        update={updateInvoiceTripCharge}
        delete={deleteInvoiceTripCharge}
        data={tripChargesList as unknown as []}
        search={false}
        isLoading={tripChargesTableLoading}
        // tooltipText="Enter Trip Charge"
      />
    </div>
  );
};

export default InvoiceTripCharges;
