import React, { useState } from "react";
import { Container, Grid, Paper, TextField, Button } from "@material-ui/core";
import "./Login.scss";
import { LogInVM } from "../../brines-refrigerator-api";
import * as client from "../../helpers/offline/offline-api";
import UserRole from "../../helpers/constants/userRole";
import { useDispatch } from "react-redux";
import { setOnlineState } from "../../global-state/actions/onlineStateActions";
import { useSnackbar } from "notistack";

interface LoginForm {
  invalidEmail: boolean;
  invalidPassword: boolean;
}

interface LoginProps {
  setUserAuthenticated: Function;
}

const Login = (props: LoginProps) => {
  const [hasError, setErrorState] = useState<LoginForm>({
    invalidEmail: false,
    invalidPassword: false,
  });
  const [getDataForOffline] = client.useCachingForOffline();
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const signIn = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.currentTarget;
    const email: string = form.email.value;
    const password: string = form.password.value;

    const loginVm = new LogInVM({ email, password });

    try {
      const loginResult = await client.logIn(loginVm);
      // Get user data
      let user = loginResult;
      // Get user roles
      let role = await client.getUserRole(user.id);
      const isOffline = role.id === null;
      // Set temporary user data
      sessionStorage.setItem("userData", JSON.stringify({ user, role }));
      props.setUserAuthenticated(true);

      if (isOffline) {
        dispatch(setOnlineState(false));
        enqueueSnackbar(
          "You are offline and may not be viewing the latest data.",
          { variant: "info" }
        );
      }

      const requests = await client.getCachedRequests();
      if (role.name === UserRole.Technician && requests.length === 0) {
        getDataForOffline();
      }
    } catch (error) {
      setErrorState({
        invalidEmail: true,
        invalidPassword: true,
      });
      props.setUserAuthenticated(false);
    }
  };
  const clearValidations = () => {
    setErrorState({
      invalidEmail: false,
      invalidPassword: false,
    });
  };

  return (
    <Container className="login__page">
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <Grid item lg={4} xs={12}>
          <Paper>
            <Grid container justify="center" alignItems="center">
              <form className="MuiGrid-grid-lg-10" onSubmit={signIn}>
                <Grid className="login__page__input__container">
                  <TextField
                    required
                    // onFocus={clearValidations}
                    /* error={hasError.invalidEmail} */
                    // type="email"
                    autoComplete="username"
                    id="outlined-email"
                    name="email"
                    label="E-mail"
                    /* helperText={hasError.invalidEmail && 'Invailid E-mail'} */
                    variant="outlined"
                  />
                </Grid>
                <Grid className="login__page__input__container">
                  <TextField
                    required
                    onFocus={clearValidations}
                    error={hasError.invalidPassword}
                    type="password"
                    autoComplete="current-password"
                    id="outlined-password"
                    name="password"
                    label="Password"
                    helperText={
                      hasError.invalidPassword && "Invalid credentials"
                    }
                    variant="outlined"
                  />
                </Grid>
                <Grid>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    size="large"
                    fullWidth
                  >
                    LOG IN
                  </Button>
                </Grid>
              </form>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

Login.displayName = "Login";

export default Login;
