import React from 'react';
import { Grid } from '@material-ui/core';
import Priority from './Priority';
import Trades from './Trades';

const PriorityAndTrades: React.FC = () => {

    return (
        <Grid item container className='priority_and_trades' spacing={3}>
            <Priority/>
            <Trades/>
        </Grid>
    )
};

PriorityAndTrades.displayName = 'PriorityAndTrades';

export default PriorityAndTrades;