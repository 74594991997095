import React, { useState, useEffect, forwardRef } from "react";
import {
  ManufacturerClient,
  ManufacturerVM,
  PartAddVM,
  PartClient,
  PartVM,
} from "../../../brines-refrigerator-api";
import { Grid, Paper, Button, Box, makeStyles } from "@material-ui/core";
import BasicTable from "../../../components/common/table/BasicTable";
import MaterialTable, { Column, MTableToolbar } from "material-table";
import CancelButton from "../../../components/common/dialog/CancelButton";
import FormDialog from "../../../components/common/dialog/FormDialog";
import Delete from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import "./DispatchParts.scss";
import ConfirmDialog from "../../../components/common/dialog/ConfirmationDialog";
import { useSnackbar } from "notistack";
import handleServerError from "../../../helpers/handleServerError";
import PartDetails from "../../../components/admin/PartsList/PartDetails";
import Edit from "@material-ui/icons/Edit";
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
import { Icons } from "material-table";
import { SvgIconProps } from "@material-ui/core";
import { AddBox } from "@material-ui/icons";

const useStyles = makeStyles({
  button: {
    marginBottom: "1em",
  },
  boxScroll: {
    overflowY: "auto",
    overflowX: "hidden",
  },
  partTable: {
    width: "60rem",
    height: "30rem",
  },
  addPartTableButton: {
    textAlign: "end",
  },
  highlight: {
    backgroundColor: "#3f51b5cc",
    "& .MuiTypography-h6": {
      color: "#ffffff",
    },
  },
});

const DispatchParts = (props) => {
  const { enqueueSnackbar } = useSnackbar();

  const manufacturerClient = new ManufacturerClient();
  const partsClient = new PartClient();

  //DELETE CONFIRMATION DIALOGUE
  const [confirm, setConfirmOpen] = useState(false);

  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogMessage, setDialogMessage] = useState("");
  const [idForDelete, setIdForDelete] = useState(0);
  const [partsTableIsLoading, setTableLoading] = useState(true);

  function confirmOpen() {
    setConfirmOpen(true);
  }

  function openDialogue(id: number, name: string) {
    setDialogTitle("Delete?");
    setDialogMessage(
      `Are you sure you want to remove ${name} from this dispatch?`
    );

    setIdForDelete(id);
    setConfirmOpen(true);
  }

  const classes = useStyles();

  // PARTS
  const [partsModal, setPartsModal] = useState(false);

  const openPartsModal = () => {
    setPartsModal(true);
  };

  const closePartsModal = () => {
    setPartsModal(false);
  };

  //GET PARTS
  const [partsList, setPartsList] = useState([]);
  const [selectedParts, setSelectedParts] = useState([]);
  const [dispatchTableParts, setDispatchTableParts] = useState(
    props.dispatchTableParts
  );

  const [enterNewPartsModal, setEnterNewPartsModal] = useState(false);

  const openNewPartsModal = () => {
    setEnterNewPartsModal(true);
  };

  const partsColumns = [
    { title: "#", field: "id", hidden: true },
    { title: "Name", field: "name" },
    { title: "Description", field: "description" },
    { title: "Price", field: "price" },
  ];

  const getParts = async () => {
    try {
      const parts = await partsClient.get();
      setPartsList(
        parts.map((e: PartVM) => ({
          id: Number(e.id),
          name: e.name,
          description: e.description,
          price: e.price,
        }))
      );
      setTableLoading(false);
    } catch (error) {
      handleServerError(error, enqueueSnackbar);
    }
  };

  useEffect(() => {
    getParts();
    getManufacturers();
  }, []);

  useEffect(() => {
    // force re-render
    setDispatchTableParts(props.dispatchTableParts);
  }, [props.dispatchTableParts]);

  const partsToList = async (rows) => {
    await setSelectedParts(rows);
  };

  const addSelectedPartsToDispatch = async () => {
    props.addSelectedPart(selectedParts);
    closePartsModal();
    setPartsList(
      partsList.map((e) => ({ ...e, tableData: { checked: false } }))
    );
    setTableLoading(false);
  };

  const dispatchPartsColumns = [
    { title: "#", field: "id", hidden: true, editable: false },
    { title: "Name", field: "name", editable: false },
    { title: "Number", field: "number", type: "numeric" },
    { title: "Quantity", field: "quantity", type: "numeric" },
    { title: "Price", field: "price", type: "numeric" },
  ];

  const dispatchPartsTableActions = [
    (rowData) => ({
      icon: () => <Delete color="primary" />,
      onClick: (event, rowData: PartVM) => {
        openDialogue(rowData.id, rowData.name);
      },
      tooltip: "Delete Part",
    }),
  ];

  const partsTable = (
    <div className="parts-modal">
      <Grid container className={classes.partTable}>
        <Grid item xs={12}>
          <BasicTable
            width={"100%"}
            title={"Add Parts"}
            data={partsList as []}
            columns={partsColumns}
            selection={true}
            components={{
              Container: (props) => <Paper {...props} elevation={0} />,
              Toolbar: (props) => (
                <Grid
                  container
                  item
                  xs={12}
                  justify="flex-start"
                  alignItems="center"
                >
                  <Grid item xs={11}>
                    <MTableToolbar
                      classes={{ highlight: classes.highlight }}
                      {...props}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <CancelButton onClick={closePartsModal} />
                  </Grid>
                </Grid>
              ),
            }}
            onSelectionChange={partsToList}
            paging={false}
            isLoading={partsTableIsLoading}
          />
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            onClick={openNewPartsModal}
          >
            Enter new material
          </Button>
        </Grid>
        <Grid className={classes.addPartTableButton} item xs={6}>
          <Button
            variant="contained"
            color="primary"
            disabled={!(selectedParts.length > 0)}
            onClick={addSelectedPartsToDispatch}
          >
            Add Selected To Dispatch
          </Button>
        </Grid>
      </Grid>
    </div>
  );

  const [manufacturers, setManufacturers] = useState([]);

  async function getManufacturers() {
    try {
      const manufacturers = await manufacturerClient.get();
      setManufacturers(manufacturers);
    } catch (error) {
      enqueueSnackbar("Error getting manufacturers.", { variant: "error" });
    }
  }

  const [newPart, setNewPart] = useState(
    new PartVM({
      id: 0,
      number: 0,
      name: "",
      source: "",
      manufacturer: new ManufacturerVM({ id: 0, name: "" }),
      description: "",
      price: 0,
    })
  );

  const clearPartFields = () => {
    setEnterNewPartsModal(false);
    closePartsModal();
    getParts();
    setNewPart(
      new PartVM({
        id: 0,
        number: 0,
        name: "",
        source: "",
        manufacturer: new ManufacturerVM({ id: 0, name: "" }),
        description: "",
        price: 0,
      })
    );
  };

  const addPart = async (
    e: React.FormEvent<HTMLFormElement>,
    id: number,
    manufacturer: ManufacturerVM
  ) => {
    e.preventDefault();
    const form = e.currentTarget;
    const part = new PartAddVM({
      number:
        form.partNumber.value === 0 ? null : Number(form.partNumber.value),
      name: form.partName.value,
      source: form.source.value,
      manufacturerId: manufacturer.id === 0 ? null : manufacturer.id,
      description: form.description.value,
      price: form.price.value === 0 ? null : Number(form.price.value),
    });
    try {
      const createdPart = await partsClient.post(part);

      enqueueSnackbar("Part added successfully.", { variant: "success" });
      setEnterNewPartsModal(false);
      clearPartFields();

      props.addSelectedPart([createdPart]);
    } catch (error) {
      enqueueSnackbar("Error adding part.", { variant: "error" });
    }
  };

  const enterNewPartsBody = (
    <PartDetails
      clearFields={clearPartFields}
      part={newPart}
      formAction={addPart}
      manufacturers={manufacturers}
      formTitle="Add Part"
      formButtonTitle="Add Part"
    />
  );

  const tableIcons: Icons = {
    Add: forwardRef((props, ref) => (
      <AddBox {...props} ref={ref} htmlColor="#3f51b5" />
    )),
    Delete: forwardRef((props, ref) => (
      <Delete {...props} ref={ref} htmlColor="#3f51b5" />
    )),
    Edit: forwardRef((props: SvgIconProps, ref) => (
      <Edit {...props} ref={ref} htmlColor="#3f51b5" />
    )),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  };

  return (
    <div className="dispatch-parts_container ">
      <Button
        variant="outlined"
        color="primary"
        size="small"
        className={classes.button}
        startIcon={<AddIcon />}
        onClick={openPartsModal}
        disabled={props.disabled}
      >
        Add Parts/Misc/Freight
      </Button>
      {partsModal && <FormDialog open={partsModal} body={partsTable} />}
      {enterNewPartsModal && (
        <FormDialog open={enterNewPartsModal} body={enterNewPartsBody} />
      )}
      <Box>
        <MaterialTable
          icons={tableIcons}
          columns={dispatchPartsColumns as Column<any>[]}
          title=" "
          data={dispatchTableParts as []}
          actions={dispatchPartsTableActions}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
          editable={{
            onRowUpdate: (newData) =>
              new Promise((resolve) => {
                props.handlePartChange("CHANGE_PRICE", newData);
                console.table(newData);
                resolve(null);
              }),
          }}
          options={{
            actionsColumnIndex: -1,
            search: false,
            paging: false,
            pageSize: 5,
            rowStyle: {
              fontSize: 16,
            },
          }}
          isLoading={props.isLoading}
        />
      </Box>
      {confirm && (
        <ConfirmDialog
          title={dialogTitle}
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={() => {
            props.handlePartChange("delete", idForDelete);
          }}
        >
          {dialogMessage}
        </ConfirmDialog>
      )}
    </div>
  );
};
export default DispatchParts;
