import React, { useEffect, useReducer, useState, ElementType } from 'react';
import { Grid, Button, InputAdornment, FormControl, InputLabel, Select, MenuItem, Typography, TextField, InputBaseComponentProps } from '@material-ui/core';
import { CustomerVM, PhoneTypeVM, StateVM, PhoneVM, HeadquarterVM } from '../../brines-refrigerator-api';
import { decimalNumberFormat, phoneNumberFormat } from '../../helpers/inputFormatters';
import { useTextValidation, useSelectValidation } from '../../helpers/validations';
import { Autocomplete } from '@material-ui/lab';
import UserRole from '../../helpers/constants/userRole';

interface FormProps {
    title: string,
    button: JSX.Element,
    customer: CustomerVM,
    phoneTypes: PhoneTypeVM[],
    states: StateVM[],
    formAction: Function
}

interface StateType {
    id?: number,
    company?: string,
    number?: string,
    active?: boolean,
    laborRate?: number,
    tripRate?: number,
    otRate?: number,
    holidayRate?: number,
    customerMarkup?: number,
    phones?: PhoneVM[],
    headquarterLocation: HeadquarterVM,
    date: Date,
}


export default function CustomerCRUDForm(props: FormProps) {
    const dateTimeFormat = new Intl.DateTimeFormat('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
    const [statee, setStatee] = useReducer(
        (state: StateType, newState: StateType) => ({ ...state, ...newState }),
        {
            id: props.customer.id,
            company: props.customer.company,
            active: props.customer.active,
            laborRate: props.customer.laborRate,
            tripRate: props.customer.tripRate,
            otRate: props.customer.otRate,
            holidayRate: props.customer.holidayRate,
            customerMarkup: props.customer.customerMarkup,
            phones: props.customer.phones,
            headquarterLocation: props.customer.headquarterLocation,
            date: props.customer.created
        },
    );

    useEffect(() => {
        setStatee({
            id: props.customer.id,
            company: props.customer.company,
            active: props.customer.active,
            laborRate: props.customer.laborRate,
            tripRate: props.customer.tripRate,
            otRate: props.customer.otRate,
            holidayRate: props.customer.holidayRate,
            customerMarkup: props.customer.customerMarkup,
            phones: props.customer.phones,
            headquarterLocation: props.customer.headquarterLocation,
            date: props.customer.created
        })
        resetCompanyInputProps();
        resetAddressLine1InputProps();
        resetCityInputProps();
        resetZipInputProps();
        resetContactPhoneInputProps();
        resetStateInputProps();
        resetPhoneTypeInputProps();
    }, [props])

    const [companyInputProps, triggerCompanyValidation, resetCompanyInputProps] = useTextValidation();
    const [addressLine1InputProps, triggerAddressLine1Validation, resetAddressLine1InputProps] = useTextValidation();
    const [cityInputProps, triggerCityValidation, resetCityInputProps] = useTextValidation();
    const [zipInputProps, triggerZipValidation, resetZipInputProps] = useTextValidation();
    const [contactPhoneInputProps, triggerContactPhoneValidation, resetContactPhoneInputProps] = useTextValidation();
    const [phoneTypeInputProps, triggerPhoneTypeValidation, resetPhoneTypeInputProps] = useSelectValidation();
    const [stateInputProps, triggerStateValidation, resetStateInputProps] = useSelectValidation();

    const [phone1, setPhone1] = useState(props.customer.phones.length >= 1 ? props.customer.phones[0].number : '');
    const handlePhone1Change = (event: React.ChangeEvent<{ value: unknown }>) => {
        setPhone1(event.target.value as string);
        if (contactPhoneInputProps.error) {
            triggerContactPhoneValidation(event.target.value as string);
        }
    };
    useEffect(() => {
        setPhone1(props.customer.phones.length >= 1 ? props.customer.phones[0].number : '')
    }, [props.customer])

    const [phoneType1, setPhoneType1] = useState(props.customer.phones.length >= 1 ? props.customer.phones[0].phoneType.id : 0);
    const handlePhoneType1Change = (event: React.ChangeEvent<{ value: unknown }>) => {
        setPhoneType1(event.target.value as number);
        resetPhoneTypeInputProps();
    };
    useEffect(() => {
        setPhoneType1(props.customer.phones.length >= 1 ? props.customer.phones[0].phoneType.id : 0)
    }, [props])

    const [phone2, setPhone2] = useState(props.customer.phones.length >= 2 ? props.customer.phones[1].number : '');
    const handlePhone2Change = (event: React.ChangeEvent<{ value: unknown }>) => {
        setPhone2(event.target.value as string);
    };
    useEffect(() => {
        setPhone2(props.customer.phones.length >= 2 ? props.customer.phones[1].number : '')
    }, [props])

    const [phoneType2, setPhoneType2] = useState(props.customer.phones.length >= 2 ? props.customer.phones[1].phoneType.id : 0);
    const handlePhoneType2Change = (event: React.ChangeEvent<{ value: unknown }>) => {
        setPhoneType2(event.target.value as number);
    };
    useEffect(() => {
        setPhoneType2(props.customer.phones.length >= 2 ? props.customer.phones[1].phoneType.id : 0)
    }, [props])

    const [phone3, setPhone3] = useState(props.customer.phones.length >= 3 ? props.customer.phones[2].number : '');
    const handlePhone3Change = (event: React.ChangeEvent<{ value: unknown }>) => {
        setPhone3(event.target.value as string);
    };
    useEffect(() => {
        setPhone3(props.customer.phones.length >= 3 ? props.customer.phones[2].number : '')
    }, [props])

    const [phoneType3, setPhoneType3] = useState(props.customer.phones.length >= 3 ? props.customer.phones[2].phoneType.id : 0);
    const handlePhoneType3Change = (event: React.ChangeEvent<{ value: unknown }>) => {
        setPhoneType3(event.target.value as number);
    };
    useEffect(() => {
        setPhoneType3(props.customer.phones.length >= 3 ? props.customer.phones[2].phoneType.id : 0)
    }, [props])

    const handleDecimalOnChange = (value: string, fieldName: string) => {
        setStatee({ ...statee, [fieldName]: Number(value).toFixed(2) })
    }

    const getPhonesFromForm = () => {
        const phones: PhoneVM[] = [];
        const types: number[] = [phoneType1, phoneType2, phoneType3];
        const numbers: string[] = [phone1, phone2, phone3];
        for (let index = 0; index < 3; index++) {
            phones.push(new PhoneVM({
                id: statee.phones[index] === undefined ? 0 : statee.phones[index].id,
                number: numbers[index],
                phoneTypeId: types[index]
            }))
        }
        return phones;
    }

    const validateAllFields = (): boolean => {
        const resultCompany = triggerCompanyValidation(statee.company)
        const resultAddress = triggerAddressLine1Validation(statee.headquarterLocation.addressLine1)
        const resultCity = triggerCityValidation(statee.headquarterLocation.city)
        const resultZip = triggerZipValidation(statee.headquarterLocation.zip, true)
        const resultState = triggerStateValidation(statee.headquarterLocation.stateId)
        const resultPhone = triggerContactPhoneValidation(phone1)
        const resultPhoneType = triggerPhoneTypeValidation(phoneType1)
        return resultCompany || resultAddress || resultCity || resultZip || resultState || resultPhone || resultPhoneType;
    }

    const executeFormAction = (e: React.FormEvent<HTMLFormElement>) => {
        if (validateAllFields()) {
            e.preventDefault();
            return;
        }
        props.formAction(e, Number(statee.id), getPhonesFromForm(), statee.headquarterLocation.id, statee.headquarterLocation.stateId)
    }

    const changeState = (event: React.ChangeEvent<{ value: unknown }>, value: StateVM) => {
        setStatee({
            ...statee,
            headquarterLocation: ({ ...statee.headquarterLocation, stateId: Number(value.id), state: value }) as unknown as HeadquarterVM
        })
        resetStateInputProps()
    }

    // Get user data from session storage
    const userData: any = JSON.parse(sessionStorage.getItem('userData') || '{}');
    //get user role
    const role = userData.role.name;

    return (
        <>
            <Grid className='customer_crud_form_title'>
                <Grid container spacing={1}>
                    <Grid item container xs={6} alignContent='center'>
                        <Typography><b>{props.title}</b></Typography>
                    </Grid>
                    <Grid item container xl={6} xs={6} justify='flex-end'>
                        {props.button}
                    </Grid>
                </Grid>
                <hr />
            </Grid>
            <form noValidate autoComplete="off"
                onSubmit={e => executeFormAction(e)}>
                <Grid container className='customer_form'>
                    <Grid item container xl={6} sm={6}>
                        <div className='customer_details'>
                            <Typography>Customer details</Typography>
                            <Grid item container>
                                <TextField
                                    label="Date Added"
                                    value={dateTimeFormat.format(statee.date)}
                                    InputProps={{
                                        readOnly: true,
                                        disableUnderline: true,
                                    }}
                                />
                                <TextField
                                    name='company'
                                    label="Company Name"
                                    value={statee.company}
                                    onChange={e => {
                                        setStatee({ ...statee, company: e.target.value })
                                        if (companyInputProps.error) {
                                            triggerCompanyValidation(e.target.value)
                                        }
                                    }}
                                    error={companyInputProps.error}
                                    onBlur={() => triggerCompanyValidation(statee.company)}
                                    fullWidth
                                />
                                <span className="validation_error">
                                    {companyInputProps.errorMessage}
                                </span>
                                <TextField
                                    name='address1'
                                    label="Address 1"
                                    value={statee.headquarterLocation ? (statee.headquarterLocation.addressLine1 ? statee.headquarterLocation.addressLine1 : "") : ""}
                                    onChange={e => {
                                        setStatee({
                                            ...statee,
                                            headquarterLocation: ({ ...statee.headquarterLocation, addressLine1: e.target.value }) as HeadquarterVM
                                        })
                                        if (addressLine1InputProps.error) {
                                            triggerAddressLine1Validation(e.target.value)
                                        }
                                    }}
                                    error={addressLine1InputProps.error}
                                    onBlur={() => triggerAddressLine1Validation(statee.headquarterLocation.addressLine1)}
                                    fullWidth
                                />
                                <span className="validation_error">
                                    {addressLine1InputProps.errorMessage}
                                </span>
                                <TextField
                                    name='address2'
                                    label="Address 2"
                                    value={statee.headquarterLocation ? (statee.headquarterLocation.addressLine2 ? statee.headquarterLocation.addressLine2 : "") : ""}
                                    onChange={e => setStatee({
                                        ...statee,
                                        headquarterLocation: ({ ...statee.headquarterLocation, addressLine2: e.target.value }) as HeadquarterVM
                                    })}
                                    fullWidth
                                />
                                <TextField
                                    name='city'
                                    label="City"
                                    value={statee.headquarterLocation ? (statee.headquarterLocation.city ? statee.headquarterLocation.city : "") : ""}
                                    onChange={e => {
                                        setStatee({
                                            ...statee,
                                            headquarterLocation: ({ ...statee.headquarterLocation, city: e.target.value }) as HeadquarterVM
                                        })
                                        if (cityInputProps.error) {
                                            triggerCityValidation(e.target.value)
                                        }
                                    }}
                                    error={cityInputProps.error}
                                    onBlur={() => triggerCityValidation(statee.headquarterLocation.city)}
                                    fullWidth
                                />
                                <span className="validation_error">
                                    {cityInputProps.errorMessage}
                                </span>
                                <Grid item container>
                                    <Grid container spacing={1}>
                                        <Grid item xl={8} xs={6}>
                                            <Autocomplete
                                                options={props.states}
                                                getOptionLabel={(option) => option.name}
                                                getOptionSelected={(option) => !!Number(option.id)}
                                                disableClearable
                                                fullWidth
                                                value={statee.headquarterLocation ? (statee.headquarterLocation.state ? statee.headquarterLocation.state : null) : null}
                                                onChange={changeState}
                                                renderInput={(params) =>
                                                    <TextField {...params}
                                                        required
                                                        label='State'
                                                        name='state'
                                                        error={stateInputProps.error}
                                                        onBlur={() => triggerStateValidation(statee.headquarterLocation.state.name)}
                                                    />}
                                            />
                                            <span className="validation_error">
                                                {stateInputProps.errorMessage}
                                            </span>
                                        </Grid>
                                        <Grid item xl={4} xs={6}>
                                            <TextField
                                                name='zip'
                                                label="ZIP"
                                                value={statee.headquarterLocation ? (statee.headquarterLocation.zip ? statee.headquarterLocation.zip : "") : ""}
                                                onChange={e => {
                                                    setStatee({
                                                        ...statee,
                                                        headquarterLocation: ({ ...statee.headquarterLocation, zip: e.target.value }) as HeadquarterVM
                                                    })
                                                    if (zipInputProps.error) {
                                                        triggerZipValidation(e.target.value, true)
                                                    }
                                                }}
                                                error={zipInputProps.error}
                                                onBlur={() => triggerZipValidation(statee.headquarterLocation.zip, true)}
                                                fullWidth
                                            />
                                            <span className="validation_error">
                                                {zipInputProps.errorMessage}
                                            </span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item container xl={6} sm={6}>
                        <Grid item className='customer_contacts' xl={12} sm={12}>
                            <Typography>Contacts</Typography>
                            <TextField fullWidth className='hidden_component_xl hidden_component_lg hidden_component_sm remove_component_xs' />
                            <Grid item container>
                                <Grid item container xl={5} sm={5} xs={6} className='customer_contacts_phone'>
                                    <FormControl required fullWidth>
                                        <InputLabel>Phone Type</InputLabel>
                                        <Select
                                            value={phoneType1}
                                            onChange={handlePhoneType1Change}
                                        >
                                            {props.phoneTypes.map((e: PhoneTypeVM) => <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                    <span className="validation_error">
                                        {phoneTypeInputProps.errorMessage}
                                    </span>
                                </Grid>
                                <Grid item container xl={7} sm={7} className='customer_contacts_phone'>
                                    <TextField
                                        name='phone1'
                                        label="Phone"
                                        placeholder="Phone"
                                        value={phone1}
                                        onChange={handlePhone1Change}
                                        error={contactPhoneInputProps.error}
                                        onBlur={() => triggerContactPhoneValidation(phone1)}
                                        InputProps={{
                                            inputComponent: phoneNumberFormat as unknown as ElementType<InputBaseComponentProps>
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                    />
                                    <span className="validation_error">
                                        {contactPhoneInputProps.errorMessage}
                                    </span>
                                </Grid>
                            </Grid>
                            <Grid item container>
                                <Grid item container xl={5} sm={5} xs={6} className='customer_contacts_phone'>
                                    <FormControl fullWidth>
                                        <InputLabel>Phone Type</InputLabel>
                                        <Select
                                            value={phoneType2}
                                            onChange={handlePhoneType2Change}
                                        >
                                            {props.phoneTypes.map((e: PhoneTypeVM) => <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item container xl={7} sm={7} className='customer_contacts_phone'>
                                    <TextField
                                        name='phone2'
                                        label="Phone"
                                        placeholder="Phone"
                                        value={phone2}
                                        onChange={handlePhone2Change}
                                        InputProps={{
                                            inputComponent: phoneNumberFormat as unknown as ElementType<InputBaseComponentProps>
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container>
                                <Grid item container xl={5} sm={5} xs={6} className='customer_contacts_phone'>
                                    <FormControl fullWidth>
                                        <InputLabel>Phone Type</InputLabel>
                                        <Select
                                            value={phoneType3}
                                            onChange={handlePhoneType3Change}
                                        >
                                            {props.phoneTypes.map((e: PhoneTypeVM) => <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item container xl={7} sm={7} className='customer_contacts_phone'>
                                    <TextField
                                        name='phone3'
                                        label="Phone"
                                        placeholder="Phone"
                                        value={phone3}
                                        onChange={handlePhone3Change}
                                        InputProps={{
                                            inputComponent: phoneNumberFormat as unknown as ElementType<InputBaseComponentProps>
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container xl={12}>
                        <Grid item container xl={12} justify='space-between' className='customer_rates' >
                            <Grid item container xl={12}>
                                <Typography>Rates</Typography>
                            </Grid>
                            <Grid item container xl={2} sm={2} xs={6}>
                                <TextField
                                    className='customer_rates_input'
                                    name='laborRate'
                                    label="Labor"
                                    defaultValue={999.99}
                                    variant="outlined"
                                    value={statee.laborRate}
                                    onChange={e => handleDecimalOnChange(e.target.value, 'laborRate')}
                                    InputProps={{
                                        inputComponent: decimalNumberFormat as unknown as ElementType<InputBaseComponentProps>,
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item container xl={2} sm={2} xs={6}>
                                <TextField
                                    className='customer_rates_input'
                                    name='otRate'
                                    label="OT"
                                    defaultValue={999.99}
                                    variant="outlined"
                                    value={statee.otRate}
                                    onChange={e => handleDecimalOnChange(e.target.value, 'otRate')}
                                    InputProps={{
                                        inputComponent: decimalNumberFormat as unknown as ElementType<InputBaseComponentProps>,
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item container xl={2} sm={2} xs={6}>
                                <TextField
                                    className='customer_rates_input'
                                    name='tripRate'
                                    label="Trip"
                                    defaultValue={999.99}
                                    variant="outlined"
                                    value={statee.tripRate}
                                    onChange={e => handleDecimalOnChange(e.target.value, 'tripRate')}
                                    InputProps={{
                                        inputComponent: decimalNumberFormat as unknown as ElementType<InputBaseComponentProps>,
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item container xl={2} sm={2} xs={6}>
                                <TextField
                                    className='customer_rates_input'
                                    name='holidayRate'
                                    label="Holiday"
                                    defaultValue={999.99}
                                    variant="outlined"
                                    value={statee.holidayRate}
                                    onChange={e => handleDecimalOnChange(e.target.value, 'holidayRate')}
                                    InputProps={{
                                        inputComponent: decimalNumberFormat as unknown as ElementType<InputBaseComponentProps>,
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item container xl={2} sm={2} xs={6}>
                                <TextField
                                    className='customer_rates_input'
                                    name='customerMarkup'
                                    label="Markup"
                                    defaultValue={999.99}
                                    variant="outlined"
                                    value={statee.customerMarkup}
                                    onChange={e => handleDecimalOnChange(e.target.value, 'customerMarkup')}
                                    InputProps={{
                                        inputComponent: decimalNumberFormat as unknown as ElementType<InputBaseComponentProps>,
                                        startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                    }}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <hr />
                <Grid container spacing={1}>
                    <Grid item container justify='flex-end'>
                        <Grid item lg={4} sm={6} xs={8}>
                            <Button type='submit' color='primary' variant='contained' size='large' fullWidth disabled={role === UserRole.Dispatcher || role === UserRole.Technician}>
                                {props.title === 'ADD CUSTOMER' ? 'Add Customer' : 'Update Customer'}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </>
    );
}
