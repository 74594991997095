import React, { useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {
  Typography,
  GridList,
  GridListTile,
  IconButton,
} from "@material-ui/core";
import HistoryIcon from "@material-ui/icons/History";
import { UserBaseVM } from "../../../brines-refrigerator-api";
import FormDialog from "../dialog/FormDialog";
import CancelButton from "../dialog/CancelButton";

interface Note {
  id: number;
  created: Date;
  createdBy: UserBaseVM;
  lastModifiedBy: UserBaseVM;
  lastModified: Date;
  text: string;
  draft: boolean;
  uid: string;
  history?: Note[];
}

interface DispatchNotesPreviewProps {
  notes: Note[];
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    marginBottom: {
      marginBottom: "0.5em",
    },
    rootList: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
    },
    rootTile: {
      width: "100%",
    },
    gridList: {
      width: 560,
      backgroundColor: "rgb(245, 245, 245)",
    },
    icon: {
      color: "rgba(255, 255, 255, 0.54)",
    },
    section1: {
      height: "100%",
      backgroundColor: "rgb(245, 246, 251)",
    },
    note: {
      marginTop: "8%",
      fontSize: "1rem",
    },
    listTile: {
      borderBottom: "solid 0.2px",
      height: "auto !important",
    },
    authorFont: {
      fontSize: "0.875rem",
    },
  })
);

export default function DispatchNotesPreview(props: DispatchNotesPreviewProps) {
  const options = {
    year: "numeric",
    day: "numeric",
    month: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  const dateTimeFormat = new Intl.DateTimeFormat("en-US", options);
  const classes = useStyles();

  // Get user data from session storage
  let userData: any = JSON.parse(sessionStorage.getItem("userData") || "{}");

  // history
  const [modalState, setModalState] = useState(false);
  const [selectedHistory, setSelectedHistory] = useState([]);
  const historyBody = (history: Note[]) => (
    <Paper elevation={0} style={{ width: "min-content" }}>
      <Grid container spacing={0} justify="center">
        <Grid container item xs={12}>
          <Grid item xs={10}>
            <Typography variant="h3">Edit history</Typography>
          </Grid>
          <Grid item xs={2} style={{ textAlign: "right" }}>
            <CancelButton onClick={() => setModalState(false)} />
          </Grid>
          <Grid item xs={12}>
            <DispatchNotesPreview notes={history} />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );

  const openHistoryModal = (history: Note[]) => {
    setSelectedHistory(history);
    setModalState(true);
  };

  return (
    <div className={classes.root}>
      <Grid container item xs={12} spacing={0}>
        <Grid item xs={12}>
          <Paper elevation={0}>
            <Grid item>
              <Paper variant="outlined" square>
                <div className={classes.rootList}>
                  <GridList cellHeight={220} className={classes.gridList}>
                    {props.notes.map((note) => (
                      <GridListTile
                        key={note.id ? note.id : note.uid}
                        style={{ width: "100%" }}
                        className={classes.listTile}
                      >
                        <div className={classes.section1}>
                          <Grid container alignItems="center">
                            <Grid item xs={8}>
                              {note.lastModifiedBy === undefined ||
                              note.lastModifiedBy === null ? (
                                <Typography
                                  className={classes.authorFont}
                                  gutterBottom
                                >
                                  Added at:{" "}
                                  {note.draft
                                    ? "Draft"
                                    : dateTimeFormat.format(note.created)}
                                </Typography>
                              ) : (
                                <Typography
                                  className={classes.authorFont}
                                  gutterBottom
                                >
                                  Modified at:{" "}
                                  {note.draft
                                    ? "Draft"
                                    : dateTimeFormat.format(note.lastModified)}
                                </Typography>
                              )}
                            </Grid>
                            <Grid item xs={4} style={{ textAlign: "right" }}>
                              {note.history && !!note.history.length && (
                                <IconButton
                                  color="primary"
                                  aria-label="edit"
                                  onClick={() => openHistoryModal(note.history)}
                                >
                                  <HistoryIcon />
                                </IconButton>
                              )}
                            </Grid>
                            {note.lastModifiedBy === undefined ||
                            note.lastModifiedBy === null ? (
                              <Typography
                                className={classes.authorFont}
                                gutterBottom
                              >
                                Added by:{" "}
                                {note.draft
                                  ? userData.user.email
                                  : note.createdBy.userName}
                              </Typography>
                            ) : (
                              <Typography
                                className={classes.authorFont}
                                gutterBottom
                              >
                                Modified by:{" "}
                                {note.draft
                                  ? userData.user.email
                                  : note.lastModifiedBy.userName}
                              </Typography>
                            )}
                          </Grid>
                          <Typography className={classes.note} variant="body2">
                            <div style={{ whiteSpace: "pre-line" }}>
                              {note.text}
                            </div>
                          </Typography>
                        </div>
                        <hr />
                      </GridListTile>
                    ))}
                  </GridList>
                </div>
              </Paper>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      {modalState && (
        <FormDialog open={modalState} body={historyBody(selectedHistory)} />
      )}
    </div>
  );
}
