import React, { useReducer, useEffect, FormEvent } from "react";
import {
  Grid,
  Typography,
  Paper,
  TextField,
  Button,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { ManufacturerVM, PartVM } from "../../../brines-refrigerator-api";
import { useTextValidation } from "../../../helpers/validations";
import "./PartsDetails.scss";

interface PartDetailsProps {
  clearFields: Function;
  part: PartVM;
  formAction: Function;
  manufacturers: ManufacturerVM[];
  formTitle: string;
  formButtonTitle: string;
}

const useStyles = makeStyles({
  marginBottom: {
    marginBottom: 16,
  },
  bordedSolid: {
    borderBottom: "2px solid rgb(0, 0, 0)",
    marginBottom: "1em",
  },
  partDetailsMargin: {
    marginBottom: "2em",
  },
});

function PartDetails(props: PartDetailsProps) {
  const classes = useStyles();
  //set state for Part Form
  const [state, setState] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      id: props.part.id,
      partNumber: props.part.number,
      partName: props.part.name,
      source: props.part.source,
      manufacturer: props.part.manufacturer,
      manufacturers: props.manufacturers,
      description: props.part.description,
      price: props.part.price,
    }
  );

  const validateFields = (): boolean => {
    const resultName = triggerNameValidation(state.partName);

    return resultName;
  };
  const [nameInputProps, triggerNameValidation, resetNameInputProps] =
    useTextValidation();

  function executeFormAction(e) {
    e.preventDefault();

    if (validateFields()) {
      return;
    }

    props.formAction(e, Number(state.id), state.manufacturer);
  }

  useEffect(() => {
    setState({
      id: props.part.id,
      partNumber: props.part.number,
      partName: props.part.name,
      source: props.part.source,
      manufacturer: props.part.manufacturer,
      manufacturers: props.manufacturers,
      description: props.part.description,
      price: props.part.price,
    });
    resetNameInputProps();
  }, [props]);

  //set selected Manufacturer from dropdown select
  const handleManufacturerChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    if (event.target.value === "") {
      setState({ manufacturer: new ManufacturerVM({ id: 0, name: "" }) });
      return;
    }
    const [{ id, name }] = state.manufacturers.filter(
      (e) => e.id === (event.target.value as number)
    );
    setState({ manufacturer: new ManufacturerVM({ id, name }) });
  };

  const clearFields = () => {
    props.clearFields();
  };
  return (
    <Grid item xs={12} sm={12} className="user_crud_form">
      <Paper elevation={0} />
      <Grid container spacing={1} className={classes.partDetailsMargin}>
        <Grid item xs={6} sm={6}>
          <Typography variant="h2">{props.formTitle}</Typography>
        </Grid>
        <Grid item xs={6} sm={6}>
          <Button onClick={clearFields} color="primary">
            Clear fields
          </Button>
        </Grid>
      </Grid>
      <Grid
        className={classes.bordedSolid}
        item
        container
        xs={12}
        sm={12}
        alignItems="center"
      >
        <Grid item xs={1}>
          {/* <Checkbox color='primary'/> */}
        </Grid>
        <Grid item xs={11}>
          {/* <Typography >Active</Typography> */}
        </Grid>
      </Grid>
      <Grid container justify="center">
        <form onSubmit={(e: FormEvent) => executeFormAction(e)}>
          <Grid xs={12} item container>
            <Grid item xs={12} className={classes.marginBottom}>
              <TextField
                fullWidth={true}
                type="number"
                name="partNumber"
                label="Part Number"
                value={state.partNumber ?? 0}
                onChange={(e: any) => setState({ partNumber: e.target.value })}
                variant="outlined"
              />
            </Grid>
            <Grid xs={12} item className={classes.marginBottom}>
              <TextField
                fullWidth={true}
                type="text"
                name="partName"
                label="Part Name"
                value={state.partName}
                onChange={(e: any) => {
                  setState({ partName: e.target.value });
                  if (nameInputProps.error) {
                    triggerNameValidation(e.target.value);
                  }
                }}
                error={nameInputProps.error}
                onBlur={() => triggerNameValidation(state.partName)}
                variant="outlined"
              />
              <span className="validation_error">
                {nameInputProps.errorMessage}
              </span>
            </Grid>
            <Grid item xs={12} className={classes.marginBottom}>
              <FormControl fullWidth={true} variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">
                  Manufacturer
                </InputLabel>
                <Select
                  value={
                    state.manufacturer === undefined
                      ? ""
                      : state.manufacturer.id !== 0
                      ? state.manufacturer.id
                      : ""
                  }
                  onChange={handleManufacturerChange}
                  label="Manufacturer"
                >
                  <MenuItem value="" disabled={true}>
                    <em>Please select a Manufacturer</em>
                  </MenuItem>
                  {state.manufacturers.map((e: ManufacturerVM) => (
                    <MenuItem key={e.id} value={e.id}>
                      {e.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} className={classes.marginBottom}>
              <TextField
                fullWidth={true}
                type="text"
                name="source"
                label="Source"
                value={state.source ?? ""}
                onChange={(e: any) => {
                  setState({ source: e.target.value });
                }}
                variant="outlined"
              />
            </Grid>
            <Grid xs={12} item className={classes.marginBottom}>
              <TextField
                fullWidth={true}
                multiline
                rows={5}
                type="text"
                name="description"
                value={state.description ?? ""}
                onChange={(e: any) => {
                  setState({ description: e.target.value });
                }}
                label="Description"
                variant="outlined"
              />
            </Grid>
            <Grid xs={12} item className={classes.marginBottom}>
              <TextField
                fullWidth={true}
                type="number"
                name="price"
                label="Price"
                value={state.price ?? 0}
                onChange={(e: any) => setState({ price: e.target.value })}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              size="large"
              fullWidth
            >
              {props.formButtonTitle}
            </Button>
          </Grid>
        </form>
      </Grid>
      <Paper />
    </Grid>
  );
}

PartDetails.displayName = "PartDetails";

export default PartDetails;
