import React, { useEffect, useReducer, useState } from "react";

import "./DispatchEquipment.scss";
import { Button, makeStyles, Box, Typography, Grid } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import MaterialTable, { MTableToolbar } from "material-table";
import Note from "@material-ui/icons/Note";
import Delete from "@material-ui/icons/Delete";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import ConfirmDialog from "../../../components/common/dialog/ConfirmationDialog";
import FormDialog from "../../../components/common/dialog/FormDialog";
import {
  EquipmentClient,
  EquipmentVM,
  EquipmentNoteCreateVM,
  DocumentTypeClient,
  DocumentTypeVM,
  ManufacturerClient,
  EquipmentTypeClient,
  LocationClient,
  CustomerClient,
  EquipmentDocumentVM,
  EquipmentCreateVM,
  EquipmentNoteVM,
} from "../../../brines-refrigerator-api";
import tableIcons from "../../../components/common/table/tableIcons";
import EquipmentNotes from "../DispatchEquipmentNote/DispatchEquipmentNote";
import UploadButton from "../../../components/common/upload/UploadButton";
import UploadButtonType from "../../../helpers/constants/uploadButtonTypes";
import EquipmentChangeAction from "../../../helpers/constants/equipmentChangeActions";
import { useSnackbar } from "notistack";
import { generateUID } from "../../../helpers/generateUID";
import NoteTempVM from "../../../components/common/note/NoteTempVM";
import handleServerError from "../../../helpers/handleServerError";
import { redirectIfSessionExpired } from "../../../components/common/redirect/RedirectOnSessionTimeout";
import { useHistory } from "react-router-dom";
import {
  EquipmentDocumentAddVM,
  UploadFileClient,
} from "../../../brines-refrigerator-api-extended";
import { downloadFile } from "../../../helpers/download";
import EquipmentCRUDForm from "../../Equipment/EquipmentCRUDForm";

const useStyles = makeStyles({
  button: {
    marginBottom: "1em",
  },
  highlight: {
    backgroundColor: "#3f51b5cc",
    "& .MuiTypography-h6": {
      color: "#ffffff",
    },
  },
});

const dispatchHistorycolumns = [
  { title: "S/N", field: "serialNumber", sorting: false },
  { title: "Model", field: "model", sorting: false },
];

const addEquipmentColumns = [
  { title: "S/N", field: "serialNumber", sorting: false },
  { title: "Manufacturer", field: "manufacturer", sorting: false },
  { title: "Model", field: "model", sorting: false },
  { title: "Room location", field: "roomLocation", sorting: false },
];

const DispatchEquipment = (props) => {
  const { enqueueSnackbar } = useSnackbar();

  const equipmentClient = new EquipmentClient();

  const [documentTypes, setDocumentTypes] = useState([]);

  async function getDocumentTypes() {
    try {
      const documentTypeClient = new DocumentTypeClient();
      const documentTypes = await documentTypeClient.get();
      setDocumentTypes(
        documentTypes.map((e: DocumentTypeVM) => `.${e.extension}`)
      );
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getDocumentTypes();
  }, []);

  const tableActions = [
    (rowData: EquipmentVM) => ({
      icon: () => (
        <UploadButton
          buttonType={UploadButtonType.Icon}
          onDropAction={(files: File[]) => addDocument(files, rowData.id)}
          acceptFileTypes={documentTypes}
        />
      ),
      onClick: () => {},
    }),
    (rowData) => ({
      icon: () => <Note color="primary" />,
      onClick: (event, rowData: EquipmentVM) => {
        openEquipmentNoteModal(rowData.id);
      },
    }),
    (rowData) => ({
      icon: () => <Delete color="primary" />,
      onClick: (event, rowData: EquipmentVM) => {
        openDialogue(rowData.id, rowData.model);
      },
    }),
  ];

  const classes = useStyles();

  //EQUIPMENT NOTES
  const [selectedEquipmentId, setSelectedEquipmentId] = useState(0);

  const [equipmentNoteModal, setEquipmentNoteModal] = useState(false);

  const [equipmentNotesForDisplay, setEquipmentNotesForDisplay] = useState([]);

  const addDocument = (files: File[], id: number) => {
    props.handleEquipmentChange(EquipmentChangeAction.UploadDocument, {
      equipmentId: id,
      files,
    });
  };

  //GET EQUIPMENT NOTES FOR DISPLAY
  const getEquipmentNotes = async (id: number) => {
    try {
      console.log("getting note");
      setSelectedEquipmentId(id);
      const notes = await equipmentClient.getNotes(id, true);

      const _notes = notes.map(
        (x) =>
          new NoteTempVM(
            x.id,
            x.created,
            x.createdBy,
            x.lastModified,
            x.lastModifiedBy,
            x.text,
            false,
            generateUID(),
            x.equipmentId
          )
      );

      //we need to destructure and reinitialize the arr to force a rerender due to immutability

      setEquipmentNotesForDisplay([..._notes]);
    } catch (error) {
      handleServerError(error, enqueueSnackbar);
    }
  };

  const openEquipmentNoteModal = async (id: number) => {
    const newArr = new Array();
    setDraftEquipmentNotes([...newArr]);
    setRemovedEquipmentNotes([...newArr]);
    setEquipmentNotesForDisplay([...newArr]);
    getEquipmentNotes(id);
    setEquipmentNoteModal(true);
  };

  const closeEquipmentNoteModal = () => {
    setEquipmentNoteModal(false);
  };

  // ADD // EDIT // REMOVE NOTES
  const [removedEquipmentNotes, setRemovedEquipmentNotes] = useState([]);
  const [draftEquipmentNotes, setDraftEquipmentNotes] = useState([]);

  const addEquipmentNote = async (equipmentId, noteText) => {
    setSelectedEquipmentId(equipmentId);
    const uid = generateUID();
    const newNote = new NoteTempVM(
      null,
      new Date(),
      null,
      new Date(),
      null,
      noteText,
      true,
      uid,
      equipmentId
    );
    equipmentNotesForDisplay.push(newNote);
    const newEquipmentNotes = [...equipmentNotesForDisplay];
    setEquipmentNotesForDisplay(newEquipmentNotes);

    draftEquipmentNotes.push(newNote);
    const newDraftNotes = [...draftEquipmentNotes];
    setDraftEquipmentNotes(newDraftNotes);
  };

  const deleteEquipmentNoteCallback = (id: number, uid: string) => {
    const foundNote = equipmentNotesForDisplay.find((x) => x.uid === uid);

    //remove the note from the overall note list
    const noteIndex = equipmentNotesForDisplay.findIndex((x) => x.uid === uid);

    equipmentNotesForDisplay.splice(noteIndex, 1);
    //create a new array through destructuring as react would sometimes fail to re-render, because of react recognizing it to be completely the same(Even though it isn't)
    const newNotes = [...equipmentNotesForDisplay];

    setEquipmentNotesForDisplay(newNotes);

    if (foundNote.draft) {
      //if the specified note to remove is a draft we need to remove it from the draft notes list
      const draftNoteIndex = draftEquipmentNotes.findIndex(
        (x) => x.uid === uid
      );
      draftEquipmentNotes.splice(draftNoteIndex, 1);
      const newDraftNotes = [...draftEquipmentNotes];
      setDraftEquipmentNotes(newDraftNotes);
    } else {
      //otherwise it's a non-draft note and we need to add it to the list according to which back-end api calls are made to remove non-draft notes
      removedEquipmentNotes.push(foundNote.id);
    }
  };

  const updateEquipmentNotes = async () => {
    //function responsible for turning temp into permanent state
    for (const x of removedEquipmentNotes) {
      await equipmentClient.deleteNote(x);
    }

    for (const x of draftEquipmentNotes) {
      await equipmentClient.createNote(
        new EquipmentNoteCreateVM({
          equipmentId: x.pid,
          text: x.text,
        })
      );
    }

    setDraftEquipmentNotes([]);
    setRemovedEquipmentNotes([]);
    setEquipmentNotesForDisplay([]);

    closeEquipmentNoteModal();
  };

  // EQUIPMENT NOTE MODAL BODY
  const equipmentNoteModalBody = (
    <EquipmentNotes
      notes={equipmentNotesForDisplay}
      deleteNoteCallback={deleteEquipmentNoteCallback}
      buttonAction={addEquipmentNote}
      button2Action={updateEquipmentNotes}
      buttonTitle={"Add Note"}
      closeModal={closeEquipmentNoteModal}
      equipmentId={selectedEquipmentId}
    />
  );

  //DELETE CONFIRMATION DIALOGUE
  const [confirm, setConfirmOpen] = useState(false);

  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogMessage, setDialogMessage] = useState("");
  const [idForDelete, setIdForDelete] = useState(0);

  function confirmOpen() {
    setConfirmOpen(true);
  }

  function openDialogue(id: number, model: string) {
    setDialogTitle("Delete?");
    setDialogMessage(
      `Are you sure you want to remove ${model} from this dispatch?`
    );

    setIdForDelete(id);
    setConfirmOpen(true);
  }

  //ADD EQUIPMENT MODAL
  const [modalState, setModalState] = useState(false);
  const [equipment, setEquipment] = useState([]);
  const [selectedEquipment, setSelectedEquipment] = useState([]);

  const getEquipment = async () => {
    try {
      const equipment = await equipmentClient.getWithFilter(props.locationId);

      setEquipment(equipment);
    } catch (error) {
      handleServerError(error, enqueueSnackbar);
    }
  };

  const openAddEquipment = async () => {
    setModalState(true);
    await getEquipment();
  };

  const addSelectedEquipment = () => {
    props.addSelectedEquipment(selectedEquipment);

    setSelectedEquipment([]);
    setModalState(false);
  };

  const addEquipmentCloseHandler = () => {
    setModalState(false);
    setSelectedEquipment([]);
  };

  const addDispatchEquipmentModal = () => {
    return (
      <Grid className="equipment-modal" container xs={12}>
        <Grid item container xs={12}>
          <Grid item xs={10}>
            <Typography variant="h2">Add equipment</Typography>
          </Grid>
          <Grid item xs={2} justify="flex-end">
            <Button
              endIcon={<CancelOutlinedIcon />}
              color="primary"
              size="medium"
              disableElevation
              fullWidth
              onClick={addEquipmentCloseHandler}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <MaterialTable
            columns={addEquipmentColumns}
            title={""}
            data={equipment as []}
            icons={tableIcons}
            options={{
              search: false,
              actionsColumnIndex: -1,
              selection: equipment.length > 0,
              paging: false,
            }}
            components={{
              Toolbar: (props) => (
                <MTableToolbar
                  classes={{ highlight: classes.highlight }}
                  {...props}
                />
              ),
            }}
            onSelectionChange={(rows) => {
              setSelectedEquipment(rows);
            }}
          />
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={4}>
            <Button
              color="primary"
              variant="contained"
              size="large"
              disableElevation
              fullWidth
              onClick={() => {
                setAddEquipmentModalOpen(true);
              }}
            >
              Add new Equipment
            </Button>
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <Button
              color="primary"
              variant="contained"
              size="large"
              disabled={!(selectedEquipment.length > 0)}
              disableElevation
              fullWidth
              onClick={addSelectedEquipment}
            >
              ADD SELECTED
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  ///ADD NEW EQUIPMENT
  const [addEquipmentModalOpen, setAddEquipmentModalOpen] = useState(false);
  const history = useHistory();
  const customerClient = new CustomerClient();

  const [manufacturerList, setManufacturerList] = useState([]);
  const [equipmentTypeList, setEquipmentTypeList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [locationList, setLocationList] = useState([]);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      equipment: {
        id: 0,
        manufacturer: "",
        model: "",
        type: "",
        serialNumber: "",
        roomLocation: "",
        notes: [],
        customer_name: { id: 0, company: "" },
        customer_location: { id: 0, name: "" },
      },
      formTitle: "ADD EQUIPMENT",
      formAction: addNewEquipment,
    }
  );

  const getManufacturerList = async () => {
    try {
      const manufacturerClient = new ManufacturerClient();
      const manufacturerList = await manufacturerClient.get();
      setManufacturerList(manufacturerList);
    } catch (error) {
      redirectIfSessionExpired(history, error);
      enqueueSnackbar("Error while getting manufacturers.", {
        variant: "error",
      });
    }
  };

  const getEquipmentTypeList = async () => {
    try {
      const equipmentTypeClient = new EquipmentTypeClient();
      const equipmentTypeList = await equipmentTypeClient.get();
      setEquipmentTypeList(equipmentTypeList);
    } catch (error) {
      redirectIfSessionExpired(history, error);
      enqueueSnackbar("Error while getting equipment type.", {
        variant: "error",
      });
    }
  };

  const getCustomerList = async () => {
    try {
      const customerList = await customerClient.get();
      setCustomerList(customerList);
    } catch (error) {
      redirectIfSessionExpired(history, error);
      enqueueSnackbar("Error while getting customers.", { variant: "error" });
    }
  };

  const getLocationList = async () => {
    try {
      const locationClient = new LocationClient();
      const locationList = await locationClient.get();
      setLocationList(locationList);
    } catch (error) {
      redirectIfSessionExpired(history, error);
      enqueueSnackbar("Error while getting locations.", { variant: "error" });
    }
  };

  useEffect(() => {
    const fetchDataAsync = async () => {
      getManufacturerList();
      getEquipmentTypeList();
      getCustomerList();
      getLocationList();
    };
    fetchDataAsync();
  }, []);

  interface StateFromCRUDForm {
    id: number;
    manufacturer: string;
    model: string;
    serialNumber: string;
    roomLocation: string;
    type: string;
    notes: [{ createdBy: string; created: string; text: string }];
    customer_name: { id: number; company: string };
    customer_location: { id: number; name: string };
  }

  const uploadDraftDocuments = async (
    id: number,
    draftDocuments: EquipmentDocumentAddVM[]
  ) => {
    try {
      const uploadClient = new UploadFileClient();
      for (const document of draftDocuments) {
        document.equipmentId = id;
        document.name = `${document.name}.${document.file.name
          .split(".")
          .pop()}`;
        await uploadClient.uploadEquipmentDocument(document);
      }
    } catch (error) {
      redirectIfSessionExpired(history, error);
      enqueueSnackbar("Error while uploading document.", { variant: "error" });
    }
  };

  const downloadDocument = async (id: number) => {
    const response = await equipmentClient.preview(id);
    await downloadFile(response.data, response.fileName);
  };

  async function addNewEquipment(
    e: React.FormEvent<HTMLFormElement>,
    state: StateFromCRUDForm,
    draftDocuments: EquipmentDocumentAddVM[],
    _1,
    _2,
    draftNotes: EquipmentNoteVM[]
  ) {
    e.preventDefault();
    const {
      manufacturer,
      model,
      serialNumber,
      type,
      customer_location,
      roomLocation,
    } = state;

    const equipment = new EquipmentCreateVM({
      model: model,
      serialNumber: serialNumber,
      equipmentType: type,
      locationId: customer_location.id,
      manufacturer: manufacturer,
      roomLocation,
    });

    try {
      const newEquipment = await equipmentClient.create(equipment);
      await uploadDraftDocuments(newEquipment.id, draftDocuments);
      await updateNotes(newEquipment.id, draftNotes);
      clearFields();
      enqueueSnackbar("New equipment added successfuly.", {
        variant: "success",
      });
      getEquipment();
    } catch (error) {
      redirectIfSessionExpired(history, error);
      enqueueSnackbar("Error while adding equipment.", { variant: "error" });
    }
  }

  function clearFields() {
    setAddEquipmentModalOpen(false);
    getEquipment();
  }

  const [documents, setDocuments] = useState<EquipmentDocumentVM[]>([]);
  const [documentsToBeDeleted, setDocumentsToBeDeleted] = useState<
    EquipmentDocumentVM[]
  >([]);
  const [draftDocuments, setDraftDocuments] = useState<
    EquipmentDocumentAddVM[]
  >([]);
  const addDraft = (files: File[]) => {
    setDraftDocuments(
      draftDocuments.concat(
        files.map(
          (f) =>
            new EquipmentDocumentAddVM({
              equipmentId: 0,
              file: f,
              name: f.name.split(".").slice(0, -1).join("."),
              isConfidential: false,
            })
        )
      )
    );
  };
  const removeDraft = (index: number) => {
    setDraftDocuments(draftDocuments.filter((d, i) => i !== index));
  };
  const setIsConfidentialDraft = (index: number) => {
    draftDocuments[index].isConfidential =
      !draftDocuments[index].isConfidential;
    setDraftDocuments([...draftDocuments]);
  };
  const [lastEditedDraftDocIndex, setLastEditedDraftDocIndex] = useState(0);
  const changeDraftDocumentName = (index: number, newName: string) => {
    draftDocuments[index].name = newName;
    setDraftDocuments([...draftDocuments]);
    setLastEditedDraftDocIndex(index);
  };
  const removeDocument = (index: number) => {
    documentsToBeDeleted.push(documents[index]);
    setDocumentsToBeDeleted([...documentsToBeDeleted]);
    setDocuments(documents.filter((d, i) => i !== index));
  };
  const updateDocument = (index: number) => {
    for (let i = 0; i < documents.length; i++) {
      if (i === index) {
        documents[i].documentFile.isConfidential =
          !documents[i].documentFile.isConfidential;
        break;
      }
    }
    setDocuments([...documents]);
  };

  const [notesAddModalState, setNotesAddModalState] = useState(false);
  const [draftNotes, setDraftNotes] = useState<EquipmentNoteCreateVM[]>([]);

  const openNotesModal = () => {
    setNotesAddModalState(true);
  };
  const closeNotesModal = () => {
    setNotesAddModalState(false);
  };

  const addNewEquipmentNote = (id: number, noteText: string) => {
    draftNotes.push(
      new EquipmentNoteCreateVM({
        equipmentId: id,
        text: noteText,
      })
    );

    setDraftNotes([...draftNotes]);
    setNotesAddModalState(false);
  };

  const updateNotes = async (id: number, draftNotes: EquipmentNoteVM[]) => {
    for (const note of draftNotes) {
      note.equipmentId = id;
      await equipmentClient.createNote(note);
    }

    setDraftNotes([]);
  };

  const enterNewEquipmentBody = (
    <EquipmentCRUDForm
      title={state.formTitle}
      formAction={state.formAction}
      selectedEquipment={state.equipment}
      manufacturerList={manufacturerList}
      equipmentTypeList={equipmentTypeList}
      customerList={customerList}
      locationList={locationList}
      clearFields={clearFields}
      addDraft={addDraft}
      removeDraft={removeDraft}
      removeDocument={removeDocument}
      downloadDocument={downloadDocument}
      setIsConfidentialDraft={setIsConfidentialDraft}
      changeDraftDocumentName={changeDraftDocumentName}
      lastEditedDraftDocIndex={lastEditedDraftDocIndex}
      documents={documents}
      draftDocuments={draftDocuments}
      documentsToBeDeleted={documentsToBeDeleted}
      updateDocument={updateDocument}
      openNotesModal={openNotesModal}
      closeNotesModal={closeNotesModal}
      notesModal={notesAddModalState}
      addEquipmentNote={addNewEquipmentNote}
      draftNotes={draftNotes}
    />
  );

  return (
    <div className="dispatch-equipment_container">
      <Button
        variant="outlined"
        color="primary"
        size="small"
        className={classes.button}
        startIcon={<AddIcon />}
        onClick={async () => await openAddEquipment()}
        disabled={props.disabled}
      >
        Add Equipment
      </Button>
      <Box>
        <MaterialTable
          columns={dispatchHistorycolumns}
          title={""}
          data={props.equipment as []}
          components={{
            Pagination: () => <div hidden={true}></div>,
          }}
          options={{
            search: false,
            actionsColumnIndex: -1,
            paging: false,
          }}
          actions={tableActions}
        />
      </Box>
      {confirm && (
        <ConfirmDialog
          title={dialogTitle}
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={() => {
            props.handleEquipmentChange(
              EquipmentChangeAction.Delete,
              idForDelete
            );
          }}
        >
          {dialogMessage}
        </ConfirmDialog>
      )}
      {addEquipmentModalOpen && (
        <FormDialog open={addEquipmentModalOpen} body={enterNewEquipmentBody} />
      )}
      {modalState && (
        <FormDialog open={modalState} body={addDispatchEquipmentModal()} />
      )}
      {equipmentNoteModal && (
        <FormDialog open={equipmentNoteModal} body={equipmentNoteModalBody} />
      )}
    </div>
  );
};

export default DispatchEquipment;
