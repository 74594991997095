interface OnlineState {
    prevState: { online: boolean },
    currState: { online: boolean },
}

interface Action {
    type: string;
    payload: boolean;
}

const initialState = {
    prevState: { online: false },
    currState: { online: true },
}

export const onlineStateReducer = (state: OnlineState = initialState, action: Action) => {
    switch (action.type) {
        case 'SET_ONLINE':
            const prevState = state.currState;
            const currState = { online: action.payload };
            return {
                ...state,
                prevState,
                currState
            }
        default:
            return state
    }
}