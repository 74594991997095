import React, { useState } from "react";
import {
  Grid,
  Radio,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core";
import {
  DispatchBoardColumnAddVM,
  DispatchBoardColumnClient,
} from "../../brines-refrigerator-api";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textfield: {
      marginLeft: "0.4rem",
    },
    button: {
      margin: "1.3rem",
      width: "max-content",
    },
    dialog: {
      width: "26rem",
      height: "20rem",
    },
    formControlLeft: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    formControlRight: {
      margin: theme.spacing(1),
      width: 205,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

interface ColumnsDialogProps {
  data: {
    lanes: {
      id: number;
      title: string;
      style: {};
      index: number;
      cards: [];
      isTechColumn: boolean;
    }[];
  };
  _state: number;
  populateBoard: Function;
  resetColumnsDialog: Function;
}

export function ColumnsDialog(props: ColumnsDialogProps) {
  const classes = useStyles();

  const [selectedPositionColumn, setSelectedPositionColumn] = useState(0);
  const [selectedColumnForRemoval, setSelectedColumnForRemoval] = useState(0);
  const [selectedBeforeOrAfterColumn, setSelectedBeforeOrAfterColumn] =
    useState(0);
  const [addColumn, setAddColumn] = useState(false);
  const [deleteColumn, setDeleteColumn] = useState(false);
  const [columnName, setColumnName] = useState("");
  const [leftColumns, setLeftColumns] = useState(true);

  const getColumnsData = (leftColumns: boolean) => {
    const filteredLanes = leftColumns
      ? props.data.lanes.slice(
          0,
          props.data.lanes.findIndex((c) => c.index === null)
        )
      : [...props.data.lanes]
          .reverse()
          .slice(
            0,
            [...props.data.lanes].reverse().findIndex((c) => c.index === null)
          )
          .reverse();
    return { lanes: filteredLanes };
  };

  const [columnsData, setColumnsData] = useState(getColumnsData(true));

  const handleColumnNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setColumnName(event.target.value);
  };

  const handleOk = async () => {
    const boardClient = new DispatchBoardColumnClient();
    if (addColumn) {
      const index =
        leftColumns || columnsData.lanes.length > 0
          ? props.data.lanes.filter(
              (column) => column.id === selectedPositionColumn
            )[0].index + selectedBeforeOrAfterColumn
          : props.data.lanes.length - 2; // subtract two separator columns

      await boardClient.post(
        new DispatchBoardColumnAddVM({
          regionId: props._state,
          name: columnName,
          index: index,
        })
      );
    } else if (deleteColumn) {
      await boardClient.delete(selectedColumnForRemoval);
    }
    await props.populateBoard(props._state);
    props.resetColumnsDialog();
  };

  const handleCancel = () => {
    props.resetColumnsDialog();
  };

  const setColumns = (leftColumns: boolean) => {
    setLeftColumns(leftColumns);
    setColumnsData(getColumnsData(leftColumns));
    setAddColumn(false);
    setDeleteColumn(false);
    setSelectedPositionColumn(0);
    setSelectedBeforeOrAfterColumn(0);
    setColumnName("");
  };

  return (
    <Grid container item xs={12} className={classes.dialog}>
      <Grid item sm={12}>
        <h3>Columns</h3>
      </Grid>
      <Grid item sm={12} container>
        <Grid item sm={6}>
          <Radio
            checked={leftColumns}
            onChange={() => {
              setColumns(true);
            }}
          />{" "}
          Left columns
        </Grid>
        <Grid style={{ marginBottom: "1rem" }} item sm={6}>
          <Radio
            checked={!leftColumns}
            onChange={() => {
              setColumns(false);
            }}
            disabled={
              props.data.lanes.length > 0
                ? !props.data.lanes.some((l) => l.isTechColumn)
                : true
            }
          />{" "}
          Right columns
        </Grid>
        <Grid item sm={6}>
          <Radio
            checked={addColumn}
            onChange={() => {
              setAddColumn(true);
              setDeleteColumn(false);
              setSelectedColumnForRemoval(0);
            }}
          />{" "}
          Add column
        </Grid>
        <Grid item sm={6}>
          <TextField
            className={classes.textfield}
            label="Enter Column name"
            variant="outlined"
            size="small"
            value={columnName}
            onChange={handleColumnNameChange}
            disabled={!addColumn}
          />
        </Grid>
      </Grid>
      <Grid item sm={12} container>
        <Grid item sm={6}>
          <FormControl className={classes.formControlLeft}>
            <InputLabel id="select-addcolumn-label">Pick side</InputLabel>
            <Select
              labelId="select-addcolumn-label"
              value={selectedBeforeOrAfterColumn}
              onChange={(e) =>
                setSelectedBeforeOrAfterColumn(e.target.value as number)
              }
              className={classes.selectEmpty}
              disabled={!addColumn}
            >
              <MenuItem value={0}>{"Left"}</MenuItem>
              <MenuItem value={1}>{"Right"}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={6}>
          <FormControl className={classes.formControlRight}>
            <InputLabel id="select-addcolumn-label">Pick column</InputLabel>
            <Select
              labelId="select-addcolumn-label"
              value={selectedPositionColumn}
              onChange={(e) =>
                setSelectedPositionColumn(e.target.value as number)
              }
              className={classes.selectEmpty}
              disabled={!addColumn}
            >
              {columnsData.lanes
                .filter((e) => {
                  if (
                    selectedBeforeOrAfterColumn == 0 &&
                    e.title == "Unassigned"
                  ) {
                    return false;
                  } else {
                    return true;
                  }
                })
                .map((e) => (
                  <MenuItem key={e.id} value={e.id}>
                    {e.title}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item sm={12} container>
        <Grid item sm={6}>
          <Radio
            checked={deleteColumn}
            onChange={() => {
              setAddColumn(false);
              setDeleteColumn(true);
              setSelectedPositionColumn(0);
              setSelectedBeforeOrAfterColumn(0);
              setColumnName("");
            }}
          />
          Delete column
        </Grid>
        <Grid item sm={6}>
          <FormControl className={classes.formControlRight}>
            <InputLabel id="select-addcolumn-label">Pick column</InputLabel>
            <Select
              labelId="select-deletecolumn-label"
              value={selectedColumnForRemoval}
              onChange={(e) =>
                setSelectedColumnForRemoval(e.target.value as number)
              }
              className={classes.selectEmpty}
              disabled={!deleteColumn}
            >
              {columnsData.lanes
                .filter((l) => {
                  return (
                    l.cards.length === 0 &&
                    l.title !== "Unassigned" &&
                    l.title !== "Dispatch Review"
                  );
                })
                .map((e) => (
                  <MenuItem value={e.id}>{e.title}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item sm={12}>
        <Button autoFocus onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleOk}
          color="primary"
          disabled={
            addColumn
              ? columnsData.lanes.length > 0
                ? selectedPositionColumn === 0 || columnName === ""
                : columnName === ""
              : deleteColumn
              ? selectedColumnForRemoval === 0
              : true
          }
        >
          Ok
        </Button>
      </Grid>
    </Grid>
  );
}
