import React, { useEffect, useReducer, useState } from 'react';
import { PartClient, PartVM, ManufacturerClient, ManufacturerVM, PartAddVM } from '../../../brines-refrigerator-api';
import PartDetails from '../../admin/PartsList/PartDetails';
import { Grid, Paper } from '@material-ui/core';
import BasicTable from '../../common/table/BasicTable';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import ConfirmDialog from '../../common/dialog/ConfirmationDialog';
import { redirectIfSessionExpired } from '../../common/redirect/RedirectOnSessionTimeout';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

interface StateType {
    part: PartVM,
}

export default function PartsListView() {
    const history = useHistory();
    const [formTitle, setFormTitle] = useState('Add Part');
    const [formButtonTitle, setFormButtonTitle] = useState('Add Part');

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const partClient = new PartClient();

    //Set form to be empty
    const [state, setState] = useReducer(
        (state: StateType, newState: StateType) => ({ ...state, ...newState }),
        {
            part: new PartVM({
                id: 0,
                number: 0,
                name: '',
                source: '',
                manufacturer: new ManufacturerVM({ id: 0, name: "" }),
                description: '',
                price: 0
            }),
        },
    );

    const [manufacturers, setManufacturers] = useState([]);
    const [parts, setParts] = useState([]);

    async function populateTable() {
        try {
            const parts = await partClient.get();
            setParts(parts)
        } catch (error) {
            redirectIfSessionExpired(history, error)
            enqueueSnackbar("Error getting parts.", { variant: "error" });
        }
    };

    useEffect(() => {
        populateTable()
    }, [state.part]);

    //Edit part form
    const editPart = async (e: React.FormEvent<HTMLFormElement>, id: number, manufacturer: ManufacturerVM) => {
        e.preventDefault();

        const form = e.currentTarget;
        const part = new PartVM({
            id: id,
            number: Number(form.partNumber.value),
            name: form.partName.value,
            source: form.source.value,
            manufacturer: manufacturer,
            description: form.description.value,
            price: Number(form.price.value)
        });
        try {
            await partClient.put(part);

            enqueueSnackbar("Part edited successfully.", { variant: "success" });

            clearFields()
            setFormTitle('Add Part');
            setFormButtonTitle('ADD Part');
            setFormAction(() => addPart)
        } catch (error) {
            redirectIfSessionExpired(history, error)
            enqueueSnackbar("Error editing part.", { variant: "error" });
        }
    }

    const addPart = async (e: React.FormEvent<HTMLFormElement>, id: number, manufacturer: ManufacturerVM) => {
        e.preventDefault();
        setFormTitle('Add Part');
        setFormButtonTitle('ADD Part');
        const form = e.currentTarget;
        const part = new PartAddVM({
            number: form.partNumber.value === 0 ? null : Number(form.partNumber.value),
            name: form.partName.value,
            source: form.source.value,
            manufacturerId: manufacturer.id === 0 ? null : manufacturer.id,
            description: form.description.value,
            price: form.price.value === 0 ? null : Number(form.price.value)
        });
        try {
            await partClient.post(part);

            enqueueSnackbar("Part added successfully.", { variant: "success" });

            clearFields()
        } catch (error) {
            redirectIfSessionExpired(history, error)
            enqueueSnackbar("Error adding part.", { variant: "error" });

        }
    }

    async function getManufacturers() {
        try {
            const manufacturerClient = new ManufacturerClient();
            const manufacturers = await manufacturerClient.get();
            setManufacturers(manufacturers)
        } catch (error) {
            redirectIfSessionExpired(history, error)
            enqueueSnackbar("Error getting manufacturers.", { variant: "error" });
        }
    };

    useEffect(() => {
        getManufacturers();
    }, []);

    function clearFields() {
        setState({
            ...state,
            part: new PartVM({
                id: 0,
                number: 0,
                name: '',
                source: '',
                manufacturer: new ManufacturerVM({ id: 0, name: "" }),
                description: '',
                price: 0
            })
        })
        setFormTitle('Add Part');
        setFormButtonTitle('Add Part');
        setFormAction(() => addPart)
    }

    async function getPartForEditing(id: number) {
        setFormTitle('Edit Part');
        setFormButtonTitle('Update Part');
        setFormAction(() => editPart);

        try {
            const part = await partClient.getById(id)
            setState({ ...state, part })
        } catch (error) {
            redirectIfSessionExpired(history, error)
            enqueueSnackbar("Error while getting part for editing.", { variant: "error" });
        }
    }

    const [formAction, setFormAction] = useState(() => addPart);

    const partsListColums = [
        { title: 'Number', field: 'number' },
        { title: 'Name', field: 'name' },
        { title: 'Manufacturer', field: 'manufacturer.name' },
        { title: 'Source', field: 'source' },
        { title: 'Description', field: 'description' },
        { title: 'Price', field: 'price' }
    ];

    const [idForDelete, setIdForDelete] = useState(0);
    const [dialogMessage, setDialogMessage] = useState('');
    const [confirmOpen, setConfirmOpen] = useState(false);

    const openDeleteDialogue = (id: number) => {
        setIdForDelete(id);
        setDialogMessage(`Are you sure you want to delete Part #${id}?`);
        setConfirmOpen(true);
    }

    async function deletePart(selectedPartId: number) {
        try {
            await partClient.delete(selectedPartId);

            enqueueSnackbar("Part deleted successfully.", { variant: "success" });

            populateTable();
        } catch (error) {
            redirectIfSessionExpired(history, error)
            enqueueSnackbar("Error deleting part.", { variant: "error" });
        }
    }

    const tableActions = [
        rowData => ({
            icon: () => <Edit color='primary' />,
            onClick: (event, rowData: unknown) => { getPartForEditing((rowData as { id: number }).id) },
            tooltip: "Edit Part"
        }),
        rowData => ({
            icon: () => <Delete color='primary' />,
            onClick: (event, rowData: unknown) => { openDeleteDialogue((rowData as { id: number }).id) },
            tooltip: "Delete Part"
        })
    ]

    return (
        <section>
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <BasicTable
                        columns={partsListColums}
                        title={'Parts List'}
                        actions={tableActions}
                        data={parts as []}
                        components={{
                            Container: props => <Paper {...props} elevation={0} />,
                        }}
                        paging={true}
                        isLoading={false}
                    />
                    {confirmOpen && <ConfirmDialog
                        title={"Delete dispatch?"}
                        open={confirmOpen}
                        setOpen={setConfirmOpen}
                        onConfirm={() => { deletePart(idForDelete) }}
                    >
                        {dialogMessage}
                    </ConfirmDialog>
                    }
                </Grid>
                <Grid item xs={4}>
                    <PartDetails
                        clearFields={clearFields}
                        part={state.part}
                        formAction={formAction}
                        manufacturers={manufacturers}
                        formTitle={formTitle}
                        formButtonTitle={formButtonTitle}
                    />
                </Grid>
            </Grid>
        </section>
    );
}
