import React, { useEffect, useState } from 'react';

import { createStyles, Grid, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button/Button';
import { TeamMemberAddVM, TeamMembersAddVM, TeamMembersDeleteVM, TeamsClient, TeamUpdateVM, TeamVM, UserBaseVM, UserClient } from '../../../brines-refrigerator-api';
import TeamsTransferList from './TeamTransferList';
import { useSnackbar } from 'notistack';

interface TeamManagementProps {
    cancelTeamEdit: Function,
    team: TeamVM,
    newTeamName: string,
    triggerNameValidation: Function,
    refreshTable: Function
}

const TeamManagement = (props: TeamManagementProps) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const teamsClient = new TeamsClient();

    useEffect(() => {
        if (props.team) {
            getPeopleAndMembers();
            setDisabled(false);
        } else {
            setDisabled(true);
            clearPeopleAndMembers();
        }
    }, [props.team]);

    const [availablePeople, setAvailablePeople] = useState([]);
    const [teamMembers, setTeamMembers] = useState([]);

    const getPeopleAndMembers = async () => {
        const teamMembers = (await teamsClient.getTeamMembers(props.team.id)).map(x => x.user);
        setTeamMembers(teamMembers);

        const usersClient = new UserClient();
        const users = (await usersClient.users()).map(x => new UserBaseVM({
            id: x.id,
            userName: x.userName,
            firstName: x.firstName,
            lastName: x.lastName,
            email: x.email,
            isActive: x.isActive
        }));
        const availablePeople = users.filter(x => {
            return teamMembers.findIndex(y => y.id === x.id) === -1
        });
        setAvailablePeople(availablePeople);
    }

    const clearPeopleAndMembers = () => {
        setTeamMembers([]);
        setAvailablePeople([]);
    }

    const useStyles = makeStyles(() =>
        createStyles({
            titleRow: {
                height: "max-content",
                marginBottom: "2rem"
            },
        }),
    );

    const [disabled, setDisabled] = useState(true);

    const classes = useStyles();

    const [tempAddedMembers, setTempAddedMembers] = useState([]);
    const [tempRemovedMembers, setTempRemovedMembers] = useState([]);

    const validateUpdateTeam = () => {
        const nameError = props.triggerNameValidation(props.newTeamName)
        return !nameError;
    }

    const updateTeam = async () => {
        if (validateUpdateTeam()) {
            try {
                let refreshTeam = false;

                //check if we need to update team name
                if (props.newTeamName !== props.team.name) {
                    await teamsClient.updateTeam(new TeamUpdateVM({
                        id: props.team.id,
                        name: props.newTeamName
                    }));
                    props.refreshTable();
                }

                //update team members according to removed / added ones
                if (tempAddedMembers.length > 0) {
                    refreshTeam = true;
                    await teamsClient.addTeamMembers(new TeamMembersAddVM({
                        teamId: props.team.id,
                        teamMembers: tempAddedMembers.map((x: UserBaseVM) => new TeamMemberAddVM({
                            userId: x.id
                        }))
                    }));
                }

                if (tempRemovedMembers.length > 0) {
                    refreshTeam = true;
                    await teamsClient.deleteTeamMembersByUserId(new TeamMembersDeleteVM({
                        teamId: props.team.id,
                        teamMembers: tempRemovedMembers.map((x: UserBaseVM) => x.id)
                    }));
                }

                if (refreshTeam) {
                    getPeopleAndMembers();
                }
                enqueueSnackbar('Team members updated successfully.', { variant: "success" });
            } catch (error) {
                enqueueSnackbar('Error while updating team members.', { variant: "error" });
            }
        }
    }

    return (
        <Grid item container xs={12}>
            <Grid item container xs={12} className={classes.titleRow} alignItems="center" spacing={2}>
            </Grid>
            <Grid item container xs={12} alignItems="flex-start">
                <TeamsTransferList
                    disabled={disabled}
                    availablePeople={availablePeople}
                    teamMembers={teamMembers}
                    setTempAddedMembers={setTempAddedMembers}
                    setTempRemovedMembers={setTempRemovedMembers}
                />
            </Grid>
            <Button color='primary' variant='contained' size='large' fullWidth disableElevation onClick={updateTeam} disabled={disabled}>
                Update
            </Button>
        </Grid>
    )
}

export default TeamManagement;