import React, { useState, useEffect } from 'react';
import { makeStyles, Grid, Paper, Typography, TextField, Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { UserBaseVM, ServiceNoteUpdateVM, DispatchClient, InvoiceNotesClient, InvoiceClient } from '../../brines-refrigerator-api';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { ValidationInputProps, validateText } from '../../helpers/validations';
import { useSnackbar } from 'notistack';

interface WorkOrderProps {
  invoiceId: number,
  dispatchId: number
};

interface ServiceNoteEditVm {
  id?: number,
  dispatchId?: number,
  createdByCopyId?: number,
  createdByCopy?: UserBaseVM,
  createdCopy?: Date,
  lastModifiedByCopyId?: number,
  lastModifiedByCopy?: UserBaseVM,
  lastModifiedCopy?: Date,
  lastModifiedById?: number,
  lastModifiedBy?: UserBaseVM,
  lastModified?: Date,
  text?: string,
  isEditMode?: boolean,
  inputProps?: ValidationInputProps,
}

const useStyles = makeStyles({
  root: {
    margin: "1rem 2.5rem"
  },
  cardRoot: {
    padding: 0
  },
  cardHeader: {
    backgroundColor: "rgb(245, 246, 251)",
    padding: "1em",
  },
  cardHeaderTitle: {
    fontSize: "1rem",
    fontWeight: 500
  },
  cardBody: {
    padding: "1em"
  },
  cardMarginBottomMd: {
    marginBottom: "2rem"
  },
  visit: {
    marginTop: 'inherit'
  },
  signature: {
    paddingBottom: '1rem'
  },
  signatureAlign: {
    textAlign: 'center'
  },
  notesTextfield: {
    display: 'grid',
    width: '33.2%'
  },
  validationError: {
    fontSize: '0.8125rem',
    color: 'red'
  },
});

const InvoiceWorkOrder = (props: WorkOrderProps) => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const options = { weekday: 'long', year: 'numeric', day: 'numeric', month: 'numeric', timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone };
  const dateTimeFormat = new Intl.DateTimeFormat('en-US', options);
  const dispatchClient = new DispatchClient();
  const invoiceNotesClient = new InvoiceNotesClient();
  const invoiceClient = new InvoiceClient();

  const [visits, setVisits] = useState({});

  const getServiceNotesAndSignatures = async () => {
    try {
      const notes: ServiceNoteEditVm[] = await invoiceNotesClient.get(props.invoiceId, true)
      const signatures = await invoiceClient.getSignatures(props.invoiceId);
      let dict = {};

      notes.forEach(x => {
        if (!dict[x.createdCopy.toLocaleString('en-US', options)]) {
          dict[x.createdCopy.toLocaleString('en-US', options)] = { notes: [], signatures: [] };
        }
        dict[x.createdCopy.toLocaleString('en-US', options)].notes.push({ ...x, isEditMode: false, inputProps: { error: false, errorMessage: null } })
      })

      for (const signature of signatures) {
        if (!dict[signature.created.toLocaleString('en-US', options)]) {
          dict[signature.created.toLocaleString('en-US', options)] = { notes: [], signatures: [] };
        }
        const signaturePreview = await viewSignature(signature.documentFileId);
        dict[signature.created.toLocaleString('en-US', options)].signatures.push(signaturePreview)
      }

      setVisits(dict)
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const deleteServiceNote = async (noteId: number) => {
    try {
      await invoiceNotesClient.delete(noteId)
      getServiceNotesAndSignatures()
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const setNoteEditMode = (date: string, note: ServiceNoteEditVm) => {
    const noteForEditing = visits[date].notes.find((x: ServiceNoteEditVm) => x.id === note.id)

    noteForEditing.isEditMode = !noteForEditing.isEditMode;
    noteForEditing.inputProps = { error: false, errorMessage: null };

    setVisits({ ...visits });
  };

  const validateNote = (date: string, noteId: number, text: string) => {
    const noteForEditing = visits[date].notes.find((x: ServiceNoteEditVm) => x.id === noteId)
    const result = validateText(text)

    if (result.error === noteForEditing.inputProps.error) {
      return;
    } else {
      noteForEditing.inputProps = validateText(text);
      setVisits({ ...visits });
    }
  };

  const [editedNoteText, setEditedNoteText] = useState("");

  const editNote = async (noteId: number) => {
    try {
      await invoiceNotesClient.update(new ServiceNoteUpdateVM({ id: noteId, text: editedNoteText }))
      getServiceNotesAndSignatures()
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  useEffect(() => {
    if (props.dispatchId) {
      getServiceNotesAndSignatures()
    }
  }, [props.dispatchId])

  ///Signatures

  const viewSignature = async (fileId: number) => {
    try {
      const file = await dispatchClient.preview(fileId);

      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onloadend = () => resolve(fileReader.result as string);
        fileReader.readAsDataURL(file.data);
      });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  return (
    <div className={classes.root}>
      {Object.keys(visits).map((date) => (
        <Grid container className={classes.visit}>
          <Grid item sm={12}>
            <Paper elevation={1}>
              <Grid className={classes.cardHeader}>
                <Typography className={classes.cardHeaderTitle}>
                  Tech Visit: {date}
                </Typography>
              </Grid>
              <div>
                <div>
                  {visits[date].notes.map((note) => (
                    <div className={classes.cardBody}>
                      <Grid container >
                        <Grid container item sm={2}>
                          <Grid>
                            {
                              note.lastModifiedBy === undefined || note.lastModifiedBy === null
                                ? note.lastModifiedByCopy === undefined || note.lastModifiedByCopy === null
                                  ? <Typography className={classes.cardHeaderTitle}>Created by: &nbsp;</Typography>
                                  : <Typography className={classes.cardHeaderTitle}>Modified by: &nbsp;</Typography>
                                : <Typography className={classes.cardHeaderTitle}>Modified by: &nbsp;</Typography>
                            }
                          </Grid>
                          <Grid>
                            {
                              note.lastModifiedBy === undefined || note.lastModifiedBy === null
                                ? note.lastModifiedByCopy === undefined || note.lastModifiedByCopy === null
                                  ? <Typography>{note.createdByCopy.userName}</Typography>
                                  : <Typography>{note.lastModifiedByCopy.userName}</Typography>
                                : <Typography>{note.lastModifiedBy.userName}</Typography>
                            }
                          </Grid>
                        </Grid>
                        <Grid container item sm={2}>
                          {
                            note.lastModifiedBy === undefined || note.lastModifiedBy === null
                              ? note.lastModifiedByCopy === undefined || note.lastModifiedByCopy === null
                                ? <span>
                                  <Grid>
                                    <Typography className={classes.cardHeaderTitle}>Added at: &nbsp;</Typography>
                                  </Grid>
                                  <Grid>
                                    <Typography>{dateTimeFormat.format(note.createdCopy)} </Typography>
                                  </Grid>
                                </span>
                                : <span>
                                  <Grid>
                                    <Typography className={classes.cardHeaderTitle}>Modified at: &nbsp;</Typography>
                                  </Grid>
                                  <Grid>
                                    <Typography>{dateTimeFormat.format(note.lastModifiedCopy)} </Typography>
                                  </Grid>
                                </span>
                              : <span>
                                <Grid>
                                  <Typography className={classes.cardHeaderTitle}>Modified at: &nbsp;</Typography>
                                </Grid>
                                <Grid>
                                  <Typography>{dateTimeFormat.format(note.lastModified)} </Typography>
                                </Grid>
                              </span>
                          }
                        </Grid>
                        <Grid container item sm={1}>
                          <Grid>
                            <IconButton size="small" color="primary" onClick={() => setNoteEditMode(date, note)}>
                              <EditIcon />
                            </IconButton>
                          </Grid>
                          <Grid>
                            <IconButton size="small" color="primary" onClick={() => deleteServiceNote(note.id)}>
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                      {note.isEditMode ?
                        <Grid container>
                          <div className={classes.notesTextfield}>
                            <TextField
                              defaultValue={note.text}
                              onChange={(e) => {
                                setEditedNoteText(e.target.value)
                                validateNote(date, note.id, e.target.value)
                              }}
                              error={note.inputProps.error}
                              multiline
                              rows={4}
                              variant="outlined"
                            />
                            <span className={classes.validationError}>
                              {note.inputProps.errorMessage}
                            </span>
                          </div>
                          <IconButton disabled={note.inputProps.error} size="small" color="primary" onClick={() => editNote(note.id)}>
                            <Tooltip title="Save">
                              <DoneIcon />
                            </Tooltip>
                          </IconButton>
                          <IconButton size="small" color="primary" onClick={() => setNoteEditMode(date, note)}>
                            <Tooltip title="Cancel">
                              <CloseIcon />
                            </Tooltip>
                          </IconButton>
                        </Grid>
                        :
                        <Grid container>
                          <Typography>
                            {note.text}
                          </Typography>
                        </Grid>
                      }
                      <br />
                    </div>
                  ))}
                </div>
                <div className={classes.signature}>
                  {visits[date].signatures.map((signature: string) => <span>
                    <Grid container>
                      <Grid item sm={7}></Grid>
                      <Grid item sm={5} className={classes.signatureAlign}>
                        <img src={signature} style={{ height: "5em" }}></img>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item sm={7}></Grid>
                      <Grid item sm={5} className={classes.signatureAlign}>Customer signature</Grid>
                    </Grid>
                  </span>)}
                </div>
              </div>
            </Paper>
          </Grid>
        </Grid>
      ))
      }
    </div >
  )
}

export default InvoiceWorkOrder;
