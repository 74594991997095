import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Typography,
  makeStyles,
  createStyles,
  Paper,
  GridList,
  GridListTile,
  Button,
} from "@material-ui/core";
import { LocationVM, StateVM } from "../../brines-refrigerator-api";
import { useHistory } from "react-router-dom";
import { redirectIfSessionExpired } from "../../components/common/redirect/RedirectOnSessionTimeout";
import { useTextValidation, validateText } from "../../helpers/validations";
import * as client from "../../helpers/offline/offline-api";
import RoomIcon from "@material-ui/icons/Room";
import { useSnackbar } from "notistack";

interface TechLocationProps {
  dispatchId: number;
  dispatchLocationId: number;
}

const useStyles = makeStyles(() =>
  createStyles({
    tileList: {
      maxHeight: "25rem",
    },
    marginTop: {
      marginTop: "1rem",
    },
    fontSize: {
      fontSize: "0.8rem",
    },
  })
);

export default function TechLocation(props: TechLocationProps) {
  const classes = useStyles();
  const options = {
    year: "numeric",
    day: "numeric",
    month: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  const dateTimeFormat = new Intl.DateTimeFormat("en-US", options);
  const history = useHistory();
  const [_addLocationNote] = client.useAddLocationNote();
  const [_getLocation] = client.useGetLocation();
  const [_getTeamName] = client.useGetTeamName();

  const [noteText, setNoteText] = useState("");
  const [locationNotes, setLocationNotes] = useState([]);
  const [location, setLocation] = useState(
    new LocationVM({
      addressLine1: " ",
      addressLine2: " ",
      city: " ",
      zip: " ",
      state: new StateVM({ name: " " }),
    })
  );
  const [teamName, setTeamName] = useState(" ");

  const [noteInputProps, triggerNoteInputValidation, resetNoteInputProps] =
    useTextValidation();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const getLocation = async () => {
    if (props.dispatchLocationId) {
      try {
        const locationForDisplay = await _getLocation(
          props.dispatchId,
          props.dispatchLocationId
        );
        setLocationNotes(locationForDisplay.notes);
        setLocation(locationForDisplay);
      } catch (error) {
        redirectIfSessionExpired(history, error);
        enqueueSnackbar("Error getting location(notes).", { variant: "error" });
      }
    }
  };

  const getTeam = async () => {
    if (props.dispatchLocationId) {
      try {
        const teamName = await _getTeamName(
          props.dispatchId,
          location.customerId
        );
        setTeamName(teamName);
      } catch (error) {
        redirectIfSessionExpired(history, error);
        // No team was found. The client doesn't want to be spammed with this warning
      }
    }
  };

  const getLocationNotes = async () => {
    try {
      const locationForDisplay = await _getLocation(
        props.dispatchId,
        props.dispatchLocationId
      );
      setLocationNotes(locationForDisplay.notes);
    } catch (error) {
      redirectIfSessionExpired(history, error);
      enqueueSnackbar("Error getting location notes.", { variant: "error" });
    }
  };

  const validateNote = () => {
    const formValid = !validateText(noteText).error;
    if (!formValid) triggerNoteInputValidation(noteText, false);
    return formValid;
  };

  const addLocationNote = async () => {
    if (validateNote()) {
      try {
        await _addLocationNote(
          props.dispatchId,
          props.dispatchLocationId,
          noteText
        );
        getLocationNotes();
        setNoteText("");
        enqueueSnackbar("Successfully added location note.", {
          variant: "success",
        });
      } catch (error) {
        redirectIfSessionExpired(history, error);
        enqueueSnackbar("Error adding location note.", { variant: "error" });
      }
    }
  };

  useEffect(() => {
    getLocation();
  }, [props]);

  useEffect(() => {
    if (location.id) {
      getTeam();
    }
  }, [location]);

  const validateInput = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let noteText = e.target.value;
    setNoteText(noteText);
    if (noteInputProps.error) {
      triggerNoteInputValidation(noteText, false);
    }
  };

  const openMaps = (address: string, zipCode: string) => {
    // on other mobile devices and computers, it redirects to Google Maps
    window.open(`http://maps.apple.com/maps?q=${address} ${zipCode}`);
  };

  return (
    <div>
      <Grid container item xs={12}>
        <Typography variant="h3">Address</Typography>
        <Grid container item xs={12}>
          <Grid xs={6} item>
            {location.addressLine1}
            {location.addressLine1 !== "" && (
              <Button
                color="primary"
                onClick={() => openMaps(location.addressLine1, location.zip)}
              >
                <RoomIcon />
              </Button>
            )}
          </Grid>
          <Grid xs={6} item>
            {location.addressLine2}
            {location.addressLine2 !== "" && (
              <Button
                color="primary"
                onClick={() => openMaps(location.addressLine2, location.zip)}
              >
                <RoomIcon />
              </Button>
            )}
          </Grid>
          <Grid xs={12} item>
            <Typography>{`${location.city} ${location.state.name} ${location.zip}`}</Typography>
          </Grid>
          <Grid xs={3} item>
            <TextField
              name="ivr"
              label="IVR"
              value={location.ivr || " "}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
              }}
            />
          </Grid>
          <Grid xs={3} item>
            <TextField
              name="ivrPin"
              label="IVR Pin"
              value={location.ivrPin || " "}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
              }}
            />
          </Grid>
          <Grid xs={3} item>
            <TextField
              name="ems"
              label="EMS"
              value={location.emsNumber || " "}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
              }}
            />
          </Grid>
          <Grid xs={3} item>
            <TextField
              name="team"
              label="Team"
              value={teamName}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.marginTop}>
          <Paper variant="outlined" square>
            <div>
              <GridList className={classes.tileList} cellHeight={160}>
                {locationNotes.map((tile) => (
                  <GridListTile key={tile.id} style={{ width: "100%" }}>
                    <div>
                      <Grid container alignItems="center">
                        <Grid container item xs>
                          <Grid item xs={12}>
                            <Typography
                              className={classes.fontSize}
                              gutterBottom
                            >
                              Added at: {dateTimeFormat.format(tile.created)}
                            </Typography>
                          </Grid>
                          <Typography className={classes.fontSize} gutterBottom>
                            Added by: {tile.createdBy.userName}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Typography className={classes.marginTop} variant="body2">
                        {tile.text}
                      </Typography>
                    </div>
                  </GridListTile>
                ))}
              </GridList>
            </div>
          </Paper>
          {/* <TextField
            fullWidth={true}
            label="Add Note"
            multiline
            rows={4}
            variant="outlined"
            onChange={(event) => validateInput(event)}
            value={noteText}
            className={classes.marginTop}
            error={noteInputProps.error}
            onBlur={() => {
              triggerNoteInputValidation(noteText);
            }}
          />
          <span className="validation_error">
            {noteInputProps.errorMessage}
          </span>
          <Button
            onClick={() => addLocationNote()}
            variant="contained"
            color="primary"
            fullWidth={true}
            className={classes.marginTop}
          >
            Add Note
          </Button> */}
        </Grid>
      </Grid>
    </div>
  );
}
