import React, { useState, useEffect, useReducer } from "react";
import "./Dispatch.scss";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DispatchCRUDForm from "./DispatchCRUDForm/DispatchCRUDForm";
import DispatchHistory from "./DispatchHistory/DispatchHistory";
import {
  TradeClient,
  DispatchVM,
  DispatchStatusClient,
  CustomerClient,
  LocationClient,
  PriorityClient,
  UserClient,
  DispatchClient,
  DispatchCreateVM,
  DispatchUpdateVM,
  TradeVM,
  PriorityTypeVM,
  ServiceRequestCodeClient,
  DispatchTableVM,
  DispatchSecondaryStatusClient,
  RegionClient,
} from "../../brines-refrigerator-api";
import { redirectIfSessionExpired } from "../../components/common/redirect/RedirectOnSessionTimeout";
import { useHistory } from "react-router-dom";

import UserRole from "../../helpers/constants/userRole";
import FormDialog from "../../components/common/dialog/FormDialog";
import Visits from "../../components/dispatch/visitsModal/visits";
import DispatchForm from "./DispatchCRUDForm/DispatchForm";
import { useSnackbar } from "notistack";
import handleServerError from "../../helpers/handleServerError";

const useStyles = makeStyles({
  root: {
    padding: "1rem 2.5rem",
  },
});

interface DispatchCRUDFormState {
  formTitle: string;
  formButton1Action: string;
  formButton2Action: Function;
  dispatch: DispatchVM;
  formAction: Function;
}

const Dispatch = (props: any) => {
  const dateTimeFormat = new Intl.DateTimeFormat("en-US", {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
  const { locationIdFromLocationView, dispatchIdFromLocationView } = props
    .location.state
    ? props.location.state
    : "nothing";
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  //dispatch client is declared here to be reused across different functions without needing to initialize separately for each function
  const dispatchClient = new DispatchClient();
  const customersClient = new CustomerClient();
  const regionClient = new RegionClient();

  const [regions, setRegions] = useState([]);

  async function getRegions() {
    try {
      const regions = await regionClient.get();
      setRegions(regions);
    } catch (error) {
      enqueueSnackbar("Error while getting regions.", { variant: "error" });
    }
  }

  const classes = useStyles();

  const addNewDispatch = async (props: DispatchCreateVM) => {
    try {
      const dispatch = await dispatchClient.post(
        new DispatchCreateVM({
          ...props,
        })
      );
      clearFields();
      getDispatches();

      enqueueSnackbar("Successfully added dispatch.", { variant: "success" });
      setDispatchForEditing(dispatch.id);
    } catch (error) {
      handleServerError(error, enqueueSnackbar);
      redirectIfSessionExpired(history, error);
    }
  };

  const editDispatch = async (props: DispatchUpdateVM) => {
    try {
      const dispatch = await dispatchClient.put(
        new DispatchUpdateVM({
          ...props,
        })
      );
      await getDispatches();

      enqueueSnackbar("Dispatch updated successfully", { variant: "success" });
      setDispatchForEditing(dispatch.id);
    } catch (error) {
      handleServerError(error, enqueueSnackbar);
      redirectIfSessionExpired(history, error);
    }
  };

  const [state, setState] = useReducer(
    (state: DispatchCRUDFormState, newState: DispatchCRUDFormState) => ({
      ...state,
      ...newState,
    }),
    {
      dispatch: new DispatchVM({
        id: null,
        locationId: null,
        priorityId: null,
        tradeId: null,
        workOrderNumber: null,
        slaDate: null,
        tehnicianId: null,
        regionId: null,
        followUpDate: null,
        completedDate: null,
        statusId: null,
        secondaryStatusId: null,
      }),
      formTitle: "ADD DISPATCH",
      formButton1Action: "Add",
      formAction: addNewDispatch,
      formButton2Action: editDispatch,
    }
  );

  const [trades, setTrades] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [secondaryStatuses, setSecondaryStatuses] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const [locations, setLocations] = useState([]);
  const [users, setUsers] = useState([]);
  const [dispatches, setDispatches] = useState([]);
  const [serviceRequestCodes, setServiceRequestCodes] = useState([]);
  const [tableIsLoading, setTableLoading] = useState(true);
  const [idForSych, setIdForSync] = useState(0);

  const getServiceRequestCodes = async () => {
    try {
      const serviceRequestCodesClient = new ServiceRequestCodeClient();
      const serviceRequestCodes = await serviceRequestCodesClient.get();
      setServiceRequestCodes(serviceRequestCodes);
    } catch (error) {
      redirectIfSessionExpired(history, error);
    }
  };

  async function getTrades() {
    try {
      const tradesClient = new TradeClient();
      const trades = await tradesClient.getAll();
      trades.push(
        new TradeVM({
          id: 0,
          name: "N/A",
          hexCode: "BCBCBC",
        })
      );
      setTrades(trades);
    } catch (error) {
      redirectIfSessionExpired(history, error);
    }
  }

  async function getStatuses() {
    try {
      const statusesClient = new DispatchStatusClient();
      const statuses = await statusesClient.getAll();
      setStatuses(statuses);
    } catch (error) {
      redirectIfSessionExpired(history, error);
    }
  }

  async function getSecondaryStatuses() {
    try {
      const statusesClient = new DispatchSecondaryStatusClient();
      const statuses = await statusesClient.getAll();
      setSecondaryStatuses(statuses);
    } catch (error) {
      redirectIfSessionExpired(history, error);
    }
  }

  async function getCustomers() {
    try {
      const customers = await customersClient.getCustomersBase();
      setCustomers(customers);
    } catch (error) {
      redirectIfSessionExpired(history, error);
    }
  }

  async function getLocations() {
    try {
      const locationsClient = new LocationClient();
      const locations = await locationsClient.getLocationsDispatchHq();
      setLocations(locations);
    } catch (error) {
      redirectIfSessionExpired(history, error);
    }
  }

  async function getPriorities() {
    try {
      const prioritiesClient = new PriorityClient();
      const priorities = await prioritiesClient.get();
      priorities.push(
        new PriorityTypeVM({
          id: 0,
          name: "N/A",
          hexCode: "BCBCBC",
        })
      );
      setPriorities(priorities);
    } catch (error) {
      redirectIfSessionExpired(history, error);
    }
  }

  async function getUsers() {
    try {
      const usersClient = new UserClient();
      const users = await usersClient.usersByRole(UserRole.Technician);
      setUsers(users);
    } catch (error) {
      redirectIfSessionExpired(history, error);
    }
  }

  async function getDispatches() {
    try {
      const dispatches = await dispatchClient.getDispatchHistory();
      const formattedDispatches = dispatches.map((e: DispatchTableVM) => ({
        key: Number(e.id),
        id: Number(e.id),
        date: e.created ? dateTimeFormat.format(e.created) : "Unassigned",
        locationName: `${e.location.name} #${e.location.number}`,
        locationZip: e.location.zip,
        equipment:
          e.dispatchEquipments.length > 0
            ? e.dispatchEquipments.map((x) => x.equipment.model)
            : "Unassigned",
      }));
      setDispatches(formattedDispatches);
    } catch (error) {
      handleServerError(error, enqueueSnackbar);
      redirectIfSessionExpired(history, error);
    }
  }

  async function setDispatchForEditing(selectedDispatchId: number) {
    setIdForSync(selectedDispatchId);
    loadExistingDispatchIntoForm(selectedDispatchId);
  }

  async function loadExistingDispatchIntoForm(selectedDispatchId: number) {
    //set state of existing dispatch attributes to be loaded into the form
    const selectedDispatch = await dispatchClient.getById(selectedDispatchId);
    setState({
      ...state,
      dispatch: new DispatchVM({
        id: selectedDispatch.id,
        locationId: selectedDispatch.locationId,
        location: selectedDispatch.location,
        priorityId: selectedDispatch.priorityId,
        tradeId: selectedDispatch.tradeId,
        workOrderNumber: selectedDispatch.workOrderNumber,
        slaDate: selectedDispatch.slaDate,
        tehnicianId: selectedDispatch.tehnicianId,
        regionId: selectedDispatch.regionId,
        region: selectedDispatch.region,
        followUpDate: selectedDispatch.followUpDate,
        completedDate: selectedDispatch.completedDate,
        statusId: selectedDispatch.statusId,
        secondaryStatusId: selectedDispatch.secondaryStatusId,
        serviceRequestCodeId: selectedDispatch.serviceRequestCodeId,
        serviceRequestCode: selectedDispatch.serviceRequestCode,
      }),
      formTitle: "Edit dispatch",
      formButton1Action: "Clear",
    });
  }

  async function setDispatchForViewing(selectedDispatchId: number) {
    setIdForSync(selectedDispatchId);
    loadViewDispatchIntoForm(selectedDispatchId);
  }

  async function loadViewDispatchIntoForm(selectedDispatchId: number) {
    const selectedDispatch = await dispatchClient.getById(selectedDispatchId);
    setState({
      ...state,
      dispatch: new DispatchVM({
        id: selectedDispatch.id,
        locationId: selectedDispatch.locationId,
        priorityId: selectedDispatch.priorityId,
        tradeId: selectedDispatch.tradeId,
        workOrderNumber: selectedDispatch.workOrderNumber,
        slaDate: selectedDispatch.slaDate,
        tehnicianId: selectedDispatch.tehnicianId,
        regionId: selectedDispatch.regionId,
        region: selectedDispatch.region,
        followUpDate: selectedDispatch.followUpDate,
        completedDate: selectedDispatch.completedDate,
        statusId: selectedDispatch.statusId,
      }),
      formTitle: "View dispatch",
      formButton1Action: "Clear",
    });
  }

  async function deleteDispatch(selectedDispatchId: number) {
    try {
      clearFields();
      await dispatchClient.delete(selectedDispatchId);

      //implicitly refreshes the table by setting the new state
      getDispatches();
      enqueueSnackbar("Dispatch deleted successfully.", { variant: "success" });
    } catch (error) {
      handleServerError(error, enqueueSnackbar);
      redirectIfSessionExpired(history, error);
    }
  }

  useEffect(() => {
    const fetchDataAsync = async () => {
      setTableLoading(true);
      await getDispatches();
      await getLocations();
      await getCustomers();
      getPriorities();
      getTrades();
      getStatuses();
      getSecondaryStatuses();
      getUsers();
      getRegions();
      getServiceRequestCodes();
      setTableLoading(false);
    };
    fetchDataAsync();

    if (dispatchIdFromLocationView) {
      setDispatchForViewing(dispatchIdFromLocationView);
    }
  }, []);

  function clearFields() {
    setIdForSync(0);
    setState({
      ...state,
      dispatch: new DispatchVM({
        id: null,
        locationId: null,
        priorityId: null,
        tradeId: null,
        workOrderNumber: null,
        slaDate: null,
        tehnicianId: null,
        regionId: null,
        followUpDate: null,
        statusId: null,
        secondaryStatusId: null,
      }),
      formTitle: "ADD DISPATCH",
      formButton1Action: "Add",
    });
  }

  const syncDispatchData = async () => {
    if (idForSych !== 0) {
      setDispatchForEditing(idForSych);
    }
    getDispatches();
  };

  const openDispatchVisits = async (dispatchId: number) => {
    setVisitsSignaturesModal({ open: true, dispatchId: dispatchId });
  };

  const [visitsSignaturesModal, setVisitsSignaturesModal] = useState({
    open: false,
    dispatchId: 0,
  });

  const visitsSignaturesModalBody = (
    <>
      <Visits
        setModalState={setVisitsSignaturesModal}
        dispatchId={visitsSignaturesModal.dispatchId}
        technicians={users}
      />
    </>
  );

  return (
    <div className="dispatch-container">
      <Grid container classes={{ root: classes.root }}>
        <Grid item container xs={12} md={6}>
          {/* Dispatch form */}
          {state.formTitle != "View dispatch" && (
            <DispatchCRUDForm
              locationId={locationIdFromLocationView}
              trades={trades}
              statuses={statuses}
              secondaryStatuses={secondaryStatuses}
              customers={customers}
              locations={locations}
              priorities={priorities}
              technicians={users}
              regions={regions}
              serviceRequestCodes={serviceRequestCodes}
              dispatch={state.dispatch}
              formAction={state.formAction}
              formTitle={state.formTitle}
              clearFields={clearFields}
              formButton1Action={state.formButton1Action}
              formButton2Action={editDispatch}
            />
          )}
          {/* Dispatch form end */}
          {
            /* Dispatch view form */
            state.formTitle == "View dispatch" && (
              <DispatchForm
                dispatchId={state.dispatch.id}
                setDispatchForEditing={setDispatchForEditing}
                clearFields={clearFields}
                locationId={state.dispatch.locationId}
                technicians={users}
              />
            )
          }
          {/* Dispatch view form end */}
        </Grid>
        <Grid item container xs={12} md={6}>
          {/* Dispatch history section place */}
          <DispatchHistory
            syncBoardData={syncDispatchData}
            dispatches={dispatches}
            setDispatchForEditing={setDispatchForEditing}
            setDispatchForViewing={setDispatchForViewing}
            openDispatchVisits={openDispatchVisits}
            deleteDispatch={deleteDispatch}
            isLoading={tableIsLoading}
          />
        </Grid>
      </Grid>
      {visitsSignaturesModal && (
        <FormDialog
          open={visitsSignaturesModal.open}
          body={visitsSignaturesModalBody}
        />
      )}
    </div>
  );
};

export default Dispatch;
