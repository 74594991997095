import React, { useState, useEffect, useReducer } from "react";
import { Grid, Paper } from "@material-ui/core";
import BasicTable from "../../components/common/table/BasicTable";
import {
  DispatchTableVM,
  DispatchClient,
  TradeClient,
  TradeVM,
  DispatchStatusClient,
  CustomerClient,
  LocationClient,
  PriorityClient,
  PriorityTypeVM,
  UserClient,
  ServiceRequestCodeClient,
  DispatchVM,
  DispatchUpdateVM,
  InvoiceClient,
  DispatchSecondaryStatusClient,
} from "../../brines-refrigerator-api";
import Edit from "@material-ui/icons/Edit";
import DispatchBoardCRUDForm from "../Board/DispatchBoardCRUDForm/DispatchBoardCRUDForm";
import { useHistory } from "react-router-dom";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import Visits from "../../components/dispatch/visitsModal/visits";
import FormDialog from "../../components/common/dialog/FormDialog";
import ConfirmDialog from "../../components/common/dialog/ConfirmationDialog";
import { useSnackbar } from "notistack";
import UserRole from "../../helpers/constants/userRole";

interface DispatchBoardCRUDFormState {
  dispatch: DispatchVM;
}

export default function CompletedDispatchesView() {
  const history = useHistory();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const dispatchClient = new DispatchClient();

  const [trades, setTrades] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [secondaryStatuses, setSecondaryStatuses] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const [locations, setLocations] = useState([]);
  const [users, setUsers] = useState([]);
  const [serviceRequestCodes, setServiceRequestCodes] = useState([]);

  const [dispatches, setDispatches] = useState([]);
  const dateTimeFormat = new Intl.DateTimeFormat("en-US", {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
  const [tableIsLoading, setTableLoading] = useState(true);

  const redirectToInvoice = async (dispatchId: number) => {
    const invoiceClient = new InvoiceClient();
    const invoice = await invoiceClient.post(dispatchId);
    history.push({
      pathname: "/invoice",
      state: { invoiceId: invoice.id },
    });
  };

  const [state, setState] = useReducer(
    (
      state: DispatchBoardCRUDFormState,
      newState: DispatchBoardCRUDFormState
    ) => ({ ...state, ...newState }),
    {
      dispatch: new DispatchVM({
        id: null,
        created: null,
        locationId: null,
        priorityId: null,
        tradeId: null,
        workOrderNumber: null,
        slaDate: null,
        tehnicianId: null,
        followUpDate: null,
        statusId: null,
        secondaryStatusId: null,
      }),
    }
  );

  async function getTrades() {
    const tradesClient = new TradeClient();
    const trades = await tradesClient.getAll();
    trades.push(
      new TradeVM({
        id: 0,
        name: "N/A",
        hexCode: "BCBCBC",
      })
    );
    setTrades(trades);
  }

  async function getStatuses() {
    const statusesClient = new DispatchStatusClient();
    const statuses = await statusesClient.getAll();
    setStatuses(statuses);
  }

  async function getSecondaryStatuses() {
    const statusesClient = new DispatchSecondaryStatusClient();
    const statuses = await statusesClient.getAll();
    setSecondaryStatuses(statuses);
  }

  async function getCustomers() {
    const customersClient = new CustomerClient();
    const customers = await customersClient.getCustomersBase();
    setCustomers(customers);
  }

  async function getLocations() {
    const locationsClient = new LocationClient();
    const locations = await locationsClient.getLocationsDispatchHq();
    setLocations(locations);
  }

  async function getPriorities() {
    const prioritiesClient = new PriorityClient();
    const priorities = await prioritiesClient.get();
    priorities.push(
      new PriorityTypeVM({
        id: 0,
        name: "N/A",
        hexCode: "BCBCBC",
      })
    );
    setPriorities(priorities);
  }

  async function getUsers() {
    const usersClient = new UserClient();
    const users = await usersClient.usersByRole(UserRole.Technician);
    setUsers(users);
  }

  const getServiceRequestCodes = async () => {
    const serviceRequestCodesClient = new ServiceRequestCodeClient();
    const serviceRequestCodes = await serviceRequestCodesClient.get();
    setServiceRequestCodes(serviceRequestCodes);
  };

  const populateCRUDForm = async () => {
    setTableLoading(true);
    await getTrades();
    await getStatuses();
    await getSecondaryStatuses();
    await getCustomers();
    await getLocations();
    await getPriorities();
    await getUsers();
    await getServiceRequestCodes();
    setTableLoading(false);
  };

  useEffect(() => {
    populateCRUDForm();
  }, []);

  const setDispatchForEditing = async (dispatchId: number) => {
    const selectedDispatch = await dispatchClient.getById(dispatchId);
    setState({
      ...state,
      dispatch: new DispatchVM({
        id: selectedDispatch.id,
        locationId: selectedDispatch.locationId,
        created: selectedDispatch.created,
        priorityId: selectedDispatch.priorityId,
        tradeId: selectedDispatch.tradeId,
        workOrderNumber: selectedDispatch.workOrderNumber,
        slaDate: selectedDispatch.slaDate,
        tehnicianId: selectedDispatch.tehnicianId,
        followUpDate: selectedDispatch.followUpDate,
        completedDate: selectedDispatch.completedDate,
        statusId: selectedDispatch.statusId,
        secondaryStatusId: selectedDispatch.secondaryStatusId,
      }),
    });
  };

  const updateDispatch = async (props: DispatchUpdateVM) => {
    try {
      const dispatch = await dispatchClient.put(
        new DispatchUpdateVM({
          ...props,
        })
      );
      await setDispatchForEditing(dispatch.id);
      getDispatches();
      enqueueSnackbar("Dispatch updated successfully.", { variant: "success" });
    } catch (err) {
      enqueueSnackbar("Error updating dispatch.", { variant: "error" });
    }
  };

  async function getDispatches() {
    try {
      const dispatches = await dispatchClient.get(
        undefined,
        [7, 8],
        undefined,
        undefined
      );
      const formattedDispatches = dispatches.map((e: DispatchTableVM) => ({
        key: Number(e.id),
        id: Number(e.id),
        date: e.created ? dateTimeFormat.format(e.created) : "Unassigned",
        locationName: `${e.location.name} #${e.location.number}`,
        locationZip: e.location.zip,
        equipment:
          e.dispatchEquipments.length > 0
            ? e.dispatchEquipments.map((x) => x.equipment.model)
            : "Unassigned",
      }));
      setDispatches(formattedDispatches);
    } catch (error) {
      enqueueSnackbar("Error getting dispatches.", { variant: "error" });
    }
  }

  useEffect(() => {
    const fetchDataAsync = async () => {
      await getDispatches();
    };
    fetchDataAsync();
  }, []);

  const openDispatchVisits = async (dispatchId: number) => {
    setVisitsSignaturesModal({ open: true, dispatchId: dispatchId });
  };

  const [visitsSignaturesModal, setVisitsSignaturesModal] = useState({
    open: false,
    dispatchId: 0,
  });

  const visitsSignaturesModalBody = (
    <>
      <Visits
        setModalState={setVisitsSignaturesModal}
        dispatchId={visitsSignaturesModal.dispatchId}
        technicians={users}
      />
    </>
  );

  const completedDispatchColums = [
    { title: "Id", field: "id" },
    { title: "Date", field: "date" },
    { title: "Location", field: "locationName" },
    { title: "Zip", field: "locationZip" },
    { title: "Equipment", field: "equipment" },
  ];

  const tableActions = [
    (rowData) => ({
      icon: () => <DriveEtaIcon color="primary" />,
      onClick: (event, rowData: unknown) => {
        openDispatchVisits((rowData as { id: number }).id);
      },
      tooltip: "View Visits",
    }),
    (rowData) => ({
      icon: () => <Edit color="primary" />,
      onClick: (event, rowData: unknown) => {
        setDispatchForEditing((rowData as { id: number }).id);
      },
      tooltip: "Edit Dispatch",
    }),
  ];

  const clearFields = () => {
    setState({
      ...state,
      dispatch: new DispatchVM({
        id: null,
        locationId: null,
        priorityId: null,
        tradeId: null,
        workOrderNumber: null,
        slaDate: null,
        tehnicianId: null,
        followUpDate: null,
        statusId: null,
        secondaryStatusId: null,
      }),
    });
  };

  return (
    <div>
      <Grid container>
        <Grid item sm={6}>
          <BasicTable
            width={"100%"}
            title={"Completed Dispatches"}
            data={dispatches as []}
            components={{
              Container: (props) => <Paper {...props} elevation={0} />,
            }}
            columns={completedDispatchColums}
            paging={true}
            isLoading={tableIsLoading}
            actions={tableActions}
          />
        </Grid>

        <Grid item sm={6}>
          <DispatchBoardCRUDForm
            trades={trades}
            statuses={statuses}
            secondaryStatuses={secondaryStatuses}
            customers={customers}
            locations={locations}
            priorities={priorities}
            technicians={users}
            serviceRequestCodes={serviceRequestCodes}
            dispatch={state.dispatch}
            formAction={updateDispatch}
            clearFields={clearFields}
            showGenerateInvoiceButton={true}
            redirectToInvoice={redirectToInvoice}
          />
        </Grid>
      </Grid>
      {visitsSignaturesModal && (
        <FormDialog
          open={visitsSignaturesModal.open}
          body={visitsSignaturesModalBody}
        />
      )}
    </div>
  );
}
