import React, { forwardRef } from 'react';
import MaterialTable from 'material-table';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import Clear from '@material-ui/icons/Clear';
import Check from '@material-ui/icons/Check';
import AddBox from '@material-ui/icons/AddBox';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Search from '@material-ui/icons/Search';

import { Icons } from 'material-table';
import { SvgIconProps } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';

interface TableProps {
    width: string,
    columns: { title: string; field: string; }[],
    title: string,
    add: Function,
    delete: Function,
    data: [],
    search: boolean,
    localization?: {}
    deleteTooltip?: string
}

export default function AddTable(props: TableProps) {
    const tableIcons: Icons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} htmlColor="#3f51b5" />),
        Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} htmlColor="#3f51b5" />),
        Edit: forwardRef((props: SvgIconProps, ref) => <Edit {...props} ref={ref} htmlColor="#3f51b5" />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    }
    return (
        <div style={{ width: props.width, border: '1px' }}>
            <MaterialTable
                icons={tableIcons}
                title={props.title}
                columns={props.columns}
                data={props.data}
                editable={{
                    onRowAdd: (newData) =>
                        new Promise((resolve) => {
                            props.add(newData);
                            resolve();
                        }),
                }}
                actions={[
                    {
                        tooltip: props.deleteTooltip,
                        icon: () => <Delete color='primary' />,
                        onClick: (event, rowData: unknown) => props.delete((rowData as { id: number }).id)
                    }
                ]}
                options={{
                    actionsColumnIndex: -1,
                    search: props.search,
                }}
                localization={{
                    body: props.localization
                }}
            />
        </div>
    );
}
