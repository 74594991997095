import {
  Box,
  Button,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import {
  DispatchClient,
  DispatchDocumentsClient,
  DispatchNotesClient,
  EquipmentClient,
  InternalNotesClient,
  PartClient,
  PartPriceVM,
  PartVM,
  ServiceNotesClient,
  TeamsClient,
  UserClient,
  UserVM,
} from "../../../brines-refrigerator-api";
import NoteTempVM from "../../../components/common/note/NoteTempVM";
import { useSnackbar } from "notistack";
import DispatchNotesPreview from "../../../components/common/note/DispatchNotesPreview";
import DispatchLaborPreview from "../DispatchLabor/DispatchLaborPreview";
import DispatchPartsPreview from "../DispatchParts/DispatchPartsPreview";
import DispatchEquipmentPreview from "../DispatchEquipment/DispatchEquipmentPreview";
import DispatchDocumentsPreview from "../DispatchDocuments/DispatchDocumentsPreview";
import handleServerError from "../../../helpers/handleServerError";
import { DispatchDocumentAddVM } from "../../../brines-refrigerator-api-extended";
import { redirectIfSessionExpired } from "../../../components/common/redirect/RedirectOnSessionTimeout";
import UserRole from "../../../helpers/constants/userRole";
import { useHistory } from "react-router-dom";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

interface DispatchFormProps {
  dispatchId: number;
  locationId: number;
  setDispatchForEditing: Function;
  clearFields: Function;
  technicians: UserVM[];
}

const useStyles = makeStyles({
  button: {
    marginBottom: "1em",
  },
  boxScroll: {
    overflowY: "auto",
    overflowX: "hidden",
  },
  partTable: {
    width: "60rem",
    height: "30rem",
  },
});

const DispatchForm = (props: DispatchFormProps) => {
  const history = useHistory();

  const options = {
    year: "numeric",
    day: "numeric",
    month: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  const slaDateTimeFormat = new Intl.DateTimeFormat("en-US", options);
  const dateTimeFormat = new Intl.DateTimeFormat("en-US", {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [dispatch, setDispatch] = useState(null);
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = async (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    setTabValue(newValue);
  };

  const [dispatchNotes, setDispatchNotes] = useState([]);

  async function getDispatchNotes() {
    try {
      const notesClient = new DispatchNotesClient();
      const dispatchNotes = await notesClient.get(props.dispatchId, true);
      const modifiedDispatchNotes = dispatchNotes.map(
        (x) =>
          new NoteTempVM(
            x.id,
            x.created,
            x.createdBy,
            x.lastModified,
            x.lastModifiedBy,
            x.text,
            false,
            null,
            null,
            x.history.map(
              (x) =>
                new NoteTempVM(
                  x.id,
                  x.createdCopy,
                  x.createdByCopy,
                  x.lastModifiedCopy,
                  x.lastModifiedByCopy,
                  x.text,
                  false,
                  ""
                )
            )
          )
      );
      setDispatchNotes(modifiedDispatchNotes);
    } catch (error) {
      handleServerError(error, enqueueSnackbar);
    }
  }

  const [serviceNotes, setServiceNotes] = useState([]);

  async function getServiceNotes() {
    try {
      const serviceNotesClient = new ServiceNotesClient();
      const serviceNotes = await serviceNotesClient.get(props.dispatchId, true);
      const modifiedServiceNotes = serviceNotes.map(
        (x) =>
          new NoteTempVM(
            x.id,
            x.created,
            x.createdBy,
            x.lastModified,
            x.lastModifiedBy,
            x.text,
            false,
            null,
            null,
            x.history.map(
              (x) =>
                new NoteTempVM(
                  x.id,
                  x.createdCopy,
                  x.createdByCopy,
                  x.lastModifiedCopy,
                  x.lastModifiedByCopy,
                  x.text,
                  false,
                  ""
                )
            )
          )
      );
      setServiceNotes(modifiedServiceNotes);
    } catch (error) {
      handleServerError(error, enqueueSnackbar);
    }
  }

  const [internalNotes, setInternalNotes] = useState([]);

  async function getInternalNotes() {
    try {
      const internalNotesClient = new InternalNotesClient();
      const internalNotes = await internalNotesClient.get(
        props.dispatchId,
        true
      );
      const modifiedInternalNotes = internalNotes.map(
        (x) =>
          new NoteTempVM(
            x.id,
            x.created,
            x.createdBy,
            x.lastModified,
            x.lastModifiedBy,
            x.text,
            false,
            null,
            null,
            x.history.map(
              (x) =>
                new NoteTempVM(
                  x.id,
                  x.createdCopy,
                  x.createdByCopy,
                  x.lastModifiedCopy,
                  x.lastModifiedByCopy,
                  x.text,
                  false,
                  ""
                )
            )
          )
      );
      setInternalNotes(modifiedInternalNotes);
    } catch (error) {
      handleServerError(error, enqueueSnackbar);
    }
  }

  const [dispatchTableParts, setDispatchParts] = useState([]);

  async function getDispatchParts(id: number) {
    try {
      const partsClient = new PartClient();
      const dispatchParts = await partsClient.getDispatchParts(id);
      const modifiedParts = dispatchParts.map(
        (e) =>
          new PartPriceVM({
            ...e.part,
            price: e.partPrice,
            number: e.partNumber,
            quantity: e.partQuantity,
          })
      );
      setDispatchParts(modifiedParts);
    } catch (error) {
      handleServerError(error, enqueueSnackbar);
    }
  }

  const [dispatchEquipment, setDispatchEquipment] = useState([]);

  const getDispatchEquipment = async () => {
    try {
      const dispatchEquipmentClient = new EquipmentClient();
      const equipment = await dispatchEquipmentClient.getDispatchEquipment(
        props.dispatchId
      );
      const modifiedEquipment = equipment.map((elem) => elem.equipment);
      setDispatchEquipment(modifiedEquipment);
    } catch (error) {
      handleServerError(error, enqueueSnackbar);
    }
  };

  const [dispatchDocuments, setDispatchDocuments] = useState([]);

  //represents VM for both draft and non-draft documents
  interface IDispatchDocumentTempVM {
    dispatchId: number;
    id: string;
    name: string;
    type: string;
    relativePath: string;
    isConfidential: boolean;
    draft: boolean;
    modified: boolean;
    uid: string;
  }

  class DispatchDocumentTempVM implements IDispatchDocumentTempVM {
    dispatchId: number;
    id: string; //string so we can directly use this field to display draft when needed
    name: string;
    type: string;
    relativePath: string;
    isConfidential: boolean;
    draft: boolean;
    modified: boolean;
    uid: string;
    document?: DispatchDocumentAddVM;

    constructor(
      dispatchId,
      id,
      name,
      type,
      path,
      isConfidential,
      draft,
      modified,
      uid,
      document = null
    ) {
      this.dispatchId = dispatchId;
      this.id = id;
      this.name = name;
      this.type = type;
      this.relativePath = path;
      this.isConfidential = isConfidential;
      this.draft = draft;
      this.modified = modified;
      this.uid = uid;
      this.document = document;
    }
  }

  const getDispatchDocuments = async (dispatchId: number) => {
    const documentClient = new DispatchDocumentsClient();
    const files = await documentClient.getDispatchDocuments(dispatchId);
    const modifiedFiles = files.map((x) => {
      return new DispatchDocumentTempVM(
        x.dispatchId,
        x.documentFile.id,
        x.documentFile.name,
        x.documentFile.type.extension,
        x.documentFile.relativePath,
        x.documentFile.isConfidential,
        false,
        false,
        null
      );
    });

    setDispatchDocuments(modifiedFiles);
  };

  useEffect(() => {
    fetchDispatchData();
    getDispatchNotes();
    getServiceNotes();
    getInternalNotes();
    getDispatchParts(props.dispatchId);
    getDispatchEquipment();
    getDispatchDocuments(props.dispatchId);
  }, [props.dispatchId]);

  const [teamName, setTeamName] = useState(" ");

  const fetchDispatchData = async () => {
    const dispatchClient = new DispatchClient();
    const dispatch = await dispatchClient.getById(props.dispatchId);
    setDispatch(dispatch);
    const teamClient = new TeamsClient();
    const team = await teamClient.getByCustomerId(dispatch.location.customerId);
    setTeamName(team.name);
  };

  const [technicians, setTechnicians] = useState([]);
  async function getTechnicians() {
    try {
      const usersClient = new UserClient();
      const users = await usersClient.usersByRole(UserRole.Technician);
      setTechnicians(users);
    } catch (error) {
      redirectIfSessionExpired(history, error);
    }
  }

  const ListComponent: React.FC<{
    backgroundColor: string;
    text?: string;
    textColor?: string;
  }> = ({ backgroundColor, text, textColor }) => (
    <div
      className="priority_and_trades_form_add_data_container_pick_color_text"
      style={{
        backgroundColor,
        width: "100%",
        height: 38,
        color: textColor,
      }}
    >
      {text}
    </div>
  );

  return (
    <Grid className="dispatch-crud-form" container>
      <Grid
        container
        xs={12}
        item
        className={`customer-location disabled`}
        spacing={1}
      >
        <Grid item xs={12} lg={2}>
          <Typography variant="h2">View dispatch</Typography>
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            label="Customer"
            variant="outlined"
            disabled={true}
            value={dispatch ? dispatch.location.customer.company : ""}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            label="Location"
            variant="outlined"
            value={
              dispatch
                ? `${dispatch.location.name} #${dispatch.location.number}, ${dispatch.location.city}, ${dispatch.location.state.name}`
                : ""
            }
            disabled={true}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} lg={2}>
          <Button
            endIcon={<CancelOutlinedIcon />}
            color="primary"
            variant="outlined"
            disableElevation
            fullWidth
            onClick={() => props.clearFields()}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        xs={12}
        item
        alignItems="center"
        className={`customer-location disabled`}
        spacing={1}
        style={{ marginTop: "2rem" }}
      >
        <Grid item xs={12} lg={2}>
          <TextField
            name="ivr"
            label="IVR"
            value={dispatch ? dispatch.location.ivr || " " : " "}
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
          />
        </Grid>
        <Grid item xs={12} lg={2}>
          <TextField
            name="ivr"
            label="IVR Pin"
            value={dispatch ? dispatch.location.ivrPin || " " : " "}
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            name="ivr"
            label="EMS"
            value={dispatch ? dispatch.location.emsNumber || " " : " "}
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            name="team"
            label="Team"
            value={teamName}
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
          />
        </Grid>
      </Grid>
      <Grid xs={12} spacing={1} item container className="line"></Grid>
      <Grid
        container
        spacing={4}
        className={`${dispatch ? "" : "form-disabled"}`}
      >
        <Grid
          item
          container
          xs={12}
          lg={4}
          alignContent="flex-start"
          className="dispatch-details"
        >
          <Grid item xs={10} className="dispatch-details-title">
            <Typography variant="h3">{`Dispatch ${
              props.dispatchId ? `#${props.dispatchId}` : ""
            } details`}</Typography>
            <Button
              color="primary"
              variant="contained"
              className="btn-edit-dispatch"
              onClick={() => {
                navigator.clipboard.writeText(
                  dispatch
                    ? `${dispatch.location.addressLine1}, ${dispatch.location.city}, ${dispatch.location.state.name}, ${dispatch.location.zip}`
                    : ""
                );
              }}
            >
              Address copy
            </Button>
          </Grid>
          <Grid
            item
            justify="space-between"
            container
            xs={12}
            spacing={1}
            className="dispatch-details-inputs"
          >
            <Grid item xs={6}>
              Priority
              <ListComponent
                backgroundColor={`#${
                  dispatch
                    ? dispatch.priority
                      ? dispatch.priority.hexCode
                      : null
                    : null
                }`}
              />
            </Grid>
            <Grid item xs={6}>
              Class
              <ListComponent
                backgroundColor={`#${
                  dispatch
                    ? dispatch.trade
                      ? dispatch.trade.hexCode
                      : null
                    : null
                }`}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="wo_number"
                fullWidth
                label="WO#"
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
                value={
                  dispatch
                    ? dispatch.workOrderNumber
                      ? dispatch.workOrderNumber
                      : " "
                    : " "
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="technician"
                label="Technician"
                fullWidth
                value={
                  dispatch
                    ? dispatch.tehnicianId
                      ? props.technicians.length > 0
                        ? props.technicians.find(
                            (technician) =>
                              technician.id === dispatch.tehnicianId
                          ).userName
                        : " "
                      : " "
                    : " "
                }
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="region"
                label="Region"
                fullWidth
                value={
                  dispatch
                    ? dispatch.regionId != 0 && dispatch.regionId != null
                      ? dispatch.region.name
                      : " "
                    : " "
                }
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <span className="date-created">
                Date created:{" "}
                {dispatch
                  ? dispatch.created
                    ? dateTimeFormat.format(dispatch.created)
                    : dateTimeFormat.format(new Date())
                  : dateTimeFormat.format(new Date())}
              </span>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="SLA Date"
                fullWidth
                value={
                  dispatch
                    ? dispatch.slaDate
                      ? slaDateTimeFormat.format(dispatch.slaDate)
                      : "MM/dd/yyyy"
                    : "MM/dd/yyyy"
                }
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Follow Up/Due Date"
                fullWidth
                value={
                  dispatch
                    ? dispatch.followUpDate
                      ? dateTimeFormat.format(dispatch.followUpDate)
                      : "MM/dd/yyyy"
                    : "MM/dd/yyyy"
                }
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Completed Date"
                fullWidth
                value={
                  dispatch
                    ? dispatch.completedDate
                      ? dateTimeFormat.format(dispatch.completedDate)
                      : "MM/dd/yyyy"
                    : "MM/dd/yyyy"
                }
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Status"
                fullWidth
                value={
                  dispatch
                    ? dispatch.statusId
                      ? dispatch.status.name
                      : "Unassigned"
                    : "Unassigned"
                }
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Secondary Status"
                fullWidth
                value={
                  dispatch
                    ? dispatch.secondaryStatusId
                      ? dispatch.secondaryStatus.name
                      : "Unassigned"
                    : "Unassigned"
                }
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
              />
            </Grid>
          </Grid>
          {/* src = service request codes */}
          <Grid item xs={12}>
            <Typography variant="h3">Service Request Codes</Typography>
            <Box>
              {dispatch ? (
                <TextField
                  fullWidth
                  multiline
                  value={`${
                    dispatch.serviceRequestCode
                      ? dispatch.serviceRequestCode.code
                      : ""
                  } ${
                    dispatch.serviceRequestCode
                      ? dispatch.serviceRequestCode.description
                      : "No SRC selected"
                  }`}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                />
              ) : (
                ""
              )}
            </Box>
          </Grid>
        </Grid>

        {/* the state id checks should be included in one variable so it doesn't need to repeatedly check for the same thing -- performance thing */}
        <Grid item container xs={12} lg={8} alignContent="flex-start">
          {/* Dispatch tabs */}
          <Grid item container xs={12} className={`tabs-container`}>
            <Grid
              item
              xs={12}
              className={`form-tabs ${dispatch ? "" : "disabled"}`}
            >
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab
                  label="Dispatch notes"
                  disabled={dispatch ? false : true}
                />
                <Tab label="Service notes" disabled={dispatch ? false : true} />
                <Tab
                  label="Internal notes"
                  disabled={dispatch ? false : true}
                />
                <Tab label="Equipment" disabled={dispatch ? false : true} />
                <Tab label="Parts" disabled={dispatch ? false : true} />
                <Tab label="Labor/Trip" disabled={dispatch ? false : true} />
                <Tab label="Documents" disabled={dispatch ? false : true} />
              </Tabs>
            </Grid>
            <Grid item className="form-tab-content">
              <TabPanel value={tabValue} index={0}>
                <Box className={classes.boxScroll}>
                  <DispatchNotesPreview notes={dispatchNotes} />
                </Box>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <Box className={classes.boxScroll}>
                  <DispatchNotesPreview notes={serviceNotes} />
                </Box>
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <Box className={classes.boxScroll}>
                  <DispatchNotesPreview notes={internalNotes} />
                </Box>
              </TabPanel>
              <TabPanel value={tabValue} index={3}>
                <DispatchEquipmentPreview equipment={dispatchEquipment} />
              </TabPanel>
              <TabPanel value={tabValue} index={4}>
                <DispatchPartsPreview dispatchTableParts={dispatchTableParts} />
              </TabPanel>
              <TabPanel value={tabValue} index={5}>
                <DispatchLaborPreview
                  dispatchId={props.dispatchId}
                  technicians={technicians}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={6}>
                <DispatchDocumentsPreview documents={dispatchDocuments} />
              </TabPanel>
            </Grid>
          </Grid>
          {/* Dispatch tabs end */}

          {/* <Grid item xs={12} className={`update-dispatch ${state.id ? 'update-dispatch_visible' : ''}`}>
                        <Button type='submit' color='primary' variant='contained' size='large' onClick={editDispatchHandler} disableElevation fullWidth>UPDATE DISPATCH</Button>
                    </Grid> */}
        </Grid>
      </Grid>
      <Button
        color="primary"
        variant="contained"
        className="btn-edit-dispatch"
        onClick={() => {
          props.setDispatchForEditing(props.dispatchId);
        }}
      >
        EDIT DISPATCH
      </Button>
    </Grid>
  );
};

export default DispatchForm;
