import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Typography, GridList, GridListTile } from '@material-ui/core';
import { UserBaseVM } from '../../../brines-refrigerator-api';
import CancelButton from '../../../components/common/dialog/CancelButton';

interface Note {
    id: number,
    created: Date,
    createdBy: UserBaseVM,
    createdById: number,
    text: string,
    draft: boolean,
    uid: string
}

interface NoteProps {
    notes: Note[],
    closeModal: Function,
}

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        marginBottom: {
            marginBottom: 16
        },
        rootList: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
        },
        gridList: {
            width: 500,
            height: 450,
        },
        section1: {
            margin: 16,
            height: "100%",
            backgroundColor: "rgb(245, 245, 245)"
        },
        note: {
            marginTop: "5%"
        }
    }),
);


export default function EquipmentNotes(props: NoteProps) {
    const options = { year: 'numeric', day: 'numeric', month: 'numeric', hour: 'numeric', minute: 'numeric', timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone };
    const dateTimeFormat = new Intl.DateTimeFormat('en-US', options);
    const classes = useStyles();

    // Get user data from session storage
    const userData: any = JSON.parse(sessionStorage.getItem('userData') || '{}');

    // Map user roles
    const role = userData.role.name;

    return (
        <div className={classes.root}>
            <Grid container item xs={12} spacing={0}>
                <Grid container item xs={12}>
                    <Paper elevation={0}>
                        <Grid item container >
                            <Grid item xs={8}>
                                <Typography variant="h6" className={classes.marginBottom}>
                                    Notes
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <CancelButton
                                    onClick={props.closeModal}
                                />
                            </Grid>
                        </Grid>

                        <Grid item className={classes.marginBottom}>
                            <Paper variant="outlined" square >
                                <div className={classes.rootList}>
                                    <GridList cellHeight={160} className={classes.gridList}>
                                        {props.notes.map((tile) => (
                                            <GridListTile key={tile.uid} style={{ width: "100%" }} >
                                                <div className={classes.section1}>
                                                    <Grid container alignItems="center" >
                                                        <Grid container item xs>
                                                            <Grid item xs={8}>
                                                                <Typography gutterBottom>Added at:  {dateTimeFormat.format(tile.created)}</Typography>
                                                            </Grid>
                                                            {role.includes('Admin') &&
                                                                <Grid item xs={4}>
                                                                </Grid>
                                                            }
                                                            <Typography gutterBottom>
                                                                Added By:  {tile.draft ? 'DRAFT' : tile.createdBy.email}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Typography className={classes.note} variant="body2">
                                                        {tile.text}
                                                    </Typography>
                                                </div>
                                            </GridListTile>
                                        ))}
                                    </GridList>
                                </div>
                            </Paper>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
};