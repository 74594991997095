import React, { ChangeEventHandler } from "react";
import MaterialTable, { Action, Components } from "material-table";
import tableIcons from "./tableIcons";
import { TablePagination } from "@material-ui/core";

interface TableProps {
  width?: string;
  columns: { title: string; field: string }[];
  title?: string;
  search?: boolean;
  data: [];
  actions?: (Action<object> | ((rowData: object) => Action<object>))[];
  selection?: boolean;
  components?: Components;
  onSelectionChange?: Function;
  paging: boolean;
  isCustomPagination?: boolean;
  isLoading: boolean;
  sorting?: boolean;
  pageSize?: number;
  fontSize?: number;
  count?: number;
  page?: number;
  onChangePage?: any;
  rowsPerPage?: number;
  onChangeRowsPerPage?: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
  tableRef?: any;
}

export default function BasicTable(props: TableProps) {
  return (
    <div style={{ width: props.width }}>
      <MaterialTable
        tableRef={props.tableRef}
        icons={tableIcons}
        title={props.title}
        columns={props.columns}
        data={props.data}
        actions={props.actions}
        options={{
          actionsColumnIndex: -1,
          search: props.search,
          selection: props.selection,
          paging: props.paging,
          sorting: props.sorting,
          pageSize: props.rowsPerPage ? props.rowsPerPage : 10,
          rowStyle: {
            fontSize: props.fontSize ? props.fontSize : 16,
          },
        }}
        components={
          props.isCustomPagination
            ? {
                ...props.components,
                Pagination: () => (
                  <TablePagination
                    component="div"
                    count={props.count}
                    page={props.page}
                    onChangePage={props.onChangePage}
                    rowsPerPage={props.rowsPerPage}
                    onChangeRowsPerPage={props.onChangeRowsPerPage}
                  />
                ),
              }
            : props.components
        }
        onSelectionChange={(rows) => props.onSelectionChange(rows)}
        isLoading={props.isLoading}
      />
    </div>
  );
}
