interface IInvoicePartTempVM {
    uid: string,
    id: number,
    manufacturer: string,
    model: string,
    description: string,
    number: string,
    quantity: number,
    price: number,
    multiplier: number
    subtotal: number
}

class InvoicePartTempVM implements IInvoicePartTempVM {
    uid: string;
    id: number;
    manufacturer: string;
    model: string;
    description: string;
    number: string;
    quantity: number;
    price: number;
    multiplier: number;
    subtotal: number;

    constructor(uid, id, manufacturer, model, description, number, quantity, price, multiplier, subtotal) {
        this.uid = uid;
        this.id = id;
        this.manufacturer = manufacturer;
        this.model = model;
        this.description = description;
        this.number = number;
        this.quantity = quantity;
        this.price = price;
        this.multiplier = multiplier;
        this.subtotal = subtotal;
    }
}

export default InvoicePartTempVM;