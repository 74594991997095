import React, { useEffect, useReducer, useRef, useState } from "react";
import {
  Button,
  Grid,
  Container,
  Paper,
  Typography,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import {
  LocationClient,
  LocationVM,
  CustomerClient,
  LocationAddVM,
  StateClient,
  ContactAddVM,
  ContactClient,
  LocationBaseVM,
  LocationNoteCreateVM,
  LocationUpdateVM,
  StateVM,
  DispatchClient,
  DispatchLocationHistoryVM,
  UserClient,
} from "../../brines-refrigerator-api";
import BasicTable from "../../components/common/table/BasicTable";
import LocationCRUDForm from "../../components/location/LocationCRUDForm";
import ConfirmDialog from "../../components/common/dialog/ConfirmationDialog";
import UserRole from "../../helpers/constants/userRole";
import Delete from "@material-ui/icons/Delete";
import RestoreIcon from "@material-ui/icons/Restore";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";
import { useHistory } from "react-router-dom";
import "./Location.scss";
import { useSnackbar } from "notistack";
import { redirectIfSessionExpired } from "../../components/common/redirect/RedirectOnSessionTimeout";
import { useDebouncedSearch } from "../../helpers/search";
import SearchIcon from "@material-ui/icons/Search";
import LaunchIcon from "@material-ui/icons/Launch";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import LocationForm from "../../components/location/LocationForm";
import handleServerError from "../../helpers/handleServerError";
import FormDialog from "../../components/common/dialog/FormDialog";
import CancelButton from "../../components/common/dialog/CancelButton";
import Visits from "../../components/dispatch/visitsModal/visits";

interface StateType {
  formTitle: string;
  location: LocationVM;
  formAction: Function;
}

export default function LocationView() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();
  const dateTimeFormat = new Intl.DateTimeFormat("en-US", {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  // Get user data from session storage
  const userData: any = JSON.parse(sessionStorage.getItem("userData") || "{}");
  //get user role
  const role = userData.role.name;

  const customerClient = new CustomerClient();
  const locationClient = new LocationClient();
  const dispatchClient = new DispatchClient();
  const usersClient = new UserClient();

  const addNewLocation = async (
    e: React.FormEvent<HTMLFormElement>,
    id: number,
    stateId: number,
    customerId: number,
    contacts: ContactAddVM[],
    notesToAdd: LocationNoteCreateVM[]
  ) => {
    e.preventDefault();

    const form = e.currentTarget;
    const location = new LocationAddVM({
      name: form.locationName.value,
      addressLine1: form.addressLine1.value,
      addressLine2: form.addressLine2.value,
      city: form.city.value,
      zip: form.zip.value,
      ivr: form.ivr.value,
      ivrPin: form.ivrPin.value,
      number: form.number.value,
      emsNumber: form.emsNumber.value,
      active: true,
      pmInfo: form.pmInfo.value,
      stateId: stateId,
      customerId: customerId,
      contacts: contacts,
      notes: notesToAdd,
      laborRate: Number(form.laborRate.value),
      tripRate: Number(form.tripRate.value),
      otRate: Number(form.otRate.value),
      holidayRate: Number(form.holidayRate.value),
      locationMarkup: Number(form.locationMarkup.value),
    });

    try {
      await locationClient.post(location);
      clearFields();
      enqueueSnackbar("Location added successfully.", { variant: "success" });
      setTableLoading(true);
      populateTable();
    } catch (error) {
      redirectIfSessionExpired(history, error);
      handleServerError(error, enqueueSnackbar);
    }
  };
  const [state, setState] = useReducer(
    (state: StateType, newState: StateType) => ({ ...state, ...newState }),
    {
      location: new LocationVM({
        id: null,
        name: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        zip: "",
        ivr: "",
        ivrPin: "",
        number: "",
        emsNumber: "",
        active: true,
        pmInfo: "",
        stateId: 0,
        state: new StateVM({
          id: "0",
          abbreviation: "",
          name: "",
        }),
        customerId: 0,
        contacts: [],
      }),
      formTitle: "ADD LOCATION",
      formAction: addNewLocation,
    }
  );

  const [locations, setLocations] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [states, setStates] = useState([]);
  const [tableIsLoading, setTableLoading] = useState(true);

  const editLocation = async (
    e: React.FormEvent<HTMLFormElement>,
    id: number,
    stateId: number,
    customerId: number,
    contacts: ContactAddVM[],
    notesToAdd: LocationNoteCreateVM[]
  ) => {
    e.preventDefault();

    const contactClient = new ContactClient();
    contacts.forEach(async (contact) => {
      try {
        if (contact.hasOwnProperty("id")) {
          await contactClient.put(contact);
        } else {
          contact.locationId = id;
          await contactClient.post(contact);
        }
      } catch (error) {
        redirectIfSessionExpired(history, error);
        handleServerError(error, enqueueSnackbar);
      }
    });

    notesToAdd.forEach(async (note) => {
      try {
        locationClient.createNote(note);
      } catch (error) {
        redirectIfSessionExpired(history, error);
        handleServerError(error, enqueueSnackbar);
      }
    });

    const form = e.currentTarget;
    const location = new LocationUpdateVM({
      id: id,
      name: form.locationName.value,
      addressLine1: form.addressLine1.value,
      addressLine2: form.addressLine2.value,
      city: form.city.value,
      zip: form.zip.value,
      ivr: form.ivr.value,
      ivrPin: form.ivrPin.value,
      number: form.number.value,
      emsNumber: form.emsNumber.value,
      active: true,
      pmInfo: form.pmInfo.value,
      stateId: stateId,
      customerId: customerId,
      laborRate: Number(form.laborRate.value),
      tripRate: Number(form.tripRate.value),
      otRate: Number(form.otRate.value),
      holidayRate: Number(form.holidayRate.value),
      locationMarkup: Number(form.locationMarkup.value),
    });
    try {
      await locationClient.put(location);
      clearFields();
      enqueueSnackbar("Location updated successfully.", { variant: "success" });
      setLocationForEditing(location.id);
      setTableLoading(true);
      populateTable();
    } catch (error) {
      redirectIfSessionExpired(history, error);
      handleServerError(error, enqueueSnackbar);
    }
  };
  const button = (
    <Button
      variant="outlined"
      onClick={(e: React.MouseEvent<HTMLElement>) => clearFields()}
      color="primary"
    >
      Cancel
    </Button>
  );
  const dispatchHistoryButton = (
    <Button
      color="primary"
      variant="contained"
      onClick={(e: React.MouseEvent<HTMLElement>) => setModalState(true)}
    >
      Show Dispatch history
    </Button>
  );

  const columns = [
    { title: "Id", field: "id" },
    { title: "Location", field: "location" },
    {
      title: "Number",
      field: "number",
    },
    { title: "City", field: "city" },
    { title: "State", field: "state" },
    { title: "ZIP", field: "zip" },
  ];

  const dispatchHistoryColumns = [
    { title: "Id", field: "id" },
    { title: "Follow Up Date", field: "followUp" },
    {
      title: "Status",
      field: "status",
    },
    { title: "Equipment", field: "equipment" },
  ];

  function clearFields() {
    setState({
      ...state,
      formTitle: "ADD LOCATION",
      formAction: addNewLocation,
      location: new LocationVM({
        id: null,
        name: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        zip: "",
        ivr: "",
        ivrPin: "",
        number: "",
        emsNumber: "",
        active: true,
        pmInfo: "",
        stateId: 0,
        state: new StateVM({
          id: "0",
          abbreviation: "",
          name: "",
        }),
        customerId: 0,
        laborRate: 0,
        tripRate: 0,
        otRate: 0,
        holidayRate: 0,
        locationMarkup: 0,
        contacts: [],
        notes: [],
      }),
    });
  }

  async function setLocationForEditing(id: number) {
    const location = await locationClient.getById(id);
    setState({
      ...state,
      formTitle: "EDIT LOCATION",
      formAction: editLocation,
      location: new LocationVM({
        id: id,
        name: location.name,
        addressLine1: location.addressLine1,
        addressLine2: location.addressLine2,
        city: location.city,
        zip: location.zip,
        ivr: location.ivr,
        ivrPin: location.ivrPin,
        number: location.number,
        emsNumber: location.emsNumber,
        active: true,
        pmInfo: location.pmInfo,
        stateId: location.stateId,
        state: location.state,
        customerId: location.customerId,
        laborRate: location.laborRate,
        tripRate: location.tripRate,
        otRate: location.otRate,
        holidayRate: location.holidayRate,
        locationMarkup: location.locationMarkup,
        contacts: location.contacts,
        notes: location.notes,
      }),
    });
  }

  async function deleteLocation(id: number) {
    try {
      await locationClient.toggleArchivedStatus(id);
      clearFields();
      enqueueSnackbar("Location status toggled successfully.", {
        variant: "success",
      });
      setTableLoading(true);
      populateTable();
    } catch (error) {
      redirectIfSessionExpired(history, error);
      handleServerError(error, enqueueSnackbar);
    }
  }

  async function populateTable() {
    try {
      const locationsTable = await locationClient.getByTeam(page, rowsPerPage);
      setLocationsCount(locationsTable.count);
      setLocations(
        locationsTable.locations
          .map((e: LocationBaseVM) => ({
            id: Number(e.id),
            location: e.name,
            city: e.city,
            state: e.state.name,
            zip: e.zip,
            isArchived: e.isArchived,
            number: e.number,
          }))
          .filter(
            (x) => (x.isArchived && role === UserRole.Admin) || !x.isArchived
          )
      );
    } catch (error) {
      redirectIfSessionExpired(history, error);
      handleServerError(error, enqueueSnackbar);
    }
    setTableLoading(false);
  }

  async function getCustomers() {
    try {
      const customers = await customerClient.getCustomersBase();
      setCustomers(customers);
    } catch (error) {
      redirectIfSessionExpired(history, error);
      handleServerError(error, enqueueSnackbar);
    }
  }

  async function getStates() {
    try {
      const stateClient = new StateClient();
      const states = await stateClient.get();
      setStates(states);
    } catch (error) {
      redirectIfSessionExpired(history, error);
      handleServerError(error, enqueueSnackbar);
    }
  }

  useEffect(() => {
    const fetchDataAsync = async () => {
      await populateTable();
      getCustomers();
      getStates();
      getUsers();
    };
    fetchDataAsync();
  }, []);

  const [confirm, setConfirmOpen] = useState(false);
  const [idForDelete, setidForDelete] = useState(0);

  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogMessage, setDialogMessage] = useState("");

  function confirmOpen() {
    setConfirmOpen(true);
  }

  function openDialog(id: number, toArchive: boolean) {
    if (toArchive) {
      setDialogTitle("Delete?");
      if (userData.role.name === UserRole.Admin) {
        setDialogMessage("Are you sure you want to archive this Location?");
      } else {
        setDialogMessage("Are you sure you want to delete this Location?");
      }
    } else {
      setDialogTitle("Return?");
      setDialogMessage("Are you sure you want to restore this Location?");
    }
    setConfirmOpen(true);
    setidForDelete(id);
  }

  const openDispatchWithLocation = (locationId) => {
    history.push({
      pathname: "/dispatch",
      state: { dispatchIdFromTechDispatchView: locationId },
    });
  };

  const setLocationForViewing = async (id: number) => {
    const location = await locationClient.getById(id);
    setState({
      ...state,
      formTitle: "VIEW LOCATION",
      formAction: editLocation,
      location: new LocationVM({
        id: id,
        name: location.name,
        addressLine1: location.addressLine1,
        addressLine2: location.addressLine2,
        city: location.city,
        zip: location.zip,
        ivr: location.ivr,
        ivrPin: location.ivrPin,
        number: location.number,
        emsNumber: location.emsNumber,
        active: true,
        pmInfo: location.pmInfo,
        stateId: location.stateId,
        customerId: location.customerId,
        laborRate: location.laborRate,
        tripRate: location.tripRate,
        otRate: location.otRate,
        holidayRate: location.holidayRate,
        locationMarkup: location.locationMarkup,
        contacts: location.contacts,
        notes: location.notes,
      }),
    });

    const dispatches = await dispatchClient.getDispatchHistoryByLocation(id);
    const mappedDispatches = dispatches.map((e: DispatchLocationHistoryVM) => ({
      key: Number(e.id),
      id: Number(e.id),
      followUp: e.followUpDate
        ? dateTimeFormat.format(e.followUpDate)
        : "Unassigned",
      status: e.status ? e.status.name : "Unassigned",
      equipment:
        e.dispatchEquipments.length > 0
          ? e.dispatchEquipments.map((x) => x.equipment.model)
          : "Unassigned",
    }));

    setDispatchHistory(mappedDispatches);
  };

  const [dispatchHistory, setDispatchHistory] = useState([]);

  const tableActions = [
    (rowData) => ({
      icon: () => <AirportShuttleIcon color="primary" />,
      onClick: (event, rowData: unknown) => {
        openDispatchWithLocation((rowData as { id: number }).id);
      },
      tooltip: "Open Dispatch Within Location",
    }),
    (rowData) => ({
      icon: () => <LaunchIcon color="primary" />,
      onClick: (event, rowData: unknown) => {
        setLocationForViewing((rowData as { id: number }).id);
      },
      tooltip: "View Location",
    }),
    (rowData) => ({
      icon: () => <Delete color="primary" />,
      onClick: (event, rowData: unknown) => {
        openDialog((rowData as { id: number }).id, true);
      },
      hidden:
        rowData["isArchived"] ||
        role === UserRole.Dispatcher ||
        role === UserRole.Technician,
      tooltip: "Delete Location",
    }),
    (rowData) => ({
      icon: () => <RestoreIcon color="primary" />,
      onClick: (event, rowData: unknown) => {
        openDialog((rowData as { id: number }).id, false);
      },
      hidden:
        !rowData["isArchived"] ||
        role === UserRole.Dispatcher ||
        role === UserRole.Technician,
      tooltip: "Restore Location",
    }),
  ];

  const dispatchHistoryTableActions = [
    (rowData) => ({
      icon: () => <DriveEtaIcon color="primary" />,
      onClick: (event, rowData: unknown) => {
        openDispatchVisits((rowData as { id: number }).id);
      },
      tooltip: "View Visits",
    }),
    (rowData) => ({
      icon: () => <LaunchIcon color="primary" />,
      onClick: (event, rowData: unknown) => {
        openDispatchFromHistory((rowData as { id: number }).id);
      },
      tooltip: "View Dispatch",
    }),
    (rowData) => ({
      icon: () => <Delete color="primary" />,
      onClick: (event, rowData: unknown) => {
        openDialog((rowData as { id: number }).id, true);
      },
      hidden: role === UserRole.Dispatcher || role === UserRole.Technician,
      tooltip: "Delete Dispatch",
    }),
  ];

  let dispatchHistoryTableBody = (
    <Paper elevation={0}>
      <Grid container spacing={0} justify="center">
        <Grid container item xs={12}>
          <Grid item xs={10}></Grid>
          <Grid item xs={2} style={{ textAlign: "right" }}>
            <CancelButton onClick={() => setModalState(false)} />
          </Grid>
          <Grid item xs={12}>
            <BasicTable
              columns={dispatchHistoryColumns}
              title="Dispatch History"
              data={dispatchHistory as []}
              actions={dispatchHistoryTableActions}
              components={{
                Container: (props) => <Paper {...props} elevation={0} />,
                // Toolbar: () => <></>,
              }}
              paging={true}
              isCustomPagination={false}
              isLoading={false}
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );

  const [modalState, setModalState] = useState(false);

  const openDispatchVisits = async (dispatchId: number) => {
    setVisitsSignaturesModal({ open: true, dispatchId: dispatchId });
  };

  const [visitsSignaturesModal, setVisitsSignaturesModal] = useState({
    open: false,
    dispatchId: 0,
  });

  const [users, setUsers] = useState([]);

  async function getUsers() {
    try {
      const users = await usersClient.usersByRole(UserRole.Technician);
      setUsers(users);
    } catch (error) {
      redirectIfSessionExpired(history, error);
    }
  }

  const visitsSignaturesModalBody = (
    <>
      <Visits
        setModalState={setVisitsSignaturesModal}
        dispatchId={visitsSignaturesModal.dispatchId}
        technicians={users}
      />
    </>
  );

  const openDispatchFromHistory = (dispatchId) => {
    history.push({
      pathname: "/dispatch",
      state: { dispatchIdFromLocationView: dispatchId },
    });
  };

  const searchLocations = async (input: string, resetPage: boolean = true) => {
    const locationsTable = await locationClient.getByFilter(
      input,
      resetPage ? 0 : page,
      rowsPerPage
    );

    if (resetPage) setPage(0);

    setLocationsCount(locationsTable.count);

    const result = locationsTable.locations
      .map((e: LocationBaseVM) => ({
        id: Number(e.id),
        location: e.name,
        city: e.city,
        state: e.state.name,
        zip: e.zip,
        isArchived: e.isArchived,
        number: e.number,
      }))
      .filter(
        (x) => (x.isArchived && role === UserRole.Admin) || !x.isArchived
      );
    setLocations(result);
    return result;
  };

  const { inputText, setInputText, search } = useDebouncedSearch(
    (text: string) => searchLocations(text)
  );
  const firstEffectBySearch = useRef(true);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [locationsCount, setLocationsCount] = useState(10);
  const firstEffectByPagination = useRef(true);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const tableRef = useRef(null);

  useEffect(() => {
    const executeAsync = async () => {
      // do not trigger on initial state
      if (firstEffectByPagination.current) {
        firstEffectByPagination.current = false;
        return;
      }

      if (inputText.length > 0) {
        await searchLocations(inputText, false);
      } else {
        populateTable();
      }

      tableRef.current.dataManager.changePageSize(rowsPerPage);
    };
    executeAsync();
  }, [page, rowsPerPage]);

  useEffect(() => {
    // do not trigger on initial state
    if (firstEffectBySearch.current) {
      firstEffectBySearch.current = false;
      return;
    }

    if (inputText.length === 0) {
      if (page === 0) {
        populateTable();
      } else {
        setPage(0);
      }
      tableRef.current.dataManager.changePageSize(rowsPerPage);
    }
  }, [inputText]);

  return (
    <>
      <main className="location">
        <Container maxWidth={false}>
          <Grid container>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              xs={12}
              className="location_container"
            >
              <div className="location_table">
                <Grid container item xs={12}>
                  <Grid item sm={8}>
                    <Typography variant="h1">Locations List</Typography>
                  </Grid>
                  <Grid item sm={4}>
                    <TextField
                      id="input-with-icon-textfield"
                      label="Search"
                      value={inputText}
                      onChange={(e) => {
                        setInputText(e.target.value);
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
                <BasicTable
                  tableRef={tableRef}
                  columns={columns}
                  title="LOCATIONS LIST"
                  data={locations as []}
                  actions={tableActions}
                  components={{
                    Container: (props) => <Paper {...props} elevation={0} />,
                    Toolbar: () => <></>,
                  }}
                  paging={true}
                  isCustomPagination={true}
                  isLoading={tableIsLoading || search.loading}
                  count={locationsCount}
                  page={page}
                  onChangePage={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </div>
            </Grid>
            <Grid item xl={6} lg={6} md={6} xs={12}>
              {state.formTitle !== "VIEW LOCATION" && (
                <div className="location_crud_form">
                  <LocationCRUDForm
                    title={state.formTitle}
                    button={button}
                    location={state.location}
                    customers={customers}
                    states={states}
                    formAction={state.formAction}
                  />
                </div>
              )}
              {state.formTitle == "VIEW LOCATION" && (
                <div className="location_crud_form">
                  <LocationForm
                    title={"VIEW LOCATION"}
                    button={button}
                    dispatchHistoryButton={dispatchHistoryButton}
                    location={state.location}
                    customers={customers}
                    states={states}
                    formAction={state.formAction}
                    editLocation={setLocationForEditing}
                  />
                </div>
              )}
            </Grid>
          </Grid>
        </Container>

        {confirm && (
          <ConfirmDialog
            title={dialogTitle}
            open={confirmOpen}
            setOpen={setConfirmOpen}
            onConfirm={deleteLocation}
            param={idForDelete}
          >
            {dialogMessage}
          </ConfirmDialog>
        )}

        {modalState && (
          <FormDialog open={modalState} body={dispatchHistoryTableBody} />
        )}
        {visitsSignaturesModal && (
          <FormDialog
            open={visitsSignaturesModal.open}
            body={visitsSignaturesModalBody}
          />
        )}
      </main>
    </>
  );
}
