import { Grid } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { DispatchSecondaryStatusClient, DispatchSecondaryStatusCreateVM, DispatchSecondaryStatusUpdateVM } from '../../../brines-refrigerator-api';
import handleServerError from '../../../helpers/handleServerError';
import { validateText } from '../../../helpers/validations';
import AddEditTable from '../../common/table/AddEditTable';

const DispatchSecondaryStatusesView = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const dispatchSecondaryStatusesClient = new DispatchSecondaryStatusClient();

    const [secondaryStatuses, setSecondaryStatuses] = useState([]);

    const getDispatchSecondaryStatuses = async () => {
        const statuses = await dispatchSecondaryStatusesClient.getAll();
        setSecondaryStatuses(statuses);
    }

    useEffect(() => {
        getDispatchSecondaryStatuses();
    }, [])

    const dispatchSecondaryStatusColumns = [
        {
            title: 'Id', field: 'id', editable: false
        },
        { title: 'Name', field: 'name', validate: rowData => rowData.name === '' ? { isValid: false, helperText: 'Name can\'t be empty' } : true },
    ];

    interface dispatchSecondaryStatusData {
        id: number,
        name: string
    }

    const addDispatchSecondaryStatus = async (data: dispatchSecondaryStatusData) => {
        if (validateText(data.name).error) {
            return false;
        }

        try {
            await dispatchSecondaryStatusesClient.add(new DispatchSecondaryStatusCreateVM({
                name: data.name
            }));

            enqueueSnackbar("Added dispatch secondary status.", { variant: "success" });
            await getDispatchSecondaryStatuses();
            return true;
        }
        catch (error) {
            enqueueSnackbar(error.message, { variant: "error" });
            return false;
        }
    }

    const updateDispatchSecondaryStatus = async (data: dispatchSecondaryStatusData) => {
        if (validateText(data.name).error || !data.id) {
            return false;
        }

        try {
            await dispatchSecondaryStatusesClient.update(new DispatchSecondaryStatusUpdateVM({
                id: data.id,
                name: data.name
            }));

            enqueueSnackbar("Updated dispatch secondary status.", { variant: "success" });
            await getDispatchSecondaryStatuses();
            return true;
        }
        catch (error) {
            handleServerError(error, enqueueSnackbar);
            return false;
        }
    }

    const deleteDispatchSecondaryStatus = async (id: number) => {
        if (!id) {
            return false;
        }

        try {
            await dispatchSecondaryStatusesClient.delete(id);

            enqueueSnackbar("Deleted dispatch secondary status.", { variant: "success" });
            await getDispatchSecondaryStatuses();
            return true;
        }
        catch (error) {
            enqueueSnackbar(error.response.data.Title, { variant: "error" });
            return false;
        }
    }

    return (
        <Grid container>
            <Grid item xs={4}>
                <AddEditTable
                    width="100%"
                    columns={dispatchSecondaryStatusColumns}
                    title="Dispatch Secondary Statuses"
                    data={secondaryStatuses as []}
                    add={addDispatchSecondaryStatus}
                    update={updateDispatchSecondaryStatus}
                    delete={deleteDispatchSecondaryStatus}
                    search={false}
                />
            </Grid>
        </Grid>
    )
}

export default DispatchSecondaryStatusesView;