import React, { useState, useReducer } from "react";
import {
  Grid,
  AppBar,
  Tabs,
  Tab,
  Typography,
  Container,
} from "@material-ui/core";
import "./Admin.scss";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import PriorityAndTrades from "../../components/admin/PriorityAndTrades/PriorityAndTrades";
import Manufacturers from "../../components/admin/Manufacturers/Manufacturers";
import EquipmentType from "../../components/admin/EquipmentTypeAndLocations/EquipmentType";
import PartsListView from "../../components/admin/PartsList/PartsListView";
import DispatchTypes from "../../components/admin/DispatchTypesAndServiceRequestCodes/DispatchTypes";
import ServiceRequestCodes from "../../components/admin/DispatchTypesAndServiceRequestCodes/ServiceRequestCodes";
import DocumentTypes from "../../components/admin/DocumentTypes/DocumentTypes";
import TeamsView from "../../components/admin/Teams/Teams";
import UsersView from "../../components/admin/ManageUsers/UsersView";
import DispatchSecondaryStatusesView from "../../components/admin/DispatchSecondaryStatuses/DispatchSecondaryStatusesView";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel: React.FC<TabPanelProps> = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container maxWidth={false} className="admin_navbar_hero">
          {children}
        </Container>
      )}
    </div>
  );
};

const Admin: React.FC = () => {
  const [value, setValue] = useState(0);

  // Set component state
  const [userTable, setUserTable] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      id: "",
      role: "",
      name: "",
      email: "",
      phone: "",
      state: "",
      active: "",
      note: {
        text: "",
      },
      updateTable: true,
    }
  );

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
      },
    })
  );

  const classes = useStyles();

  const switchView = (event: any, newValue: React.SetStateAction<number>) => {
    setValue(newValue);
  };

  return (
    <div className="admin_navbar">
      <Grid>
        <AppBar
          elevation={0}
          position="static"
          color="default"
          style={{ background: "#fff" }}
        >
          <Grid container alignItems="center">
            <Typography className="admin_navbar_admin_nav_title" variant="h1">
              ADMIN
            </Typography>
            <Tabs
              value={value}
              onChange={switchView}
              variant="scrollable"
              scrollButtons="on"
              indicatorColor="primary"
            >
              <Tab label="Users" />
              <Tab label="Teams" />
              {/* <Tab label="Manufacturers" />
                            <Tab label="Equipment Types" /> */}
              <Tab label="Priority & Classes" />
              <Tab label="Dispatch Types & Service Request Codes" />
              <Tab label="Dispatch Secondary Statuses" />
              <Tab label="Parts List" />
              <Tab label="Document Types" />
            </Tabs>
          </Grid>
        </AppBar>
      </Grid>
      <TabPanel value={value} index={0}>
        <div className={classes.root}>
          <UsersView />
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className={classes.root}>
          <TeamsView />
        </div>
      </TabPanel>
      {/* <TabPanel value={value} index={2}>
        <div className={classes.root}>
          <Grid spacing={1} container>
            <Grid item xl={4} lg={4} sm={12} xs={12} md={12}>
              <Manufacturers />
            </Grid>
          </Grid>
        </div>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <div className={classes.root}>
          <Grid spacing={1} container>
            <Grid item xl={4} lg={4} sm={12} xs={12} md={12}>
              <EquipmentType />
            </Grid>
          </Grid>
        </div>
      </TabPanel> */}
      <TabPanel value={value} index={2}>
        <div className={classes.root}>
          <Grid spacing={1} container>
            <Grid item xl={12} lg={12} sm={12} xs={12} md={12}>
              <PriorityAndTrades />
            </Grid>
          </Grid>
        </div>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <div className={classes.root}>
          <Grid spacing={1} container>
            <Grid item xl={4} lg={4} sm={12} xs={12} md={12}>
              <DispatchTypes />
            </Grid>
            <Grid item xl={8} lg={8} sm={12} xs={12} md={12}>
              <ServiceRequestCodes />
            </Grid>
          </Grid>
        </div>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <div className={classes.root}>
          <DispatchSecondaryStatusesView />
        </div>
      </TabPanel>
      <TabPanel value={value} index={5}>
        <div className={classes.root}>
          <PartsListView />
        </div>
      </TabPanel>
      <TabPanel value={value} index={6}>
        <div className={classes.root}>
          <DocumentTypes />
        </div>
      </TabPanel>
    </div>
  );
};

Admin.displayName = "Admin";

export default Admin;
