import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import AddEditTable2 from "../../../components/common/table/AddEditTable2";
import {
  ClockInQuestionsClient,
  ClockOutQuestionsClient,
  DispatchClient,
  DispatchLaborAddVM,
  DispatchTripChargeAddVM,
  UserVM,
  VisitsClient,
} from "../../../brines-refrigerator-api";
import { validateText } from "../../../helpers/validations";
import {
  Button,
  Card,
  Chip,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";

interface DispatchLaborProps {
  dispatchId: number;
  disabled: boolean;
  technicians: UserVM[];
}

const useStyles = makeStyles({
  visitsContainer: {
    marginTop: "2rem",
  },
  questionContainer: {
    marginBottom: "1rem",
  },
  visitsTitle: {
    marginBottom: "1rem",
  },
  visitsDataContainer: {
    marginTop: "1rem",
  },
  visitDataContainer: {
    marginBottom: "1rem",
    border: "solid #dddddd 1px",
    boxShadow: "3px 3px #eeeeee",
  },
  visitDataBlock: {
    marginBottom: "0.5rem",
  },
  addTripChargeButton: {
    margin: "1rem 0rem",
  },
});

const DispatchLabor = (props: DispatchLaborProps) => {
  const classes = useStyles();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatchClient = new DispatchClient();
  const laborColumns = [
    {
      title: "Technician",
      field: "technicianName",
      validate: (rowData) =>
        rowData.technicianName === ""
          ? { isValid: false, helperText: "Name can't be empty" }
          : true,
    },
    {
      title: "Hourly rate",
      field: "hourlyRate",
      type: "numeric",
      validate: (rowData) =>
        rowData.hourlyRate < 0
          ? { isValid: false, helperText: "Hourly rate can't be negative" }
          : true,
    },
    {
      title: "Hours",
      field: "hours",
      type: "numeric",
      validate: (rowData) =>
        rowData.hours < 0
          ? { isValid: false, helperText: "Hours can't be negative" }
          : true,
    },
    { title: "Subtotal", field: "subtotal", editable: false },
  ];

  const tripColumns = [
    {
      title: "Rate",
      field: "price",
      validate: (rowData) =>
        rowData.price === ""
          ? { isValid: false, helperText: "Rate can't be empty" }
          : true,
    },
    {
      title: "Quantity",
      field: "quantity",
      type: "numeric",
      validate: (rowData) =>
        rowData.hours < 0
          ? { isValid: false, helperText: "Quantity can't be negative" }
          : true,
    },
    { title: "Subtotal", field: "subtotal", editable: false },
  ];

  const [laborList, setLaborList] = useState([]);
  const [laborTableLoading, setLaborTableLoading] = useState(false);

  const getDispatchLabor = async () => {
    try {
      setLaborTableLoading(true);
      const labor = await dispatchClient.getDispatchLabor(props.dispatchId);
      setLaborList(labor);
      setLaborTableLoading(false);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setLaborTableLoading(false);
    }
  };

  const addDispatchLabor = async (data) => {
    if (validateAllLaborFields(data)) {
      return false;
    }
    try {
      await dispatchClient.addDispatchLabor(
        new DispatchLaborAddVM({
          dispatchId: props.dispatchId,
          technicianName: data.technicianName,
          hourlyRate: Number(data.hourlyRate),
          hours: Number(data.hours),
        })
      );
      getDispatchLabor();
      return true;
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      return false;
    }
  };

  const addDispatchTripCharge = async (data) => {
    if (validateAllTripFields(data)) {
      return false;
    }
    try {
      await dispatchClient.addDispatchTripCharge(
        new DispatchTripChargeAddVM({
          dispatchId: props.dispatchId,
          price: data.price,
          quantity: data.quantity,
          subtotal: data.price * data.quantity,
        })
      );
      getDispatchTripCharges();
      return true;
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      return false;
    }
  };

  const validateAllTripFields = (data: unknown): boolean => {
    const resultPrice = (data as { price: number }).price
      ? { error: false, errorMessage: null }
      : { error: true, errorMessage: "Price can't be empty." };

    return resultPrice.error;
  };

  const validateAllLaborFields = (data: unknown): boolean => {
    const resultName = (
      data as { technicianName: string; hourlyRate: number; hours: number }
    ).technicianName
      ? validateText(
          (
            data as {
              technicianName: string;
              hourlyRate: number;
              hours: number;
            }
          ).technicianName
        )
      : { error: true, errorMessage: "Name can't be empty." };

    const resultHourlyRate = (
      data as { technicianName: string; hourlyRate: number; hours: number }
    ).hourlyRate
      ? { error: false, errorMessage: null }
      : { error: true, errorMessage: "Hourly Rate can't be empty." };

    const resultHours = (
      data as { technicianName: string; hourlyRate: number; hours: number }
    ).hours
      ? { error: false, errorMessage: null }
      : { error: true, errorMessage: "Hours can't be empty." };

    if (resultName.error) {
      enqueueSnackbar(resultName.errorMessage, { variant: "error" });
    }
    if (resultHourlyRate.error) {
      enqueueSnackbar(resultHourlyRate.errorMessage, { variant: "error" });
    }
    if (resultHours.error) {
      enqueueSnackbar(resultHours.errorMessage, { variant: "error" });
    }

    return resultName.error || resultHourlyRate.error || resultHours.error;
  };

  const deleteDispatchLabor = async (id: number) => {
    try {
      await dispatchClient.deleteDispatchLabor(id);
      getDispatchLabor();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const deleteDispatchTripCharge = async (id: number) => {
    try {
      await dispatchClient.deleteDispatchTripCharge(id);
      getDispatchTripCharges();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const [questions, setQuestions] = useState([]);
  const getQuestions = async () => {
    const clockInQClient = new ClockInQuestionsClient();
    const clockOutQClient = new ClockOutQuestionsClient();
    const clockInQuestions = (await clockInQClient.get()).map(
      (question) => question.questionText
    );
    const clockOutQuestions = (await clockOutQClient.get()).map(
      (question) => question.questionText
    );
    setQuestions(clockInQuestions.concat(clockOutQuestions));
  };

  const [technicians, setTechnicians] = useState({});
  useEffect(() => {
    if (props.dispatchId != null) {
      getDispatchLabor();
      getDispatchTripCharges();
      getQuestions();
      formatTechnicians();
    } else {
      setLaborList([]);
    }
  }, [props.dispatchId]);

  useEffect(() => {
    getVisitsData();
  }, [technicians]);

  const [visits, setVisits] = useState([]);

  const getVisitsData = async () => {
    const visitsClient = new VisitsClient();

    const visitsData = await visitsClient.getForDisplay(props.dispatchId);
    setVisits(visitsData);
    console.log(visitsData);
  };

  const formatTechnicians = () => {
    const technicians = {};
    props.technicians.forEach((tech) => {
      technicians[tech.id] = tech.userName ?? tech.email;
    });
    setTechnicians(technicians);
  };

  const options = {
    year: "numeric",
    day: "numeric",
    month: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  const dateTimeFormat = new Intl.DateTimeFormat("en-US", options);

  const formatAnswer = (answer: boolean) => {
    return answer ? (
      <Chip color="primary" variant="outlined" size="small" label="Yes" />
    ) : (
      <Chip color="secondary" variant="outlined" size="small" label="No" />
    );
  };

  const formatIndicator = (text: string) => {
    return (
      <Chip color="primary" variant="outlined" size="small" label={text} />
    );
  };

  const [tripChargeList, setTripChargeList] = useState([]);
  const [tripChargeTableLoading, setTripChargeTableLoading] = useState(false);
  const getDispatchTripCharges = async () => {
    try {
      setTripChargeTableLoading(true);
      const tripCharges = await dispatchClient.getDispatchTripCharges(
        props.dispatchId
      );
      setTripChargeList(tripCharges);
      setTripChargeTableLoading(false);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setTripChargeTableLoading(false);
    }
  };

  const addDispatchTripChargeFromLocation = async () => {
    try {
      await dispatchClient.addDispatchTripChargeFromLocation(props.dispatchId);
      getDispatchTripCharges();
    } catch (error) {
      enqueueSnackbar("Error adding dispatch trip charge from location", {
        variant: "error",
      });
    }
  };

  return (
    <div
      style={{ pointerEvents: props.disabled ? "none" : "unset" }}
      key={`dispatch-labor-table-${new Date().toLocaleString()}`}
    >
      <AddEditTable2
        width="100%"
        title="Labor"
        columns={laborColumns}
        add={addDispatchLabor}
        delete={deleteDispatchLabor}
        data={laborList as unknown as []}
        search={false}
        isLoading={laborTableLoading}
        tooltipText="Enter Labor"
      />
      <Grid container xs={12} className={classes.addTripChargeButton}>
        <Button
          color="primary"
          variant="outlined"
          size="medium"
          disableElevation
          fullWidth
          onClick={() => addDispatchTripChargeFromLocation()}
        >
          Add Trip From Location
        </Button>
      </Grid>
      <AddEditTable2
        width="100%"
        title="Trip"
        columns={tripColumns}
        add={addDispatchTripCharge}
        delete={deleteDispatchTripCharge}
        data={tripChargeList as unknown as []}
        search={false}
        isLoading={tripChargeTableLoading}
        tooltipText="Enter Trip"
      />
      <Grid container xs={12} className={classes.visitsContainer}>
        <Grid item xs={12} className={classes.visitsTitle}>
          <b>Visits log</b>
        </Grid>
        {questions.map((question, index) => {
          return (
            <Grid item xs={12} className={classes.questionContainer}>
              <Card>
                <Typography>
                  <b>{`Q${index + 1}`}</b> {`${question}`}
                </Typography>
              </Card>
            </Grid>
          );
        })}
        <Grid item container xs={12} className={classes.visitsDataContainer}>
          {visits.map((visit) => {
            return (
              <Grid
                item
                container
                xs={12}
                className={classes.visitDataContainer}
              >
                <Grid item xs={6} className={classes.visitDataBlock}>
                  Technician: {technicians[visit.techId]}
                </Grid>
                <Grid item xs={6} className={classes.visitDataBlock}>
                  Status:{" "}
                  {visit.finished
                    ? "Finished"
                    : visit.clockIn.arrivedOnSite
                    ? "Arrived"
                    : "Traveling"}
                </Grid>
                <Grid item container xs={12} className={classes.visitDataBlock}>
                  <Grid item xs={4}>
                    Travel start time:{" "}
                    {dateTimeFormat.format(visit.clockIn.startedTravelingTime)}
                  </Grid>
                  <Grid item xs={4}>
                    Clock in time:{" "}
                    {visit.clockIn.arrivedOnSite
                      ? dateTimeFormat.format(visit.clockIn.clockInTime)
                      : "Pending"}
                  </Grid>
                  <Grid item xs={4}>
                    Clock out time:{" "}
                    {visit.clockOut
                      ? dateTimeFormat.format(visit.clockOut.clockOutTime)
                      : "Pending"}
                  </Grid>
                </Grid>
                <Grid item container xs={12} className={classes.visitDataBlock}>
                  <Grid item xs={3}>
                    <b>Q1:</b>{" "}
                    {visit.clockIn.ArrivedOnSite
                      ? formatAnswer(visit.clockIn.clockInAnswers[0].answer)
                      : formatIndicator("Pending")}
                  </Grid>
                  <Grid item xs={3}>
                    <b>Q2:</b>{" "}
                    {visit.clockIn.ArrivedOnSite
                      ? formatAnswer(visit.clockIn.clockInAnswers[1].answer)
                      : formatIndicator("Pending")}
                  </Grid>
                  <Grid item xs={3}>
                    <b>Q3:</b>{" "}
                    {visit.clockIn.ArrivedOnSite
                      ? formatAnswer(visit.clockIn.clockInAnswers[2].answer)
                      : formatIndicator("Pending")}
                  </Grid>
                  <Grid item xs={3}>
                    <b>Q4:</b>{" "}
                    {visit.finished
                      ? formatAnswer(visit.clockOut.clockOutAnswers[0].answer)
                      : formatIndicator("Pending")}
                  </Grid>
                  <Grid item xs={3}>
                    <b>Q5:</b>{" "}
                    {visit.finished
                      ? formatAnswer(visit.clockOut.clockOutAnswers[1].answer)
                      : formatIndicator("Pending")}
                  </Grid>
                  <Grid item xs={3}>
                    <b>Q6:</b>{" "}
                    {visit.finished
                      ? formatAnswer(visit.clockOut.clockOutAnswers[2].answer)
                      : formatIndicator("Pending")}
                  </Grid>
                  <Grid item xs={3}>
                    <b>Q7:</b>{" "}
                    {visit.finished
                      ? formatAnswer(visit.clockOut.clockOutAnswers[3].answer)
                      : formatIndicator("Pending")}
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </div>
  );
};

export default DispatchLabor;
