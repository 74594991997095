import React, { useEffect, useState } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  CardActionArea,
  GridList,
  GridListTile,
} from "@material-ui/core";
import { DispatchVM } from "../../brines-refrigerator-api";
import { useHistory } from "react-router-dom";
import * as client from "../../helpers/offline/offline-api";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
      overflow: "hidden",
      backgroundColor: theme.palette.background.paper,
    },
    gridList: {
      width: "100vw",
    },
    cardRoot: {
      minHeight: "10rem",
      borderRadius: "0.5rem",
      margin: "1%",
      color: "#FFFFFF",
    },
  })
);

export default function TechDispatchHistory() {
  const classes = useStyles();
  const history = useHistory();
  const [_getDispatches] = client.useGetDispatchHistory();

  const [dispatchesForDisplay, setdispatchesForDisplay] = useState([]);

  async function getDispatches() {
    try {
      const dispatches = await _getDispatches();
      setdispatchesForDisplay(dispatches as DispatchVM[]);
    } catch (error) {
      console.log(error.message);
    }
  }

  useEffect(() => {
    getDispatches();
  }, []);

  // Redirect to Tech-Dispatch view with dispatch id sent as prop
  function openDispatch(id: number) {
    history.push({
      pathname: "/tech-dispatch-history-view",
      state: id,
    });
  }

  return (
    <div>
      <div className={classes.root}>
        <Grid container>
          <Grid item>
            <GridList cellHeight="auto" className={classes.gridList} cols={1}>
              {dispatchesForDisplay.map((dispatch) => (
                <GridListTile key={dispatch.id}>
                  <CardActionArea onClick={() => openDispatch(dispatch.id)}>
                    <Card
                      className={classes.cardRoot}
                      style={
                        dispatch.priority
                          ? {
                              backgroundColor: `#${dispatch.priority.hexCode}`,
                              color:
                                dispatch.priority.hexCode === "FFFF00" &&
                                "FFFFFF"
                                  ? "#000000"
                                  : "#FFFFFF",
                            }
                          : { backgroundColor: "#FFFFFF", color: "#000000" }
                      }
                    >
                      <CardContent>
                        <Typography variant="h5" component="h2" align="center">
                          DISPATCH WO# {dispatch.workOrderNumber}
                        </Typography>
                        <hr />
                        <Typography variant="h5" component="h2" align="center">
                          {dispatch.location.name}
                        </Typography>
                      </CardContent>
                    </Card>
                  </CardActionArea>
                </GridListTile>
              ))}
            </GridList>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
