import React, { useState, useEffect } from 'react';
import { Grid, withStyles, TextField, Typography, makeStyles } from '@material-ui/core';
import { ContactVM } from '../../brines-refrigerator-api';
import * as client from '../../helpers/offline/offline-api';

interface TechContactsProps {
  dispatchId: number,
  dispatchLocationId: number
}

const CustomTextField = withStyles({
  root: {
    '& .MuiInputBase-input': {
      color: '#000000',
    },
  },
})(TextField);

const useStyles = makeStyles({
  contactsContainer: {
    borderBottom: "0.2px solid grey",
    marginBottom: "2em"
  },
  marginTop: {
    marginTop: "6em"
  }
});

export default function TechContacts(props: TechContactsProps) {
  const classes = useStyles();
  const [contacts, setContacts] = useState<ContactVM[]>([])
  const [_getLocation] = client.useGetLocation()

  const getContactsByLocationId = async () => {
    try {
      const location = await _getLocation(props.dispatchId, props.dispatchLocationId)
      setContacts(location.contacts)
    } catch (error) {
      console.log(error.message)
    }
  }

  useEffect(() => {
    getContactsByLocationId()
  }, [props]);

  return (
    <div>
      {
        contacts.length === 0
          ? 'No contacts to display.'
          : <>
            <Typography variant="h3">Primary</Typography>
            {contacts.map((c) => (
              <Grid container item xs={12} className={classes.contactsContainer}>
                <Grid container item xs={6} >
                  <Grid xs={12} item>
                    <CustomTextField
                      disabled
                      id="outlined-disabled"
                      label="Name"
                      defaultValue={c.name}
                      InputProps={{ disableUnderline: true }}
                    />
                  </Grid>

                  <Grid xs={12} item>
                    <CustomTextField
                      disabled
                      id="outlined-disabled"
                      label="Email"
                      defaultValue={c.email}
                      InputProps={{ disableUnderline: true }}
                    />
                  </Grid>

                  <Grid xs={12} item>
                    <CustomTextField
                      disabled
                      id="outlined-disabled"
                      label="Title"
                      defaultValue={c.title}
                      InputProps={{ disableUnderline: true }}
                    />
                  </Grid>

                </Grid>
                <Grid container xs={6} item>
                  <Grid item xs={6} className={classes.marginTop}>
                    <CustomTextField
                      disabled
                      id="outlined-disabled"
                      label="Phone"
                      defaultValue={c.phone.number}
                      InputProps={{ disableUnderline: true }}
                    />
                  </Grid>
                </Grid>
              </Grid>

            ))}
          </>
      }
    </div>
  );
}