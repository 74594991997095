import React, { useEffect, useState } from 'react';
import { Grid, Paper, TextField } from '@material-ui/core';
import { ServiceRequestCodeClient, ServiceRequestCodeAddVM } from '../../../brines-refrigerator-api';
import ConfirmDialog from '../../common/dialog/ConfirmationDialog';
import AddTable from '../../common/table/AddTable';
import { redirectIfSessionExpired } from '../../common/redirect/RedirectOnSessionTimeout';
import { useHistory } from 'react-router-dom';
import { validateText } from '../../../helpers/validations';
import { useSnackbar } from 'notistack';

const ServiceRequestCodes: React.FC = () => {
  const history = useHistory();

  const requestCodesClient = new ServiceRequestCodeClient();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [serviceRequestCodes, setServiceRequestCodes] = useState([])
  const [idForDelete, setIdForDelete] = useState(0);
  const [dialogMessage, setDialogMessage] = useState('');
  const [confirmOpen, setConfirmOpen] = useState(false);

  async function getServiceRequestCodes() {
    try {
      const requestCodes = await requestCodesClient.get();
      setServiceRequestCodes(requestCodes)
    } catch (error) {
      redirectIfSessionExpired(history, error)
      enqueueSnackbar("Error getting service request codes.", { variant: "error" });
    }
  };

  useEffect(() => {
    getServiceRequestCodes();
  }, []);

  const addRequestCode = async (data: unknown) => {
    const resultCode = (data as { code: number, description: string, noteTemplate: string }).code
      ? validateText((data as { code: number, description: string, noteTemplate: string }).code.toString(), true)
      : { error: true, errorMessage: 'Code can\'t be empty.' };

    const resultDescription = (data as { name: string, description: string, noteTemplate: string }).description
      ? validateText((data as { name: string, description: string, noteTemplate: string }).description)
      : { error: true, errorMessage: 'Description can\'t be empty.' };

    if (resultCode.error) {
      enqueueSnackbar(resultCode.errorMessage, { variant: "error" });
    }
    if (resultDescription.error) {
      enqueueSnackbar(resultDescription.errorMessage, { variant: "error" });
    }
    if (resultCode.error || resultDescription.error) {
      return;
    }
    try {
      await requestCodesClient.create(new ServiceRequestCodeAddVM({
        code: Number((data as { code: number, description: string, noteTemplate: string }).code),
        description: (data as { code: number, description: string, noteTemplate: string }).description,
        noteTemplate: (data as { code: number, description: string, noteTemplate: string }).noteTemplate
      }));

      enqueueSnackbar("Service request code added successfully.", { variant: "success" });

      getServiceRequestCodes();
    } catch (error) {
      redirectIfSessionExpired(history, error)
      enqueueSnackbar("Error adding service request code.", { variant: "error" });
    }
  }

  const deleteRequestCode = async (id: number) => {
    try {
      await requestCodesClient.delete(id);
      getServiceRequestCodes()
      enqueueSnackbar("Service request code deleted successfully.", { variant: "success" });
    } catch (error) {
      redirectIfSessionExpired(history, error)
      enqueueSnackbar("Error deleting service request code.", { variant: "error" });
    }
  }
  const openDeleteDialogue = (id: number) => {
    setIdForDelete(id);
    setDialogMessage(`Are you sure you want to delete service request code #${id}?`);
    setConfirmOpen(true);
  }

  const serviceRequestCodesColums = [
    { title: 'Code', field: 'code', width: 15 },
    { title: 'Description', field: 'description', width: '25%' },
    {
      title: 'Note Template',
      field: 'noteTemplate',
      render: rowData => <TextField
        fullWidth={true}
        multiline
        value={rowData.noteTemplate}
        InputProps={{
          readOnly: true,
          disableUnderline: true,
        }}
      />
    }
  ];

  const tableTooltip = { addTooltip: "Enter Service Request Code" }

  return (
    <Grid item container spacing={2}>
      <Grid item xl={12} lg={12} md={12} sm={12}>
        <Paper elevation={0}>
          <AddTable
            width={'100%'}
            columns={serviceRequestCodesColums}
            title={"Service Request Codes"}
            add={addRequestCode}
            delete={openDeleteDialogue}
            data={serviceRequestCodes as []}
            search={false}
            localization={tableTooltip}
            deleteTooltip={"Delete Service Request Code"}
          />
          {confirmOpen && <ConfirmDialog
            title={"Delete service request code?"}
            open={confirmOpen}
            setOpen={setConfirmOpen}
            onConfirm={() => { deleteRequestCode(idForDelete) }}
          >
            {dialogMessage}
          </ConfirmDialog>
          }
        </Paper>
      </Grid>
    </Grid>
  )
};

ServiceRequestCodes.displayName = 'ServiceRequestCodes';

export default ServiceRequestCodes;