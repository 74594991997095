import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

import ClearIcon from "@material-ui/icons/Clear";
import { DispatchBoardColumnClient } from "../../brines-refrigerator-api";
import { ArrowLeft, ArrowRight } from "@material-ui/icons";

interface techColumnsDialogProps {
  stateId: number;
  resetTechColumnsDialog: Function;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContainer: {
      minWidth: "300px",
      paddingBottom: "1rem",
    },
    columnsContainer: {
      display: "flex",
    },
    techColumnBox: {
      padding: "0.2rem",
      marginRight: "0.3rem",
      border: "solid #3f51b5 1px",
      borderRadius: "2px",
      boxShadow: "3px 3px #DDDDDD",
    },
    arrowButton: {
      minWidth: "40px",
    },
  })
);

const TechColumnsDialog = (props: techColumnsDialogProps) => {
  const classes = useStyles();

  const dispatchBoardColumnClient = new DispatchBoardColumnClient();

  const handleExitDialog = () => {
    props.resetTechColumnsDialog();
  };

  const [techColumns, setTechColumns] = useState([]);
  const [columnsLowestIndex, setColumnsLowestIndex] = useState(null);
  const [columnsHighestIndex, setColumnsHighestIndex] = useState(null);

  useEffect(() => {
    getTechColumns();
  }, [props.stateId]);

  const getTechColumns = async () => {
    const columns = await dispatchBoardColumnClient.getTechColumnsByRegionId(
      props.stateId
    );
    setTechColumns(columns);
    const columnsLowestIndex = Math.min.apply(
      Math,
      columns.map(function (column) {
        return column.index;
      })
    );
    const columnsHighestIndex = Math.max.apply(
      Math,
      columns.map(function (column) {
        return column.index;
      })
    );

    setColumnsLowestIndex(columnsLowestIndex);
    setColumnsHighestIndex(columnsHighestIndex);
  };

  const moveTechColumnToLeft = async (columnId: number) => {
    await dispatchBoardColumnClient.moveTechColumnToLeft(columnId);
    await getTechColumns();
  };

  const moveTechColumnToRight = async (columnId: number) => {
    await dispatchBoardColumnClient.moveTechColumnToRight(columnId);
    await getTechColumns();
  };

  return (
    <Grid container className={classes.dialogContainer}>
      <Grid item xs={10}>
        <Typography variant="h3">Tech columns</Typography>
      </Grid>
      <Grid item xs={2}>
        <Button onClick={handleExitDialog} variant="contained" color="primary">
          SAVE & EXIT
        </Button>
      </Grid>
      <Grid item xs={12} className={classes.columnsContainer}>
        {techColumns.map((techColumn) => (
          <div key={techColumn.id} className={classes.techColumnBox}>
            <Button
              className={classes.arrowButton}
              color="primary"
              disabled={techColumn.index == columnsLowestIndex}
              onClick={() => {
                moveTechColumnToLeft(techColumn.id);
              }}
            >
              <ArrowLeft></ArrowLeft>
            </Button>
            {techColumn.name}
            <Button
              className={classes.arrowButton}
              color="primary"
              disabled={techColumn.index == columnsHighestIndex}
              onClick={() => {
                moveTechColumnToRight(techColumn.id);
              }}
            >
              <ArrowRight></ArrowRight>
            </Button>
          </div>
        ))}
      </Grid>
    </Grid>
  );
};

export default TechColumnsDialog;
