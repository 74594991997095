import React from 'react';
import Button from '@material-ui/core/Button';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';


export default function CancelButton(props) {
  return (
    <div>
      {/* This Button uses a Font Icon, see the installation instructions in the Icon component docs. */}
      <Button
        variant="outlined"
        color="primary"
        size="small"
        id="cancel-btn"
        endIcon={<CancelOutlinedIcon></CancelOutlinedIcon>}
        onClick={props.onClick}
      >
        Cancel
      </Button>
    </div>
  );
}
