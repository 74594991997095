/* eslint-disable no-use-before-define */
import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import { StateVM } from "../../../brines-refrigerator-api";

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function stateToFlag(isoCode: string) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

interface SelectProps {
  states: StateVM[];
  getState: Function;
}

export default function StateSelect(props: SelectProps) {
  const classes = useStyles();

  return (
    <Autocomplete
      onChange={(event: any, newValue: StateType | null) => {
        props.getState(newValue !== null ? Number(newValue.id) : 0);
      }}
      style={{ margin: "0.7rem" }}
      options={props.states as StateType[]}
      classes={{
        option: classes.option,
      }}
      autoHighlight
      getOptionLabel={(option) => option.name}
      renderOption={(option) => (
        <React.Fragment>
          {/* <span>{stateToFlag(option.abbreviation)}</span> */}
          {option.name}
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Choose a state"
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}

interface StateType {
  abbreviation: string;
  name: string;
  id: string;
}
