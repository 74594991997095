import React, { useState, useEffect } from 'react';
import { PriorityClient, PriorityTypeVM } from '../../../brines-refrigerator-api';
import { useTextValidation, validateText } from '../../../helpers/validations';
import { useHistory } from 'react-router-dom';
import { redirectIfSessionExpired } from '../../common/redirect/RedirectOnSessionTimeout';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import { Grid, Paper, Typography, Button, Input, TextField } from '@material-ui/core';
import { ChromePicker } from 'react-color';
import CancelButton from '../../common/dialog/CancelButton';
import BasicTable from '../../common/table/BasicTable';
import './PriorityAndTrades.scss';
import { useSnackbar } from 'notistack';


const Priority = () => {
  const history = useHistory();

  const priorityClient = new PriorityClient();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [tableDataPriority, setPriorityTableData] = useState<{ id?: number, name?: string, hexCode?: string }[]>([]);
  const [selectedColorPriority, setPrioritySelectedColor] = useState('');
  const [colorPriorityInputProps, triggerColorPriorityValidation, resetColorPriorityInputProps] = useTextValidation();
  const [displayPriorityColorPicker, setDisplayPriorityColorPicker] = useState(false);
  const [priorityName, setPriorityName] = useState('');
  const [priorityNameInputProps, triggerPriorityNameValidation, resetPriorityNameInputProps] = useTextValidation();
  const [priorityAction, setPriorityAction] = useState({ id: 0, buttonLabel: 'Add' });

  const setPriorityForEditing = (id: number) => {
    const priority = tableDataPriority.find(p => p.id === id);
    setPrioritySelectedColor(`#${priority.hexCode}`)
    setDisplayPriorityColorPicker(true)
    setPriorityName(priority.name)
    setPriorityAction({ id: id, buttonLabel: 'Edit' })
  }

  const populatePriorityTable = async () => {
    const priorityList = await priorityClient.get();
    setPriorityTableData(priorityList);
  }

  useEffect(() => {
    (async () => {
      try {
        await populatePriorityTable()
      } catch (error) {
        redirectIfSessionExpired(history, error)
        enqueueSnackbar("Error getting priorities.", { variant: "error" });
      }
    })();
  }, []);

  const addEditPriority = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const resultName = validateText(priorityName).error;
    const resultColor = validateText(selectedColorPriority).error;
    if (resultName) {
      triggerPriorityNameValidation(priorityName)
    }
    if (resultColor) {
      triggerColorPriorityValidation(selectedColorPriority)
    }
    if (resultName || resultColor) {
      return;
    }

    const form = e.currentTarget;
    const name = form.priority_text.value;
    const hexCode = selectedColorPriority.split('#')[1];

    const priorityItem = new PriorityTypeVM({
      id: priorityAction.id,
      name,
      hexCode
    })
    let successMessage = 'Priority added successfully.'
    try {
      if (priorityAction.id) {
        await priorityClient.update(priorityItem)
        successMessage = 'Priority edited successfully.'
      } else {
        await priorityClient.create(priorityItem)
      }
      enqueueSnackbar(successMessage, { variant: "success" });
      populatePriorityTable()
      form.reset();
      resetPriority()
    } catch (error) {
      redirectIfSessionExpired(history, error)
      enqueueSnackbar(priorityAction.id
        ? 'Error while editing priority.'
        : 'Error while adding new priority.', { variant: "error" });
    }
  }

  const resetPriority = () => {
    setPrioritySelectedColor('')
    resetPriorityNameInputProps()
    resetColorPriorityInputProps()
    setDisplayPriorityColorPicker(false)
    setPriorityName('')
    setPriorityAction({ id: 0, buttonLabel: 'Add' })
  }

  const handleClickPriorityColorPicker = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setDisplayPriorityColorPicker(!displayPriorityColorPicker)
  };

  const handleClosePriorityColorPicker = () => {
    setDisplayPriorityColorPicker(false)
    triggerColorPriorityValidation(selectedColorPriority)
  };

  const priorityTableColumns = [
    { title: 'Color', field: 'hexCode' },
    { title: 'Priority', field: 'name' },
  ];

  const priorityTableActions = [
    rowData => ({
      icon: () => <Edit color='primary' />,
      onClick: (event, rowData: unknown) => { setPriorityForEditing((rowData as { id: number }).id) },
      tooltip: "Edit Priority"
    }),
    rowData => ({
      icon: () => <Delete color='primary' />,
      onClick: (event, rowData: unknown) => { removePriority((rowData as { id: number }).id) },
      tooltip: "Delete Priority"
    })
  ]

  const removePriority = async (id: number) => {
    try {
      await priorityClient.delete(id);
      enqueueSnackbar("Priority deleted successfully.", { variant: "success" });
      await populatePriorityTable()
    } catch (error) {
      redirectIfSessionExpired(history, error)
      enqueueSnackbar("Error deleting priority.", { variant: "error" });
    }
  }

  return (
    <>
      <Grid item container xl={3} lg={4} md='auto' sm={6} className='priority_and_trades_form_container'>
        <Paper elevation={0}>
          <Grid item container>
            <form onSubmit={addEditPriority}>
              <Grid item container className='priority_and_trades_form_title'>
                <Grid item lg={10}>
                  <Typography variant="h2">Priority</Typography>
                </Grid>
                <Grid item lg={2}>
                  <CancelButton onClick={resetPriority} />
                </Grid>
              </Grid>
              <div className='priority_and_trades_form_add_data'>
                <Grid item container justify='space-evenly' alignItems='center' className='priority_and_trades_form_add_data_container'>
                  <Grid item lg={3} sm='auto'>
                    {selectedColorPriority === '' && <span>
                      <Button
                        color={colorPriorityInputProps.error ? 'secondary' : 'default'}
                        onClick={handleClickPriorityColorPicker}>
                        Pick color
                                            </Button>
                      <p className="validation_error">
                        {colorPriorityInputProps.errorMessage}
                      </p>
                    </span>}
                    {selectedColorPriority !== ''
                      && <span className='priority_and_trades_color_input'>
                        <Input type="color" id="favcolor" name="favcolor"
                          placeholder="Pick color" disableUnderline={true}
                          value={selectedColorPriority}
                          onClick={handleClickPriorityColorPicker} />
                      </span>}
                    {displayPriorityColorPicker &&
                      <span className='priority_and_trades_popover'>
                        <span className='priority_and_trades_cover' onClick={handleClosePriorityColorPicker} />
                        <ChromePicker
                          disableAlpha={true}
                          color={selectedColorPriority}
                          onChange={(color) => setPrioritySelectedColor(color.hex)} />
                      </span>}
                  </Grid>
                  <Grid item lg={6} sm='auto'>
                    <TextField
                      name='priority_text'
                      label='Enter priority'
                      variant='outlined'
                      className='priority_and_trades_form_add_data_container_input'
                      margin='dense'
                      value={priorityName}
                      onChange={e => {
                        setPriorityName(e.target.value)
                        if (priorityNameInputProps.error) {
                          triggerPriorityNameValidation(e.target.value)
                        }
                      }}
                      error={priorityNameInputProps.error}
                      onBlur={() => triggerPriorityNameValidation(priorityName)}
                    />
                    <p className="validation_error">
                      {priorityNameInputProps.errorMessage}
                    </p>
                  </Grid>
                  <Grid item lg={2} md='auto' className='priority_and_trades_form_add_data_container_button'>
                    <Button type='submit' variant='contained' color='primary' disableElevation data-testid='event-add-priority'>
                      {priorityAction.buttonLabel}
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </form>
            <Grid item container>
              <BasicTable
                width={'100%'}
                columns={priorityTableColumns}
                data={tableDataPriority.map(p => ({
                  ...p,
                  hexCode: <div className='priority_and_trades_table_cell' style={{ background: `#${p.hexCode}` }}></div>
                })) as []}
                actions={priorityTableActions}
                isLoading={false}
                paging={true}
                search={false}
                selection={false}
                components={{
                  Container: props => <Paper {...props} elevation={0} />,
                  Toolbar: () => (
                    <></>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
}
export default Priority;