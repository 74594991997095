import React, { useState, useEffect } from "react";
import { makeStyles, Grid, Paper, Button } from "@material-ui/core";
import { MTableToolbar } from "material-table";
import { EquipmentVM } from "../../brines-refrigerator-api";
import BasicTable from "../../components/common/table/BasicTable";
import CancelButton from "../../components/common/dialog/CancelButton";
import * as client from "../../helpers/offline/offline-api";
import { useSnackbar } from "notistack";

interface TechEquipmentAddProps {
  closeEquipmentModal: Function;
  updateDispatchEquipment: Function;
}

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    "& #cancel-btn": {
      marginLeft: "2.5em",
      fontSize: "0.6rem",
    },
  },
  button: {
    marginBottom: "1em",
  },
  equipmentTable: {
    height: "30rem",
  },
  addEquipmentTableButton: {
    marginLeft: "67%",
  },
  highlight: {
    backgroundColor: "#3f51b5cc",
    "& .MuiTypography-h6": {
      color: "#ffffff",
    },
  },
});

const TechEquipmentAdd = (props: TechEquipmentAddProps) => {
  const classes = useStyles();

  const [selectedEquipment, setSelectedEquipment] = useState([]);
  const [equipmentTableIsLoading, setTableLoading] = useState(true);
  const [equipmentList, setEquipmentList] = useState([]);

  const [_getEquipmentAll] = client.useGetEquipmentAll();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const getEquipment = async () => {
    try {
      const equipment = await _getEquipmentAll();
      setEquipmentList(
        equipment.map((e: EquipmentVM) => ({
          id: Number(e.id),
          model: e.model,
          manufacturer: e.manufacturer,
          serialNumber: e.serialNumber,
          roomLocation: e.roomLocation,
        }))
      );
    } catch (error) {
      enqueueSnackbar("Error getting equipment.", { variant: "error" });
    }
    setTableLoading(false);
  };

  useEffect(() => {
    getEquipment();
  }, []);

  const addSelectedEquipmentsToDispatch = async () => {
    props.updateDispatchEquipment(selectedEquipment);
    closeEquipmentModal();
    setEquipmentList(
      equipmentList.map((e) => ({ ...e, tableData: { checked: false } }))
    );
    setTableLoading(false);
  };

  const equipmentToList = (rows) => {
    setSelectedEquipment(rows);
  };

  const closeEquipmentModal = () => {
    props.closeEquipmentModal();
  };

  const equipmentColumns = [
    { title: "#", field: "id" },
    { title: "Model", field: "model" },
    { title: "Manufacturer", field: "manufacturer" },
    { title: "Serial Number", field: "serialNumber" },
    { title: "Room Location", field: "roomLocation" },
  ];

  return (
    <div className="equipment-modal">
      <div className={classes.root}>
        <Grid container className={classes.equipmentTable}>
          <Grid item xs={12}>
            <BasicTable
              width={"100%"}
              title={"Add Equipment"}
              data={equipmentList as []}
              columns={equipmentColumns}
              selection={true}
              components={{
                Container: (props) => <Paper {...props} elevation={0} />,
                Toolbar: (props) => (
                  <Grid
                    container
                    item
                    xs={12}
                    justify="flex-start"
                    alignItems="center"
                  >
                    <Grid item xs={8}>
                      <MTableToolbar
                        classes={{ highlight: classes.highlight }}
                        {...props}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <CancelButton onClick={closeEquipmentModal} />
                    </Grid>
                  </Grid>
                ),
              }}
              onSelectionChange={equipmentToList}
              search={false}
              paging={false}
              isLoading={equipmentTableIsLoading}
            />
          </Grid>
          <Grid className={classes.addEquipmentTableButton} item xs={4}>
            <Button
              variant="contained"
              color="primary"
              onClick={addSelectedEquipmentsToDispatch}
            >
              Add Selected
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
export default TechEquipmentAdd;
