import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./global-state/store";
import { ThemeProvider, createMuiTheme } from "@material-ui/core";
import { SnackbarProvider } from "notistack";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

// Sentry.init({
//   dsn: "https://6153080ff4b24415a8d5b6d60dec0e79@o4503913300951040.ingest.sentry.io/4503913308160000",
//   beforeSend(event, hint) {
//     // Check if it is an exception, and if so, show the report dialog
//     if (event.exception) {
//       Sentry.showReportDialog({ eventId: event.event_id });
//     }
//     debugger;
//     return event;
//   },
//   integrations: [new BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

const theme = createMuiTheme({
  typography: {
    h1: {
      fontSize: "1.5rem",
      fontWeight: "bold",
      color: "#000000",
    },
    h2: {
      fontSize: "1.3125rem",
      fontWeight: "bold",
      color: "#000000",
    },
    h3: {
      fontSize: "1.3125rem",
      fontWeight: 300,
      color: "#3f51b5",
    },
    h6: {
      fontSize: "1.3125rem",
      fontWeight: "bold",
      color: "#000000",
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={10}>
          <App />
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
