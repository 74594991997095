import React, { useEffect, useState } from 'react';

import { Button, Grid, Typography, FormControl, FormLabel, FormControlLabel, Radio, RadioGroup, Checkbox, FormGroup } from '@material-ui/core';

import './ClockInOut.scss';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useHistory, useLocation } from 'react-router-dom';
import { VisitClockInVM, ClockInArriveOnSiteVM, ClockInAnswerCreateVM } from '../../../brines-refrigerator-api';
import * as client from '../../../helpers/offline/offline-api';
import { useSnackbar } from 'notistack';

const TechClockIn: React.FC = () => {
    const history = useHistory();
    const location = useLocation();
    const [disabled, setDisabled] = useState(false);
    const [_getClockInQuestions] = client.useGetClockInQuestions()
    const [_visitEnterSite] = client.useVisitEnterSite()

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    function handleBackClick() {
        history.goBack();
    }

    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        getQuestions();
    }, []);

    const getQuestions = async () => {
        const questions = await _getClockInQuestions();
        setQuestions(questions.map(question => ({
            ...question, answer: null
        })));
    }

    const submitAnswers = async () => {
        if (validateAnswers()) {
            try {
                const preparedAnswers = questions.map((question) => { return new ClockInAnswerCreateVM({ clockInQuestionId: question.id, answer: question.answer }) });

                const clockIn = new ClockInArriveOnSiteVM({
                    clockInAnswers: preparedAnswers
                })

                // const visitsClient = new VisitsClient();
                const _answers = new VisitClockInVM({
                    dispatchId: (location.state as { dispatchId: number }).dispatchId,
                    clockIn: clockIn,
                    arrivedOnSiteTime: new Date(),
                    visitId: (location.state as { visitId: number }).visitId,
                });

                setDisabled(true);
                //clock in is displayed to tech as "on site"
                _visitEnterSite(_answers);
                enqueueSnackbar("Successfully checked in to site!!!", { variant: "success" });
                setTimeout(handleBackClick, 2000);
            }
            catch (error) {
                enqueueSnackbar("Error checking in to site!", { variant: "error" })
            }
        }
    }

    const handleRadioAnswerChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        //questions contain both the question and answer info in order to handle change/submit/render logic and validation simpler
        const newQuestions = [...questions];
        newQuestions[index].answer = event.target.value === "true" ? true : false;
        setQuestions(newQuestions);
        validateAnswers();
    }
    const handleCheckboxAnswerChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        //questions contain both the question and answer info in order to handle change/submit/render logic and validation simpler
        const newQuestions = [...questions];
        newQuestions[index].answer = event.target.checked;
        setQuestions(newQuestions);
        validateAnswers();
    }

    const [validationState, setValidationState] = useState({
        isTouched: false,
        areAllQuestionsAnswered: false,
        areAllMandatoryQuestionsConfirmed: false
    });

    const validateAnswers = () => {
        const validationResult = {
            isTouched: true,
            areAllQuestionsAnswered: questions.every(question => question.answer != null),
            areAllMandatoryQuestionsConfirmed: questions.filter(question => question.isMandatory).every(question => !!question.answer)
        }

        setValidationState({
            ...validationResult
        });

        return Object.values(validationResult).every(validationRuleValidated => validationRuleValidated);
    }

    return (
        <div>
            <Button startIcon={<ArrowBackIosIcon />} onClick={handleBackClick} color="primary">Back</Button>
            <Grid className='clock-io' container>
                <Typography variant="body1" className='clock-io_desc'>
                    Please answer these questions before starting work on the dispatch.
                </Typography>
                <Grid container item xs={12} className='clock-io_questions'>
                    {questions.map((question, index) => {
                        return (
                            <Grid key={question.id} container item xs={12} className='clock-io_question'>
                                <Grid item xs={12} className='clock-io_question-answers'>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend" className='clock-io_question-desc'>{question.isMandatory ? `${question.questionText}*` : question.questionText}</FormLabel>
                                        {question.isMandatory ?
                                            <FormGroup>
                                                <FormControlLabel
                                                    label={question.affirmativeAnswerText}
                                                    control={<Checkbox
                                                        value={question.answer}
                                                        name={`question-${index}-answer`}
                                                        onChange={(e) => handleCheckboxAnswerChange(e, index)}
                                                    />}
                                                /></FormGroup> :
                                            <RadioGroup aria-label={`question-${index}-answer`} name={`question-${index}-answer`} value={question.answer} onChange={(e) => { handleRadioAnswerChange(e, index) }}>
                                                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                                <FormControlLabel value={false} control={<Radio />} label="No" />
                                            </RadioGroup>
                                        }
                                    </FormControl>
                                </Grid>
                            </Grid>
                        );
                    })}
                    {validationState.isTouched && Object.values(validationState).some(validationRule => !validationRule) && <Grid item className="validation_errors" xs={12}>
                        {!validationState.areAllQuestionsAnswered && <span>
                            Please answer all questions.
                        </span>}
                        {!validationState.areAllMandatoryQuestionsConfirmed && <span>
                            Please confirm mandatory checks*
                        </span>}
                    </Grid>}
                    <Grid item xs={12}>
                        <Button className='clock-io_submit' type='submit' color='primary' variant='contained' size='large' disabled={disabled} onClick={submitAnswers} disableElevation>SUBMIT</Button>
                    </Grid>
                </Grid>

            </Grid>
        </div>
    )

}

export default TechClockIn;