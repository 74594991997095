import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import clsx from "clsx";
import {
  AppBar,
  Toolbar,
  Button,
  Grid,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  InputBase,
  Paper,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import { NavLink } from "react-router-dom";
import "./Header.scss";
import UserRole from "../helpers/constants/userRole";
import * as client from "../helpers/offline/offline-api";
import { useSnackbar } from "notistack";
import { DispatchClient } from "../brines-refrigerator-api";

type Anchor = "left";

const useStyles = makeStyles((theme) => ({
  active: {
    backgroundColor: "rgba(255, 255, 255, 0.12)",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
}));

interface HeaderProps {
  setUserAuthenticated: Function;
  getConnectionId: Function;
}

const Header = (props: HeaderProps) => {
  const classes = useStyles();
  const [state, setState] = useState({ left: false });
  const [searchText, setSearchText] = useState<number>(0);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();

  const dispatchClient = new DispatchClient();

  // Get user data from session storage
  let userData: any = JSON.parse(sessionStorage.getItem("userData") || "{}");

  // Map user roles
  let role = userData.role.name;

  const openDispatchFromHistory = (dispatchId) => {
    history.push({
      pathname: "/dispatch",
      state: { dispatchIdFromLocationView: dispatchId },
    });
  };

  async function searchDispatches(workOrderNumber: number) {
    try {
      const dispatches = await dispatchClient.get(
        undefined,
        undefined,
        undefined,
        workOrderNumber
      );
      if (dispatches.length > 0) {
        openDispatchFromHistory(dispatches[0].id);
      }
    } catch (error) {
      enqueueSnackbar("Error getting dispatches.", { variant: "error" });
    }
  }

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "left",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {role === UserRole.Admin && (
        <List>
          <ListItem button component={Link} to="/admin">
            <ListItemText primary="Admin" />
          </ListItem>
        </List>
      )}
      <List>
        <ListItem button component={Link} to="/my-account">
          <ListItemText primary="My Account" />
        </ListItem>
      </List>
      {role !== UserRole.Technician && (
        <List>
          <ListItem button component={Link} to="/customers">
            <ListItemText primary="Customers" />
          </ListItem>
        </List>
      )}
      {role !== UserRole.Technician && (
        <List>
          <ListItem button component={Link} to="/locations">
            <ListItemText primary="Locations" />
          </ListItem>
        </List>
      )}
      {role !== UserRole.Technician && (
        <List>
          <ListItem button component={Link} to="/equipment">
            <ListItemText primary="Equipment" />
          </ListItem>
        </List>
      )}
      {role !== UserRole.Technician && (
        <List>
          <ListItem button component={Link} to="/dispatch">
            <ListItemText primary="Dispatch" />
          </ListItem>
        </List>
      )}
      {role !== UserRole.Technician && (
        <List>
          <ListItem button component={Link} to="/completed-dispatches">
            <ListItemText primary="Completed Dispatches" />
          </ListItem>
        </List>
      )}
      {role !== UserRole.Technician && (
        <List>
          <ListItem button component={Link} to="/dispatch-board">
            <ListItemText primary="Board" />
          </ListItem>
        </List>
      )}
      {role === UserRole.Technician && (
        <List>
          <ListItem button component={Link} to="/tech-view">
            <ListItemText primary="Dispatches" />
          </ListItem>
        </List>
      )}
      {role === UserRole.Technician && (
        <List>
          <ListItem button component={Link} to="/tech-dispatch-history">
            <ListItemText primary="Dispatch History" />
          </ListItem>
        </List>
      )}
      {role !== UserRole.Technician && (
        <List>
          <ListItem button component={Link} to="/follow-up">
            <ListItemText primary="Follow Up / Due Date" />
          </ListItem>
        </List>
      )}
      {role !== UserRole.Technician && role !== UserRole.Dispatcher && (
        <List>
          <ListItem button component={Link} to="/invoices">
            <ListItemText primary="Invoices" />
          </ListItem>
        </List>
      )}
      {/* {(role !== UserRole.Technician) && <List>
                <ListItem button component={Link} to='/logs'>
                    <ListItemText primary='Logs' />
                </ListItem>
            </List>} */}
      {role !== UserRole.Technician && role !== UserRole.Dispatcher && (
        <List>
          <ListItem button component={Link} to="/tax-rates">
            <ListItemText primary="Tax Rates" />
          </ListItem>
        </List>
      )}
      {role !== UserRole.Technician && role !== UserRole.Dispatcher && (
        <List>
          <ListItem button component={Link} to="/reporting">
            <ListItemText primary="Reporting" />
          </ListItem>
        </List>
      )}
      <List>
        <ListItem button component={Link} to="/">
          <ListItemText primary="Log out" />
        </ListItem>
      </List>
    </div>
  );

  const HamburgerMenu: any = () => {
    const classes = useStyles();
    return (
      <>
        {(["left"] as Anchor[]).map((anchor) => (
          <React.Fragment key={anchor}>
            <IconButton
              onClick={toggleDrawer(anchor, true)}
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
            >
              {list(anchor)}
            </Drawer>
          </React.Fragment>
        ))}
      </>
    );
  };

  const logOutUser = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();

    sessionStorage.setItem("userData", null);

    await client.logOut(props.getConnectionId());

    props.setUserAuthenticated(false);
  };

  return (
    <AppBar position="static" elevation={0}>
      <Toolbar>
        {window.innerWidth < 780 && <HamburgerMenu />}
        <Grid item container alignItems="center" wrap="nowrap">
          <Grid container className="header_navlinks" item sm={8} xl={8}>
            {role !== UserRole.Technician && (
              <ListItem
                button
                component={NavLink}
                exact
                to="/customers"
                activeClassName={classes.active}
              >
                Customers
              </ListItem>
            )}
            {role !== UserRole.Technician && (
              <ListItem
                button
                component={NavLink}
                exact
                to="/locations"
                activeClassName={classes.active}
              >
                Locations
              </ListItem>
            )}
            {role !== UserRole.Technician && (
              <ListItem
                button
                component={NavLink}
                exact
                to="/equipment"
                activeClassName={classes.active}
              >
                Equipment
              </ListItem>
            )}
            {role !== UserRole.Technician && (
              <ListItem
                button
                component={NavLink}
                exact
                to="/dispatch"
                activeClassName={classes.active}
              >
                Dispatch
              </ListItem>
            )}
            {role !== UserRole.Technician && (
              <ListItem
                button
                component={NavLink}
                exact
                to="/completed-dispatches"
                activeClassName={classes.active}
              >
                Completed Dispatches
              </ListItem>
            )}
            {role !== UserRole.Technician && (
              <ListItem
                button
                component={NavLink}
                exact
                to="/dispatch-board"
                activeClassName={classes.active}
              >
                Board
              </ListItem>
            )}
            {role !== UserRole.Technician && role !== UserRole.Dispatcher && (
              <ListItem
                button
                component={NavLink}
                exact
                to="/invoices"
                activeClassName={classes.active}
              >
                Invoices
              </ListItem>
            )}
            {role === UserRole.Technician && (
              <ListItem
                button
                component={NavLink}
                exact
                to="/tech-view"
                activeClassName={classes.active}
              >
                Dispatches
              </ListItem>
            )}
            {role === UserRole.Technician && (
              <ListItem
                button
                component={NavLink}
                exact
                to="/tech-dispatch-history"
                activeClassName={classes.active}
              >
                Dispatch History
              </ListItem>
            )}
            {role !== UserRole.Technician && (
              <ListItem
                button
                component={NavLink}
                exact
                to="/follow-up"
                activeClassName={classes.active}
              >
                Follow Up / Due Date
              </ListItem>
            )}
            {role !== UserRole.Technician && role !== UserRole.Dispatcher && (
              <ListItem
                button
                component={NavLink}
                exact
                to="/tax-rates"
                activeClassName={classes.active}
              >
                Tax Rates
              </ListItem>
            )}
            {role !== UserRole.Technician && role !== UserRole.Dispatcher && (
              <ListItem
                button
                component={NavLink}
                exact
                to="/reporting"
                activeClassName={classes.active}
              >
                Reporting
              </ListItem>
            )}
            {/* {role !== UserRole.Technician && (
              <ListItem
                button
                component={NavLink}
                exact
                to="/logs"
                activeClassName={classes.active}
              >
                Logs
              </ListItem>
            )} */}
            {role === UserRole.Admin && (
              <ListItem
                button
                component={NavLink}
                exact
                to="/admin"
                activeClassName={classes.active}
              >
                Admin
              </ListItem>
            )}
          </Grid>
          <Grid item container justify="flex-end" sm={4} xl={4} xs={12}>
            {role !== UserRole.Technician && (
              <Paper
                component="form"
                style={{
                  padding: "2px 4px",
                  // display: "flex",
                  // alignItems: "center",
                  // width: 400,
                }}
              >
                <InputBase
                  // style={{ backgroundColor: "white" }}
                  placeholder="Search Dispatch by workorder"
                  type="number"
                  onChange={(e: any) => setSearchText(e.target.value)}
                />
                <IconButton
                  onClick={() => searchDispatches(searchText)}
                  // className={classes.menuButton}
                  color="inherit"
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            )}
            <ListItem
              button
              component={NavLink}
              exact
              to="/my-account"
              activeClassName={classes.active}
            >
              My Account
            </ListItem>
            <Button color="inherit" onClick={logOutUser}>
              Log out
            </Button>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

Header.displayName = "Header";

export default Header;
