import React, { useState, useEffect } from "react";

import {
  Button,
  Grid,
  makeStyles,
  Modal,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Edit from "@material-ui/icons/Edit";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import { useSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ErrorIcon from "@material-ui/icons/Error";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import TimerOffIcon from "@material-ui/icons/TimerOff";

import {
  InvoiceClient,
  LocationClient,
  QbClient,
  QbRequestAddVM,
} from "../../brines-refrigerator-api";
import BasicTable from "../../components/common/table/BasicTable";
import ConfirmDialog from "../../components/common/dialog/ConfirmationDialog";
import { MTableToolbar } from "material-table";
import { QbGuide } from "./QbGuide";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "1rem 2.5rem",
  },
  loadingCircle: {
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
  invoiceTable: {
    marginBottom: "2rem",
  },
  statusCell: {
    display: "flex",
    alignItems: "center",
  },
  iconSvg: {
    filter: "opacity(75%)",
  },
  qbTableButton: {
    marginRight: "0.375rem",
    marginBottom: "0.375rem",
  },
}));

const InvoiceList = () => {
  const invoiceClient = new InvoiceClient();
  const qbClient = new QbClient();
  const classes = useStyles();
  const history = useHistory();
  const [invoices, setInvoices] = useState([]);
  const [qbRequests, setQbRequests] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  const dateTimeFormat = new Intl.DateTimeFormat("en-US", { ...options });

  const invoiceColumns = [
    { title: "Invoice #", field: "number" },
    { title: "Work Order #", field: "workOrderNumber" },
    { title: "Customer", field: "customer" },
    { title: "Created", field: "created" },
    { title: "Follow Up", field: "followUpDate" },
  ];

  const qbRequestColumns = [
    { title: "Id - Request type", field: "id", width: 150 },
    { title: "Created", field: "timeCreated" },
    { title: "Updated", field: "timeUpdated" },
    {
      title: "Status",
      field: "status",
      render: (rowData) => renderStatus(rowData.statusId),
    },
    { title: "Error", field: "error", width: 700 },
    {
      title: "ErrorCode",
      field: "errorCode",
      width: 30,
    },
    { title: "QbTicket#", field: "ticket", width: 180 },
  ];

  const actions = [
    () => ({
      icon: () => <Edit color="primary" />,
      onClick: (event, rowData: unknown) => {
        openInvoice((rowData as { id: number }).id);
      },
      tooltip: "Edit Invoice",
    }),
    // () => ({
    //   icon: () => (
    //     <ImportExportIcon color="primary">QB export</ImportExportIcon>
    //   ),
    //   onClick: (event, rowData: unknown) => {
    //     exportInvoice((rowData as { id: number }).id);
    //   },
    //   tooltip: "Export invoice to QB desktop",
    // }),
  ];

  const [confirm, setConfirmOpen] = useState(false);
  const [openedInvoiceId, setOpenedInvoiceId] = useState(null);
  const [openedLocation, setOpenedLocation] = useState(null);

  const confirmTaxRatesSetDialogue = () => {
    history.push({
      pathname: "/tax-rates",
      state: { locationId: openedLocation },
    });
  };

  const cancelTaxRatesSetDialogue = () => {
    history.push({
      pathname: "/invoice",
      state: { invoiceId: openedInvoiceId },
    });
  };

  const openInvoice = async (id: number) => {
    setOpenedInvoiceId(id);
    const locationsClient = new LocationClient();
    const result = await locationsClient.checkTaxRatesSet(id);
    setOpenedLocation(result.id);
    if (!result.areTaxRatesSet) {
      setConfirmOpen(true);
    } else {
      history.push({
        pathname: "/invoice",
        state: { invoiceId: id },
      });
    }
  };

  const exportInvoice = async (invoiceId: number) => {
    //call back end endpoint for exporting invoice through web connector with SOAP
    try {
      await qbClient.exportInvoiceToQb(
        new QbRequestAddVM({
          invoiceId: invoiceId,
        })
      );
      enqueueSnackbar(
        "Added invoice to QB export queue. Wait up to a minute for synch.",
        { variant: "success" }
      );
      await getQbRequests();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      await getQbRequests();
    }
  };

  const getInvoices = async () => {
    try {
      const invoices = await invoiceClient.get();
      const formattedInvoices = invoices.map((invoice) => ({
        id: invoice.id,
        number: invoice.number,
        workOrderNumber: invoice.dispatch.workOrderNumber,
        customer: invoice.dispatch.location.customer.company,
        created: dateTimeFormat.format(invoice.created),
        followUpDate: dateTimeFormat.format(invoice.dispatch.followUpDate),
        inTaxReview: invoice.inTaxReview,
      }));
      setInvoices(formattedInvoices);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const getQbRequests = async () => {
    try {
      const qbRequests = await qbClient.get();
      const formattedQbRequests = qbRequests.map((request) => {
        return {
          id: `#${request.id} ${request.qbRequestType.name}`,
          timeCreated: dateTimeFormat.format(request.timeCreated),
          timeUpdated: dateTimeFormat.format(request.timeUpdated),
          error: formatError(request.error),
          errorCode: request.errorCode,
          ticket: request.ticket,
          statusId: request.qbRequestStatusId,
          author: request.authorId,
        };
      });
      setQbRequests(formattedQbRequests);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const formatError = (errorMessage: string) => {
    if (errorMessage) {
      return errorMessage.replaceAll("&quot;", '"');
    }
    return "";
  };

  const renderStatus = (statusId: number) => {
    //TODO: make a small simple component for these below?
    switch (statusId) {
      case 10:
        return (
          <Typography className={classes.statusCell}>
            <AddCircleIcon htmlColor="#1a1a1a" className={classes.iconSvg} />
            Created
          </Typography>
        );

      case 20:
        return (
          <Typography className={classes.statusCell}>
            <ErrorIcon htmlColor="#ff0033" className={classes.iconSvg} />
            SOAP Server Error
          </Typography>
        );

      case 30:
        return (
          <Typography className={classes.statusCell}>
            <CircularProgress
              size="22px"
              color="primary"
              className={classes.loadingCircle}
            />
            QB Export Queue
          </Typography>
        );

      case 40:
        return (
          <Typography className={classes.statusCell}>
            <ErrorIcon htmlColor="#ff0033" className={classes.iconSvg} />
            QB Desktop Error
          </Typography>
        );

      case 45:
        return (
          <Typography className={classes.statusCell}>
            <ErrorIcon htmlColor="#ff0033" className={classes.iconSvg} />
            QBXML Parse Error
          </Typography>
        );

      case 50:
        return (
          <Typography className={classes.statusCell}>
            <CheckCircleIcon htmlColor="#55a362" className={classes.iconSvg} />
            Processed
          </Typography>
        );

      case 60:
        return (
          <Typography className={classes.statusCell}>
            <TimerOffIcon htmlColor="#ffae42" className={classes.iconSvg} />
            Expired
          </Typography>
        );

      default:
        break;
    }
  };

  useEffect(() => {
    getInvoices();
    getQbRequests();
  }, []);

  const [isQbGuideModalOpen, setIsQbGuideModalOpen] = useState(false);
  const openQbGuide = () => {
    setIsQbGuideModalOpen(true);
  };

  const closeQbGuide = () => {
    setIsQbGuideModalOpen(false);
  };

  const updateQbRequests = async () => {
    const qbClient = new QbClient();

    await qbClient.updateRequests();
    await getQbRequests();
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item container xs={12} className={classes.invoiceTable}>
          <Grid item xs={6}>
            <BasicTable
              width={"99%"}
              title="Invoices"
              columns={invoiceColumns}
              data={invoices.filter((i) => !i.inTaxReview) as []}
              actions={actions}
              search={true}
              isLoading={false}
              paging={true}
            />
          </Grid>
          <Grid item xs={6}>
            <BasicTable
              width={"99%"}
              title="Invoices for Tax Review"
              columns={invoiceColumns}
              data={invoices.filter((i) => i.inTaxReview) as []}
              actions={actions}
              search={true}
              isLoading={false}
              paging={true}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <BasicTable
            title="Qb request queue"
            columns={qbRequestColumns}
            data={qbRequests as []}
            search={false}
            isLoading={false}
            sorting={false}
            paging={true}
            pageSize={10}
            fontSize={14}
            components={{
              Toolbar: (propss) => (
                <Grid
                  container
                  item
                  xs={12}
                  justify="flex-start"
                  alignItems="center"
                >
                  <Grid item xs={10}>
                    <MTableToolbar {...propss} />
                  </Grid>
                  <Grid
                    item
                    container
                    xs={2}
                    alignContent="flex-end"
                    alignItems="flex-end"
                    justify="space-around"
                  >
                    <Tooltip title="View Qb Guide">
                      <div>
                        <Button
                          onClick={openQbGuide}
                          variant="contained"
                          color="primary"
                          className={classes.qbTableButton}
                        >
                          GUIDE
                        </Button>
                        <Button
                          onClick={updateQbRequests}
                          variant="contained"
                          color="primary"
                          className={classes.qbTableButton}
                        >
                          UPDATE REQUESTS STATUS
                        </Button>
                      </div>
                    </Tooltip>
                  </Grid>
                </Grid>
              ),
            }}
          />
        </Grid>
        {confirm && (
          <ConfirmDialog
            title={"Set tax rates?"}
            open={confirm}
            setOpen={setConfirmOpen}
            onConfirm={() => {
              confirmTaxRatesSetDialogue();
            }}
            onCancel={() => {
              cancelTaxRatesSetDialogue();
            }}
          >
            {
              "Tax rates haven't been set for this invoice's location. Would you like to set them?"
            }
          </ConfirmDialog>
        )}
        {isQbGuideModalOpen && (
          <Modal open={isQbGuideModalOpen} onClose={closeQbGuide}>
            <QbGuide />
          </Modal>
        )}
      </Grid>
    </div>
  );
};

export default InvoiceList;
