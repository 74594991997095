import React, { useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Button, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { SignatureVM } from '../../../brines-refrigerator-api';
import * as client from '../../../helpers/offline/offline-api';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%'
        },
        item: {
            display: 'grid',
            width: 'inherit'
        },
        itemHeader: {
            display: 'flex',
            width: 'inherit'
        },
        widthInherit: {
            width: 'inherit'
        },
        preview: {
            width: 'fit-content',
            border: '0.125rem solid',
            padding: '0.125rem'
        }
    }),
);

export default function SignatureSection(props: { signatures: SignatureVM[] }) {
    const dateTimeFormat = new Intl.DateTimeFormat('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
    const classes = useStyles();
    const [signaturePreview, setSignaturePreview] = useState({ id: -1, signature: '' })
    const [_preview] = client.usePreview()

    const viewSignature = async (id: number, fileId: number) => {
        const file = await _preview(fileId);
        let reader = new FileReader();
        reader.readAsDataURL(file.data);
        reader.onloadend = function () {
            setSignaturePreview({ id, signature: reader.result as string });
        }
    }

    return (
        <div className={classes.root}>
            <List>
                {props.signatures.map(s =>
                    <ListItem>
                        <div className={classes.item}>
                            <div className={classes.itemHeader}>
                                <ListItemText
                                    primary={
                                        <React.Fragment>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                color="textSecondary"
                                            >
                                                {dateTimeFormat.format(s.created)}
                                            </Typography>
                                        </React.Fragment>
                                    }
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                component="span"
                                                variant="body1"
                                                color="textPrimary"
                                            >
                                                {s.name}
                                            </Typography>
                                        </React.Fragment>
                                    }
                                />
                                <Button onClick={() => viewSignature(s.id, s.documentFileId)}>View</Button>
                            </div>
                            <div className={classes.widthInherit}>
                                {signaturePreview.id === s.id
                                    ? <img className={classes.widthInherit} src={signaturePreview.signature} />
                                    : null}
                            </div>
                        </div>
                    </ListItem>
                )}
            </List>
        </div>
    );
}