import React, { useEffect, useState } from 'react'
import { Paper, Grid, Button, Typography, TextField, makeStyles } from '@material-ui/core'
import CancelButton from '../dialog/CancelButton';
import { useTextValidation, validateText } from '../../../helpers/validations';
import ConfirmDialog from '../dialog/ConfirmationDialog';

interface AddNoteProps {
  noteText: string;
  buttonAction: Function;
  id: number;
  title: string;
  buttonText: string;
  onClick: Function;
  uid: string;
}

const useStyles = makeStyles({
  marginBotom: {
    marginBottom: "1em"
  },
  marginTop: {
    marginTop: "1em"
  }
});

export default function AddNote(props: AddNoteProps) {
  const [initalNoteText, setInitialNoteText] = useState('')
  const [noteText, setNoteText] = useState(props.noteText)
  const [confirmDialog, setConfirmDialog] = useState(false)

  const classes = useStyles();

  const handleNoteTextChange = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let noteText = e.target.value;
    setNoteText(noteText)

    if (noteInputProps.error) {
      triggerNoteInputValidation(noteText, false);
    }
  }

  const [noteInputProps, triggerNoteInputValidation, resetNoteInputProps] = useTextValidation();

  const handleNoteModalSubmit = () => {
    if (validateNoteModal()) props.buttonAction(props.id, noteText, props.uid);
  }

  const validateNoteModal = () => {
    const formValid = !validateText(noteText).error;

    if (!formValid) triggerNoteInputValidation(noteText, false);

    return formValid;
  }

  useEffect(() => {
    setInitialNoteText(JSON.stringify(props.noteText))
  }, [props]);

  const onCancel = () => {
    if (initalNoteText !== JSON.stringify(noteText)) {
      setConfirmDialog(true)
    } else {
      props.onClick()
    }
  }

  const clearNoteTextField = () => {
    props.onClick()
    setNoteText(props.noteText)
  }

  return (
    <Paper elevation={0}>
      <Grid container spacing={0} justify="center">
        <Grid container item xs={11}
          className={classes.marginBotom}
        >
          <Grid item xs={9}>
            <Typography variant="h3"
              className={classes.marginBotom}
            >{props.title}</Typography>
          </Grid>
          <Grid item xs={2}>
            <CancelButton
              onClick={onCancel}
            />

          </Grid>
          <TextField
            fullWidth={true}
            label="Note"
            multiline
            rows={4}
            variant="outlined"
            onChange={handleNoteTextChange}
            value={noteText}
            error={noteInputProps.error}
            onBlur={() => { triggerNoteInputValidation(noteText) }}
          />
          <span className="validation_error">
            {noteInputProps.errorMessage}
          </span>
          <Button
            onClick={handleNoteModalSubmit}
            variant="contained"
            color="primary"
            fullWidth={true}
            className={classes.marginTop}
          >
            {props.buttonText}</Button>

          {confirmDialog &&
            <ConfirmDialog
              title={"Clear note?"}
              open={confirmDialog}
              setOpen={setConfirmDialog}
              onConfirm={() => { clearNoteTextField() }}
            >
              {"You are about to clear note text, any unsaved changes will be lost. Proceed?"}
            </ConfirmDialog>
          }
        </Grid >
      </Grid>
    </Paper>
  )
}