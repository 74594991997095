import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles({
  modalContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50vw",
    background: "#ffffff",
    border: "2px solid #000",
    p: 4,
    padding: "1rem",
  },
  guideBlock: {
    marginBottom: "1rem",
  },
  guideParagraph: {
    marginBottom: "0.375rem",
  },
});

export const QbGuide = () => {
  const classes = useStyles();

  return (
    <Box className={classes.modalContainer}>
      <div className={classes.guideBlock}>
        <Typography variant="h6">Quickbooks Guide</Typography>
        <Typography variant="body1">
          A guide on exporting Invoices to QuickBooks Desktop
        </Typography>
      </div>
      <div className={classes.guideBlock}>
        <Typography variant="body1" className={classes.guideParagraph}>
          1. Open your QuickBooks Desktop and open your company file, type in
          your company file password and username as needed
        </Typography>
        <Typography variant="body1" className={classes.guideParagraph}>
          2. Open QuickBooks Web Connector and click "Add an Application" button
          in the lower right
        </Typography>
        <Typography variant="body1" className={classes.guideParagraph}>
          3. Find and select your QuickBooks Web Connector(*.qwc) file supplied
          to you by the Developers. Type in the password in the box as needed,
          supplied to you by the Developers.
        </Typography>
        <Typography variant="body1" className={classes.guideParagraph}>
          4. You will be prompted by QuickBooks with a dialogue saying "An
          application is requesting access to the following QuickBooks company
          file...", select the 3rd option: "Yes, whenever this QuickBooks
          company file is open". This will grant the Web Connector access to
          your QuickBooks company file so that Invoices can be imported to it.
          Press Continue, and then press Done in the new pop-up window. The Web
          Connector now has the appropriate access.
        </Typography>
        <Typography variant="body1" className={classes.guideParagraph}>
          5. From the Invoice list, click the "Export Invoice to Qb Desktop"
          icon button. Make sure that your Invoice has the appropriate data,
          that the Customer you selected has the same name in Brines application
          and in your QuickBooks Desktop. Make sure you have at least one labor
          or part row selected in the Invoice as well, otherwise exporting the
          Invoice will fail.
        </Typography>
        <Typography variant="body1" className={classes.guideParagraph}>
          6. Wait up to one minute for exporting the Invoice to QuickBooks
          Desktop. Otherwise, you can open the WebConnector, select the
          Application you added by ticking the checkbox and then click "Update
          Selected" button in the upper left.
        </Typography>
        <Typography variant="body1" className={classes.guideParagraph}>
          7. For troubleshooting refer to the error messages displayed under
          QbRequest queue table in Brines application. The developers may also
          request that you send your log. The log can be accessed through Web
          Connector by selecting your Application and clicking "View Log" in the
          upper right.
        </Typography>
      </div>
    </Box>
  );
};
